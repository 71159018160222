import {combineReducers} from "redux"
import {authReducer} from "./auth/authStore";
import {bookClubReducer} from "./bookClub/bookClubStore";
import {bookshelfReducer} from "./bookshelf/bookshelfStore";
import {essayReducer} from "./essay/essayStore";
import {scrapReducer} from "./scrap/scrapStore";
import {adminReducer} from "./admin/adminStore";
import {homeReducer} from "./home/homeStore";



const reducers = combineReducers({
  auth: authReducer,
  home: homeReducer,
  bookClub: bookClubReducer,
  bookshelf: bookshelfReducer,

  essay: essayReducer,
  scrap: scrapReducer,

  admin: adminReducer,
});


const rootReducer = (state, action) => {
  console.log(action.type);
  return reducers(state, action);
};

export default rootReducer;