import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import Colors from "../../constants/Colors";
import {Container, Segment, Grid, Image} from 'semantic-ui-react';
import Analytics from "../../middleware/Analytics";

class AboutLibraryContainer extends Component {

  componentDidMount() {
    Analytics.pageViewAll('/about/library');
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <Container fluid style={{
          minHeight: '30rem',
          backgroundColor: Colors.backgroundColor,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Image src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Flibrary_icon.png?alt=media'} size={'small'}/>
        </Container>
        <Grid stackable>

          <Grid.Row verticalAlign={'middle'}>
            <Grid.Column style={{
              minHeight: '70vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                서재.
              </div>
              <div className={'subHeaderText'}>
                누구에게나 멋진
              </div>
              <div className={'mainDescriptionText'}>
                언제. 어디서나.<br/>
                영감을 잊지 않도록.<br/>
              </div>
              <p className={'detailDescriptionText'}>
                우리는 멋진 서재가 필요합니다.<br/>
                읽었던 책과 읽을 책들.<br/>
                기억하고 싶은 생각과 영감.<br/>
                메모를 모두 리더스 서재에 보관하세요.
              </p>
              <div className={'mainHeaderText'} style={{fontWeight: '100'}}>
                나만을 위한 <span style={{textDecoration: 'wavy underline #69FF46', fontWeight: 'bold'}}>영감과 생각을</span> 이어갈 수 있도록.
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row
            columns={3}
            verticalAlign={'middle'}>
            <Grid.Column style={{
              minHeight: '40vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                책과
              </div>
              <div className={'subHeaderText'}>
                읽었던, 읽고 싶은 책 모두
              </div>
              <Image
                centered
                size={'medium'}
                src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Flibrary_1.png?alt=media'}
              />
            </Grid.Column>
            <Grid.Column style={{
              minHeight: '40vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                영감과
              </div>
              <div className={'subHeaderText'}>
                떠오르는 생각을 즉시
              </div>
              <Image
                centered
                size={'medium'}
                src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Flibrary_2.png?alt=media'}
              />
            </Grid.Column>
            <Grid.Column style={{
              minHeight: '40vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                나의 생각을.
              </div>
              <div className={'subHeaderText'}>
                나만의 언어로.
              </div>
              <Image
                centered
                size={'medium'}
                src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Flibrary_3.png?alt=media'}
              />
            </Grid.Column>
          </Grid.Row>


          <Grid.Row columns={2} verticalAlign={'middle'}>
            <Grid.Column style={{
              // minHeight: '70vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <Image
                className={'curationImage'}
                src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Flibrary_4.png?alt=media'}
              />
            </Grid.Column>
            <Grid.Column style={{
              minHeight: '70vh',
              // textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                책장.
              </div>
              <div className={'subHeaderText'}>
                생각의 연결
              </div>
              <div className={'mainDescriptionText'}>
                생각이 생각의<br/>
                꼬리를 물고.
              </div>
              <p className={'detailDescriptionText'}>
                수 많은 책들에 둘러쌓여 본 적이 있나요?<br/>
                책 한권으로도 충분히 멋지지만<br/>
                함께 놓여 있을 때,<br/>
                또 다른 영감을 받을 수 있습니다. <br/>
              </p>
              <div className={'mainHeaderText'} style={{fontWeight: '100'}}>
                모든 지식과 영감은 <span style={{textDecoration: 'wavy underline #69FF46', fontWeight: 'bold'}}>다른 생각과 연결</span>되어 있어요.
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={2} verticalAlign={'middle'}>
            <Grid.Column style={{
              // minHeight: '70vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <Image
                className={'curationImage'}
                src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Flibrary_5.png?alt=media'}
              />
            </Grid.Column>
            <Grid.Column style={{
              minHeight: '70vh',
              // textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                스크랩.
              </div>
              <div className={'subHeaderText'}>
                절대 잊혀지지 않는 순간의 영감
              </div>
              <div className={'mainDescriptionText'}>
                잊혀질만 하면<br/>
                알려주고, <br/>
                또 알려주고.
              </div>
              <p className={'detailDescriptionText'}>
                <span style={{textDecoration: 'wavy underline #69FF46', fontWeight: 'bold', color:Colors.mainColor}}>가장 똑똑한 리더스 카메라</span>로 남겨 놓은<br/>
                나만의 영감 조각들. <br/>
                보배로 만들기 위한 구슬 조각들을 <br/>
                한곳에 모아서
              </p>
              <div className={'mainHeaderText'} style={{fontWeight: '100'}}>
                <span style={{textDecoration: 'wavy underline #69FF46', fontWeight: '900'}}>구슬이 있어야 </span>꿰어서 보배를 만들죠.
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={2} verticalAlign={'middle'}>
            <Grid.Column style={{
              // minHeight: '70vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <Image
                className={'curationImage'}
                src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Flibrary_6.png?alt=media'}
              />
            </Grid.Column>
            <Grid.Column style={{
              minHeight: '70vh',
              // textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                에세이.
              </div>
              <div className={'subHeaderText'}>
                나만의 목소리로, 나만의 생각을.
              </div>
              <div className={'mainDescriptionText'}>
                자, 이제.<br/>
                보배로 꿰어 볼 시간.
              </div>
              <p className={'detailDescriptionText'}>
                거창하지 않아도 돼요.<br/>
                내가 무엇을 느끼는지<br/>
                어떻게 생각하는 지, <br/>
                영감의 구슬을 엮어서, 보배같은 나만의 생- 각- 으로. <br/>
              </p>
              <div className={'mainHeaderText'} style={{fontWeight: '100'}}>
                결국 가장 중요한 건 <span style={{textDecoration: 'wavy underline #69FF46', fontWeight: 'bold'}}>나의 생각</span>이니까요.
              </div>
              <p className={'detailDescriptionText'}>
                아! 그거 알죠?<br/>
                안 적으면, 보배같은 생각은 신기루처럼 사라져요.
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            verticalAlign={'middle'}>
            <Grid.Column style={{
              minHeight: '70vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                어때요?
              </div>
              <div className={'mainHeaderText'} style={{fontWeight: '100'}}>
                충분히 <span style={{textDecoration: 'wavy underline #69FF46', fontWeight: 'bold'}}>멋진 서재</span>.
              </div>
              <div style={{display: 'flex', marginTop: '3rem',}}>
                <a className={'roundButton'} href={'https://readersrn.page.link/fromhomepage'} target={'_blank'}>
                  앱 다운로드
                </a>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(AboutLibraryContainer);