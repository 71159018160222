import React, { Component } from "react";
import { connect } from "react-redux";
import { authActionCreators } from "../../redux/auth/authStore";
import {
  Button,
  Container,
  Header,
  Form,
  Checkbox,
  Image,
} from "semantic-ui-react";
import { db } from "../../constants/firebase";
import moment from "moment";
import { firebase } from "../../constants/firebase";
import FileUploader from "react-firebase-file-uploader";

class AdminBannerDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: props.location.state ? props.location.state.banner : {},
      isUploading: false,
      progress: 0,
      imageUrl: ""
    };
  }

  componentDidMount() {
    console.log(this.state.banner)
    console.log(this.state.banner);
  }

  handleChange = e => {
    const { banner } = this.state;
    const { name, value } = e.target;
    banner[name] = value;
    this.setState({
      banner
    });
  };

  handleSubmit = async () => {
    const confirm = window.confirm("수정하시겠습니까?");
    if (!confirm) return;

    const { banner } = this.state;
    await db
      .collection("banners")
      .doc(banner.id)
      .update(banner)
      .then(() => {
        this.props.history.goBack();
      })
      .catch(e => {
        console.log(e);
      });
  };

  // 배너 사진 업로드
  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = async filename => {
    console.log(filename);
    this.setState({ progress: 100, isUploading: false });
    await firebase.storage()
      .ref("banner/")
      .child(filename)
      .getDownloadURL()
      .then(imageUrl => this.setState({ imageUrl }));
  };

  render() {
    const { banner } = this.state;
    return (
      <Container style={{ marginTop: "50px" }}>
        <Header> {banner.header} 배너 정보 </Header>
        <Form>
          <Checkbox
            toggle
            label={banner.isPublic ? "공개" : "비공개"}
            checked={banner.isPublic}
            onClick={() => {
              banner.isPublic = !banner.isPublic;
              this.setState({
                banner
              });
            }}
          />

          <Form.Input
            fluid
            label="헤더"
            name="header"
            value={banner.header}
            onChange={this.handleChange}
          />

          <Form.Input
            fluid
            label="서브헤더"
            name="subHeader"
            value={banner.subHeader}
            onChange={this.handleChange}
          />

          <Form.Input
            fluid
            label="생성일"
            name="createdAt.seconds"
            value={moment(banner.createdAt.seconds * 1000).format(
              "YYYY. MM. DD"
            )}
            onChange={this.handleChange}
          />

          <Form.Input
            fluid
            label="ID"
            name="id"
            value={banner.id}
            onChange={this.handleChange}
          />

          {banner.tag &&
            banner.tag.map((tag, i) => {
              return (
                <Form.Input
                  key={i}
                  fluid
                  label={`TAG ${i}`}
                  name="tag"
                  value={tag}
                  onChange={e => {
                    banner.tag[i] = e.target.value;
                    this.setState({
                      banner
                    });
                  }}
                />
              );
            })}

          <Button
            onClick={() => {
              if (banner.tag && banner.tag.length > 0) {
                banner.tag.push("");
              } else {
                banner.tag = [""];
              }
              this.setState({
                banner
              });
            }}
          >
            태그 추가
          </Button>

          <Form.Input
            fluid
            label="TYPE"
            name="type"
            value={banner.type}
            onChange={this.handleChange}
          />

          <Header as="h5">배너이미지</Header>
          {this.state.imageUrl !== "" ? (
            <Image src={this.state.imageUrl} />
          ) : (
            <Image src={banner.imageUrl} size="medium" />
          )}

          <label
            style={{
              backgroundColor: "#E0E1E2",
              color: "black",
              fontWeight: "bold",
              padding: 10,
              borderRadius: 4,
              cursor: "pointer",
              display: "block",
              width: 150,
              textAlign: "center",
              marginBottom: 20
            }}
          >
            배너 사진 업로드
            <FileUploader
              hidden
              accept="image/*"
              name="avatar"
              storageRef={firebase.storage().ref("banner/")}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={this.handleUploadSuccess}
              onProgress={this.handleProgress}
            />
          </label>
          <Button color="black" onClick={this.handleSubmit} type="submit">
              완료
            </Button>
        
        </Form>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    auth: state.auth
  }),
  {
    ...authActionCreators
  }
);
export default enhance(AdminBannerDetailContainer);
