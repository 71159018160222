import authLogic from "./auth/authLogic";
import bookClubLogic from "./bookClub/bookClubLogic";
import bookshelfLogics from "./bookshelf/bookshelfLogics";
import essayLogics from "./essay/essayLogics";
import scrapLogics from "./scrap/scrapLogics";
import homeLogic from "./home/homeLogic";
import adminLogic from "./admin/adminLogic";



export default [
  ...homeLogic,
  ...authLogic,
  ...bookClubLogic,

  ...bookshelfLogics,
  ...essayLogics,
  ...scrapLogics,

  ...adminLogic,
]