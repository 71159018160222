import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {
  Segment,
  Card,
  Header,
  Table,
  Grid,
  Label,
  Feed,
  Statistic,
  Radio,
  Image,
  Container,
  Button,
  Loader
} from 'semantic-ui-react'
import Network from "../../library/Network";
import {bookClubActionCreators} from "../../redux/bookClub/bookClubStore";
import AdminUserComponent from '../../components/user/AdminUserComponent';
import UserSMSComponent from '../../components/user/UserSMSComponent';
import UserPushComponent from '../../components/user/UserPushComponent';
import moment from 'moment';
import {auth, db, firebase, firebaseDB} from "../../constants/firebase";
import BookClubDepositRefundButton from "../../components/bookClubManage/BookClubDepositRefundButton";
import {adminActionCreators} from "../../redux/admin/adminStore";
import BookClubPushNotificationComponent from '../../components/bookClub/BookClubPushNotificationComponent'
import _ from 'lodash';
import {
  TRANSACTION_TYPE_REGISTER,
  TRANSACTION_TYPE_REGISTER_CANCEL,
  TRANSACTION_TYPE_RETURN_DEPOSIT
} from "../../dataStructure/transaction";
import numeral from 'numeral';
import AdminEssayButtonComponent from "../../components/essay/AdminEssayButtonComponent";
import {Link} from 'react-router-dom';

class AdminBookClubManageMainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookClubId: this.props.match.params.bookClubId,
      bookClub: {},
      bookClubEssays: [],
      bookClubEssaySchedules: [],
      bookClubApplies: [],
      transactions: [],
    }
  }


  componentDidMount() {
    this._loadBookClub();
    this._loadBookClubEssays();
    this._loadBookClubEssaySchedules();
    this._loadBookClubApplies();
    this._loadTransactions();
  }

  _loadBookClubEssaySchedules = async () => {
    const {bookClubId} = this.props.match.params;
    const bookClubEssaySchedules = await Network.getBookClubEssaySchedulesByBookClubId(bookClubId);
    if (bookClubEssaySchedules.err) return window.alert('오류');
    this.setState({
      bookClubEssaySchedules
    })
  };

  _loadBookClubEssays = async () => {
    const {bookClubId} = this.props.match.params;
    const bookClubEssays = await Network.getEssaysByBookClubId(bookClubId);
    if (bookClubEssays.err) return window.alert('에세이 로드 오류');
    this.setState({
      bookClubEssays: _.orderBy(bookClubEssays, ['createdAt'], ['asc'])
    })
  };

  _loadBookClub = async () => {
    const bookClub = await Network.getBookClubDataById(this.state.bookClubId);
    if (bookClub.err) return;
    this.setState({
      bookClub,
      isLoaded: true,
    })
  };

  _loadBookClubApplies = async () => {
    const {bookClubId} = this.props.match.params;
    const bookClubApplies = await Network.getBookClubAppliesByBookClubId(bookClubId);
    if (bookClubApplies.err) return;
    this.setState({
      bookClubApplies: _.orderBy(_.filter(bookClubApplies, bca => !bca.isDeleted), ['isDenied', 'paymentCompleted', 'paymentConfirmedByAdmin', 'createdAt.seconds'], ['asc', 'desc', 'desc', 'asc'])
    })
  };

  _loadTransactions = async () => {
    const {bookClubId} = this.props.match.params;
    const transactions = await Network.getTransactionsByBookClubId(bookClubId);
    if (transactions.err) return;
    this.setState({
      transactions,
    })
  };

  _handleUpdateApply = async (bookClubApply) => {
    const a = window.confirm('반영하시겠습니까?');
    if (!a) return;

    const {bookClubId} = this.props.match.params;
    const userId = bookClubApply.userId;
    const user = await Network.getUserInfoByUserId(userId);
    if (user.err) return alert('오류');

    const batch = db.batch();
    const bookClubRef = firebaseDB.bookClubs.doc(bookClubId);

    let bookClubUpdate;
    let bookClubApplyUpdate = bookClubApply;

    if (bookClubApply.isDenied) {
      // this._handleToDeny(bookClubApply);

      bookClubUpdate = {
        participantIds: firebase.firestore.FieldValue.arrayRemove(userId),
        participantEmails: firebase.firestore.FieldValue.arrayRemove(user.email),

        applicantIds: firebase.firestore.FieldValue.arrayRemove(userId),
        applicantEmails: firebase.firestore.FieldValue.arrayRemove(user.email),

        deniedIds: firebase.firestore.FieldValue.arrayUnion(userId),
        deniedEmails: firebase.firestore.FieldValue.arrayUnion(user.email),

      };

      bookClubApply.userConfirmed = false;

    } else if (bookClubApply.isApproved) {
      // this._handleToApprove(bookClubApply);

      bookClubUpdate = {
        participantIds: firebase.firestore.FieldValue.arrayUnion(userId),
        participantEmails: firebase.firestore.FieldValue.arrayUnion(user.email),

        applicantIds: firebase.firestore.FieldValue.arrayRemove(userId),
        applicantEmails: firebase.firestore.FieldValue.arrayRemove(user.email),

        deniedIds: firebase.firestore.FieldValue.arrayRemove(userId),
        deniedEmails: firebase.firestore.FieldValue.arrayRemove(user.email),
      };

    } else {
      // this._handleToCancelAppove(bookClubApply)

      bookClubUpdate = {
        participantIds: firebase.firestore.FieldValue.arrayRemove(userId),
        participantEmails: firebase.firestore.FieldValue.arrayRemove(user.email),

        applicantIds: firebase.firestore.FieldValue.arrayUnion(userId),
        applicantEmails: firebase.firestore.FieldValue.arrayUnion(user.email),

        deniedIds: firebase.firestore.FieldValue.arrayRemove(userId),
        deniedEmails: firebase.firestore.FieldValue.arrayRemove(user.email),
      };
      bookClubApply.userConfirmed = false;
    }


    const bookClubApplyRef = firebaseDB.bookClubApplies.doc(userId + '-' + bookClubId);

    batch.update(bookClubRef, bookClubUpdate);
    batch.update(bookClubApplyRef, bookClubApplyUpdate);

    batch
      .commit()
      .then(() => {
        window.alert('반영 완료');
        this._loadBookClubApplies();
      }).catch(e => {
      console.log(e);
      window.alert('에러');
    });

  };

  _handleDeleteApply = async (bookClubApply) => {
    const {bookClubId} = this.props.match.params;
    const userId = bookClubApply.userId;
    const user = await Network.getUserInfoByUserId(userId);

    const batch = db.batch();

    const bookClubRef = firebaseDB.bookClubs.doc(bookClubId);
    const bookClubUpdate = {
      participantIds: firebase.firestore.FieldValue.arrayRemove(userId),
      participantEmails: firebase.firestore.FieldValue.arrayRemove(user.email),

      applicantIds: firebase.firestore.FieldValue.arrayRemove(userId),
      applicantEmails: firebase.firestore.FieldValue.arrayRemove(user.email),

      deniedIds: firebase.firestore.FieldValue.arrayRemove(userId),
      deniedEmails: firebase.firestore.FieldValue.arrayRemove(user.email),
    };
    bookClubApply.userConfirmed = false;
    const bookClubApplyRef = firebaseDB.bookClubApplies.doc(userId + '-' + bookClubId);
    const bookClubApplyUpdate = null;



    batch.update(bookClubRef, bookClubUpdate);
    batch.update(bookClubApplyRef, bookClubApplyUpdate);

  };



  render() {
    const {
      isLoaded,
      bookClub,
      bookClubEssays,
      bookClubEssaySchedules,
      bookClubApplies,
      transactions,
    } = this.state;

    const {users} = this.props.admin;

    if (!isLoaded) return <Loader active/>;

    const totalAmountPaymentNeed = _.sumBy(bookClubApplies, o => o.amountPaymentNeeded);
    const totalAmountCashUsed = -_.sumBy(_.filter(transactions, tr => tr.transactionType === TRANSACTION_TYPE_REGISTER), o => o.cash) - _.sumBy(_.filter(transactions, tr => tr.transactionType === TRANSACTION_TYPE_REGISTER_CANCEL), o => o.cash)

    return (
      <div>
        {/* */}
        <Container>
          <Header content={'매출 통계'}/>
          <Grid>
            <Grid.Row columns={3}>
              <Grid.Column>
                <Table size={'small'} compact={'very'} celled>
                  <Table.Header>
                    <Table.Row>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        입금된 금액
                      </Table.Cell>
                      <Table.Cell>
                        {numeral(totalAmountPaymentNeed).format('0,0')}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        캐시사용 액
                      </Table.Cell>
                      <Table.Cell>
                        {numeral(totalAmountCashUsed).format('0,0')}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        Total flow in
                      </Table.Cell>
                      <Table.Cell>
                        {numeral(totalAmountPaymentNeed + totalAmountCashUsed).format('0,0')}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

              </Grid.Column>
              <Grid.Column>
                <Table size={'small'} compact={'very'} celled>
                  <Table.Header>
                    <Table.Row>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>기본 회비</Table.Cell>
                      <Table.Cell>
                        {numeral(_.sumBy(bookClubApplies, o => o.defaultMembership)).format('0,0')}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        할인 금액
                      </Table.Cell>
                      <Table.Cell>
                        {numeral(_.sumBy(_.filter(transactions, tr => tr.transactionType === TRANSACTION_TYPE_REGISTER), o => o.discountAmount)).format('0,0')}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        기본 회비 매출
                      </Table.Cell>
                      <Table.Cell>
                        {numeral(_.sumBy(bookClubApplies, o => o.defaultMembership) - _.sumBy(_.filter(transactions, tr => tr.transactionType === TRANSACTION_TYPE_REGISTER), o => o.discountAmount)).format('0,0')}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column>
                <Table size={'small'} compact={'very'} celled>
                  <Table.Header>
                    <Table.Row>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>

                    <Table.Row>
                      <Table.Cell>
                        돌려준 디파짓
                      </Table.Cell>
                      <Table.Cell>
                        {numeral(_.sumBy(_.filter(transactions, tr => tr.transactionType === TRANSACTION_TYPE_RETURN_DEPOSIT), o => o.cash)).format('0,0')}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        남은 디파짓
                      </Table.Cell>
                      <Table.Cell>
                        {numeral(_.sumBy(bookClubApplies, o => o.numberOfEssays * o.depositPerEssay) - _.sumBy(_.filter(transactions, tr => tr.transactionType === TRANSACTION_TYPE_RETURN_DEPOSIT), o => o.cash)).format('0,0')}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        디파짓 총액
                      </Table.Cell>
                      <Table.Cell>
                        {numeral(_.sumBy(bookClubApplies, o => o.numberOfEssays * o.depositPerEssay)).format('0,0')}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <Container as={Segment} style={{}}>
          <Grid stackable>
            <Grid.Column width={4}>
              <Card>
                <Image src={bookClub.coverImageUrl}/>
                <Card.Content>
                  <Card.Header>
                    {bookClub.header && bookClub.header.content}
                  </Card.Header>
                  <Card.Meta>
                    {bookClub.subHeader && bookClub.subHeader.content}
                  </Card.Meta>
                  <Card.Description>
                    {bookClub.descriptionNew && bookClub.descriptionNew.content}
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column width={12}>
              <BookClubPushNotificationComponent bookClubData={bookClub}/>
              <Button content={'스크랩 보기'} as={Link} to={`/admin/bookclub/manage/scrap/${bookClub.id}`}/>
            </Grid.Column>
          </Grid>
        </Container>

        <Container as={Segment} style={{}}>
          <Header content={'bookClub 데이터에 있는 내용'}/>
          <Grid>
            <Grid.Row columns={4}>

              <Grid.Column>
                <Header content={`참가자${bookClub.participantIds && bookClub.participantIds.length}`}/>
                <Table celled size={'small'} style={{fontSize: '0.8rem'}}>
                  <Table.Body>
                    {
                      bookClub.participantIds.map((userId, i) => {
                        const user = users.find(us => userId === us.id);
                        if (!user) return null;
                        return (
                          <Table.Row key={i}>
                            <Table.Cell>
                              <Image avatar src={user.photoURL}/>
                              {user.displayName}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })
                    }
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column>
                <Header content={`참가 신청${bookClub.applicantIds && bookClub.applicantIds.length}`}/>
                <Table celled size={'small'} style={{fontSize: '0.8rem'}}>
                  <Table.Body>
                    {
                      bookClub.applicantIds.map((userId, i) => {
                        const user = users.find(us => userId === us.id);
                        if (!user) return null;
                        return (
                          <Table.Row key={i}>
                            <Table.Cell>
                              <Image avatar src={user.photoURL}/>
                              {user.displayName}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })
                    }
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column>
                <Header content={`알림 유저${bookClub.alarmUserIds && bookClub.alarmUserIds.length}`}/>
                <Table celled size={'small'} style={{fontSize: '0.8rem'}}>
                  <Table.Body>
                    {
                      bookClub.alarmUserIds && bookClub.alarmUserIds.map((userId, i) => {
                        const user = users.find(us => userId === us.id);
                        if (!user) return null;
                        return (
                          <Table.Row key={i}>
                            <Table.Cell>
                              <Image avatar src={user.photoURL}/>
                              {user.displayName}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })
                    }
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column>
                <Header content={`거부${bookClub.deniedIds && bookClub.deniedIds.length}`}/>
                <Table celled size={'small'} style={{fontSize: '0.8rem'}}>
                  <Table.Body>
                    {
                      bookClub.deniedIds.map((userId, i) => {
                        const user = users.find(us => userId === us.id);
                        if (!user) return null;
                        return (
                          <Table.Row key={i}>
                            <Table.Cell>
                              <Image avatar src={user.photoURL}/>
                              {user.displayName}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })
                    }
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Container>

        <Container as={Segment} style={{}}>
          <Table celled size={'small'} style={{fontSize: '0.8rem'}}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}>No.</Table.HeaderCell>
                <Table.HeaderCell width={2}>d.N</Table.HeaderCell>
                <Table.HeaderCell width={2}>본명</Table.HeaderCell>
                <Table.HeaderCell width={2}>전화번호</Table.HeaderCell>
                <Table.HeaderCell width={2}>문자/푸시</Table.HeaderCell>
                <Table.HeaderCell width={1}>Denied</Table.HeaderCell>
                <Table.HeaderCell width={1} style={{fontSize: '0.5rem'}}>
                  payment Completed
                </Table.HeaderCell>
                <Table.HeaderCell width={1} style={{fontSize: '0.5rem'}}>
                  payment Confirmed ByAdmin
                </Table.HeaderCell>
                <Table.HeaderCell width={1} style={{fontSize: '0.5rem'}}>
                  isApproved
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>-</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                bookClubApplies.map((bookClubApply, i) => {
                  const user = users.find(us => bookClubApply.userId === us.id);
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>
                        {i + 1}
                      </Table.Cell>
                      <Table.Cell>
                        {
                          user && (
                            <Fragment>
                              <AdminUserComponent user={user}/>
                            </Fragment>
                          )
                        }
                      </Table.Cell>
                      <Table.Cell>
                        {
                          user && (
                            <Fragment>
                              {user.detailProfile && user.detailProfile.name}
                            </Fragment>
                          )
                        }
                      </Table.Cell>
                      <Table.Cell>
                        {
                          user && (
                            <Fragment>
                              {user.detailProfile && user.detailProfile.phoneNumber}
                            </Fragment>
                          )
                        }
                      </Table.Cell>
                      <Table.Cell>
                        <UserPushComponent user={user} bookClubData={bookClub}/>
                        <UserSMSComponent user={user} bookClubData={bookClub}/>
                        <br/>
                        {user && user.token && (
                          <span>{user.token.length}</span>
                        )}
                      </Table.Cell>

                      <Table.Cell>
                        <Radio
                          // label={`${bookClubApply.isDenied ? '거부됨' : ''}`}
                          toggle
                          name={`isDenied-${bookClubApply.id}`}
                          value='right'
                          checked={bookClubApply.isDenied}
                          onChange={e => {
                            bookClubApplies[i].isDenied = !bookClubApply.isDenied;
                            this.setState({bookClubApplies})
                          }}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Radio
                          // label={`${bookClubApply.paymentCompleted ? '입금 완료' : '입금 전'}`}
                          style={{marginBottom: '0.3rem'}}
                          toggle
                          name={`paymentCompleted-${bookClubApply.id}`}
                          value='right'
                          checked={bookClubApply.paymentCompleted}
                          onChange={e => {
                            bookClubApplies[i].paymentCompleted = !bookClubApply.paymentCompleted;
                            this.setState({
                              bookClubApplies
                            })
                          }}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Radio
                          // label={`${bookClubApply.paymentConfirmedByAdmin ? '입금 확인' : '확인 전'}`}
                          style={{marginBottom: '0.3rem'}}
                          toggle
                          name={`paymentConfirmedByAdmin-${bookClubApply.id}`}
                          value='right'
                          checked={bookClubApply.paymentConfirmedByAdmin}
                          onChange={e => {
                            bookClubApplies[i].paymentConfirmedByAdmin = !bookClubApply.paymentConfirmedByAdmin;
                            this.setState({
                              bookClubApplies
                            })
                          }}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Radio
                          // label={`${bookClubApply.isApproved ? '승인 완료' : '승인 대기'}`}
                          style={{marginBottom: '0.3rem'}}
                          toggle
                          name={`isApproved-${bookClubApply.id}`}
                          value='right'
                          checked={bookClubApply.isApproved}
                          onChange={e => {
                            bookClubApplies[i].isApproved = !bookClubApply.isApproved;
                            this.setState({
                              bookClubApplies
                            })
                          }}
                        />

                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          size={'mini'}
                          compact
                          content={'반영'}
                          onClick={() => {
                            this._handleUpdateApply(bookClubApply)
                          }}
                        />
                        <Button
                          size={'mini'}
                          compact
                          negative
                          content={'삭제'}
                          onClick={() => {
                            const a = window.confirm('삭제하시겠습니까?');
                            if (!a) return;
                            const b = window.confirm('중요하여 한번 더 물어봅니다. 삭제하시겠습니까?');
                            if (!b) return;
                            this._handleDeleteApply(bookClubApply);
                          }}
                        />
                      </Table.Cell>

                    </Table.Row>
                  )
                })
              }
            </Table.Body>
          </Table>
        </Container>

        <Container as={Segment} style={{}}>
          <Header content={'EssaySchedule'}/>
          <Table celled size={'small'} style={{fontSize: '0.8rem'}}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>

                </Table.HeaderCell>
                <Table.HeaderCell>

                </Table.HeaderCell>
                {
                  bookClubEssaySchedules.map((book, i) => {
                    return (
                      <Table.HeaderCell key={i}>
                        <Image src={book.cover} size={'mini'}/>
                        {moment(book.startDate && book.startDate.seconds * 1000).format('YYYY.MM.DD')}
                        <br/>
                        {moment(book.endDate && book.endDate.seconds * 1000).format('YYYY.MM.DD')}
                      </Table.HeaderCell>
                    )
                  })
                }
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                _.filter(bookClubApplies, bca => bca.isApproved).map((bcApply, i) => {
                  const user = users.find(us => bcApply.userId === us.id);

                  return (
                    <Table.Row key={i}>
                      <Table.Cell>
                        {i + 1}
                      </Table.Cell>
                      <Table.Cell>
                        {
                          user && (
                            <Fragment>
                              <Image src={user.photoURL} avatar/>
                              {user.displayName}
                            </Fragment>
                          )
                        }
                      </Table.Cell>
                      {
                        bookClubEssaySchedules.map((essaySchedule, j) => {
                          const essay = bookClubEssays.find(essay => (essay.userId === bcApply.userId) && (essaySchedule.id === essay.bookClubEssayScheduleId));
                          return (
                            <Table.Cell key={j}>
                              {
                                essay ? (
                                  <Fragment>
                                    <AdminEssayButtonComponent essay={essay} user={user}/>
                                    <BookClubDepositRefundButton
                                      bookClubEssaySchedule={essaySchedule}
                                      essay={essay}
                                      bookClub={bookClub}
                                      bookClubApply={bcApply}
                                    />
                                    <p>
                                      {moment(essay.createdAt.seconds * 1000).format('MM.DD(ddd) HH:mm:ss')}
                                    </p>
                                  </Fragment>
                                ) : null
                              }
                            </Table.Cell>
                          )
                        })
                      }
                    </Table.Row>
                  )
                })
              }
            </Table.Body>
          </Table>
        </Container>

        <Container>
          <Header content={'BOOKCLUBAPPLY - '}/>
          <Table size={'small'} compact={'very'} celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>승인됨</Table.HeaderCell>
                <Table.HeaderCell>거부됨</Table.HeaderCell>
                <Table.HeaderCell>입금완료</Table.HeaderCell>
                <Table.HeaderCell>어드민 컨펌</Table.HeaderCell>
                <Table.HeaderCell>에세이당 디파짓</Table.HeaderCell>
                <Table.HeaderCell>에세이수</Table.HeaderCell>
                <Table.HeaderCell>기본회비</Table.HeaderCell>
                <Table.HeaderCell>디파짓</Table.HeaderCell>
                <Table.HeaderCell>결제금액</Table.HeaderCell>
                <Table.HeaderCell>돌려준 디파짓</Table.HeaderCell>
                <Table.HeaderCell>남은 디파짓</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                bookClubApplies.map((bca, i) => {
                  const user = users.find(us => bca.userId === us.id);
                  const sumOfDeposit = bca.numberOfEssays * bca.depositPerEssay;
                  const sumOfReturnedDeposit = _.sumBy(_.filter(transactions, tr => (tr.transactionType === TRANSACTION_TYPE_RETURN_DEPOSIT) && (tr.userId === bca.userId)), tr => tr.cash);
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>{moment(bca.createdAt.seconds * 1000).format('YYYY.MM.DD(ddd) HH:mm')}</Table.Cell>
                      <Table.Cell><AdminUserComponent user={user}/></Table.Cell>
                      <Table.Cell>{bca.isApproved ? '승인' : '-'}</Table.Cell>
                      <Table.Cell>{bca.isDenied}</Table.Cell>
                      <Table.Cell>{bca.paymentCompleted ? '입금완료' : '-'}</Table.Cell>
                      <Table.Cell>{bca.paymentConfirmedByAdmin ? '입금확인' : '-'}</Table.Cell>
                      <Table.Cell textAlign={'right'}>{numeral(bca.depositPerEssay).format('0,0')}</Table.Cell>
                      <Table.Cell textAlign={'right'}>{bca.numberOfEssays}</Table.Cell>
                      <Table.Cell textAlign={'right'}>{numeral(bca.defaultMembership).format('0,0')}</Table.Cell>
                      <Table.Cell textAlign={'right'}>
                        {numeral(sumOfDeposit).format('0,0')}
                      </Table.Cell>
                      <Table.Cell textAlign={'right'}>
                        {numeral(bca.amountPaymentNeeded).format('0,0')}
                      </Table.Cell>
                      <Table.Cell textAlign={'right'}>
                        {numeral(sumOfReturnedDeposit).format('0,0')}
                      </Table.Cell>
                      <Table.Cell textAlign={'right'}>
                        {numeral(sumOfDeposit - sumOfReturnedDeposit).format('0,0')}
                      </Table.Cell>
                    </Table.Row>
                  )
                })
              }
              <Table.Row>
                <Table.Cell/>
                <Table.Cell/>
                <Table.Cell/>
                <Table.Cell/>
                <Table.Cell/>
                <Table.Cell/>
                <Table.Cell/>
                <Table.Cell/>
                <Table.Cell textAlign={'right'}>
                  {numeral(_.sumBy(bookClubApplies, o => o.defaultMembership)).format('0,0')}
                </Table.Cell>
                <Table.Cell textAlign={'right'}>
                  {numeral(_.sumBy(bookClubApplies, o => o.numberOfEssays * o.depositPerEssay)).format('0,0')}
                </Table.Cell>
                <Table.Cell textAlign={'right'}>
                  {numeral(_.sumBy(bookClubApplies, o => o.amountPaymentNeeded)).format('0,0')}
                </Table.Cell>
                <Table.Cell textAlign={'right'}>
                  {numeral(_.sumBy(_.filter(transactions, tr => (tr.transactionType === TRANSACTION_TYPE_RETURN_DEPOSIT)), tr => tr.cash)).format('0,0')}
                </Table.Cell>
                <Table.Cell textAlign={'right'}>
                  {numeral(_.sumBy(bookClubApplies, o => o.numberOfEssays * o.depositPerEssay) - _.sumBy(_.filter(transactions, tr => (tr.transactionType === TRANSACTION_TYPE_RETURN_DEPOSIT)), tr => tr.cash)).format('0,0')}
                  <br/>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Header content={'TRANSACTION - REGISTER'}/>
          <Table size={'small'} compact={'very'} celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>시간</Table.HeaderCell>
                <Table.HeaderCell>유저</Table.HeaderCell>
                <Table.HeaderCell>캐시</Table.HeaderCell>
                <Table.HeaderCell>할인금액</Table.HeaderCell>
                <Table.HeaderCell>디파짓</Table.HeaderCell>
                <Table.HeaderCell>결제금액</Table.HeaderCell>
                <Table.HeaderCell>쿠폰사용여부</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                _.filter(_.orderBy(transactions, ['createdAt.seconds'], ['desc']), tr => tr.transactionType === TRANSACTION_TYPE_REGISTER)
                  .map((transaction, i) => {
                    const user = users.find(u => u.id === transaction.userId);
                    const bookClubApply = bookClubApplies.find(bca => bca.id === transaction.bookClubApplyId);
                    return (
                      <Table.Row key={i}>
                        <Table.Cell>{moment(transaction.createdAt.seconds * 1000).format('YYYY.MM.DD ddd HH:mm')}</Table.Cell>
                        <Table.Cell><AdminUserComponent user={user}/></Table.Cell>
                        <Table.Cell textAlign={'right'}>{numeral(transaction.cash).format('0,0')}</Table.Cell>
                        <Table.Cell textAlign={'right'}>{numeral(transaction.discountAmount).format('0,0')}</Table.Cell>
                        <Table.Cell textAlign={'right'}>{numeral(transaction.deposit).format('0,0')}</Table.Cell>
                        <Table.Cell
                          textAlign={'right'}>{numeral(transaction.amountPaymentNeeded).format('0,0')}</Table.Cell>
                        <Table.Cell>{transaction.couponId !== '' ? '쿠폰사용' : ''}</Table.Cell>
                      </Table.Row>
                    )
                  })
              }
              <Table.Row>
                <Table.Cell/>
                <Table.Cell/>
                <Table.Cell textAlign={'right'}>
                  {numeral(_.sumBy(_.filter(transactions, tr => tr.transactionType === TRANSACTION_TYPE_REGISTER), o => o.cash)).format('0,0')}
                </Table.Cell>
                <Table.Cell textAlign={'right'}>
                  {numeral(_.sumBy(_.filter(transactions, tr => tr.transactionType === TRANSACTION_TYPE_REGISTER), o => o.discountAmount)).format('0,0')}
                </Table.Cell>
                <Table.Cell textAlign={'right'}>
                  {numeral(_.sumBy(_.filter(transactions, tr => tr.transactionType === TRANSACTION_TYPE_REGISTER), o => o.deposit)).format('0,0')}
                </Table.Cell>
                <Table.Cell textAlign={'right'}>
                  {numeral(_.sumBy(_.filter(transactions, tr => tr.transactionType === TRANSACTION_TYPE_REGISTER), o => o.amountPaymentNeeded)).format('0,0')}
                </Table.Cell>
                <Table.Cell/>
              </Table.Row>
            </Table.Body>
          </Table>

          <Header content={'TRANSACTION - CANCEL'}/>
          <Table size={'small'} compact={'very'} celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>시간</Table.HeaderCell>
                <Table.HeaderCell>유저</Table.HeaderCell>
                <Table.HeaderCell>캐시</Table.HeaderCell>
                <Table.HeaderCell>할인금액</Table.HeaderCell>
                <Table.HeaderCell>디파짓</Table.HeaderCell>
                <Table.HeaderCell>결제금액</Table.HeaderCell>
                <Table.HeaderCell>쿠폰사용여부</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                _.filter(_.orderBy(transactions, ['createdAt.seconds'], ['desc']), tr => tr.transactionType === TRANSACTION_TYPE_REGISTER_CANCEL)
                  .map((transaction, i) => {
                    const user = users.find(u => u.id === transaction.userId);
                    const bookClubApply = bookClubApplies.find(bca => bca.id === transaction.bookClubApplyId);
                    return (
                      <Table.Row key={i}>
                        <Table.Cell>{moment(transaction.createdAt.seconds * 1000).format('YYYY.MM.DD ddd HH:mm')}</Table.Cell>
                        <Table.Cell><AdminUserComponent user={user}/></Table.Cell>
                        <Table.Cell textAlign={'right'}>{numeral(transaction.cash).format('0,0')}</Table.Cell>
                        <Table.Cell textAlign={'right'}>{numeral(transaction.discountAmount).format('0,0')}</Table.Cell>
                        <Table.Cell textAlign={'right'}>{numeral(transaction.deposit).format('0,0')}</Table.Cell>
                        <Table.Cell
                          textAlign={'right'}>{numeral(transaction.amountPaymentNeeded).format('0,0')}</Table.Cell>
                        <Table.Cell>{transaction.couponId !== '' ? '쿠폰사용' : ''}</Table.Cell>
                      </Table.Row>
                    )
                  })
              }
              <Table.Row>
                <Table.Cell/>
                <Table.Cell/>
                <Table.Cell
                  textAlign={'right'}>{numeral(_.sumBy(_.filter(transactions, tr => tr.transactionType === TRANSACTION_TYPE_REGISTER_CANCEL), o => o.cash)).format('0,0')}</Table.Cell>
                <Table.Cell
                  textAlign={'right'}>{numeral(_.sumBy(_.filter(transactions, tr => tr.transactionType === TRANSACTION_TYPE_REGISTER_CANCEL), o => o.discountAmount)).format('0,0')}</Table.Cell>
                <Table.Cell
                  textAlign={'right'}>{numeral(_.sumBy(_.filter(transactions, tr => tr.transactionType === TRANSACTION_TYPE_REGISTER_CANCEL), o => o.deposit)).format('0,0')}</Table.Cell>
                <Table.Cell
                  textAlign={'right'}>{numeral(_.sumBy(_.filter(transactions, tr => tr.transactionType === TRANSACTION_TYPE_REGISTER_CANCEL), o => o.amountPaymentNeeded)).format('0,0')}</Table.Cell>
                <Table.Cell/>
              </Table.Row>
            </Table.Body>
          </Table>

          <Header content={'TRANSACTION - ETC'}/>
          <Table size={'small'} compact={'very'} celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>날짜</Table.HeaderCell>
                <Table.HeaderCell>유저</Table.HeaderCell>
                <Table.HeaderCell>거래 타입</Table.HeaderCell>
                <Table.HeaderCell>캐시</Table.HeaderCell>
                <Table.HeaderCell>할인금액</Table.HeaderCell>
                <Table.HeaderCell>디파짓</Table.HeaderCell>
                <Table.HeaderCell>결제필요금</Table.HeaderCell>
                <Table.HeaderCell>책</Table.HeaderCell>
                <Table.HeaderCell>쿠폰</Table.HeaderCell>
                <Table.HeaderCell>출금완료</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                _.filter(_.orderBy(transactions, ['createdAt.seconds'], ['desc']), tr => (tr.transactionType !== TRANSACTION_TYPE_RETURN_DEPOSIT) && (tr.transactionType !== TRANSACTION_TYPE_REGISTER))
                  .map((transaction, i) => {
                    const user = users.find(u => u.id === transaction.userId);
                    return (
                      <Table.Row key={i}>
                        <Table.Cell>{moment(transaction.createdAt.seconds * 1000).format('YYYY.MM.DD ddd HH:mm')}</Table.Cell>
                        <Table.Cell>
                          <AdminUserComponent user={user}/>
                        </Table.Cell>
                        <Table.Cell>{transaction.transactionType}</Table.Cell>
                        <Table.Cell textAlign={'right'}>{transaction.cash}</Table.Cell>
                        <Table.Cell textAlign={'right'}>{transaction.discountAmount}</Table.Cell>
                        <Table.Cell textAlign={'right'}>{transaction.deposit}</Table.Cell>
                        <Table.Cell textAlign={'right'}>{transaction.amountPaymentNeeded}</Table.Cell>
                        <Table.Cell>{transaction.bookClubEssayScheduleId}</Table.Cell>
                        <Table.Cell>{transaction.couponId !== '' ? '쿠폰사용' : ''}</Table.Cell>
                        <Table.Cell>{transaction.withDrawCompleted}</Table.Cell>
                      </Table.Row>
                    )
                  })
              }
            </Table.Body>

          </Table>

          <Header content={'TRANSACTION - RETURN DEPOSIT'}/>
          <Table size={'small'} compact={'very'} celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>날짜</Table.HeaderCell>
                <Table.HeaderCell width={2}>책</Table.HeaderCell>
                <Table.HeaderCell width={2}>유저</Table.HeaderCell>
                <Table.HeaderCell width={2}>금액</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                _.filter(_.orderBy(transactions, ['createdAt.seconds'], ['desc']), tr => tr.transactionType === TRANSACTION_TYPE_RETURN_DEPOSIT)
                  .map((transaction, i) => {
                    const user = users.find(u => u.id === transaction.userId);
                    const bookClubEssaySchedule = bookClubEssaySchedules.find(b => b.id === transaction.bookClubEssayScheduleId);
                    return (
                      <Table.Row key={i}>
                        <Table.Cell>{moment(transaction.createdAt.seconds * 1000).format('YYYY.MM.DD ddd HH:mm')}</Table.Cell>
                        <Table.Cell>
                          {bookClubEssaySchedule && (
                            <span>
                              {bookClubEssaySchedule.title}
                            </span>
                          )}
                        </Table.Cell>
                        <Table.Cell><AdminUserComponent user={user}/></Table.Cell>
                        <Table.Cell textAlign={'right'}>{numeral(transaction.cash).format('0,0')}</Table.Cell>

                      </Table.Row>
                    )
                  })
              }
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell textAlign={'right'}>
                  {numeral(_.sumBy(_.filter(transactions, tr => tr.transactionType === TRANSACTION_TYPE_RETURN_DEPOSIT), o => o.cash)).format('0,0')}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Container>
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
    ...adminActionCreators,
    ...bookClubActionCreators,
  }
);


export default enhance(AdminBookClubManageMainContainer);