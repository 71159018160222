import Colors from "../constants/Colors";

export const NEW_TIP = {
  order: 0,

  title: {
    content: '',
    align: 'left',
    color: Colors.mainBlack,
  },
  subTitle: {
    content: '',
    align: 'left',
    color: Colors.mainBlack,
  },
  background: {
    imageUrl: '',
    color: 'grey',
  },
  description: {
    content: '',
    align: 'left',
    color: Colors.mainBlack,
  },
  image: {
    square: '',
    horizontal: '',
    vertical: '',

    squareLarge: '',
    horizontalLarge: '',
    verticalLarge: '',
  },

  imageUrl: '',
  backgroundImage: '',
  backgroundImageLarge: '',
  backgroundColor: 'white',
  textColor: Colors.mainBlack,

  descriptionModules: [],
  tags: [],

  isPublic: false,
  isDeleted: false,

  createdAt: new Date(),
  modifiedAt: new Date(),
};