import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import Network from "../../library/Network";
import {Loader, Comment, Image, Container, Segment, Button, Form, Radio, Table, Modal,} from 'semantic-ui-react';
import moment from 'moment';
import {getUpdateRequestStatusList, NEW_UPDATE_REQUEST} from "../../dataStructure/updateRequest";
import {withLineEnter} from "../../middleware/TextHandler";
import {bookClubStatusDropDownList} from "../../dataStructure/bookClub";
import {db} from "../../constants/firebase";
import _ from 'lodash';

class AdminUpdateRequestMainContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      updateRequests: [],
      isLoaded: false,
      selectedUpdateRequest: NEW_UPDATE_REQUEST(),
      modalOpen: false,
    }
  }


  componentDidMount() {
    this._loadUpdateRequests();
  }

  _loadUpdateRequests = async () => {
    const res = await Network.getUpdateRequestAll();
    if (res.err) {
      window.alert('에러');
      return;
    }

    this.setState({
      isLoaded: true,
      updateRequests: _.orderBy(res,['status'],['asc'])
    })

  };

  _handleUpdate = async () => {
    const {selectedUpdateRequest} = this.state;
    const res = await Network.updateUpdateRequest(selectedUpdateRequest);
    if (res.err) {
      return window.alert('오류 발생');
    } else {
      this._loadUpdateRequests();
      window.alert('완료되었습니다.');
      this._closeModal();
    }
  };

  _openModal = (updateRequest) => {
    this.setState({
      selectedUpdateRequest: updateRequest,
      modalOpen: true
    })
  };

  _closeModal = () => {
    this.setState({
      selectedUpdateRequest: NEW_UPDATE_REQUEST(),
      modalOpen: false
    })
  };

  render() {
    if (!this.state.isLoaded) return <Loader active/>;
    const {updateRequests, selectedUpdateRequest} = this.state;
    const selectedUser = this.props.admin.users.find(us => us.id === selectedUpdateRequest.userId);
    return (
      <Container>
        <Table>
          <Table.Header>

          </Table.Header>
          <Table.Body>
            {
              updateRequests.map((updateRequest, i) => {
                const user = this.props.admin.users.find(us => us.id === updateRequest.userId);
                const status = getUpdateRequestStatusList().find(status => status.value === updateRequest.status);
                return (
                  <Table.Row key={i}>
                    <Table.Cell>
                      {i + 1}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(updateRequest.createdAt.seconds * 1000).format('YYYY. MM. DD(ddd)')}
                    </Table.Cell>
                    <Table.Cell>
                      {status && status.text}
                    </Table.Cell>
                    <Table.Cell>
                      {user && (
                        <Image avatar src={user.photoURL}/>
                      )}
                      {user && user.displayName}
                    </Table.Cell>
                    <Table.Cell>
                      {updateRequest.title}
                    </Table.Cell>
                    <Table.Cell>
                      <Button content={'답변'} onClick={() => {
                        this._openModal(updateRequest)
                      }}/>
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
        <Modal open={this.state.modalOpen}>
          <Modal.Content>
            {selectedUser && (
              <Fragment>
                <Image avatar src={selectedUser && selectedUser.photoURL}/>
                <p>
                  {selectedUser.displayName}
                </p>
              </Fragment>
            )}
            <h2>
              {selectedUpdateRequest.title}
            </h2>
            <p>
              {withLineEnter(selectedUpdateRequest.content)}
            </p>

            <Form>
              <Form.Dropdown
                width={4}
                placeholder={'status'}
                selection
                label="status"
                name="status"
                value={selectedUpdateRequest.status}
                options={getUpdateRequestStatusList()}
                onChange={(e, {value}) => {
                  selectedUpdateRequest.status = value;
                  this.setState({
                    selectedUpdateRequest
                  })
                }}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button content={'업데이트'}
                    positive
                    onClick={() => {
                      const a = window.confirm('업데이트 하시겠습니까?');
                      if (!a) return;
                      this._handleUpdate();
                    }}/>
            <Button content={'취소'}
                    negative
                    onClick={() => {
                      const a = window.confirm('취소하시겠습니까?');
                      if (!a) return;
                      this._closeModal();
                    }}/>
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(AdminUpdateRequestMainContainer);