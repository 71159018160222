import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {
  Container,
  Icon,
  Input,
  Image,
  Label,
  Card,
  Item,
  Segment,
  Grid,
  Button,
  Table,
  Form,
  Radio
} from 'semantic-ui-react'
import SearchBook, {
  QUERY_TYPE_KEYWORD,
  SEARCH_TARGET_BOOK,
  searchTargetDropDownList
} from "../../middleware/SearchBook";
import {db} from "../../constants/firebase";
import {NEW_ADMIN_BOOK} from "../../dataStructure/adminBook";
import _ from "lodash";
import {adminActionCreators} from "../../redux/admin/adminStore";

class AdminBookMainContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      adminBooks: [],
      searchedBookList: [],
      searchBookValue: '',

      searchTarget: SEARCH_TARGET_BOOK,
      uploading: false,
    }
  };


  componentDidMount() {
    // this._loadAdminBookList();
    this.props.fetchAllAdminBooks();
  }

  _loadAdminBookList = () => {
    db.collection('adminBooks')
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshot => {
        const adminBooks = [];
        snapshot.forEach(doc => {
          let adminBook = Object.assign(doc.data(), {id: doc.id});
          adminBooks.push(adminBook)
        });
        this.setState({
          adminBooks
        })
      })
      .catch(e => {
        console.log(e)
      })
  };

  _addAdminBook = async (book) => {

    if (this.state.uploading) return;

    const {adminBooks} = this.props.admin;
    const index = adminBooks.findIndex(b => b.isbn === book.isbn);
    if (index > -1) return window.alert('이미 존재하는 책입니다');
    this.setState({uploading: true});
    const newAdminBook = Object.assign(NEW_ADMIN_BOOK(), book);
    db.collection('adminBooks')
      .add(newAdminBook)
      .then(() => {
        this.props.fetchAllAdminBooks(() => {
          this.setState({uploading: false});
        });
      })
      .catch(e => {
        console.log(e)
      })
  };

  _removeAdminBook = (book) => {

  };



  render() {
    const {searchedBookList} = this.state;
    const {adminBooks} = this.props.admin;
    return (
      <Container fluid>
        <Grid padded>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Container as={Segment} vertical basic style={{flex: 1}}>
                <Table size={'mini'}>
                  <Table.Header>

                  </Table.Header>
                  <Table.Body>
                    {
                      adminBooks.map((book, i) => {
                        return (
                          <Table.Row key={i}>
                            <Table.Cell>
                              <Image src={book.cover} size={'mini'}/>
                            </Table.Cell>
                            <Table.Cell style={{fontSize: '0.5rem'}}>{book.id}</Table.Cell>
                            <Table.Cell>
                              <b>{book.title}</b><br/>
                              {book.author} <br/>
                              {book.publisher} {book.pubDate}
                            </Table.Cell>
                          </Table.Row>
                        )
                      })
                    }
                  </Table.Body>
                </Table>
                {/*<Item.Group divided>
                  {
                    adminBooks.map((book, i) => {
                      return (
                        <Item key={i}>
                          <Item.Image src={book.cover} size={'tiny'}/>
                          <Item.Content>
                            <Item.Header>
                              {book.title}
                            </Item.Header>
                            <Item.Description>
                              <Form>
                                <Form.TextArea
                                  value={book.adminComment}
                                  onChange={(e, {value}) => {
                                    adminBooks[i].adminComment = value;
                                    this.setState({
                                      adminBooks
                                    })
                                  }}
                                />
                              </Form>
                            </Item.Description>
                            <Item.Extra>
                              {book.tags.map((tag, j) => {
                                return (
                                  <Input
                                    fluid
                                    key={j}
                                    value={tag}
                                    onChange={(e, {value}) => {
                                      adminBooks[i].tags[j] = value;
                                      this.setState({
                                        adminBooks
                                      })
                                    }}>
                                    <input/>
                                    <Button
                                      onClick={() => {
                                        adminBooks[i].tags.splice(j, 1);
                                        this.setState({
                                          adminBooks
                                        })
                                      }}
                                      icon>
                                      <Icon name={'close'}/>
                                    </Button>
                                  </Input>
                                )
                              })}
                            </Item.Extra>
                            <Item.Extra>
                              <Button
                                size={'mini'}
                                onClick={() => {
                                  adminBooks[i].tags.push('');
                                  this.setState({
                                    adminBooks
                                  })
                                }}>
                                태그 추가
                              </Button>

                              <Button
                                size={'mini'}
                                onClick={() => {
                                  db.collection('adminBooks')
                                    .doc(book.id)
                                    .update(book)
                                    .then(() => {
                                      window.alert('완료');
                                      this._loadAdminBookList();
                                    })
                                    .catch(e => {
                                      console.log(e)
                                    })
                                }}>
                                반영
                              </Button>
                            </Item.Extra>
                          </Item.Content>
                        </Item>
                      )
                    })
                  }
                </Item.Group>*/}
              </Container>
            </Grid.Column>
            <Grid.Column>
              <Container as={Segment} vertical basic style={{flex: 1}}>
                <Form>
                  <Form.Dropdown
                    selection
                    value={this.state.searchTarget}
                    placeholder={'구분'}
                    options={searchTargetDropDownList()}
                    onChange={(e, {value}) => {
                      this.setState({
                        searchTarget: value,
                      })
                    }}
                  />
                  <Form.Input
                    onChange={(e, {value}) => {
                      this.setState({
                        searchBookValue: value
                      })
                    }}
                    value={this.state.searchBookValue}>
                    <input/>
                    <Button icon onClick={async (e) => {
                      e.preventDefault();
                      const res = await SearchBook.AladinItemSearchFromFirebase({
                        query: this.state.searchBookValue,
                        SearchTarget: this.state.searchTarget,
                        queryType: QUERY_TYPE_KEYWORD,
                        page: 1,
                        perPage: 30,
                      });
                      if (!res.err) {
                        const {item: searchedBookList} = res;
                        this.setState({searchedBookList});
                      } else {
                        window.alert(res.err)
                      }

                    }}>
                      <Icon name='search'/>
                    </Button>
                  </Form.Input>
                </Form>
                <Table size={'small'} compact={'very'}>
                  <Table.Header>

                  </Table.Header>
                  <Table.Body>
                    {
                      searchedBookList && searchedBookList.map((book, i) => {
                        return (
                          <Table.Row key={i}>
                            <Table.Cell>
                              <Image src={book.cover} size={'mini'}/>
                            </Table.Cell>
                            <Table.Cell>
                              {book.title} <br/>
                              {book.author} <br/>
                              {book.publisher} {book.pubDate}
                            </Table.Cell>
                            <Table.Cell>
                              <Button
                                active={this.state.uploading}
                                disabled={this.state.uploading}
                                onClick={() => {
                                  this._addAdminBook(book);
                                }}>
                                추가
                              </Button>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })
                    }
                  </Table.Body>
                </Table>
                AdminBookMainContainer
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
    ...adminActionCreators,
  }
);


export default enhance(AdminBookMainContainer);