import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Image, Segment} from "semantic-ui-react";
import {
  CONTENT_TYPE_BOOKS,
  CONTENT_TYPE_GAP, CONTENT_TYPE_PHOTOS,
  CONTENT_TYPE_TEXT,
  CONTENT_TYPE_WEB_LINK
} from "../../dataStructure/contentModule";
import {withLineEnter} from "../../middleware/TextHandler";
import {Link} from "react-router-dom";

class ContentModuleComponent extends Component {
  render() {
    const {contentModule} = this.props;
    if (!contentModule) return <></>;
    switch (contentModule.type) {
      case CONTENT_TYPE_TEXT:
        return (
          <Container text style={{marginBottom: contentModule.bottomGap || null}}>
            <span style={{
              fontSize: contentModule.fontSize,
              fontWeight: contentModule.fontFamily,
              lineHeight: contentModule.lineHeight > contentModule.fontSize * 1.5 ? 2 : 1,
            }}>
              {withLineEnter(contentModule.text)}
            </span>
          </Container>
        );

      case CONTENT_TYPE_WEB_LINK:
        return (
          <Container text style={{marginBottom: contentModule.bottomGap || null}}>
            <a href={contentModule.uri} target={'blank'}>
              {contentModule.buttonText}
            </a>
          </Container>
        );

      case CONTENT_TYPE_PHOTOS:
        return (
          <Container text style={{marginBottom: contentModule.bottomGap || null}}>
            {
              contentModule.imageUrls.map((image, i) => {
                return (
                  <img src={image} key={i} style={{width: '100%'}}/>
                )
              })
            }

          </Container>
        );

      case CONTENT_TYPE_BOOKS:
        return (
          <Container text style={{marginBottom: contentModule.bottomGap || null}}>
            {
              contentModule.books.map((item, i) => {
                return (
                  <Image src={item.coverImageUrl} key={i} size={'tiny'}/>
                )
              })
            }

          </Container>
        );

      case CONTENT_TYPE_GAP:
        return (
          <div style={{height: contentModule.height}}/>
        );

      default:
        return (
          <Container as={Segment} vertical basic>
            {contentModule.type}
          </Container>
        )

    }
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(ContentModuleComponent);