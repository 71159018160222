import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {Segment, Divider, Grid, Image, Container, Button,} from 'semantic-ui-react'
import Colors from "../../constants/Colors";
import Analytics from "../../middleware/Analytics";

class AboutCameraContainer extends Component {


  componentDidMount() {
    Analytics.pageViewAll('/about/camera');
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div style={{}}>
        <Container fluid style={{
          minHeight: '30rem',
          backgroundColor: Colors.backgroundColor,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Image src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Fcamera_icon.png?alt=media'} size={'small'}/>
        </Container>
        <Grid stackable>
          <Grid.Row
            verticalAlign={'middle'}>
            <Grid.Column style={{
              minHeight: '70vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                찰칵.
              </div>
              <div className={'subHeaderText'}>
                잊어 버리지 않는 기록
              </div>
              <div className={'mainDescriptionText'}>
                기억하고 싶은 것을 찰칵<br/>
                찍고, 긋고, 끝.
              </div>
              <p className={'detailDescriptionText'}>
                우리는 책에서 영감을 받습니다. <br/>
                하지만 받은 영감은 오래 가지 않죠. <br/>
                감동과 기억은 쉽게 사라집니다. <br/>
              </p>
              <div className={'mainHeaderText'} style={{fontWeight: '100'}}>
                리더스 스크랩을 <span style={{textDecoration: 'wavy underline #69FF46', fontWeight: 'bold'}}>사용하기 전</span> 까지는
                말이죠.
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            columns={3}
            verticalAlign={'middle'}>
            <Grid.Column style={{
              minHeight: '40vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                찍고.
              </div>
              <Image
                centered
                size={'medium'}
                src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Fcamera_1.png?alt=media'}
              />
            </Grid.Column>
            <Grid.Column style={{
              minHeight: '40vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                긋고.
              </div>
              <Image
                centered
                size={'medium'}
                src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Fcamera_2.png?alt=media'}
              />
            </Grid.Column>
            <Grid.Column style={{
              minHeight: '40vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                영감을 쓱-쓰윽.
              </div>
              <Image
                centered
                size={'medium'}
                src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Fcamera_3.png?alt=media'}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            verticalAlign={'middle'}>
            <Grid.Column style={{
              minHeight: '70vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                불러오기
              </div>
              <div className={'subHeaderText'}>
                리더스 ON
              </div>
              <div className={'mainDescriptionText'}>
                언제, 어디서나, 가장 똑똑하게.
              </div>
              <p className={'detailDescriptionText'}>
                키워드로 검색.<br/>
                리더스가 사진 속에서 영감들을<br/>
                찾아줄 거에요.<br/>
              </p>
              <div className={'mainHeaderText'} style={{fontWeight: '100'}}>
                심지어 똑똑하게 <span style={{textDecoration: 'wavy underline #69FF46', fontWeight: 'bold'}}>사진 속 문자들까지</span>
                말이죠. <sup style={{fontWeight:'900'}}>*</sup>
              </div>
            </Grid.Column>
          </Grid.Row>
          {/*<Grid.Row
            columns={3}
            verticalAlign={'middle'}>
            <Grid.Column style={{
              minHeight: '40vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                책별로.
              </div>
              <Image
                centered
                size={'medium'}
                src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Fcamera_1.png?alt=media'}
              />
            </Grid.Column>
            <Grid.Column style={{
              minHeight: '40vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                키워드로.
              </div>
              <Image
                centered
                size={'medium'}
                src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Fcamera_2.png?alt=media'}
              />
            </Grid.Column>
            <Grid.Column style={{
              minHeight: '40vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                함께.
              </div>
              <Image
                centered
                size={'medium'}
                src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Fcamera_3.png?alt=media'}
              />
            </Grid.Column>
          </Grid.Row>*/}
          <Grid.Row
            verticalAlign={'middle'}>
            <Grid.Column style={{
              minHeight: '70vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                어때요?
              </div>
              <div className={'mainHeaderText'} style={{fontWeight: '100'}}>
                충분히 <span style={{textDecoration: 'wavy underline #69FF46', fontWeight: 'bold'}}>똑똑한</span> 카메라죠?
              </div>
              <div style={{display: 'flex', marginTop: '3rem',}}>
                <a className={'roundButton'} href={'https://readersrn.page.link/fromhomepage'} target={'_blank'}>
                  앱 다운로드
                </a>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div style={{textAlign:'center'}}>
          * 해당 기능은 Beta 운영 중이며, 추후 PRO 멤버십 구독자에게만 제공될 수 있습니다.
        </div>
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(AboutCameraContainer);