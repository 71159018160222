import React, {Component} from 'react';
import {connect} from 'react-redux';
import NetworkApiV1 from "../../library/NetworkApiV1";
import {BOOKCLUB_STATUS_ING} from "../../dataStructure/bookClub";
import {Container, Card, Image, Loader, Segment, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";
import Colors from "../../constants/Colors";
import moment from 'moment';

class BookClubListIngContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      bookClubs: [],
    }
  }

  componentDidMount() {
    this._loadBookClub();
  }

  _loadBookClub = async () => {
    const {bookClubs, err} = await NetworkApiV1.getPublicBookClubList(BOOKCLUB_STATUS_ING);
    if (!err) {
      this.setState({
        isLoaded: true,
        bookClubs,
      })
    }
  };


  render() {
    if (!this.state.isLoaded) return <Loader active/>;
    return (
      <Container as={Segment} vertical basic>
        <Card.Group itemsPerRow={4}>
          {
            this.state.bookClubs.map((bookClub, i) => {
              return (
                <Card
                  as={Link}
                  to={`/book-club/${bookClub.id}`}
                  key={i}>
                  <Image src={bookClub.coverImageUrl}/>
                  <Card.Content>
                    <Card.Header content={bookClub.title}/>
                    <Card.Meta>
                      {bookClub.subTitle} <br/>
                      {moment(bookClub.startDate).format('YYYY.MM.DD')} - {moment(bookClub.endDate).format('YYYY.MM.DD')}
                    </Card.Meta>
                  </Card.Content>
                  <Card.Content style={{color: Colors.fontGrey}}>
                    <Icon name={'calendar alternate outline'} color={'grey'}/> 진행중
                  </Card.Content>
                </Card>
              )
            })
          }
        </Card.Group>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BookClubListIngContainer);