import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import Network from "../../library/Network";
import {Table, Label, Divider, Container, Segment, Image, Button, Comment, Loader} from 'semantic-ui-react';
import {adminActionCreators} from "../../redux/admin/adminStore";
import moment from 'moment';
import _ from 'lodash';
import AdminUserComponent from '../../components/user/AdminUserComponent';
import mockUpImages from "../../constants/mockUpImages";
import {withLineEnter} from "../../middleware/TextHandler";
import AdminInquiryComponent from "../../components/inquiry/AdminInquiryComponent";

class AdminInquiryMainContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      inquiries: [],
      isLoaded: false,
      showIsNotAnswered: false,
    }
  }



  componentDidMount() {
    this._loadInquiries()

  }

  _loadInquiries = async () => {
    this.props.fetchAllInquiriesAdmin(() => {
      this.setState({
        isLoaded: true,
      })
    });
    return;
    const res = await Network.getInquiriesAll();
    if (res.err) {
      window.alert('오류')
    } else {
      this.setState({
        inquiries: res,
        isLoaded: true
      })
    }
  };


  showIsNotAnswered = () => {
    this.setState({
      showIsNotAnswered: !this.state.showIsNotAnswered
    })
  };



  render() {
    const {inquiries} = this.props.admin;
    if (!this.state.isLoaded) return <Loader active/>;

    const $tableHeader = (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>시간</Table.HeaderCell>
          <Table.HeaderCell>유저</Table.HeaderCell>
          <Table.HeaderCell>질문내용</Table.HeaderCell>
          <Table.HeaderCell>답변시간</Table.HeaderCell>
          <Table.HeaderCell>답변</Table.HeaderCell>
          <Table.HeaderCell>-</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    )



    return (
      <Fragment>

        <Container as={Segment}>
          <Button content={'새로고침'} onClick={this._loadInquiries}/>
          <Divider/>
          <Button content={'답변 전'} onClick={this.showIsNotAnswered}/>
          <Button content={'답변 완료'} onClick={this.showIsNotAnswered}/>
        </Container>
        <Container as={Segment}>
          <Table compact size={'small'} celled>
            {$tableHeader}
            <Table.Body>
              {
                _.filter(_.orderBy(inquiries, ['createdAt.seconds'], ['desc']), inq => inq.isAnswered === this.state.showIsNotAnswered).map((inquiry, i) => {
                  const user = this.props.admin.users.find(us => us.id === inquiry.userId);
                  return (
                    <AdminInquiryComponent
                      key={i}
                      inquiry={inquiry}
                      refresh={this._loadInquiries}
                      user={user}/>
                  )
                })
              }
            </Table.Body>
          </Table>
        </Container>
        {/*<Container as={Segment}>
          <Table compact size={'small'} celled>
            {$tableHeader}
            <Table.Body>
              {
                _.filter(inquiries, inq => inq.isAnswered).map((inquiry, i) => {
                  const user = this.props.admin.users.find(us => us.id === inquiry.userId);
                  return (
                    <AdminInquiryComponent
                      key={i}
                      inquiry={inquiry}
                      refresh={this._loadInquiries}
                      user={user}/>
                  )
                })
              }
            </Table.Body>
          </Table>
        </Container>*/}
      </Fragment>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
    ...adminActionCreators,
  }
);


export default enhance(AdminInquiryMainContainer);