import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {db, firebase} from "../../constants/firebase";
import {NEW_TIP} from "../../dataStructure/tip";
import _ from 'lodash';
import moment from 'moment';
import {
  Container,
  Button,
  Grid,
  Card,
  Image,
  Segment,
  Radio,
  Header,
  Divider,
  Icon,
  Loader,
  Form
} from 'semantic-ui-react'
import FileUploader from "react-firebase-file-uploader";
import mockUpImages from "../../constants/mockUpImages";
import {NEW_DESCRIPTION_MODULE} from "../../dataStructure/descriptionModule";
import {moveItemInArrayFromIndexToIndex} from "../../middleware/arrayHandler";


const storageRef = `tip/${moment().format("YYYYMM")}`;

class AdminTipDetailContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tip: {},
      isNewTip: null,
      isLoaded: false,
    }
  }


  componentDidMount() {
    this._loadTip();
  }

  _loadTip = async () => {
    const isNewTip = this.props.match.params.tipId === undefined;
    // console.log({isNewTip});

    if (isNewTip) {
      this.setState({
        isNewTip,
        tip: _.cloneDeep(NEW_TIP),
        isLoaded: true,
      })
    } else {
      const tipId = this.props.match.params.tipId;
      db.collection('tips')
        .doc(tipId)
        .get()
        .then(doc => {
          if (!doc.exists) return window.alert('경고\n 팁를 불러올 수 없습니다.');

          const tip = Object.assign(_.cloneDeep(NEW_TIP), doc.data(), {id: doc.id});

          tip.createdAt = new Date(moment(tip.createdAt.seconds * 1000).toISOString());
          tip.modifiedAt = new Date(moment(tip.modifiedAt.seconds * 1000).toISOString());


          this.setState({
            tip,
            isNewTip,
            isLoaded: true,
          })
        })
        .catch(e => {
          console.log(e);
        })
    }
  };

  _updateOrAddTip = async () => {

    const confirm = window.confirm('업로드 하시겠습니까?');
    if (!confirm) return;

    let {tip} = this.state;
    const isNewTip = this.props.match.params.tipId === undefined;
    if (isNewTip) {

      tip.createdAt = new Date();
      tip.modifiedAt = new Date();

      db.collection('tips')
        .add(tip)
        .then(() => {
          window.alert('팁가 등록되었습니다.');
          this.props.history.push('/admin/tip')
        })
        .catch(e => {
          console.log(e)
        })
    } else {

      tip.modifiedAt = new Date();

      db.collection('tips')
        .doc(tip.id)
        .update(tip)
        .then(() => {
          window.alert('팁가 수정되었습니다.');
          this.props.history.push('/admin/tip')
        })
        .catch(e => {
          console.log(e)
        })
    }

  };

  handleUploadStart = () =>
    this.setState({
      isUploading: true,
      uploadProgress: 0
    });

  handleProgress = progress =>
    this.setState({
      uploadProgress: progress
    });

  handleUploadError = error => {
    this.setState({
      isUploading: false
      // Todo: handle error
    });
    console.error(error);
  };

  handleUploadSuccess = async (filename, imageCategory) => {
    const {tip} = this.state;
    this.setState({progress: 100, isUploading: false});
    await firebase.storage()
      .ref(storageRef)
      .child(filename)
      .getDownloadURL()
      .then(imageUrl => {
        tip.image[imageCategory] = imageUrl;
        // console.log(tip);
        this.setState({tip})
      });
  };


  deleteTip = async () => {

  };

  restoreTip = async () => {

  };

  render() {
    const {tip, isLoaded, isNewTip} = this.state;
    if (!isLoaded) {
      return (
        <Loader active/>
      )
    }
    return (
      <Fragment>
        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={`팁 정보`} size={'large'} dividing/>
          <Button content={isNewTip ? '만들기' : '수정하기'} primary
                  onClick={this._updateOrAddTip}/>
          <Button content={'삭제'} negative disabled={isNewTip || tip.isDeleted}
                  onClick={this.deleteTip}/>
          <Button content={'복구'} positive disabled={!tip.isDeleted}
                  onClick={this.restoreTip}/>

        </Container>

        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'정보'} size={'large'} dividing/>
          <Form>
            <Form.Field>
              <Radio
                label='공개'
                toggle
                name='radioGroup'
                value='right'
                checked={tip.isPublic}
                onChange={e => {
                  tip.isPublic = !tip.isPublic;
                  this.setState({
                    tip
                  })
                }}
              />
            </Form.Field>

            {/* skfWk */}
            <Fragment>
              <Form.Group>
                <Form.Input
                  disabled={true}
                  name={'createdAt'}
                  label={'createdAt'}
                  type={'datetime-local'}
                  value={moment(tip.createdAt).format('YYYY-MM-DDThh:mm')}
                  onChange={(e, {value, name}) => {
                    tip.createdAt = new Date(value);
                    this.setState({
                      tip
                    })
                  }}
                />
                <Form.Input
                  disabled={true}
                  name={'modifiedAt'}
                  label={'modifiedAt'}
                  type={'datetime-local'}
                  value={moment(tip.modifiedAt).format('YYYY-MM-DDThh:mm')}
                  onChange={(e, {value, name}) => {
                    tip.modifiedAt = new Date(value);
                    this.setState({
                      tip
                    })
                  }}
                />
              </Form.Group>
            </Fragment>

            <Fragment>
              <Form.Group>
                <Form.Input
                  name={'title'}
                  label={'title'}
                  value={tip.title.content}
                  onChange={e => {
                    tip.title.content = e.target.value;
                    this.setState({tip})
                  }}
                  width={12}
                />
                <Form.Input
                  name={'titleColor'}
                  label={'titleColor'}
                  value={tip.title.color}
                  onChange={e => {
                    tip.title.color = e.target.value;
                    this.setState({tip})
                  }}
                  width={4}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <Radio
                    label='왼쪽 정렬'
                    name='title'
                    value='left'
                    checked={tip.title.align === 'left'}
                    onChange={(e) => {
                      tip.title.align = 'left';
                      this.setState({
                        tip
                      })
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label='가운데 정렬'
                    name='title'
                    value='center'
                    checked={tip.title.align === 'center'}
                    onChange={e => {
                      tip.title.align = 'center';
                      this.setState({
                        tip
                      })
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label='오른쪽 정렬'
                    name='title'
                    value='right'
                    checked={tip.title.align === 'right'}
                    onChange={e => {
                      tip.title.align = 'right';
                      this.setState({
                        tip
                      })
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Fragment>

            <Fragment>

              <Form.Group>
                <Form.Input
                  name={'subTitle'}
                  label={'subTitle'}
                  value={tip.subTitle.content}
                  onChange={e => {
                    tip.subTitle.content = e.target.value;
                    this.setState({tip})
                  }}
                  width={12}
                />
                <Form.Input
                  name={'subTitleColor'}
                  label={'subTitleColor'}
                  value={tip.subTitle.color}
                  onChange={e => {
                    tip.subTitle.color = e.target.value;
                    this.setState({tip})
                  }}
                  width={4}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <Radio
                    label='왼쪽 정렬'
                    name='subTitle'
                    value='left'
                    checked={tip.subTitle.align === 'left'}
                    onChange={(e) => {
                      tip.subTitle.align = 'left';
                      this.setState({
                        tip
                      })
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label='가운데 정렬'
                    name='subTitle'
                    value='center'
                    checked={tip.subTitle.align === 'center'}
                    onChange={e => {
                      tip.subTitle.align = 'center';
                      this.setState({
                        tip
                      })
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label='오른쪽 정렬'
                    name='subTitle'
                    value='right'
                    checked={tip.subTitle.align === 'right'}
                    onChange={e => {
                      tip.subTitle.align = 'right';
                      this.setState({
                        tip
                      })
                    }}
                  />
                </Form.Field>
              </Form.Group>

            </Fragment>

            <Fragment>
              <Form.Group>
                <Form.TextArea
                  name={'description'}
                  label={'description'}
                  rows={10}
                  style={{
                    textAlign: tip.description.align,
                    color: tip.description.color,

                  }}
                  value={tip.description.content}
                  onChange={e => {
                    tip.description.content = e.target.value;
                    this.setState({tip})
                  }}
                  width={12}
                />
                <Form.Input
                  name={'descriptionColor'}
                  label={'descriptionColor'}
                  value={tip.description.color}
                  onChange={e => {
                    tip.description.color = e.target.value;
                    this.setState({tip})
                  }}
                  width={4}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <Radio
                    label='왼쪽 정렬'
                    name='description'
                    value='left'
                    checked={tip.description.align === 'left'}
                    onChange={(e) => {
                      tip.description.align = 'left';
                      this.setState({
                        tip
                      })
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label='가운데 정렬'
                    name='description'
                    value='center'
                    checked={tip.description.align === 'center'}
                    onChange={e => {
                      tip.description.align = 'center';
                      this.setState({
                        tip
                      })
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label='오른쪽 정렬'
                    name='description'
                    value='right'
                    checked={tip.description.align === 'right'}
                    onChange={e => {
                      tip.description.align = 'right';
                      this.setState({
                        tip
                      })
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Fragment>

          </Form>
        </Container>

        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'TAGS'} size={'large'} dividing/>
          <Form>
            <Form.Input
              label={'order'}
              width={3}
              name={'order'}
              value={tip.order ? tip.order.toString() : ''}
              onChange={(e, {value}) => {
                tip.order = Number(value);
                this.setState({
                  tip
                })
              }}
            />
            <Header>
              TAGS
            </Header>
            {
              tip.tags.map((tag, i) => {
                return (
                  <Form.Input
                    width={3}
                    name={`tag-${i}`}
                    value={tag}
                    key={i}
                    onChange={(e, {value}) => {
                      tip.tags[i] = value;
                      this.setState({
                        tip
                      })
                    }}
                  >
                    <input/>
                    <Button icon onClick={() => {
                      tip.tags.splice(i, 1);
                      this.setState({
                        tip
                      })
                    }}>
                      <Icon name={'close'}/>
                    </Button>
                  </Form.Input>
                )
              })
            }
            <Button
              content={'태그 추가하기'}
              onClick={() => {
                tip.tags.push('');
                this.setState({tip})
              }}/>

          </Form>
        </Container>
        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'배경 및 사진'} size={'large'} dividing/>
          <Form>
            <Form.Group>
              <Form.Input
                label={'배경 색'}
                name={'backgroundColor'}
                value={tip.backgroundColor}
                onChange={this.handleChange}
              />
              <Form.Input
                label={'텍스트 색'}
                name={'textColor'}
                value={tip.textColor}
                onChange={this.handleChange}
              />
            </Form.Group>
          </Form>
          <Divider/>
          <Grid>
            <Grid.Row columns={6}>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  MAIN
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          tip.imageUrl = imageUrl;
                          this.setState({
                            tip
                          })
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(tip.imageUrl)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    tip.imageUrl = '';
                    this.setState({
                      tip
                    })
                  }}/>
                <Image src={tip.imageUrl || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  배경
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          tip.backgroundImage = imageUrl;
                          this.setState({
                            tip
                          })
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(tip.backgroundImage)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    tip.backgroundImage = '';
                    this.setState({
                      tip
                    })
                  }}/>
                <Image src={tip.backgroundImage || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  배경 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          tip.backgroundImageLarge = imageUrl;
                          this.setState({
                            tip
                          })
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(tip.backgroundImageLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    tip.backgroundImageLarge = '';
                    this.setState({
                      tip
                    })
                  }}/>
                <Image src={tip.backgroundImageLarge || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={6}>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  정사각
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="square"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'square');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(tip.image && tip.image.square)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    tip.image.square = '';
                    this.setState({
                      tip
                    })
                  }}/>
                <Image src={(tip.image && tip.image.square) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  가로
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="horizontal"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'horizontal');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(tip.image && tip.image.horizontal)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    tip.image.horizontal = ''
                    this.setState({
                      tip
                    })
                  }}/>
                <Image src={(tip.image && tip.image.horizontal) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  세로
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="vertical"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'vertical');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(tip.image && tip.image.vertical)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    tip.image.vertical = ''
                    this.setState({
                      tip
                    })
                  }}/>
                <Image src={(tip.image && tip.image.vertical) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  정사각 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="imageSquareLarge"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'squareLarge');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(tip.image && tip.image.squareLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    tip.image.squareLarge = ''
                    this.setState({
                      tip
                    })
                  }}/>
                <Image src={(tip.image && tip.image.squareLarge) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  가로 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="imageHorizontalLarge"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'horizontalLarge');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(tip.image && tip.image.horizontalLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    tip.image.horizontalLarge = '';
                    this.setState({
                      tip
                    })
                  }}/>
                <Image src={(tip.image && tip.image.horizontalLarge) || mockUpImages.planeImage}
                       size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  세로 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="imageVerticalLarge"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'verticalLarge');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(tip.image && tip.image.verticalLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    tip.image.verticalLarge = ''
                    this.setState({
                      tip
                    })
                  }}/>
                <Image src={(tip.image && tip.image.verticalLarge) || mockUpImages.planeImage}
                       size={'medium'}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'description Module'} size={'large'} dividing/>
          <Button onClick={() => {
            tip.descriptionModules.push(_.cloneDeep(NEW_DESCRIPTION_MODULE));
            this.setState({
              tip
            })
          }}>
            모듈 추가하기
          </Button>
          <Divider hidden/>
          <Grid>
            {
              tip.descriptionModules.map((module, i) => {
                return (
                  <Grid.Row key={i}>
                    <Grid.Column width={2}>
                      <Button
                        size={'mini'}
                        icon
                        negative
                        onClick={() => {
                          const a = window.confirm('삭제하시겠습니까?');
                          if (!a) return;
                          tip.descriptionModules.splice(i, 1);
                          this.setState({tip})
                        }}>
                        <Icon name={'remove'}/>
                      </Button>
                      <Button
                        size={'mini'}
                        icon
                        disabled={i === 0}
                        onClick={() => {
                          tip.descriptionModules = moveItemInArrayFromIndexToIndex(tip.descriptionModules, i, i - 1);
                          this.setState({tip})
                        }}>
                        <Icon name={'angle up'}/>
                      </Button>
                      <Button
                        size={'mini'}
                        icon
                        disabled={i === tip.descriptionModules.length - 1}
                        onClick={() => {
                          tip.descriptionModules = moveItemInArrayFromIndexToIndex(tip.descriptionModules, i, i + 1);
                          this.setState({tip})
                        }}>
                        <Icon name={'angle down'}/>
                      </Button>
                    </Grid.Column>
                    <Grid.Column width={14}>
                      <Card fluid>
                        <Card.Content>
                          <Form key={i}>
                            {/* header */}
                            <Form.Group style={{alignItems: 'center'}}>
                              <Form.Input
                                width={10}
                                label={'header'}
                                value={module.header.content}
                                onChange={(e, {value}) => {
                                  tip.descriptionModules[i].header.content = value;
                                  this.setState({
                                    tip
                                  })
                                }}
                              />
                              <Form.Input
                                width={3}
                                label={'color'}
                                value={module.header.color}
                                onChange={(e, {value}) => {
                                  tip.descriptionModules[i].header.color = value;
                                  this.setState({
                                    tip
                                  })
                                }}
                              />
                              <div style={{marginTop: 30}}>
                                <Form.Field>
                                  <Radio
                                    label='LEFT'
                                    name={`header-${i}`}
                                    value='left'
                                    checked={module.header.align === 'left'}
                                    onChange={(e) => {
                                      tip.descriptionModules[i].header.align = 'left';
                                      this.setState({
                                        tip
                                      })
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <Radio
                                    label='CENTER'
                                    name={`header-${i}`}
                                    value='center'
                                    checked={module.header.align === 'center'}
                                    onChange={(e) => {
                                      tip.descriptionModules[i].header.align = 'center';
                                      this.setState({
                                        tip
                                      })
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <Radio
                                    label='RIGHT'
                                    name={`header-${i}`}
                                    value='right'
                                    checked={module.header.align === 'right'}
                                    onChange={(e) => {
                                      tip.descriptionModules[i].header.align = 'right';
                                      this.setState({
                                        tip
                                      })
                                    }}
                                  />
                                </Form.Field>
                              </div>
                            </Form.Group>

                            {/* subHeader */}
                            <Form.Group style={{alignItems: 'center'}}>
                              <Form.Input
                                width={10}
                                label={'subHeader'}
                                value={module.subHeader.content}
                                onChange={(e, {value}) => {
                                  tip.descriptionModules[i].subHeader.content = value;
                                  this.setState({
                                    tip
                                  })
                                }}
                              />
                              <Form.Input
                                width={3}
                                label={'color'}
                                value={module.subHeader.color}
                                onChange={(e, {value}) => {
                                  tip.descriptionModules[i].subHeader.color = value;
                                  this.setState({
                                    tip
                                  })
                                }}
                              />
                              <div style={{marginTop: 30}}>
                                <Form.Field>
                                  <Radio
                                    label='LEFT'
                                    name={`subHeader-${i}`}
                                    value='left'
                                    checked={module.subHeader.align === 'left'}
                                    onChange={(e) => {
                                      tip.descriptionModules[i].subHeader.align = 'left';
                                      this.setState({
                                        tip
                                      })
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <Radio
                                    label='CENTER'
                                    name={`subHeader-${i}`}
                                    value='center'
                                    checked={module.subHeader.align === 'center'}
                                    onChange={(e) => {
                                      tip.descriptionModules[i].subHeader.align = 'center';
                                      this.setState({
                                        tip
                                      })
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <Radio
                                    label='RIGHT'
                                    name={`subHeader-${i}`}
                                    value='right'
                                    checked={module.subHeader.align === 'right'}
                                    onChange={(e) => {
                                      tip.descriptionModules[i].subHeader.align = 'right';
                                      this.setState({
                                        tip
                                      })
                                    }}
                                  />
                                </Form.Field>
                              </div>
                            </Form.Group>

                            {/* description */}
                            <Form.Group>
                              <Form.TextArea
                                width={10}
                                label={'description'}
                                rows={10}
                                value={module.description.content}
                                onChange={(e, {value}) => {
                                  tip.descriptionModules[i].description.content = value;
                                  this.setState({
                                    tip
                                  })
                                }}
                              />

                              <Form.Input
                                width={3}
                                label={'color'}
                                value={module.description.color}
                                onChange={(e, {value}) => {
                                  tip.descriptionModules[i].description.color = value;
                                  this.setState({
                                    tip
                                  })
                                }}
                              />
                              <div style={{marginTop: 23}}>
                                <Form.Field>
                                  <Radio
                                    label='LEFT'
                                    name='title'
                                    value='left'
                                    checked={module.description.align === 'left'}
                                    onChange={(e) => {
                                      tip.descriptionModules[i].description.align = 'left';
                                      this.setState({
                                        tip
                                      })
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <Radio
                                    label='CENTER'
                                    name='title'
                                    value='left'
                                    checked={module.description.align === 'center'}
                                    onChange={(e) => {
                                      tip.descriptionModules[i].description.align = 'center';
                                      this.setState({
                                        tip
                                      })
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <Radio
                                    label='RIGHT'
                                    name='title'
                                    value='left'
                                    checked={module.description.align === 'right'}
                                    onChange={(e) => {
                                      tip.descriptionModules[i].description.align = 'right';
                                      this.setState({
                                        tip
                                      })
                                    }}
                                  />
                                </Form.Field>
                              </div>
                            </Form.Group>
                          </Form>
                        </Card.Content>
                        <Card.Content>
                          <Form>
                            <Form.Field>
                              <Radio
                                toggle
                                label={module.hasYouTubeId ? 'YouTube 영상 있음' : 'YouTube 영상 없음'}
                                checked={module.hasYouTubeId}
                                onChange={() => {
                                  tip.descriptionModules[i].hasYouTubeId = !module.hasYouTubeId;
                                  this.setState({tip})
                                }}
                              />
                            </Form.Field>
                            <Form.Input
                              disabled={!module.hasYouTubeId}
                              width={10}
                              label={'youTubeId'}
                              value={module.youTubeId}
                              onChange={(e, {value}) => {
                                tip.descriptionModules[i].youTubeId = value;
                                this.setState({
                                  tip
                                })
                              }}
                            />
                          </Form>
                        </Card.Content>
                        <Card.Content>
                          <Form>
                            <Form.Field>
                              <Radio
                                toggle
                                label={module.hasButton ? '버튼있음' : '버튼없음'}
                                checked={module.hasButton}
                                onChange={() => {
                                  tip.descriptionModules[i].hasButton = !module.hasButton;
                                  this.setState({tip})
                                }}
                              />
                            </Form.Field>
                            <Form.Input
                              disabled={!module.hasButton}
                              width={10}
                              label={'externalLink'}
                              value={module.externalLink}
                              onChange={(e, {value}) => {
                                tip.descriptionModules[i].externalLink = value;
                                this.setState({
                                  tip
                                })
                              }}
                            />
                            <Form.Input
                              disabled={!module.hasButton}
                              width={10}
                              label={'buttonText'}
                              value={module.buttonText}
                              onChange={(e, {value}) => {
                                tip.descriptionModules[i].buttonText = value;
                                this.setState({
                                  tip
                                })
                              }}
                            />

                          </Form>
                        </Card.Content>
                        <Card.Content>
                          {/* 이미지 추가 */}
                          <Header size={'small'}>
                            이미지 추가
                          </Header>
                          <Button as={'label'} size={'mini'}>
                            이미지 추가
                            <FileUploader
                              hidden
                              accept="image/*"
                              name={`module${i}`}
                              randomizeFilename
                              storageRef={firebase.storage().ref(storageRef)}
                              onUploadSuccess={async filename => {
                                await firebase.storage()
                                  .ref(storageRef)
                                  .child(filename)
                                  .getDownloadURL()
                                  .then(imageUrl => {
                                    tip.descriptionModules[i].imageUrl = imageUrl;
                                    this.setState({tip})
                                  });
                              }}
                            />
                          </Button>
                          <Button
                            size={'mini'}
                            negative
                            disabled={!module.imageUrl}
                            content={'이미지 삭제'}
                            onClick={() => {
                              const a = window.confirm('이미지를 삭제하시겠습니까?');
                              if (!a) return;
                              tip.descriptionModules[i].imageUrl = '';
                              this.setState({
                                tip
                              })
                            }}/>
                          <Divider hidden/>
                          <Form>
                            <Form.Field>
                              <Radio
                                toggle
                                label={module.isImageFullWidth ? 'FULL WIDTH' : 'FULL WIDTH 아님'}
                                checked={module.isImageFullWidth}
                                onChange={() => {
                                  tip.descriptionModules[i].isImageFullWidth = !module.isImageFullWidth;
                                  this.setState({tip})
                                }}
                              />
                            </Form.Field>
                          </Form>
                          <Divider hidden/>
                          <Image src={module.imageUrl || mockUpImages.planeImage} size={'small'}/>
                        </Card.Content>
                      </Card>
                    </Grid.Column>
                  </Grid.Row>

                )
              })
            }
          </Grid>

        </Container>

      </Fragment>
    );
  }
}

const styles = {
  verticalModule: {
    marginBottom: 50,
    marginTop: 50
  },
  header: {
    // marginTop: 40,
  }
};

const enhance = connect(
  state => ({
    auth: state.auth,
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(AdminTipDetailContainer);