import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {Link} from 'react-router-dom';
import {db} from "../../constants/firebase";
import {Table, Label, Segment, Button, Container, Header} from 'semantic-ui-react';
import moment from 'moment';
import numeral from 'numeral';
import {bookClubActionCreators} from "../../redux/bookClub/bookClubStore";
import {adminActionCreators} from "../../redux/admin/adminStore";

class AdminBookClubMainContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bookClubBanners: [],
    }
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.fetchBookClubBannersAdmin();
  }

  render() {
    const {bookClubBanners} = this.props.admin;
    return (
      <Container as={Segment} vertical>
        <Header>북클럽 배너 어드민페이지</Header>
        <Button
          content={'북클럽 배너 만들기'}
          as={Link}
          to={'/admin/bookclubbanner/write'}/>
        <Table compact={'very'} size={'small'}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No</Table.HeaderCell>
              <Table.HeaderCell>공개</Table.HeaderCell>
              <Table.HeaderCell>삭제</Table.HeaderCell>
              <Table.HeaderCell>이름</Table.HeaderCell>
              <Table.HeaderCell>버튼</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              bookClubBanners.map((bookClubBanner, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>{i}</Table.Cell>
                    <Table.Cell>
                      {bookClubBanner.isPublic ? "공개" : "비공개"}
                    </Table.Cell>
                    <Table.Cell>{bookClubBanner.isDeleted ? '삭제됨' : ''}</Table.Cell>
                    <Table.Cell>
                      <Label size={'mini'} content={bookClubBanner.type}/>
                      <br/>
                      {bookClubBanner.title.content}
                    </Table.Cell>
                    <Table.Cell>
                      <Button as={Link}
                              content={'>'}
                              to={{
                                pathname: `/admin/bookclubbanner/read/${bookClubBanner.id}`,
                                state: {bookClub: bookClubBanner}
                              }}
                              size={'mini'}/>
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
    ...bookClubActionCreators,
    ...adminActionCreators,
  }
);


export default enhance(AdminBookClubMainContainer);