export const scrapActions = {
  FETCH_ALL_MY_SCRAPS: 'FETCH_ALL_MY_SCRAPS',
  FETCH_ALL_MY_SCRAPS_SUCCESS: 'FETCH_ALL_MY_SCRAPS_SUCCESS',

  FETCH_ALL_PUBLIC_SCRAPS: 'FETCH_ALL_PUBLIC_SCRAPS',
  FETCH_ALL_PUBLIC_SCRAPS_SUCCESS: 'FETCH_ALL_PUBLIC_SCRAPS_SUCCESS',
};



export const scrapActionCreators = {
  fetchAllMyScraps: (cb) => ({type: scrapActions.FETCH_ALL_MY_SCRAPS, cb}),
  fetchAllMyScrapsSuccess: (scraps, cb) => ({type: scrapActions.FETCH_ALL_MY_SCRAPS_SUCCESS, cb, scraps}),
  fetchAllPublicScraps: (cb) => ({type: scrapActions.FETCH_ALL_PUBLIC_SCRAPS, cb}),
  fetchAllPublicScrapsSuccess: (publicScraps, cb) => ({
    type: scrapActions.FETCH_ALL_PUBLIC_SCRAPS_SUCCESS,
    publicScraps,
    cb
  }),
};


const initialState = {
  scraps: [],
  publicScraps: [],
};


export const scrapReducer = (state = initialState, action) => {

  switch (action.type) {
    case scrapActions.FETCH_ALL_MY_SCRAPS_SUCCESS:
      return {
        ...state,
        scraps: [
          ...action.scraps
        ]
      };
    case scrapActions.FETCH_ALL_PUBLIC_SCRAPS_SUCCESS:
      return {
        ...state,
        publicScraps: [
          ...action.publicScraps,
        ]
      };
    default:
      return {
        ...state
      }
  }
};