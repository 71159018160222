import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Button, Input, Checkbox, Divider, Form, Header, Loader,} from "semantic-ui-react";
import NetworkApiV1 from "../../library/NetworkApiV1";
import Colors, {getColorCode} from "../../constants/Colors";
import {authActionCreators} from "../../redux/auth/authStore";
import numeral from 'numeral';

class BookClubApplyDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relationUserBookClub: {},
      isLoaded: false,
      writeApplicantCompleted: false,

      accountLoaded: false,
      isCheckedTerms: false,

      coupons: [],
      couponsLoaded: false,

      bookClub: {},
      bookClubLoaded: false,

      discountAmount: 0,
      cashAmount: 0,
      amountPaymentNeeded: 0,

      paymentInProgress: false,
    }
  }

  componentDidMount() {
    this._loadCoupons();
    this._loadBookClub();
    this.props.fetchUserInfoMy(() => {
      this.setState({
        accountLoaded: true,
      })
    });
  }

  _loadBookClub = async () => {
    const {bookClubId} = this.props.match.params;
    const res = await NetworkApiV1.getBookClubById(bookClubId);
    if (!res.err) {
      if (res.relationUserBookClub) {
        window.alert('이미 지원하신 북클럽입니다.');
        this.props.history.replace(`/my/book-club/${bookClubId}`);
      } else {
        if (!res.err) {
          this.setState({bookClub: res, bookClubLoaded: true}, () => {
            this._handleCalculatePaymentNeeded();
          })
        }
      }
    }
  };


  _loadCoupons = async () => {
    const res = await NetworkApiV1.getMyCoupon();
    if (!res.err) {
      this.setState({
        coupons: res,
        couponsLoaded: true,
      })
    }
  };

  _handleCalculateCashAmount = cashAmount => {
    if (cashAmount > this.props.auth.user.account.cash) return window.alert('오류\n보유한 캐시보다 많은 양을 사용할수 없습니다.');
    this._handleCalculatePaymentNeeded(this.state.discountAmount, cashAmount);
  };

  _handleCalculatePaymentNeeded = (discountAmount = this.state.discountAmount, cashAmount = this.state.cashAmount) => {
    const {bookClub} = this.state;
    const rawTotalAmount = bookClub.defaultMembership + bookClub.numberOfEssays * bookClub.depositPerEssay;

    let amountPaymentNeeded = rawTotalAmount - discountAmount - cashAmount;

    if (amountPaymentNeeded < 0) {
      window.alert('결제필요 금액보다 더 많은 캐시를 사용할 수 없습니다.');
      cashAmount = rawTotalAmount - discountAmount;
      amountPaymentNeeded = 0;
    }


    this.setState({
      amountPaymentNeeded,
      discountAmount,
      cashAmount,
    })
  };

  _handleNext = async () => {
    const {relationUserBookClub} = this.state;
    // this.props.history.push('/my/book-club/');
    this.setState({
      writeApplicantCompleted: true,
    })
  };

  _handleApply = async () => {
    const {bookClub} = this.state;
    if (this.state.paymentInProgress) return;
    await this.setState({paymentInProgress: true});

    const data = {
      bookClubId: bookClub.id,
      cash: -this.state.cashAmount,
      transfer: -this.state.amountPaymentNeeded,
      amountPaymentNeeded: this.state.amountPaymentNeeded,

      // couponId: selectedCouponId,
      discountAmount: this.state.discountAmount
    };

    const res = await NetworkApiV1.applyBookClub(data);
    if (!res.err) {
      window.alert('북클럽 신청이 정상적으로 처리 되었습니다.');
      this.props.fetchUserInfoMy();
      this.props.fetchMyBookClubsApiV1();
      this.props.fetchMyBookClubsBeforeApprovalApiV1();
      this.props.fetchMyBookClubsDeniedApiV1();
      this.props.history.replace(`/my/book-club/${bookClub.id}`);
    } else {
      window.alert(res.userMessage || '오류가 발생했습니다.');
      this.setState({paymentInProgress: false});
    }
  };


  render() {
    const {coupons, couponsLoaded, bookClub, bookClubLoaded, accountLoaded} = this.state;
    if (!couponsLoaded || !accountLoaded || !bookClubLoaded) {
      return <Loader active/>;
    }

    return (
      <div style={{
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: '400px',
        margin: 'auto',
      }}>
        <div>
          {
            (bookClub.defaultMembership > 0 && coupons.length > 0) && (
              <div>
                <Header content={'보유 쿠폰'}/>
              </div>
            )
          }
          {
            this.props.auth.user?.account?.cash > 0 && (
              <div>
                <Header content={'보유캐시'}/>
                <p>사용가능 {numeral(this.props.auth.user?.account?.cash).format('0,0')}원</p>
                <Input
                  fluid
                  value={this.state.cashAmount}
                  onChange={(e, {value: cashAmount}) => {
                    if (!/^\d+$/.test(cashAmount) && cashAmount !== '') return;
                    console.log(Number(cashAmount));
                    this._handleCalculateCashAmount(Number(cashAmount) === NaN ? 0 : Number(cashAmount))
                  }}
                />
              </div>
            )
          }


          <Header content={'결제액'}/>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <p>북클럽 회비</p>
            <p>{numeral(bookClub.defaultMembership).format('0,0')} 원</p>
          </div>
          {
            this.state.discountAmount > 0 && (
              <div style={{display: 'flex', justifyContent: 'space-between',}}>
                <p>쿠폰 적용</p>
                <p>- {numeral(this.state.discountAmount).format('0,0')} 원</p>
              </div>
            )
          }
          <div style={{display: 'flex', justifyContent: 'space-between',}}>
            <p>디파짓</p>
            <p>{numeral(bookClub.numberOfEssays * bookClub.depositPerEssay).format('0,0')} 원</p>
          </div>
          <span style={{color: Colors.fontGrey, fontSize: '0.8rem'}}>
            에세이 {bookClub.numberOfEssays}편 x {numeral(bookClub.depositPerEssay).format('0,0')}원
          </span>
          <Divider/>
          <div style={{display: 'flex', justifyContent: 'space-between',}}>
            <p>최종 결제액</p>
            <p>{numeral(this.state.amountPaymentNeeded).format('0,0')} 원</p>
          </div>
          <div>
            <Checkbox
              onClick={() => {
                this.setState({isCheckedTerms: !this.state.isCheckedTerms})
              }}
              label={{children: '위 내용을 확인하였으며, 동의합니다'}} checked={this.state.isCheckedTerms}/>
          </div>
          <Divider hidden/>
          <Button fluid
                  disabled={this.state.paymentInProgress}
                  active={this.state.paymentInProgress}
                  content={'신청하기'}
                  onClick={() => {
                    if (!this.state.isCheckedTerms) return window.alert('약관 내용에 동의해 주셔야 가입이 가능합니다.');
                    const a = window.confirm('북클럽에 신청하시겠습니까?');
                    if (!a) return;
                    this._handleApply()
                  }}/>
        </div>
      </div>
    );

    if (!this.state.writeApplicantCompleted) {
      return (
        <div style={{minHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          <Container text>
            <Form>
              <Form.TextArea
                rows={10}/>
              <Form.TextArea
                rows={10}/>
              <Form.Button content={'제출하기'} fluid onClick={this._handleNext}/>
            </Form>
          </Container>
        </div>
      );
    } else {
      return (
        <div style={{minHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          <Container text>
            <Form>
              <Form.Dropdown
                width={4}
                placeholder={'color'}
                selection
                label="color"
                // value={story.coverTitle.color}
                // options={getColorCode()}
                onChange={(e, {value}) => {
                  // story.coverTitle.color = value;
                  // this.setState({story})
                }}/>

            </Form>
          </Container>
        </div>
      );
    }

  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);

export default enhance(BookClubApplyDetailContainer);