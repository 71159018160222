import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from '../../redux/auth/authStore';
import {Link} from 'react-router-dom';
import {db} from "../../constants/firebase";
import {Table, Segment, Button, Container, Image, Header} from 'semantic-ui-react';
import moment from 'moment';

class AdminFAQMainContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      faqs: []
    }
  }

  componentDidMount() {
    this._loadFaqData()
  }

  _loadFaqData = async () => {
    await db.collection('faqs')
      .where('isDeleted', '==', false)
      .orderBy('order')
      .get()
      .then(snapshot => {
        const faqs = []
        snapshot.forEach(doc => {
          const faq = Object.assign(doc.data(), {id: doc.id})
          faqs.push(faq)
        })
        this.setState({
          faqs
        })
        console.log(faqs)
      })
  }

  render() {
    const {faqs} = this.state
    return (
      <Container as={Segment} vertical>
        <Header>FAQ 어드민페이지</Header>
        <Button as={Link} to={'/admin/faq/make'}>
          FAQ 만들기
        </Button>
        <Table compact size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>제목</Table.HeaderCell>
              <Table.HeaderCell>Order</Table.HeaderCell>
              <Table.HeaderCell>Tags</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>생성일</Table.HeaderCell>
              <Table.HeaderCell>수정일</Table.HeaderCell>
              <Table.HeaderCell>공개 여부</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              faqs.map((faq, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>
                      <Button
                        size={'mini'}
                        compact
                        as={Link}
                        to={`/admin/faq/detail/${faq.id}`}>
                        {faq.id}
                      </Button>
                    </Table.Cell>
                    <Table.Cell>{faq.title.content}</Table.Cell>
                    <Table.Cell>{faq.order}</Table.Cell>
                    <Table.Cell>
                      {faq.tags}
                    </Table.Cell>
                    <Table.Cell>{faq.description.content.slice(0, 30)}</Table.Cell>
                    <Table.Cell>{moment(faq.createdAt.seconds * 1000).format('YYYY. MM. DD HH:mm')}</Table.Cell>
                    <Table.Cell>{moment(faq.modifiedAt.seconds * 1000).format('YYYY. MM. DD HH:mm')}</Table.Cell>
                    <Table.Cell>
                      {faq.isPublic ? '공개' : '비공개'}
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    auth: state.auth,
  }),
  {
    ...authActionCreators,
  }
);
export default enhance(AdminFAQMainContainer);