import axios from 'axios';
import {AladinTTBKEy} from "../config/keys";

const request = require('request');

// const itemSearchAPI = 'https://www.aladin.co.kr/ttb/api/ItemSearch.aspx';
const itemSearchAPI = '/ttb/api/ItemSearch.aspx';
const itemLookUpAPI = 'https://www.aladin.co.kr/ttb/api/ItemLookUp.aspx';
const FIREBASE_API_END_POINT = 'https://us-central1-readers-prod.cloudfunctions.net/api';

export const QUERY_TYPE_TITLE = 'Title';
export const QUERY_TYPE_AUTHOR = 'Author';
export const QUERY_TYPE_KEYWORD = 'Keyword';
export const QUERY_TYPE_PUBLISHER = 'Publisher';

export const SEARCH_TARGET_BOOK = 'Book';
export const SEARCH_TARGET_FOREIGN = 'Foreign';
export const SEARCH_TARGET_MUSIC = 'Music';
export const SEARCH_TARGET_DVD = 'DVD';

export const searchTargetDropDownList = () => {
  return [
    {
      key : 0,
      text : '국내도서',
      value : 'Book',
    },
    {
      key : 1,
      text : '해외도서',
      value : 'Foreign',
    },
  ]
}


const AladinItemSearchFromFirebase = async (
  {
    query,
    queryType = QUERY_TYPE_KEYWORD,
    page,
    perPage,
    SearchTarget = SEARCH_TARGET_BOOK
  }) => {
  return await axios
    .get(FIREBASE_API_END_POINT + '/aladin/itemsearch', {
      headers: {},
      params: {
        query,
        queryType,
        page,
        perPage,
        SearchTarget,
      }
    })
    .then(response => {
      // console.log(response.data);
      if (response.data.errorCode) {
        return {
          err: response.data.errorMessage
        };
      } else {
        return response.data;
      }
    }).catch(e => {
      console.log(e);
      return {
        err: '에러가 발생했습니다.'
      };
    });
};


const AladinItemSearch = async (
  {
    query,
    queryType = QUERY_TYPE_KEYWORD,
    page,
    perPage,
    SearchTarget = SEARCH_TARGET_BOOK
  }) => {

  return await axios
    .get(itemSearchAPI, {
      params: {
        ttbkey: AladinTTBKEy,
        Query: query,

        QueryType: queryType,
        SearchTarget: SearchTarget,

        Start: page,
        MaxResults: perPage,
        Output: 'js',
        Version: '20131101',
        Cover: 'Big',
        includeKey: 1,
      },
      headers: {
        'Content-Type': 'application/json',
        // Accept: '*/*',
        // 'Access-Control-Allow-Headers': 'Origin,Accept,X-Requested-With,Content-Type,Access-Control-Request-Method,Access-Control-Request-Headers,Authorization'
      }
    })
    .then(response => {
      if (response.data.errorCode) {
        return {
          err: response.data.errorMessage
        };
      } else {
        console.log(response.data);
        return response.data;
      }
    })
    .catch(e => {
      console.log(e);
      return {
        err: '에러가 발생했습니다.'
      }
    })
};


const AladinItemLookUpSearch = async ({isbn13}) => {
  console.log({isbn13})
  return await axios
    .get(itemLookUpAPI, {
      params: {
        ttbkey: AladinTTBKEy,
        ItemId: isbn13,
        // 선택
        ItemIdType: 'ISBN13',
        Cover: 'Big',
        Output: 'JS',
        Version: '20131101',
        includeKey: 1,

        OptResult: 'ebookList,reviewList,previewImgList,authors,fullDescription,categoryIdList,publisherFulldescription,toc'
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(e => {
      console.log(e);
      return {
        err: true
      }
    })
}



export default {
  AladinItemSearch,
  AladinItemLookUpSearch,
  AladinItemSearchFromFirebase
}
