import _ from 'lodash';

const BOOKSHELF_STATUS_ONLY_FOR_ME = 'BOOKSHELF_STATUS_ONLY_FOR_ME';
const BOOKSHELF_STATUS_FOR_FOLLOWER = 'BOOKSHELF_STATUS_FOR_FOLLOWER';
const BOOKSHELF_STATUS_PUBLIC = 'BOOKSHELF_STATUS_PUBLIC';

export const bookshelfStatusList = [
  {
    key: 0,
    text: '공개',
    value: BOOKSHELF_STATUS_PUBLIC,
  },
  {
    key: 1,
    text: '팔로워 공개',
    value: BOOKSHELF_STATUS_FOR_FOLLOWER,
  },
  {
    key: 2,
    text: '비공개',
    value: BOOKSHELF_STATUS_ONLY_FOR_ME,
  },
];


export const NEW_BOOKSHELF = {
  title: '',
  tags: [],
  categories: [],
  order: 999,

  description: '',
  backgroundImage: '',

  bookIsbns : [],
  numberOfBooks : 0,

  status: BOOKSHELF_STATUS_PUBLIC,
  userId : '',
  userEmail:'',

  isDeleted: false,
  createdAt: new Date(),
  modifiedAt: new Date(),

};

