import {firebase} from "../constants/firebase";

export const NEW_ESSAY_COMMENT = {
  essayId :'',
  userId : '',
  content : '',

  likeUserIds : [],
  numberOfLikes : 0,

  reportUserIds : [],
  numberOfReports : 0,

  isDeleted : false,
  isReported : false,


  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
};