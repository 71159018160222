import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {Container, Divider, Radio, Image, Segment, Button, Grid, Modal, Loader, Form} from 'semantic-ui-react';
import {
  NEW_STORY, STORY_COVER_TYPE_3_SUB_MAIN_DESC, STORY_COVER_TYPE_4_CONTENT_CENTERED,
  STORY_COVER_TYPE_5_CONTENT_LIST, STORY_LIST_CONTENT_TYPE_BOOK,
  storyCoverTypeDropDownList,
  storyListContentTypeDropDownList
} from "../../dataStructure/story";
import Network from "../../library/Network";
import {getColorCode} from "../../constants/Colors";
import {firebase} from "../../constants/firebase";
import FileUploader from "react-firebase-file-uploader";
import moment from "moment";
import mockUpImages from "../../constants/mockUpImages";
import ContentModuleComponent from "../../components/contentModule/ContentModuleComponent";
import {contentTypeDropDownList, contentTypeList, NEW_CONTENT_MODULE} from "../../dataStructure/contentModule";
import {moveItemInArrayFromIndexToIndex} from "../../middleware/arrayHandler";
import AlgoliaSearch from "../../middleware/AlgoliaSearch";
import ContentListItemComponent from "../../components/story/ContentListItemComponent";

const storageRef = `story/${moment().format("YYYYMM")}`;

class AdminStoryDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      story: {},
      isLoaded: false,
      uploading: false,
      modalOpen: false,
      indexOfContentModuleAdded: 0,

    }
  }


  componentDidMount() {
    this._loadStory();
  }

  _loadStory = async () => {
    const storyId = this.props.match.params.storyId;
    const isNew = storyId === undefined;

    if (!isNew) {
      const res = await Network.getStoryById(storyId);
      this.setState({
        story: res,
        isLoaded: true,
      })
    } else {
      this.setState({
        story: NEW_STORY(),
        isLoaded: true,
      })
    }
  };
  _handleComplete = async () => {
    const storyId = this.props.match.params.storyId;
    const isNew = storyId === undefined;

    if (isNew) {
      const a = window.confirm('생성하시겠습니까?');
      if (!a) return;

      this._handleMake();
    } else {
      const a = window.confirm('수정하시겠습니까?');
      if (!a) return;

      this._handleUpdate();
    }
  };
  _handleMake = async () => {
    const {story} = this.state;
    this.setState({uploading: true,});

    const res = await Network.postStory(story);

    this.setState({uploading: false,});
    if (res.err) {
      window.alert('오류 발생');
      return;
    }

    const storyId = res.id;
    window.alert('큐레이션이 등록되었습니다.');
    this.props.history.replace(`/admin/story/detail/${storyId}`);
  };
  _handleUpdate = async () => {
    const {story} = this.state;
    this.setState({uploading: true,});
    const res = await Network.updateStory(story);

    this.setState({uploading: false,});
    if (res.err) {
      window.alert('오류 발생');
      return;
    }
    window.alert('수정되었습니다.');
    this._loadStory();
  };
  _handleDelete = async () => {
    const {story} = this.state;
    this.setState({uploading: true,});
    const res = await Network.updateStory({
      id: story.id,
      isDeleted: true,
    });

    this.setState({uploading: false,});
    if (res.err) {
      window.alert('오류 발생');
      return;
    }
    window.alert('수정되었습니다.');
    this._loadStory();
  };
  _handleRestore = async () => {
    const {story} = this.state;
    this.setState({uploading: true,});
    const res = await Network.updateStory({
      id: story.id,
      isDeleted: false,
    });

    this.setState({uploading: false,});
    if (res.err) {
      window.alert('오류 발생');
      return;
    }
    window.alert('수정되었습니다.');
    this._loadStory();
  };
  handleUploadSuccess = async (filename) => {
    const {story} = this.state;
    await firebase.storage()
      .ref(storageRef)
      .child(filename)
      .getDownloadURL()
      .then(imageUrl => {
        story.coverBackgroundImageUrl = imageUrl;
        // console.log(curation);
        this.setState({story})
      });
  };


  render() {
    const {story, isLoaded, uploading} = this.state;
    const storyId = this.props.match.params.storyId;
    const isNew = storyId === undefined;

    if (!isLoaded) return <Loader active/>;
    return (
      <div>
        {/* 기본 정보 */}
        <Container as={Segment} vertical>
          <Button
            primary
            content={'확인'}
            disabled={uploading}
            onClick={() => {
              this._handleComplete();
            }}
          />
          <Button
            negative
            content={'삭제'}
            disabled={uploading || story.isDeleted || isNew}
            onClick={() => {
              this._handleDelete();
            }}
          />
          <Button
            positive
            content={'복구'}
            disabled={uploading || !story.isDeleted}
            onClick={() => {
              this._handleRestore();
            }}
          />
          <Divider/>

          <Radio
            toggle
            label={story.isPublic ? '공개' : '비공개'}
            checked={story.isPublic}
            onChange={() => {
              story.isPublic = !story.isPublic;
              this.setState({story})
            }}
          />
          <Radio
            toggle
            label={story.onlyForNewUser ? '신규 회원 전용 컨텐츠 O' : '신규 회원 전용 컨텐츠 X'}
            checked={story.onlyForNewUser}
            onChange={() => {
              story.onlyForNewUser = !story.onlyForNewUser;
              this.setState({story})
            }}
          />
          <Divider/>
          <Form>
            <Form.Group>
              <Form.Input
                type={'number'}
                label={'order'}
                value={story.order}
                onChange={(e, {value}) => {
                  story.order = Number(value);
                  this.setState({story});
                }}
              />
              <Form.Dropdown
                width={8}
                placeholder={'coverType'}
                selection
                label="coverType"
                value={story.coverType}
                options={storyCoverTypeDropDownList()}
                onChange={(e, {value}) => {
                  story.coverType = value;
                  this.setState({story})
                }}
              />
              <Form.Dropdown
                width={7}
                disabled={story.coverType !== STORY_COVER_TYPE_5_CONTENT_LIST}
                placeholder={'listContentType'}
                selection
                label="listContentType"
                value={story.listContentType}
                options={storyListContentTypeDropDownList()}
                onChange={(e, {value}) => {
                  story.listContentType = value;
                  this.setState({story})
                }}
              />
            </Form.Group>
          </Form>
        </Container>

        {
          story.coverType === STORY_COVER_TYPE_5_CONTENT_LIST && (
            <Container as={Segment} vertical>
              <ContentListItemComponent
                story={story}
                onChangeState={(story) => {
                  this.setState({story})
                }}/>
              <Form>
                <Form.Group style={{alignItems: 'flex-end'}}>
                  <Form.Input
                    width={8}
                    type={'number'}
                    value={story.maxNumberOfListContents}
                    disabled={story.coverType !== STORY_COVER_TYPE_5_CONTENT_LIST}
                    label={'maxNumberOfListContents'}
                    onChange={(e, {value}) => {
                      story.maxNumberOfListContents = Number(value);
                      this.setState({story})
                    }}
                  />
                  <Form.Radio
                    width={4}
                    toggle
                    label={story.showButton ? '더보기 버튼 보임' : '더보기 버튼 숨김'}
                    disabled={story.coverType !== STORY_COVER_TYPE_5_CONTENT_LIST}
                    checked={story.showButton}
                    onChange={() => {
                      story.showButton = !story.showButton;
                      this.setState({story})
                    }}
                  />
                  <Form.Input
                    width={4}
                    value={story.buttonText}
                    disabled={story.coverType !== STORY_COVER_TYPE_5_CONTENT_LIST || !story.showButton}
                    label={'buttonText'}
                    onChange={(e, {value}) => {
                      story.buttonText = value;
                      this.setState({story})
                    }}
                  />
                </Form.Group>
              </Form>
            </Container>
          )
        }

        {/* 커버 타이틀, 등등*/}
        <Container as={Segment} vertical>
          <Grid>
            <Grid.Row>
              <Grid.Column width={3}>
                <Button as={'label'} size={'mini'}>
                  이미지 업로드
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="square"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename);
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(story.coverBackgroundImageUrl)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    story.coverBackgroundImageUrl = '';
                    this.setState({
                      story
                    })
                  }}/>
                <Image src={story.coverBackgroundImageUrl || mockUpImages.planeImage} size={'medium'}/>
                <Form>
                  <Form.Input
                    value={story.coverBackgroundColor}
                    label={'coverBackgroundColor'}
                    onChange={(e, {value}) => {
                      story.coverBackgroundColor = value;
                      this.setState({story})
                    }}
                  />
                  <Form.Input
                    disabled={story.coverType !== STORY_COVER_TYPE_3_SUB_MAIN_DESC}
                    value={story.coverBottomBackgroundColor}
                    label={'coverBottomBackgroundColor'}
                    onChange={(e, {value}) => {
                      story.coverBottomBackgroundColor = value;
                      this.setState({story})
                    }}
                  />
                </Form>
              </Grid.Column>
              <Grid.Column width={13}>
                <Form>
                  <Form.Group>
                    <Form.TextArea
                      width={7}
                      value={story.coverTitle.text}
                      label={'coverTitle.text'}
                      onChange={(e, {value}) => {
                        story.coverTitle.text = value;
                        this.setState({story})
                      }}
                    />
                    <Form.Dropdown
                      width={4}
                      placeholder={'color'}
                      selection
                      label="color"
                      value={story.coverTitle.color}
                      options={getColorCode()}
                      onChange={(e, {value}) => {
                        story.coverTitle.color = value;
                        this.setState({story})
                      }}
                    />
                    <Form.Input
                      width={3}
                      value={story.coverTitle.color}
                      label={'color'}
                      onChange={(e, {value}) => {
                        story.coverTitle.color = value;
                        this.setState({story})
                      }}
                    />
                    <Form.Input
                      width={2}
                      type={'number'}
                      value={story.coverTitle.fontSize}
                      label={'fontSize'}
                      onChange={(e, {value}) => {
                        story.coverTitle.fontSize = Number(value);
                        this.setState({story})
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.TextArea
                      width={7}
                      value={story.coverSubTitle.text}
                      label={'coverSubTitle'}
                      onChange={(e, {value}) => {
                        story.coverSubTitle.text = value;
                        this.setState({story})
                      }}
                    />
                    <Form.Dropdown
                      width={4}
                      placeholder={'color'}
                      selection
                      label="color"
                      value={story.coverSubTitle.color}
                      options={getColorCode()}
                      onChange={(e, {value}) => {
                        story.coverSubTitle.color = value;
                        this.setState({story})
                      }}
                    />
                    <Form.Input
                      width={3}
                      value={story.coverSubTitle.color}
                      label={'color'}
                      onChange={(e, {value}) => {
                        story.coverSubTitle.color = value;
                        this.setState({story})
                      }}
                    />
                    <Form.Input
                      width={2}
                      type={'number'}
                      value={story.coverSubTitle.fontSize}
                      label={'fontSize'}
                      onChange={(e, {value}) => {
                        story.coverSubTitle.fontSize = Number(value);
                        this.setState({story})
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.TextArea
                      width={7}
                      value={story.coverDescription.text}
                      label={'text'}
                      onChange={(e, {value}) => {
                        story.coverDescription.text = value;
                        this.setState({story})
                      }}
                    />
                    <Form.Dropdown
                      width={4}
                      placeholder={'color'}
                      selection
                      label="color"
                      value={story.coverDescription.color}
                      options={getColorCode()}
                      onChange={(e, {value}) => {
                        story.coverDescription.color = value;
                        this.setState({story})
                      }}
                    />
                    <Form.Input
                      width={3}
                      value={story.coverDescription.color}
                      label={'color'}
                      onChange={(e, {value}) => {
                        story.coverDescription.color = value;
                        this.setState({story})
                      }}
                    />
                    <Form.Input
                      width={2}
                      type={'number'}
                      value={story.coverDescription.fontSize}
                      label={'fontSize'}
                      onChange={(e, {value}) => {
                        story.coverDescription.fontSize = Number(value);
                        this.setState({story})
                      }}
                    />
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
            {
              story.coverType === STORY_COVER_TYPE_4_CONTENT_CENTERED && (
                <Grid.Row>
                  <Grid.Column width={3}>
                    <Button as={'label'} size={'mini'}>
                      이미지 업로드
                      <FileUploader
                        hidden
                        accept="image/*"
                        name="square"
                        randomizeFilename
                        storageRef={firebase.storage().ref(storageRef)}
                        onUploadSuccess={filename => {
                          const {story} = this.state;
                          firebase.storage()
                            .ref(storageRef)
                            .child(filename)
                            .getDownloadURL()
                            .then(imageUrl => {
                              story.centerContentImageUrl = imageUrl;
                              // console.log(curation);
                              this.setState({story})
                            });
                        }}
                      />
                    </Button>
                    <Button
                      size={'mini'}
                      disabled={!(story.centerContentImageUrl)}
                      content={'삭제'}
                      negative
                      onClick={() => {
                        story.centerContentImageUrl = '';
                        this.setState({
                          story
                        })
                      }}/>
                    <Image src={story.centerContentImageUrl || mockUpImages.planeImage} size={'medium'}/>
                    <Form>
                      <Form.Input
                        type={'number'}
                        value={story.centerContentImageRadius}
                        label={'centerContentImageRadius'}
                        onChange={(e, {value}) => {
                          story.centerContentImageRadius = Number(value);
                          this.setState({story})
                        }}
                      />
                      <Form.Input
                        type={'number'}
                        value={story.centerContentImageHeight}
                        label={'centerContentImageHeight'}
                        onChange={(e, {value}) => {
                          story.centerContentImageHeight = Number(value);
                          this.setState({story})
                        }}
                      />
                    </Form>
                  </Grid.Column>
                  <Grid.Column width={13}>
                    <Form>
                      <Form.Group>
                        <Form.TextArea
                          width={7}
                          value={story.centerContentText.text}
                          label={'centerContentText.text'}
                          onChange={(e, {value}) => {
                            story.centerContentText.text = value;
                            this.setState({story})
                          }}
                        />
                        <Form.Dropdown
                          width={4}
                          placeholder={'color'}
                          selection
                          label="color"
                          value={story.centerContentText.color}
                          options={getColorCode()}
                          onChange={(e, {value}) => {
                            story.centerContentText.color = value;
                            this.setState({story})
                          }}
                        />
                        <Form.Input
                          width={3}
                          value={story.centerContentText.color}
                          label={'color'}
                          onChange={(e, {value}) => {
                            story.centerContentText.color = value;
                            this.setState({story})
                          }}
                        />
                        <Form.Input
                          width={2}
                          type={'number'}
                          value={story.centerContentText.fontSize}
                          label={'fontSize'}
                          onChange={(e, {value}) => {
                            story.centerContentText.fontSize = Number(value);
                            this.setState({story})
                          }}
                        />
                      </Form.Group>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              )
            }
          </Grid>
        </Container>

        {/* 컨텐츠 타이틀, 등등*/}
        <Container as={Segment} vertical>
          <Grid>
            <Grid.Row>
              <Grid.Column width={3}>
                <Button as={'label'} size={'mini'}>
                  이미지 업로드
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="square"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      const {story} = this.state;
                      firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          story.contentBackgroundImageUrl = imageUrl;
                          // console.log(curation);
                          this.setState({story})
                        });
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(story.contentBackgroundImageUrl)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    story.contentBackgroundImageUrl = '';
                    this.setState({
                      story
                    })
                  }}/>
                <Image src={story.contentBackgroundImageUrl || mockUpImages.planeImage} size={'medium'}/>
                <Form>
                  <Form.Input
                    value={story.contentBackgroundColor}
                    label={'contentBackgroundColor'}
                    onChange={(e, {value}) => {
                      story.contentBackgroundColor = value;
                      this.setState({story})
                    }}
                  />
                  <Form.Input
                    value={story.contentHeaderColor}
                    label={'contentHeaderColor'}
                    onChange={(e, {value}) => {
                      story.contentHeaderColor = value;
                      this.setState({story})
                    }}
                  />
                  <Form.Input
                    value={story.contentHeaderTextColor}
                    label={'contentHeaderTextColor'}
                    onChange={(e, {value}) => {
                      story.contentHeaderTextColor = value;
                      this.setState({story})
                    }}
                  />
                </Form>
              </Grid.Column>
              <Grid.Column width={13}>
                <Form>
                  <Form.Group>
                    <Form.TextArea
                      width={7}
                      value={story.contentTitle.text}
                      label={'contentTitle.text'}
                      onChange={(e, {value}) => {
                        story.contentTitle.text = value;
                        this.setState({story})
                      }}
                    />
                    <Form.Dropdown
                      width={4}
                      placeholder={'color'}
                      selection
                      label="color"
                      value={story.contentTitle.color}
                      options={getColorCode()}
                      onChange={(e, {value}) => {
                        story.contentTitle.color = value;
                        this.setState({story})
                      }}
                    />
                    <Form.Input
                      width={3}
                      value={story.contentTitle.color}
                      label={'color'}
                      onChange={(e, {value}) => {
                        story.contentTitle.color = value;
                        this.setState({story})
                      }}
                    />
                    <Form.Input
                      width={2}
                      type={'number'}
                      value={story.contentTitle.fontSize}
                      label={'fontSize'}
                      onChange={(e, {value}) => {
                        story.contentTitle.fontSize = Number(value);
                        this.setState({story})
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.TextArea
                      width={7}
                      value={story.contentSubTitle.text}
                      label={'contentSubTitle'}
                      onChange={(e, {value}) => {
                        story.contentSubTitle.text = value;
                        this.setState({story})
                      }}
                    />
                    <Form.Dropdown
                      width={4}
                      placeholder={'color'}
                      selection
                      label="color"
                      value={story.contentSubTitle.color}
                      options={getColorCode()}
                      onChange={(e, {value}) => {
                        story.contentSubTitle.color = value;
                        this.setState({story})
                      }}
                    />
                    <Form.Input
                      width={3}
                      value={story.contentSubTitle.color}
                      label={'color'}
                      onChange={(e, {value}) => {
                        story.contentSubTitle.color = value;
                        this.setState({story})
                      }}
                    />
                    <Form.Input
                      width={2}
                      type={'number'}
                      value={story.contentSubTitle.fontSize}
                      label={'fontSize'}
                      onChange={(e, {value}) => {
                        story.contentSubTitle.fontSize = Number(value);
                        this.setState({story})
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.TextArea
                      width={7}
                      value={story.contentDescription.text}
                      label={'text'}
                      onChange={(e, {value}) => {
                        story.contentDescription.text = value;
                        this.setState({story})
                      }}
                    />
                    <Form.Dropdown
                      width={4}
                      placeholder={'color'}
                      selection
                      label="color"
                      value={story.contentDescription.color}
                      options={getColorCode()}
                      onChange={(e, {value}) => {
                        story.contentDescription.color = value;
                        this.setState({story})
                      }}
                    />
                    <Form.Input
                      width={3}
                      value={story.contentDescription.color}
                      label={'color'}
                      onChange={(e, {value}) => {
                        story.contentDescription.color = value;
                        this.setState({story})
                      }}
                    />
                    <Form.Input
                      width={2}
                      type={'number'}
                      value={story.contentDescription.fontSize}
                      label={'fontSize'}
                      onChange={(e, {value}) => {
                        story.contentDescription.fontSize = Number(value);
                        this.setState({story})
                      }}
                    />
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        {/* 컨텐츠 모듈 */}
        <Container as={Segment} vertical>
          {
            story.contentModules.map((contentModule, index) => {
              return (
                <ContentModuleComponent
                  save={this._handleComplete}
                  onChangeState={(module) => {
                    story.contentModules[index] = module;
                    this.setState({story});
                  }}
                  moveModuleFoward={() => {
                    if (index === 0) return window.alert('처음입니다');
                    story.contentModules = moveItemInArrayFromIndexToIndex(story.contentModules, index, index - 1);
                    this.setState({story})
                  }}
                  moveModuleBack={() => {
                    if (index === story.contentModules.length - 1) return window.alert('끝입니다');
                    story.contentModules = moveItemInArrayFromIndexToIndex(story.contentModules, index, index + 1);
                    this.setState({story})
                  }}
                  deleteModule={() => {
                    const a = window.confirm('삭제하시겠습니까?');
                    if (!a) return;
                    story.contentModules.splice(index, 1);
                    this.setState({story});
                  }}
                  addModule={() => {
                    this.setState({
                      modalOpen: true,
                      indexOfContentModuleAdded: index + 1,
                    })
                  }}
                  contentModule={contentModule}
                  key={index}/>
              )
            })
          }
          <Button
            content={'추가하기'}
            onClick={() => {
              this.setState({
                modalOpen: true,
                indexOfContentModuleAdded: story.contentModules.length,
              })
            }}
          />
          <Button
            primary
            content={'저장하기'}
            disabled={uploading}
            onClick={() => {
              this._handleComplete();
            }}
          />
          <Modal open={this.state.modalOpen}>
            <Modal.Content>
              {
                contentTypeDropDownList().map((contentType, i) => {
                  return (
                    <Button
                      fluid
                      content={contentType.text}
                      key={i}
                      style={{marginBottom: 5}}
                      onClick={() => {
                        story.contentModules.splice(this.state.indexOfContentModuleAdded, 0, NEW_CONTENT_MODULE(contentType.value));
                        this.setState({
                          story
                        })
                      }}
                    />
                  )
                })
              }
            </Modal.Content>
            <Modal.Actions>
              <Button content={'취소'}
                      negative
                      onClick={() => {
                        this.setState({modalOpen: false,})
                      }}/>
            </Modal.Actions>
          </Modal>
        </Container>
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(AdminStoryDetailContainer);