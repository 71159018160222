import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';


export const COLLECTION_NAME_LEADER_REVIEWS = 'leaderReviews';
export const COLLECTION_NAME_BOOKSHELVES = 'bookshelves';
export const COLLECTION_NAME_ADMIN_SETTING = 'adminSettings';
export const COLLECTION_NAME_BOOK_CLUB_BANNER = 'bookClubBanners';
export const COLLECTION_NAME_STORY = 'stories';
export const COLLECTION_NAME_PLACE = 'places';


const firebaseConfig = {
  apiKey: "AIzaSyBTaIOttirBazP0Wpv87ULOoZ5iLlxsn-Y",
  authDomain: "readers-prod.firebaseapp.com",
  databaseURL: "https://readers-prod.firebaseio.com",
  projectId: "readers-prod",
  storageBucket: "readers-prod.appspot.com",
  messagingSenderId: "491256222034",
  appId: "1:491256222034:web:beeb1bc13320155a"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export const firebaseDB = {
  accounts: db.collection('accounts'),
  adminSettings: db.collection(COLLECTION_NAME_ADMIN_SETTING),
  adminBooks: db.collection('adminBooks'),
  banners: db.collection('banners'),
  bookClubApplies: db.collection('bookClubApplies'),
  bookClubBanners: db.collection(COLLECTION_NAME_BOOK_CLUB_BANNER),
  bookClubBooks: db.collection('bookClubBooks'),
  bookClubEssaySchedules: db.collection('bookClubEssaySchedules'),
  bookClubs: db.collection('bookClubs'),
  books: db.collection('books'),
  bookshelves: db.collection('bookshelves'),
  couponCodes: db.collection('couponCodes'),
  coupons: db.collection('coupons'),
  curations: db.collection('curations'),
  essays: db.collection('essays'),
  essayComments: db.collection('essayComments'),
  faqs: db.collection('faqs'),
  leaders: db.collection('leaders'),
  notices: db.collection('notices'),
  partners: db.collection('partners'),
  places: db.collection(COLLECTION_NAME_PLACE),
  scraps: db.collection('scraps'),
  stories: db.collection(COLLECTION_NAME_STORY),
  tips: db.collection('tips'),
  transactions: db.collection('transactions'),
  users: db.collection('users'),
  inquiries: db.collection('inquiries'),

  libraries: db.collection('libraries'),
  leaderReviews: db.collection(COLLECTION_NAME_LEADER_REVIEWS),
};

export {
  firebase,
  db,
  auth,
  storage
};