const AladinTTBKEy = 'ttbyhyun05131736001';
const GoogleMapKey = 'AIzaSyBTaIOttirBazP0Wpv87ULOoZ5iLlxsn-Y';
export const kakaoAPIKey = 'KakaoAK 4d717c99edda54b1171e718936d2ab7b';

export const ALGOLIA_APP_ID = 'AS83TYXCO6';
export const ALGOLIA_SEARCH_KEY = '3b332056ce7770e8dc05fc1971b9a98c';
export const AMPLITUDE_API_KEY = '4f7ec9e33482233a52d4551a2271ed58';

export {
  AladinTTBKEy,
  GoogleMapKey,
}