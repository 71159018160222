import React, {Component} from 'react';
import Colors from "../../constants/Colors";
import {Container, Image, Segment, Grid, Divider, Button, List, Header,} from 'semantic-ui-react';
import {Link} from 'react-router-dom'

class Footer extends Component {
  render() {
    return (
      <div>
        <Segment vertical basic
                 style={{padding: '5em 0em', backgroundColor: 'white'}}>
          <Container>
            <Grid divided stackable>
              <Grid.Row>
                {/*<Grid.Column width={4}>
                  <Header as='h4' content='북클럽'/>
                  <List link>
                    <List.Item style={styles.linkText} as={Link} to={'/bookclub/list/recruit'}>모집중</List.Item>
                    <List.Item style={styles.linkText} as={Link} to={'/bookclub/list/comingsoon'}>모집예정</List.Item>
                    <List.Item style={styles.linkText} as={Link} to={'/bookclub/list/ing'}>진행중</List.Item>
                    <List.Item style={styles.linkText} as={Link} to={'/bookclub/list/ended'}>종료</List.Item>
                  </List>
                </Grid.Column>*/}
                <Grid.Column width={4}>
                  <Header as='h4' content='소개'/>
                  <List link>
                    <List.Item style={styles.linkText} as={Link} to={'/about/bookclub'}>북클럽</List.Item>
                    <List.Item style={styles.linkText} as={Link} to={'/about/camera'}>카메라</List.Item>
                    <List.Item style={styles.linkText} as={Link} to={'/about/library'}>서재</List.Item>
                    {/*<List.Item style={styles.linkText} as={Link} to={'/about/space'}>공간</List.Item>*/}
                  </List>
                </Grid.Column>
                {/*<Grid.Column width={4}>
                  <Header as='h4' content='제휴'/>
                  <List link>
                    <List.Item style={styles.linkText} as={Link} to={'/inquiry/partner'}>제휴문의</List.Item>
                    <List.Item style={styles.linkText} as={Link} to={'/inquiry/leader'}>클럽장 모집</List.Item>
                  </List>
                </Grid.Column>*/}
              </Grid.Row>
            </Grid>
            <Divider style={{marginTop: '2rem', marginBottom: '2rem'}}/>
            <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'center', marginBottom: '1rem'}}>
              <a href="mailto:support@my-assi.com">
                <Image avatar src={'/images/social/gmail.png'}/>
              </a>
              <a href='http://pf.kakao.com/_xawQfj/chat' target={'_blank'}>
                <Image avatar src={'/images/social/kakao.png'}/>
              </a>
              <a href='https://www.facebook.com/SphereReaders' target={'_blank'}>
                <Image avatar src={'/images/social/facebook.png'}/>
              </a>
              <a href='https://blog.naver.com/withreaders/' target={'_blank'}>
                <Image avatar src={'/images/social/naver.jpg'}/>
              </a>
            </div>
            <div style={{fontSize: '0.8rem', textAlign: 'center'}}>
              {/*비잉앤두잉 | 사업자등록번호 : 396-21-00643 | 통신판매업신고번호 : 제 2019-서울송파-2186호 | 대표 : 윤영훈 | 주소 : 서울특별시 송파구 송파대로 111<br/>*/}
              <Link to={'/privacy'}>개인정보처리방침</Link> {/*| <Link to={'/terms/privacy'}>이용약관</Link>*/}
            </div>
          </Container>
        </Segment>
      </div>
    );
  }
}

const styles = {
  linkText: {
    fontSize: 12,
  }
};

export default Footer;
