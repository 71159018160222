import {ESSAYS_PER_PAGE, SCRAPS_PER_PAGE} from "../../library/Network";

export const adminActions = {
  ADMIN_INITIALIZE: 'ADMIN_INITIALIZE',
  TOGGLE_ADMIN_MODE: 'TOGGLE_ADMIN_MODE',

  FETCH_ALL_USERS: 'FETCH_ALL_USERS',
  FETCH_ALL_USERS_SUCCESS: 'FETCH_ALL_USERS_SUCCESS',

  FETCH_ALL_COUPON_CODE: 'FETCH_ALL_COUPON_CODE',
  FETCH_ALL_COUPON_CODE_SUCCESS: 'FETCH_ALL_COUPON_CODE_SUCCESS',

  FETCH_ALL_COUPONS: 'FETCH_ALL_COUPONS',
  FETCH_ALL_COUPONS_SUCCESS: 'FETCH_ALL_COUPONS_SUCCESS',


  FETCH_ALL_BOOK_CLUBS_ADMIN: 'FETCH_ALL_BOOK_CLUBS_ADMIN',
  FETCH_ALL_BOOK_CLUBS_ADMIN_SUCCESS: 'FETCH_ALL_BOOK_CLUBS_ADMIN_SUCCESS',

  FETCH_BOOK_CLUB_BANNERS_ADMIN: 'FETCH_BOOK_CLUB_BANNERS_ADMIN',
  FETCH_BOOK_CLUB_BANNERS_ADMIN_SUCCESS: 'FETCH_BOOK_CLUB_BANNERS_ADMIN_SUCCESS',


  FETCH_SCRAPS_ADMIN: 'FETCH_SCRAPS_ADMIN',
  FETCH_SCRAPS_MORE_ADMIN: 'FETCH_SCRAPS_MORE_ADMIN',
  FETCH_SCRAPS_ADMIN_SUCCESS: 'FETCH_SCRAPS_ADMIN_SUCCESS',

  FETCH_ESSAYS_ADMIN: 'FETCH_ESSAYS_ADMIN',
  FETCH_ESSAYS_MORE_ADMIN: 'FETCH_ESSAYS_MORE_ADMIN',
  FETCH_ESSAYS_ADMIN_SUCCESS: 'FETCH_ESSAYS_ADMIN_SUCCESS',

  FETCH_ALL_PLACE_ADMIN: 'FETCH_ALL_PLACE_ADMIN',
  FETCH_ALL_PLACE_ADMIN_SUCCESS: 'FETCH_ALL_PLACE_ADMIN_SUCCESS',

  FETCH_ALL_ADMIN_BOOKS: 'FETCH_ALL_ADMIN_BOOKS',
  FETCH_ALL_ADMIN_BOOKS_SUCCESS: 'FETCH_ALL_ADMIN_BOOKS_SUCCESS',

  FETCH_ALL_LEADERS_ADMIN: 'FETCH_ALL_LEADERS_ADMIN',
  FETCH_ALL_LEADERS_ADMIN_SUCCESS: 'FETCH_ALL_LEADERS_ADMIN_SUCCESS',

  FETCH_ALL_PARTNERS_ADMIN: 'FETCH_ALL_PARTNERS_ADMIN',
  FETCH_ALL_PARTNERS_ADMIN_SUCCESS: 'FETCH_ALL_PARTNERS_ADMIN_SUCCESS',

  FETCH_ALL_STORIES_ADMIN: 'FETCH_ALL_STORIES_ADMIN',
  FETCH_ALL_STORIES_ADMIN_SUCCESS: 'FETCH_ALL_STORIES_ADMIN_SUCCESS',


  FETCH_ALL_INQUIRIES_ADMIN: 'FETCH_ALL_INQUIRIES_ADMIN',
  FETCH_ALL_INQUIRIES_ADMIN_SUCCESS: 'FETCH_ALL_INQUIRIES_ADMIN_SUCCESS',


};

export const adminActionCreators = {
  adminInitialize: (cb) => ({type: adminActions.ADMIN_INITIALIZE, cb}),
  toggleAdminMode: (cb) => ({type: adminActions.TOGGLE_ADMIN_MODE}),
  fetchAllUsers: (cb) => ({type: adminActions.FETCH_ALL_USERS, cb}),
  fetchAllUsersSuccess: (users, cb) => ({type: adminActions.FETCH_ALL_USERS_SUCCESS, users, cb}),
  fetchAllCouponCodes: (cb) => ({type: adminActions.FETCH_ALL_COUPON_CODE, cb}),
  fetchAllCouponCodesSuccess: (couponCodes, cb) => ({
    type: adminActions.FETCH_ALL_COUPON_CODE_SUCCESS,
    couponCodes,
    cb
  }),
  fetchAllCoupons: (cb) => ({type: adminActions.FETCH_ALL_COUPONS, cb}),
  fetchAllCouponsSuccess: (coupons, cb) => ({
    type: adminActions.FETCH_ALL_COUPONS_SUCCESS,
    coupons,
    cb
  }),

  fetchAllBookClubsAdmin: (cb) => ({type: adminActions.FETCH_ALL_BOOK_CLUBS_ADMIN, cb}),
  fetchAllBookClubsAdminSuccess: (bookClubs, cb,) => ({
    type: adminActions.FETCH_ALL_BOOK_CLUBS_ADMIN_SUCCESS,
    bookClubs,
    cb
  }),
  fetchBookClubBannersAdmin: (cb) => ({type: adminActions.FETCH_BOOK_CLUB_BANNERS_ADMIN, cb}),
  fetchBookClubBannersAdminSuccess: (bookClubBanners, cb) => ({
    type: adminActions.FETCH_BOOK_CLUB_BANNERS_ADMIN_SUCCESS,
    bookClubBanners,
    cb
  }),

  fetchScrapsAdmin: (cb) => ({type: adminActions.FETCH_SCRAPS_ADMIN, cb}),
  fetchScrapsMoreAdmin: (cb) => ({type: adminActions.FETCH_SCRAPS_MORE_ADMIN, cb}),
  fetchScrapsAdminSuccess: (scraps, scrapLastVisible, cb) => ({
    type: adminActions.FETCH_SCRAPS_ADMIN_SUCCESS,
    scraps,
    scrapLastVisible,
    cb
  }),

  fetchEssaysAdmin: (cb) => ({type: adminActions.FETCH_ESSAYS_ADMIN, cb}),
  fetchEssaysMoreAdmin: (cb) => ({type: adminActions.FETCH_ESSAYS_MORE_ADMIN, cb}),
  fetchEssaysAdminSuccess: (essays, essayLastVisible, cb) => ({
    type: adminActions.FETCH_ESSAYS_ADMIN_SUCCESS,
    essays,
    essayLastVisible,
    cb
  }),

  fetchAllPlacesAdmin: (cb) => ({type: adminActions.FETCH_ALL_PLACE_ADMIN, cb}),
  fetchAllPlacesAdminSuccess: (places, cb) => ({type: adminActions.FETCH_ALL_PLACE_ADMIN_SUCCESS, places, cb}),

  fetchAllAdminBooks: (cb) => ({type: adminActions.FETCH_ALL_ADMIN_BOOKS, cb}),
  fetchAllAdminBooksSuccess: (adminBooks, cb) => ({type: adminActions.FETCH_ALL_ADMIN_BOOKS_SUCCESS, adminBooks, cb}),

  fetchAllLeadersAdmin: (cb) => ({type: adminActions.FETCH_ALL_LEADERS_ADMIN, cb}),
  fetchAllLeadersAdminSuccess: (leaders, cb) => ({type: adminActions.FETCH_ALL_LEADERS_ADMIN_SUCCESS, leaders, cb}),

  fetchAllPartnersAdmin: (cb) => ({type: adminActions.FETCH_ALL_PARTNERS_ADMIN, cb}),
  fetchAllPartnersAdminSuccess: (partners, cb) => ({type: adminActions.FETCH_ALL_PARTNERS_ADMIN_SUCCESS, partners, cb}),

  fetchAllStoriesAdmin: (cb) => ({type: adminActions.FETCH_ALL_STORIES_ADMIN, cb}),
  fetchAllStoriesAdminSuccess: (stories, cb) => ({type: adminActions.FETCH_ALL_STORIES_ADMIN_SUCCESS, stories, cb}),

  fetchAllInquiriesAdmin: (cb) => ({type: adminActions.FETCH_ALL_INQUIRIES_ADMIN, cb}),
  fetchAllInquiriesAdminSuccess: (inquiries, cb) => ({
    type: adminActions.FETCH_ALL_INQUIRIES_ADMIN_SUCCESS,
    inquiries,
    cb
  }),

};


const initialState = {
  adminMode: false,
  users: [],
  coupons: [],
  couponCodes: [],
  bookClubs: [],
  bookClubBanners: [],

  scraps: [],
  scrapLastVisible: {},
  scrapIsLoaded: false,
  scrapReachToEnd: false,

  essays: [],
  essayLastVisible: {},
  essayIsLoaded: false,
  essayReachToEnd: false,

  leaders: [],
  partners: [],

  adminBooks: [],

  stories: [],

  places: [],
  inquiries: [],
};


export const adminReducer = (state = initialState, action) => {

  switch (action.type) {
    case adminActions.TOGGLE_ADMIN_MODE:
      return {
        ...state,
        // To do list
        adminMode: !state.adminMode,
      };

    case adminActions.FETCH_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: action.users
      };
    case adminActions.FETCH_ALL_COUPONS_SUCCESS:
      return {
        ...state,
        coupons: action.coupons
      };
    case adminActions.FETCH_ALL_COUPON_CODE_SUCCESS:
      return {
        ...state,
        couponCodes: action.couponCodes
      };

    case adminActions.FETCH_ALL_BOOK_CLUBS_ADMIN_SUCCESS:
      return {
        ...state,
        bookClubs: action.bookClubs
      };

    case adminActions.FETCH_BOOK_CLUB_BANNERS_ADMIN_SUCCESS:
      return {
        ...state,
        bookClubBanners: action.bookClubBanners,
      };

    case adminActions.FETCH_SCRAPS_ADMIN_SUCCESS:
      return {
        ...state,
        scraps: action.scraps,
        scrapLastVisible: action.scrapLastVisible,
        scrapIsLoaded: true,
        scrapReachToEnd: action.scraps.length < SCRAPS_PER_PAGE,
      };
    case adminActions.FETCH_ESSAYS_ADMIN_SUCCESS:
      return {
        ...state,
        essays: action.essays,
        essayLastVisible: action.essayLastVisible,
        essayIsLoaded: true,
        essayReachToEnd: action.essays.length < ESSAYS_PER_PAGE,
      };
    case adminActions.FETCH_ALL_ADMIN_BOOKS_SUCCESS:
      return {
        ...state,
        adminBooks: action.adminBooks,
      };
    case adminActions.FETCH_ALL_PLACE_ADMIN_SUCCESS:
      return {
        ...state,
        places: action.places,
      };
    case adminActions.FETCH_ALL_LEADERS_ADMIN_SUCCESS:
      return {
        ...state,
        leaders: action.leaders,
      };
    case adminActions.FETCH_ALL_PARTNERS_ADMIN_SUCCESS:
      return {
        ...state,
        partners: action.partners,
      };
    case adminActions.FETCH_ALL_STORIES_ADMIN_SUCCESS:
      return {
        ...state,
        stories: action.stories,
      };

    case adminActions.FETCH_ALL_INQUIRIES_ADMIN_SUCCESS:
      return {
        ...state,
        inquiries: action.inquiries,
      };
    default:
      return state;
  }
};