import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import Network from "../../library/Network";
import {Container, Input, Card, Divider, Header, Segment, Button, Image, Feed, Loader,} from 'semantic-ui-react';
import moment from 'moment';
import {withLineEnter} from "../../middleware/TextHandler";

class AdminEssayDetailScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      essay: {},
      essayIsLoaded: false,
      essayComments: [],
      essayCommentsIsLoaded: false,
    }
  }


  componentDidMount() {
    this._loadEssay();
    this._loadEssayComment();
    window.scrollTo(0, 0);
  }

  _loadEssay = async () => {
    const {essayId} = this.props.match.params;
    const res = await Network.getEssayByEssayId(essayId);
    if (res.err) return window.alert('오류');
    this.setState({essay: res, essayIsLoaded: true,})
  };

  _loadEssayComment = async () => {
    const {essayId} = this.props.match.params;
    const res = await Network.getEssayCommentsByEssayId(essayId);
    if (res.err) return window.alert('오류');
    this.setState({essayComments: res, essayCommentsIsLoaded: true});
  };

  _updateEssay = () => {

  };
  _deleteEssay = async () => {
    const a = window.confirm('삭제하시겠습니까?');
    if (!a) return;
    const {essayId} = this.props.match.params;
    const res = await Network.updateEssay({
      id: essayId,
      isDeleted: true,
    });
    if (res.err) return window.alert('오류');
    this._loadEssay();
  };
  _restoreEssay = async () => {
    const a = window.confirm('복구하시겠습니까?');
    if (!a) return;
    const {essayId} = this.props.match.params;
    const res = await Network.updateEssay({
      id: essayId,
      isDeleted: false,
    });
    if (res.err) return window.alert('오류');
    this._loadEssay();
  };

  _handleConfirm = async () => {
    const a = window.confirm('수정하신 내용을 반영하겠습니까?');
    if (!a) return;

    const {essay} = this.state;
    const res = await Network.updateEssay(essay);
    if (res.err) return window.alert('오류');
    window.alert('완료');
    this._loadEssay();
  };

  render() {
    const {essay, essayComments} = this.state;
    if (!this.state.essayIsLoaded) return <Loader active/>;
    const user = this.props.admin.users.find(u => u.id === essay.userId);

    return (
      <div>
        <Container as={Segment} vertical>
          <Button
            content={'수정'}
            primary
            onClick={this._handleConfirm}
          />
          <Button
            negative
            disabled={essay.isDeleted}
            content={'삭제'}
            onClick={this._deleteEssay}
          />
          <Button
            positive
            disabled={!essay.isDeleted}
            content={'복구'}
            onClick={this._restoreEssay}
          />
          <Header
            content={essay.status}
          />
          <Divider/>
          <Card.Group>
            {
              essay.tags && essay.tags.map((tag, i) => {
                return (
                  <Card key={i}>
                    <Card.Content>
                      <Input
                        fluid
                        value={tag}
                        onChange={(e, {value}) => {
                          essay.tags[i] = value;
                          this.setState({
                            essay
                          })
                        }}
                      />
                    </Card.Content>
                    <Card.Content>
                      <Button
                        fluid
                        content={'삭제'}
                        onClick={() => {
                          essay.tags.splice(i, 1);
                          this.setState({
                            essay
                          })
                        }}
                      />
                    </Card.Content>
                  </Card>
                )
              })
            }
            <Card>
              <Card.Content>
                <Button
                  content={'추가하기'}
                  onClick={() => {
                    essay.tags.push('');
                    this.setState({
                      essay,
                    })
                  }}
                />
              </Card.Content>
            </Card>
          </Card.Group>
        </Container>
        {
          (user && user.id) && (
            <Container as={Segment} vertical text>
              <Image src={user.photoURL} avatar/>
              <Header
                content={user.displayName}
                size={'small'}
              />
              <p>
                {moment(essay.createdAt.seconds * 1000).format('YYYY.MM.DD(ddd) HH:mm:ss')}
              </p>

              <Header
                content={essay.title}
              />
              <p>
                {withLineEnter(essay.content)}
              </p>
            </Container>
          )
        }

        <Container as={Segment} vertical text>
          <Header
            content={'댓글'}
          />
          <Feed>
            {essayComments.map((comment, i) => {
              const user = this.props.admin.users.find(u => u.id === comment.userId);

              if (!user) return;

              return (
                <Feed.Event key={i}>
                  <Feed.Label image={(user && user.id) && user.photoURL}/>
                  <Feed.Content>
                    <Feed.Summary>
                      <Feed.User>{user.displayName}</Feed.User> {comment.content}
                      <Feed.Date>
                        {moment(comment.createdAt.seconds * 1000).format('YYYY. MM. DD(ddd) HH:mm')}
                      </Feed.Date>
                    </Feed.Summary>
                    <Feed.Extra text>
                      {}
                    </Feed.Extra>
                  </Feed.Content>
                </Feed.Event>
              )
            })}
          </Feed>
        </Container>
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(AdminEssayDetailScreen);