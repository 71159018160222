import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {firebaseDB} from "../../constants/firebase";
import {Container, Divider, Radio, Image, Segment, Button, Header, Modal, Loader, Form} from 'semantic-ui-react';

const docRef = firebaseDB.adminSettings.doc('bankAccount');

class AdminSettingBankAccountContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setting: {
        bankAccount: '',
        bankName: '',
        accountHolder :''
      },
    }
  }


  componentDidMount() {
    this._loadSetting();
  }

  _loadSetting = async () => {
    await docRef
      .get()
      .then(doc => {
        if (!doc.exists) return;
        const setting = Object.assign(doc.data(), {id: doc.id});
        this.setState({
          setting
        })
      })
  };

  _updateSetting = async () => {
    const a = window.confirm('저장하시겠습니까?');
    if (!a) return;
    await docRef
      .set(this.state.setting, {merge: true})
      .then(() => {
        window.alert('완료');
        this._loadSetting();
      })
      .catch(e => {
        console.log(e);
        window.alert('오류');
      })
  };

  render() {
    const {setting} = this.state;
    return (
      <div>
        <Container as={Segment}>
          <Button
            onClick={this._updateSetting}
            content={'저장'}
          />
          <Form>
            <Form.Group widths={'equal'}>
              <Form.Input
                value={setting.bankAccount}
                label={'계좌번호'}
                name={'계좌번호'}
                onChange={(e, {value}) => {
                  setting.bankAccount = value;
                  this.setState({setting})
                }}
              />
              <Form.Input
                value={setting.bankName}
                label={'은행 이름'}
                name={'은행 이름'}
                onChange={(e, {value}) => {
                  setting.bankName = value;
                  this.setState({setting})
                }}
              />
              <Form.Input
                value={setting.accountHolder}
                label={'받는 사람'}
                name={'받는 사람'}
                onChange={(e, {value}) => {
                  setting.accountHolder = value;
                  this.setState({setting})
                }}
              />
            </Form.Group>
            <Form.TextArea
              value={setting.accountHolderDescription}
              label={'받는 사람 설명'}
              name={'받는 사람 설명'}
              onChange={(e, {value}) => {
                setting.accountHolderDescription = value;
                this.setState({setting})
              }}
            />
          </Form>

        </Container>
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(AdminSettingBankAccountContainer);