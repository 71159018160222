import _ from 'lodash';
import Colors from "../constants/Colors";


export const NEW_CURATION = {
  order: 99,
  category: '',
  layout: '',
  tag: '',

  header: '',
  subHeader: '',

  hasButton: false,
  buttonText: '',
  buttonTextColor: Colors.mainBlack,
  buttonBorderColor: Colors.mainBlack,

  maxNumberOfItems: 3,

  title: {
    content: '',
    align: 'left',
    color: Colors.mainBlack,
  },
  subTitle: {
    content: '',
    align: 'left',
    color: Colors.mainBlack,
  },

  description: {
    content: '',
    align: 'left',
    color: Colors.mainBlack,
  },

  imageUrl: '',
  backgroundImage: '',
  backgroundImageLarge: '',

  image: {
    square: '',
    horizontal: '',
    vertical: '',

    squareLarge: '',
    horizontalLarge: '',
    verticalLarge: '',
  },

  backgroundColor: Colors.mainGrey,
  bottomModuleBackgroundColor: Colors.mainUnderLineColorBlueOpacity,

  headerColor: Colors.mainBlack,
  subHeaderColor: Colors.mainBlack,
  descriptionColor: Colors.mainBlack,
  textColor: Colors.mainBlack,


  descriptionModules: [],
  tags: [],

  onlyForNewUser: false,
  isPublic: true,
  isDeleted: false,
  modifiedAt: new Date(),
  createdAt: new Date(),

};



export const CURATION_CATEGORY_NOTICE = 'CURATION_CATEGORY_NOTICE'
export const CURATION_CATEGORY_NOTICE_LIST = 'CURATION_CATEGORY_NOTICE_LIST';

export const CURATION_CATEGORY_BOOKCLUB = 'CURATION_CATEGORY_BOOKCLUB';
export const CURATION_CATEGORY_BOOKCLUB_LIST = 'CURATION_CATEGORY_BOOKCLUB_LIST';

export const CURATION_CATEGORY_BOOKCLUB_LIST_BEFORE_RECRUIT = 'CURATION_CATEGORY_BOOKCLUB_LIST_BEFORE_RECRUIT';
export const CURATION_CATEGORY_BOOKCLUB_LIST_RECRUITING = 'CURATION_CATEGORY_BOOKCLUB_LIST_RECRUITING';
export const CURATION_CATEGORY_BOOKCLUB_LIST_ING = 'CURATION_CATEGORY_BOOKCLUB_LIST_ING';
export const CURATION_CATEGORY_BOOKCLUB_LIST_ENDED = 'CURATION_CATEGORY_BOOKCLUB_LIST_ENDED';

export const CURATION_CATEGORY_BOOK = 'CURATION_CATEGORY_BOOK';
export const CURATION_CATEGORY_BOOK_LIST = 'CURATION_CATEGORY_BOOK_LIST';
export const CURATION_CATEGORY_ESSAY = 'CURATION_CATEGORY_ESSAY';
export const CURATION_CATEGORY_ESSAY_LIST = 'CURATION_CATEGORY_ESSAY_LIST';

export const CURATION_CATEGORY_MEMBER = 'CURATION_CATEGORY_MEMBER';
export const CURATION_CATEGORY_MEMBER_LIST = 'CURATION_CATEGORY_MEMBER_LIST';
export const CURATION_CATEGORY_LEADER = 'CURATION_CATEGORY_LEADER';
export const CURATION_CATEGORY_LEADER_LIST = 'CURATION_CATEGORY_LEADER_LIST';

export const CURATION_CATEGORY_TIP = 'CURATION_CATEGORY_TIP';
export const CURATION_CATEGORY_TIP_LIST = 'CURATION_CATEGORY_TIP_LIST';

export const CURATION_CATEGORY_SCRAP = 'CURATION_CATEGORY_SCRAP';
export const CURATION_CATEGORY_SCRAP_LIST = 'CURATION_CATEGORY_SCRAP_LIST';


export const curationCategoryList = [

  CURATION_CATEGORY_TIP,
  CURATION_CATEGORY_TIP_LIST,

  CURATION_CATEGORY_NOTICE,
  CURATION_CATEGORY_NOTICE_LIST,

  CURATION_CATEGORY_BOOKCLUB,
  CURATION_CATEGORY_BOOKCLUB_LIST,
  CURATION_CATEGORY_BOOKCLUB_LIST_BEFORE_RECRUIT,
  CURATION_CATEGORY_BOOKCLUB_LIST_RECRUITING,
  CURATION_CATEGORY_BOOKCLUB_LIST_ING,
  CURATION_CATEGORY_BOOKCLUB_LIST_ENDED,

  CURATION_CATEGORY_BOOK,
  CURATION_CATEGORY_BOOK_LIST,
  CURATION_CATEGORY_ESSAY,
  CURATION_CATEGORY_ESSAY_LIST,

  CURATION_CATEGORY_MEMBER,
  CURATION_CATEGORY_MEMBER_LIST,

  CURATION_CATEGORY_LEADER,
  CURATION_CATEGORY_LEADER_LIST,

  CURATION_CATEGORY_SCRAP,
  CURATION_CATEGORY_SCRAP_LIST,

];


export const curationCategoryDropDownList = () => {
  return _.map(curationCategoryList, (curation, index) => ({
    key: index,
    text: curation,
    value: curation
  }));
};


export const CURATION_LAYOUT_CARD = 'CURATION_LAYOUT_CARD';
export const CURATION_LAYOUT_LIST_IN_CARD = 'CURATION_LAYOUT_LIST_IN_CARD';
export const CURATION_LAYOUT_GRID = 'CURATION_LAYOUT_GRID';
export const CURATION_LAYOUT_HORIZONTAL_LIST = 'CURATION_LAYOUT_HORIZONTAL_LIST';

export const curationLayoutList = [
  CURATION_LAYOUT_CARD,
  CURATION_LAYOUT_LIST_IN_CARD,
  CURATION_LAYOUT_GRID,
  CURATION_LAYOUT_HORIZONTAL_LIST,
];


export const curationLayoutDropDownList = () => {
  return _.map(curationLayoutList, (layout, index) => ({
    key: index,
    text: layout,
    value: layout
  }));
};