import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {authActionCreators} from "../../redux/auth/authStore";
import {
  Button,
  Grid,
  Segment,
  Loader,
  Container,
  Table,
  Divider,
  Message,
  Header,
  Icon,
  Form, Card,
  Radio,
  Image,
  List
} from "semantic-ui-react";
import {db, storage} from "../../constants/firebase";
import {firebase} from "../../constants/firebase";
import FileUploader from "react-firebase-file-uploader";
import _ from 'lodash';
import {NEW_COUPON, NEW_COUPON_CODE} from "../../dataStructure/coupon";
import Network from "../../library/Network";
import moment from "moment";
import mockUpImages from "../../constants/mockUpImages";
import {adminActionCreators} from "../../redux/admin/adminStore";

const storageRef = `coupon/${moment().format("YYYYMM")}`;


class AdminCouponIssueContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      targetUserIds: [],
      selectedCouponCode: _.cloneDeep(NEW_COUPON_CODE),

    };
  }


  componentDidMount() {
  }

  handleChange = e => {
    const {coupon} = this.state;
    const {name, value} = e.target;
    coupon[name] = value;
    this.setState({
      coupon
    });
  };

  handleSubmit = async () => {
    const {targetUserIds, selectedCouponCode} = this.state;
    console.log(targetUserIds, selectedCouponCode);
    if (targetUserIds.length < 1) return window.alert('유저가 선택되지 않았습니다.');
    if (!selectedCouponCode.id) return window.alert('쿠폰이 선택되지 않았습니다.');
    const confirm = window.confirm("발급 하시겠습니까?");
    if (!confirm) return;

    const {coupon} = this.state;
    console.log(coupon);

    const batch = db.batch();

    this.state.targetUserIds.forEach(userId => {
      const newCouponRef = db.collection('coupons').doc();
      const newCoupon = Object.assign(NEW_COUPON, {
        userId,
        couponCodeId: selectedCouponCode.id,
        expireDate : moment(selectedCouponCode.expireDate.seconds*1000).endOf('day').toDate(),
      });
      batch.set(newCouponRef, newCoupon);
    });

    await batch.commit();

    this.props.fetchAllCoupons();
    window.alert('완료');

    this.props.history.replace(`/admin/coupon`)

  };


  render() {

    const {targetUserIds, selectedCouponCode} = this.state;

    return (
      <Fragment>
        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={`쿠폰 정보`} dividing/>
          <Button content={'발급하기'} primary onClick={this.handleSubmit}/>
        </Container>

        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'기본정보'} dividing/>
          <Form>
            <Form.Group widths={'equal'}>
              <Form.Dropdown
                selection
                value={selectedCouponCode}
                placeholder={'CATEGORY'}
                options={_.map(this.props.admin.couponCodes, (code, i) => ({
                  key: i,
                  text: code.name,
                  value: code
                }))}
                onChange={(e, {value}) => {
                  console.log(value);
                  this.setState({
                    selectedCouponCode: value,
                  })
                }}
              />
              <Form.Dropdown
                multiple
                selection
                value={targetUserIds}
                placeholder={'CATEGORY'}
                options={_.map(this.props.admin.users, (user, i) => ({
                  key: i,
                  text: user.displayName,
                  value: user.id
                }))}
                onChange={(e, {value}) => {
                  this.setState({
                    targetUserIds: value,
                  })
                }}
              />
            </Form.Group>
          </Form>
        </Container>
        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Card>
            <Card.Content>
              {selectedCouponCode.name}
            </Card.Content>
            <Card.Content>
              {selectedCouponCode.repeatPossible ? '중복발급 가능' : '중복발급 불가'} <br/>
              {selectedCouponCode.type} <br/>
              {selectedCouponCode.discountAmountOrRate}
            </Card.Content>
            <Card.Content>
              사용기한: {selectedCouponCode.id && moment(selectedCouponCode.expireDate.seconds * 1000).format('YYYY.MM.DD')}
            </Card.Content>
          </Card>
        </Container>
      </Fragment>
    );
  }
}

const styles = {
  verticalModule: {
    marginBottom: 50,
    marginTop: 50
  },
  header: {
    marginTop: 40,
  }
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
    ...adminActionCreators,
  }
);
export default enhance(AdminCouponIssueContainer);
