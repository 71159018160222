import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import Network from "../../library/Network";
import {
  BOOK_CLUB_BANNER_TYPE_BOOK_CLUB,
  bookClubBannerTypeDropDownList,
  NEW_BOOK_CLUB_BANNER
} from "../../dataStructure/bookClubBanner";
import {Container, Radio, Loader, Segment, Form, Image, Button} from 'semantic-ui-react';
import moment from "moment/moment";
import {firebase} from "../../constants/firebase";
import FileUploader from "react-firebase-file-uploader";
import mockUpImages from "../../constants/mockUpImages";
import {adminActionCreators} from "../../redux/admin/adminStore";
import _ from "lodash";
import {bookClubStatusDropDownList} from "../../dataStructure/bookClub";

const storageRef = `bookclub/${moment().format("YYYYMM")}`;

class AdminBookClubBannerDetailContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bookClubBanner: {},
      isLoaded: false,
      isNewBookClubBanner: false,
    }
  }

  componentDidMount() {
    this._loadBookClubBanner();
    this._loadBookClubs();
  }

  _loadBookClubs = async () => {
    const {bookClubs} = this.props.admin;
    if (bookClubs.length < 1) {
      this.props.fetchAllBookClubsAdmin();
    }
  };

  _loadBookClubBanner = async () => {
    const {bookClubBannerId} = this.props.match.params;
    const isNewBookClubBanner = bookClubBannerId === undefined;
    if (bookClubBannerId) {
      const res = await Network.getBookClubBannerByBookClubBannerId(bookClubBannerId);
      if (!res.err) {
        this.setState({
          bookClubBanner: res,
          isLoaded: true,
          isNewBookClubBanner,
        })
      } else {
        window.alert('오류')
      }
    } else {
      this.setState({
        bookClubBanner: NEW_BOOK_CLUB_BANNER(),
        isLoaded: true,
        isNewBookClubBanner
      })
    }
  };

  _handleConfirm = async () => {
    const {bookClubBannerId} = this.props.match.params;
    const {isNewBookClubBanner, bookClubBanner} = this.state;

    if (isNewBookClubBanner) {
      // bookClubBannerId가 없으면, 새로 만들고
      bookClubBanner.createdAt = new Date();
      bookClubBanner.modifiedAt = new Date();

      const res = await Network.postBookClubBanner(bookClubBanner);
      if (res.err) {
        return window.alert('오류');
      }
      window.alert('배너 생성이 완료되었습니다.');
      this.props.history.replace(`/admin/bookclubbanner/read/${res.id}`);

    } else {
      // 있으면 수정하는 것으로
      bookClubBanner.modifiedAt = new Date();

      const res = await Network.updateBookClubBanner(bookClubBanner);
      if (res.err) {
        return window.alert('오류');
      }
      window.alert('배너가 수정되었습니다.');
      this._loadBookClubBanner();
    }
  };

  _deleteBookClubBanner = async () => {
    const {bookClubBannerId} = this.props.match.params;
    const res = await Network.updateBookClubBanner({
      id: bookClubBannerId,
      isDeleted: true,
    });

    if (res.err) return window.alert('오류');
    window.alert('완료');
    this._loadBookClubBanner();
  };

  _restoreBookClubBanner = async () => {
    const {bookClubBannerId} = this.props.match.params;
    const res = await Network.updateBookClubBanner({
      id: bookClubBannerId,
      isDeleted: false,
    });

    if (res.err) return window.alert('오류');
    window.alert('완료');
    this._loadBookClubBanner();
  };


  render() {
    const {bookClubBanner, isLoaded, isNewBookClubBanner} = this.state;
    const {bookClubs} = this.props.admin;
    if (!isLoaded) return <Loader active/>;
    return (
      <div>
        <Container as={Segment} vertical basic>
          <Button content={isNewBookClubBanner ? '만들기' : '수정하기'} primary
                  onClick={this._handleConfirm}/>
          <Button content={'삭제하기'}
                  negative
                  disabled={isNewBookClubBanner || bookClubBanner.isDeleted}
                  onClick={this._deleteBookClubBanner}/>
          <Button content={'복구하기'}
                  positive
                  onClick={this._restoreBookClubBanner}
                  disabled={!bookClubBanner.isDeleted}/>
        </Container>
        <Container as={Segment} vertical>
          <Form>
            <Form.Field>
              <Radio
                toggle
                label={bookClubBanner.isPublic ? "공개" : "비공개"}
                checked={bookClubBanner.isPublic}
                style={{margin: "10px 0"}}
                onClick={() => {
                  bookClubBanner.isPublic = !bookClubBanner.isPublic;
                  this.setState({
                    bookClubBanner
                  });
                }}
              />
            </Form.Field>

            <Form.Group style={{alignItems: 'center'}}>
              <Form.Input
                width={10}
                label={'TITLE'}
                value={bookClubBanner.title.content}
                onChange={(e, {value}) => {
                  bookClubBanner.title.content = value;
                  this.setState({
                    bookClubBanner
                  })
                }}
              />
              <Form.Input
                width={3}
                label={'TITLE COLOR'}
                value={bookClubBanner.title.color}
                onChange={(e, {value}) => {
                  bookClubBanner.title.color = value;
                  this.setState({
                    bookClubBanner
                  })
                }}
              />
              <Form.Input
                width={3}
                label={'TITLE SIZE'}
                value={bookClubBanner.title.fontSize}
                onChange={(e, {value}) => {
                  bookClubBanner.title.fontSize = Number(value);
                  this.setState({
                    bookClubBanner
                  })
                }}
              />
            </Form.Group>

            <Form.Group style={{alignItems: 'center'}}>
              <Form.Input
                width={10}
                label={'subTitle'}
                value={bookClubBanner.subTitle.content}
                onChange={(e, {value}) => {
                  bookClubBanner.subTitle.content = value;
                  this.setState({
                    bookClubBanner
                  })
                }}
              />
              <Form.Input
                width={3}
                label={'subTitle COLOR'}
                value={bookClubBanner.subTitle.color}
                onChange={(e, {value}) => {
                  bookClubBanner.subTitle.color = value;
                  this.setState({
                    bookClubBanner
                  })
                }}
              />
              <Form.Input
                width={3}
                label={'subTitle SIZE'}
                value={bookClubBanner.subTitle.fontSize}
                onChange={(e, {value}) => {
                  bookClubBanner.subTitle.fontSize = Number(value);
                  this.setState({
                    bookClubBanner
                  })
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                width={8}
                label={'ORDER'}
                value={bookClubBanner.order.toString()}
                onChange={(e, {value}) => {
                  bookClubBanner.order = Number(value);
                  this.setState({
                    bookClubBanner
                  })
                }}
              />
              <Form.Input
                width={8}
                label={'backgroundColor'}
                value={bookClubBanner.backgroundColor}
                onChange={(e, {value}) => {
                  bookClubBanner.backgroundColor = value;
                  this.setState({
                    bookClubBanner
                  })
                }}
              />
            </Form.Group>


            <Form.Dropdown
              // width={4}
              placeholder={'TYPE'}
              selection
              label="TYPE"
              name="TYPE"
              value={bookClubBanner.type}
              options={bookClubBannerTypeDropDownList()}
              onChange={(e, {value}) => {
                bookClubBanner.type = value;
                this.setState({
                  bookClubBanner
                })
              }}
            />


            <Form.Dropdown
              multiple
              selection
              value={bookClubBanner.bookClubIds}
              placeholder={'북클럽 선택'}
              options={_.map(_.orderBy(bookClubs, ['endDate.seconds'], ['desc']), (bookClub, i) => ({
                key: i,
                text: `${bookClub.header.content} - ${moment(bookClub.startDate.seconds * 1000).format('YYYY.MM.DD')}`,
                value: bookClub.id
              }))}
              onChange={(e, {value}) => {
                bookClubBanner.bookClubIds = value;
                this.setState({
                  bookClubBanner,
                })
              }}
            />
          </Form>
          <Button as={'label'} size={'mini'}>
            imageUrl
            <FileUploader
              hidden
              accept="image/*"
              randomizeFilename
              storageRef={firebase.storage().ref(storageRef)}
              onUploadSuccess={async filename => {
                await firebase.storage()
                  .ref(storageRef)
                  .child(filename)
                  .getDownloadURL()
                  .then(imageUrl => {
                    bookClubBanner.imageUrl = imageUrl;
                    this.setState({
                      bookClubBanner
                    })
                  })
                  .catch(e => {
                    console.error(e);
                  })
              }}
            />
          </Button>
          <Button
            size={'mini'}
            disabled={!(bookClubBanner.imageUrl)}
            content={'삭제'}
            negative
            onClick={() => {
              bookClubBanner.imageUrl = '';
              this.setState({
                bookClubBanner
              })
            }}/>
          <Image src={bookClubBanner.imageUrl || mockUpImages.planeImage} size={'medium'}/>
        </Container>
        <Container as={Segment} vertical>
          {JSON.stringify(bookClubBanner)}
        </Container>
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
    ...adminActionCreators,
  }
);


export default enhance(AdminBookClubBannerDetailContainer);