export const ESSAY_STATUS_PRIVATE = 'ESSAY_STATUS_PRIVATE';
export const ESSAY_STATUS_FOLLOWER = 'ESSAY_STATUS_FOLLOWER';
export const ESSAY_STATUS_PUBLIC = 'ESSAY_STATUS_PUBLIC';

export const essayStatusList = [
  {
    key: 0,
    value: ESSAY_STATUS_PUBLIC,
    text: '전체 공개',
  },
  {
    key: 1,
    value: ESSAY_STATUS_FOLLOWER,
    text: '팔로워 공개'
  },
  {
    key: 2,
    value: ESSAY_STATUS_PRIVATE,
    text: '비공개'
  }
];

export const NEW_ESSAY = {
  title: '',
  content: '',
  status: ESSAY_STATUS_PUBLIC,

  userId: '',

  likeUserIds: [],
  numberOfLikes: 0,

  commentUserIds: [],
  essayCommentIds: [],
  numberOfComments: 0,

  reportUserIds: [],
  numberOfReports: 0,

  isbn: '',
  tags: [],
  curationTags: [],

  // 개인이 그냥 적은 에세이
  bookId: null,

  // 북클럽에서 적은 에세이
  bookClubEssayScheduleId: null,
  bookClubId: null,

  isDeleted: false,
  isReported: false,

  createdAt: new Date(),
  modifiedAt: new Date(),

};