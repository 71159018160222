import React, {Component} from "react";
import {connect} from "react-redux";
import {authActionCreators} from "../../redux/auth/authStore";
import {
  Button,
  Container,
  Header,
  Form,
  Checkbox,
  Image,
  Segment,
  Grid
} from "semantic-ui-react";
import {db} from "../../constants/firebase";
import {firebase} from "../../constants/firebase";
import FileUploader from "react-firebase-file-uploader";
import moment from "moment";

const storageRef = `user/${moment().format("YYYYMM")}`;

class AdminUserDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.location.state ? props.location.state.user : {}
    };
  }

  componentDidMount() {
    console.log(this.state.user);
  }

  handleChange = e => {
    const {user} = this.state;
    const {name, value} = e.target;
    user[name] = value;
    this.setState({
      user
    });
  };

  handleSubmit = async () => {
    const confirm = window.confirm('수정하시겠습니까?')
    if (!confirm) return

    const {user} = this.state
    await db.collection('users')
      .doc(user.id)
      .update(user)
      .then(() => {
        this.props.history.goBack()
      })
  }

  handleUploadStart = () => this.setState({isUploading: true, progress: 0});
  handleProgress = progress => this.setState({progress});
  handleUploadError = error => {
    this.setState({isUploading: false});
    console.error(error);
  };
  handleUploadSuccess = async (filename) => {
    const {user} = this.state
    this.setState({progress: 100, isUploading: false});
    await firebase.storage()
      .ref(storageRef)
      .child(filename)
      .getDownloadURL()
      .then(imageUrl => {
        user.photoURL = imageUrl
        this.setState({user})
      });
  };

  delete = async () => {
    const confirm = window.confirm("삭제하시겠습니까아?");
    if (!confirm) return;

    const {user} = this.state;
    await db
      .collection("users")
      .doc(user.id)
      .update({
        isDeleted: true
      })
      .then(() => {
        this.props.history.goBack();
      });
  };

  restore = async () => {
    const confirm = window.confirm('복구하시겠습니까?')
    if (!confirm) return

    const {user} = this.state
    await db.collection('users')
      .doc(user.id)
      .update({
        isDeleted: false
      })
      .then(() => {
        window.alert('복구되었습니다.')
      })
      .catch(e => {
        console.log(e)
      })
  }

//   createdAt: Timestamp {seconds: 1564116932, nanoseconds: 580000000}
// displayName: "훌룰룰루" //
// email: "gizmo7duck@gmail.com" //
// emailVerified: false
// id: "8qiRmImKWJSpNb1whZ8HWU6cqvx1"
// isAdmin: true
// isAnonymous: false
// metadata: {creationTime: 1564116930175, lastSignInTime: 1564116930175}
// modifiedAt: Timestamp {seconds: 1564116932, nanoseconds: 580000000}
// phoneNumber: null
// photoURL: null
// providerData: [{…}]
// providerId: "firebase"
// tags: ["USER_1"]
// token: []
// uid: "8qiRmImKWJSpNb1whZ8HWU6cqvx1"
// __proto__: Object
  render() {
    const {user} = this.state;
    return (
      <Container style={{marginTop: 50}}>
        <Header>{user.displayName}님의 정보</Header>
        <Form>
          <Form.Group widths='equal'>
            <Form.Input
              fluid
              label="Display Name"
              name="displayName"
              value={user.displayName}
              onChange={this.handleChange}
            />

            <Form.Input
              fluid
              label="Email"
              name="email"
              value={user.email}
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group widths='equal'>
            <Form.Input
              fluid
              label="User ID"
              readOnly
              name="id"
              value={user.id}
              onChange={this.handleChange}
            />
            <Form.Input
              fluid
              label="Admin"
              readOnly
              name="isAdmin"
              value={user.isAdmin}
              onChange={this.handleChange}
            />
          </Form.Group>


          <Form.Input
            fluid
            label="계정 생성일"
            name="createdAt.seconds"
            value={moment(user.createdAt.seconds * 1000).format("YYYY. MM. DD")}
            onChange={this.handleChange}
          />

          <Form.Input
            fluid
            label="최근 접속일 및 시간"
            name="metadata.lastSignInTime"
            value={
              moment
                .unix(user.lastLoginAt ? (user.lastLoginAt) : (user.metadata.lastSignInTime) / 1000)
                .format("YYYY. MM. DD. HH:mm")}
            onChange={this.handleChange}
          />

          <Form.Input
            fluid
            label="전화번호"
            name="phoneNumber"
            value={user.phoneNumber ? user.phoneNumber : ''}
            onChange={this.handleChange}
          />

          {user.tags &&
          user.tags.map((tag, i) => {
            return (
              <Form.Input
                key={i}
                fluid
                label={`TAG ${i}`}
                name="tag"
                value={tag}
                onChange={(e) => {
                  user.tags[i] = e.target.value
                  this.setState({
                    user
                  })
                }}
              />
            )
          })
          }

          <Button
            onClick={() => {
              if (user.tags && user.tags.length > 0) {
                user.tags.push("")
              } else {
                user.tags = [""]
              }
              this.setState({
                user
              })
            }}
          >태그 추가
          </Button>

          <Header as="h5"> 유저 이미지</Header>
          <Image size="medium" src={user.photoURL ? user.photoURL : "avatar"}/>
          <label
            style={{
              backgroundColor: "#E0E1E2",
              color: "black",
              fontWeight: "bold",
              padding: 10,
              borderRadius: 4,
              cursor: "pointer",
              display: "block",
              width: 150,
              textAlign: "center"
            }}
            onClick={this.targetName}
          >
            이미지 업로드
            <FileUploader
              hidden
              accept="image/*"
              name="profileImage"
              randomizeFilename // FIXME (덕현) 파일이름은 계속 바뀌게 해야해서 바꿨음.
              storageRef={firebase.storage().ref(storageRef)}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={this.handleUploadSuccess}
              onProgress={this.handleProgress}
            />
          </label>
          <Button color="black" onClick={this.handleSubmit} type="submit">
            완료
          </Button>
          {
            user.isDeleted ? (
                <Button
                  onClick={this.restore}
                > 복구</Button>
              ) :
              <Button
                onClick={this.delete}
                style={{backgroundColor: 'red', color: 'white'}}
              >
                삭제
              </Button>
          }
        </Form>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    auth: state.auth
  }),
  {
    ...authActionCreators
  }
);
export default enhance(AdminUserDetailContainer);
