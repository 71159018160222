import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from '../../redux/auth/authStore';
import {db} from '../../constants/firebase';
import {Link} from 'react-router-dom';
import {Table, Segment, Button, Image, Container, Header} from 'semantic-ui-react';
import moment from 'moment';

class AdminPartnerMainContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      partners: []
    }
  }

  componentDidMount() {
    this._loadPartnerData()
  }

  _loadPartnerData = async () => {
    await db.collection('partners')
      .get()
      .then(snapshot => {
        const partners = []
        snapshot.forEach(doc => {
          const partner = Object.assign(doc.data(), {id: doc.id})
          partners.push(partner)
        })
        this.setState({
          partners
        })
        console.log(partners)
      })
  }

  render() {
    const {partners} = this.state
    return (
      <Container as={Segment} vertical>
        <Header>파트너 어드민페이지</Header>
        <Button as={Link} to={'/admin/partner/make'}>
          파트너 만들기
        </Button>

        <Table compact size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No</Table.HeaderCell>
              <Table.HeaderCell>이미지</Table.HeaderCell>
              <Table.HeaderCell>파트너명</Table.HeaderCell>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>생성일</Table.HeaderCell>
              <Table.HeaderCell>Order</Table.HeaderCell>
              <Table.HeaderCell>Tags</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>버튼</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              partners.map((partner, i) => {
                return (<Table.Row key={i}>
                    <Table.Cell>{i}</Table.Cell>
                    <Table.Cell><Image size="mini" src={partner.profileImage}/></Table.Cell>
                    <Table.Cell>{partner.name}</Table.Cell>
                    <Table.Cell>{partner.id}</Table.Cell>
                    <Table.Cell>{moment(partner.createdAt.seconds * 1000).format('YYYY. MM. DD')}</Table.Cell>
                    <Table.Cell>{partner.order}</Table.Cell>
                    <Table.Cell>{partner.tags}</Table.Cell>
                    <Table.Cell>{partner.description}</Table.Cell>
                    <Table.Cell>
                      <Button as={Link} to={{
                        pathname: `/admin/partner/detail/${partner.id}`,
                        state: {partner: partner}
                      }}>상세보기</Button>
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    auth: state.auth,
  }),
  {
    ...authActionCreators,
  }
);
export default enhance(AdminPartnerMainContainer);