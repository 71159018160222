import {createLogic} from 'redux-logic';
import {essayActionCreators, essayActions} from "./essayStore";
import {auth, db} from "../../constants/firebase";
import Network from "../../library/Network";


const fetchAllMyEssaysLogic = createLogic({
  type: essayActions.FETCH_ALL_MY_ESSAYS,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {

    const currentUser = auth.currentUser;
    const {uid: userId} = currentUser;
    const res = await Network.getUserEssayListByUserId(userId);
    if (!res.err) {
      dispatch(essayActionCreators.fetchAllMyEssaysSuccess(res))
    }
    done();
  }
});


export default [
  fetchAllMyEssaysLogic,
]