import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {Modal, Image, Header, Form, Button, List, Divider} from 'semantic-ui-react';
import {getNewPushNotification, getPushNotificationDropDownList} from "../../dataStructure/pushNotification";
import {horizontalGapDropDownList} from "../../dataStructure/contentModule";
import {getNewNotification, NOTIFICATION_TYPE_BOOK_CLUB_MEMBER} from "../../dataStructure/notification";
import {firebase} from "../../constants/firebase";
import moment from "moment/moment";
import {MockUpBookClubCoverImage} from "../../dataStructure/bookClub";
import axios from 'axios';
import mockUpImages from "../../constants/mockUpImages";
import {API_NEW} from "../../constants/API";
import FileUploader from "react-firebase-file-uploader";

const storageRef = `pushNotification/${moment().format("YYYYMM")}`;

class UserPushComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      detailModalOpen: false,
      pushNotification: {
        ...getNewPushNotification(),
        data: {
          ...getNewPushNotification().data,
          type: NOTIFICATION_TYPE_BOOK_CLUB_MEMBER,
          bookClubId: props.bookClubData.id,
        }
      },
      notification: {
        ...getNewNotification(),
        image: (props.bookClubData && props.bookClubData.coverImageUrl !== '') ? props.bookClubData.coverImageUrl : mockUpImages.planeImage,
        bookClubId: props.bookClubData.id,
        type: NOTIFICATION_TYPE_BOOK_CLUB_MEMBER,
      },
      push: true,
      pushSoundOn: true,
    }
  }


  componentDidMount() {

  }

  _loadUserInfo = async () => {

  };

  _handleModalOpen = () => {
    this.setState({detailModalOpen: true,})
  };

  _handleSend = async () => {
    const a = window.confirm('발송하시겠습니까?');
    if (!a) return;

    const notificationData = {
      notificationData: this.state.notification,
      targetUserId: this.props.user.id,
    };

    const b = await axios.post(`${API_NEW}/notification/notification`, notificationData)
      .then(res => {
        console.log(res);
        return true;
      })
      .catch(e => {
        console.log(e);
        return false;
      });

    if (!b) return window.alert('알림 생성에 실패하였습니다');

    if (this.state.push) {
      const data = {
        pushNotificationData: {
          ...this.state.pushNotification,
          notification: {
            ...this.state.pushNotification.notification,
            sound: this.state.pushSoundOn ? 'default' : '',
          }
        },
        targetUserId: this.props.user.id,
      };
      axios.post(`${API_NEW}/notification/push`, data,)
        .then(res => {
          console.log(res);
          window.alert('발송 완료되었습니다.')
        })
        .catch(e => {
          console.log(e)
          window.alert('발송에 실패하였습니다.');
        });
    }
  };

  render() {
    const {userId, user, bookClubData} = this.props;
    if (!user || !user.id) return <div/>;
    const {pushNotification, notification} = this.state;

    return (
      <Fragment>
        <Button size={'mini'} compact content={'PUSH'} onClick={this._handleModalOpen}/>
        <Modal open={this.state.detailModalOpen}>
          <Header>
            Notification 만들기 {user.displayName}
          </Header>
          <Modal.Content image>
            <Image src={notification.image} size={'medium'}/>
            <Modal.Description>
              <Button as={'label'} size={'mini'}>
                이미지 바꾸기
                <FileUploader
                  hidden
                  accept="image/*"
                  name="square"
                  randomizeFilename
                  storageRef={firebase.storage().ref(storageRef)}
                  onUploadSuccess={filename => {
                    firebase.storage()
                      .ref(storageRef)
                      .child(filename)
                      .getDownloadURL()
                      .then(imageUrl => {
                        notification.image = imageUrl;
                        this.setState({notification: notification})
                      });
                  }}
                />
              </Button>
              <Button
                size={'mini'}
                content={'이미지 초기화'}
                disabled={notification.image === bookClubData.coverImageUrl}
                onClick={() => {
                  notification.image = bookClubData.coverImageUrl;
                  this.setState({
                    notification,
                  })
                }}
              />
              <Form>
                <Form.Radio
                  toggle
                  checked={this.state.push}
                  label={'PUSH ALARM 전송'}
                  onChange={(e, {value}) => {
                    this.setState({push: value})
                  }}
                />
                <Form.Radio
                  toggle
                  checked={this.state.pushSoundOn}
                  label={'PUSH ALARM 소리'}
                  onChange={(e, {value}) => {
                    this.setState({pushSoundOn: !this.state.pushSoundOn})
                  }}
                />
                <Form.Input
                  placeholder={'푸시 타이틀'}
                  value={pushNotification.notification.title}
                  onChange={(e, {value}) => {
                    pushNotification.notification.title = value;
                    this.setState({
                      pushNotification,
                    })
                  }}
                />
                <Form.TextArea
                  placeholder={'푸시 내용'}
                  value={pushNotification.notification.body}
                  onChange={(e, {value}) => {
                    pushNotification.notification.body = value;
                    this.setState({
                      pushNotification,
                    })
                  }}
                />
                <Form.TextArea
                  placeholder={'알람 내용'}
                  value={notification.content}
                  onChange={(e, {value}) => {
                    notification.content = value;
                    this.setState({
                      notification,
                    })
                  }}
                />
                <Form.Group>
                  <Form.Dropdown
                    width={16}
                    selection
                    search
                    value={pushNotification.data && pushNotification.data.type}
                    placeholder={'PUSH NOTIFICATION TYPE'}
                    options={getPushNotificationDropDownList()}
                    onChange={(e, {value}) => {
                      pushNotification.data.type = value;
                      notification.type = value;
                      this.setState({pushNotification, notification})
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Content>
            <Header content={'푸시 알람 메시지'}/>
            <p>
              <b> {this.state.pushNotification.notification.title}</b> <br/>
              {this.state.pushNotification.notification.body}
            </p>
            <Header content={'알람 메시지'}/>
            <p>
              {this.state.notification.content}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button content={'보내기'}
                    positive
                    onClick={() => {
                      this._handleSend();
                    }}/>
            <Button content={'닫기'}
                    negative
                    onClick={() => {
                      this.setState({detailModalOpen: false,})
                    }}/>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

const enhance = connect(
  state => ({
    // ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(UserPushComponent);