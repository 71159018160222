import React, {Component, Fragment} from "react";
import {Link, NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {
  Sidebar,
  Button,
  Menu,
  Input,
  Responsive, Form,
  Container,
  Icon,
  Dropdown,
  Image, Segment,
} from "semantic-ui-react";
import {authActionCreators} from "../../redux/auth/authStore";
import {adminActionCreators} from "../../redux/admin/adminStore";
import Colors from "../../constants/Colors";

class MenuBar extends Component {
  state = {
    sidebarToggle: false,
    isLoggedIn: false,
    searchValue: '',
  };

  onSignOut = () => {
    const a = window.confirm('로그아웃하시겠습니까?');
    if (!a) return;
    this.props.signOut();

  };

  toggleMenuBar = () => {
    this.props.toggleAdminMode();
  };
  closeMenuBar = () => {
    this.setState({sidebarToggle: false});
  };

  render() {
    const {isLoggedIn, user} = this.props.auth;
    const {adminMode} = this.props.admin;
    const {isAdmin} = user;

    if (adminMode) {
      return (
        <Menu
          fixed="top"
          secondary
          pointing
          style={{}}
        >
          <Container>
            <Menu.Item as={Link} to="/" name="Home">
              <span style={{fontSize: 20, fontWeight: "900", color: Colors.mainColor}}>리더스</span>
            </Menu.Item>
            <Menu.Menu>
              <Dropdown
                text="세팅"
                className={"link item"}
                style={{...styles.button, marginTop: 5}}>
                <Dropdown.Menu>
                  <Menu.Item
                    as={Link}
                    to="/admin/setting"
                    content={'세팅'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/setting/bankAccount"
                    content={'세팅-계좌번호'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/privacy-policy"
                    content={'개인정보수집방칙'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/terms-of-service"
                    content={'서비스 이용약관'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/bookclub-rule"
                    content={'북클럽 참여규칙'}/>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
            <Menu.Menu>
              <Dropdown
                text="공지/팁 등"
                className={"link item"}
                style={{...styles.button, marginTop: 5}}>
                <Dropdown.Menu>
                  <Menu.Item
                    as={Link}
                    to="/admin/push"
                    content={'전체 푸시 보내기'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/story"
                    content={'스토리'}/>
                  <Menu.Item/>
                  <Menu.Item
                    as={Link}
                    to="/admin/notice"
                    content={'공지'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/tip"
                    content={'팁'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/faq"
                    content={'FAQ'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/faq-apply"
                    content={'FAQ-Apply'}/>
                  <Menu.Item/>
                  <Menu.Item
                    as={Link}
                    to="/admin/curation"
                    content={'큐레이션'}/>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>

            <Menu.Menu>
              <Dropdown
                text="요청"
                className={"link item"}
                style={{...styles.button, marginTop: 5}}>
                <Dropdown.Menu>
                  <Menu.Item
                    as={Link}
                    to="/admin/inquiry"
                    content={'1:1 문의'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/update-request"
                    content={'업데이트 요청'}/>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>

            <Menu.Menu>
              <Dropdown
                text="북클럽"
                className={"link item"}
                style={{...styles.button, marginTop: 5}}>
                <Dropdown.Menu>
                  <Menu.Item
                    as={Link}
                    to="/admin/bookclub"
                    content={'북클럽'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/bookclubbanner"
                    content={'북클럽 배너'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/transaction"
                    content={'거래'}/>
                  <Menu.Item/>
                  <Menu.Item
                    as={Link}
                    to="/admin/bookclub/setting/book"
                    content={'세팅-책'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/bookclub/setting/deposit"
                    content={'세팅-디파짓'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/bookclub/setting/essay"
                    content={'세팅-에세이'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/bookclub/setting/headCount"
                    content={'세팅-정원'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/bookclub/setting/membership"
                    content={'세팅-참가비'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/bookclub/setting/offlineMeeting"
                    content={'세팅-오프라인모임'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/bookclub/setting/onlineMeeting"
                    content={'세팅-온라인모임'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/bookclub/setting/scrap"
                    content={'세팅-스크랩'}/>

                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>


            <Menu.Menu>
              <Dropdown
                text="쿠폰"
                className={"link item"}
                style={{...styles.button, marginTop: 5}}>
                <Dropdown.Menu>
                  <Menu.Item
                    as={Link}
                    to="/admin/coupon"
                    content={'쿠폰'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/couponCode"
                    content={'쿠폰코드'}/>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>

            <Menu.Menu>
              <Dropdown
                text="유저"
                className={"link item"}
                style={{...styles.button, marginTop: 5}}>
                <Dropdown.Menu>
                  <Menu.Item
                    as={Link}
                    to="/admin/user"
                    content={'유저'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/user/book"
                    content={'유저-책'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/user/bookshelf"
                    content={'유저-책장'}/>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>

            <Menu.Menu>
              <Dropdown
                text="컨텐츠"
                className={"link item"}
                style={{...styles.button, marginTop: 5}}>
                <Dropdown.Menu>
                  <Menu.Item
                    as={Link}
                    to="/admin/scrap"
                    content={'스크랩'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/essay"
                    content={'에세이'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/book"
                    content={'책'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/place"
                    content={'플레이스'}/>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>

            <Menu.Menu>
              <Dropdown
                text="리더/파트너"
                className={"link item"}
                style={{...styles.button, marginTop: 5}}>
                <Dropdown.Menu>
                  <Menu.Item
                    as={Link}
                    to="/admin/leader"
                    content={'리더'}/>
                  <Menu.Item
                    as={Link}
                    to="/admin/partner"
                    content={'파트너'}/>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>

            <Menu.Menu position="right">
              <Menu.Item
                as={Link}
                to="/admin/banner"
                content={'배너'}/>
              <Menu.Item
                onClick={this.toggleMenuBar}
                content={'유저 모드'}/>
            </Menu.Menu>
          </Container>
        </Menu>
      )
    }

    return <Fragment>
      {/* 핸드폰 사이즈 일때 < 764 */}
      <Responsive maxWidth={764}>
        <Sidebar
          as={Menu}
          vertical
          secondary
          visible={this.state.sidebarToggle}
          animation="overlay"
          direction={'top'}
          width="wide"
          style={{
            zIndex: 999,
            // backgroundColor: 'white',
          }}
        >
          <Menu.Item onClick={this.closeMenuBar}>
            닫기
            <Icon name="close"/>
          </Menu.Item>
          <Menu.Item/>
          {/*<Menu.Item
            as={Link}
            to="/about/bookclub"
            onClick={this.closeMenuBar}
          >
            북클럽
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/about/camera"
            onClick={this.closeMenuBar}
          >
            카메라
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/about/library"
            onClick={this.closeMenuBar}
          >
            서재
          </Menu.Item>*/}
          <Menu.Item/>
          {/*
          {isLoggedIn ? (
            <Fragment>
              <Menu.Item
                as={Link}
                to="/my/bookclub"
                onClick={() => {
                  this.setState({sidebarToggle: false});
                }}
              >
                나의 북클럽
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/my/info"
                onClick={() => {
                  this.setState({sidebarToggle: false});
                }}
              >
                내 정보
              </Menu.Item>
            </Fragment>
          ) : (
            <Fragment>
              <Menu.Item
                as={Link}
                to={{pathname: "/login"}}
                onClick={() => {
                  this.setState({sidebarToggle: false});
                }}
              >
                로그인/회원가입
              </Menu.Item>
            </Fragment>
          )}
          */}
        </Sidebar>
        <div
          style={{
            position: 'fixed',
            backgroundColor: "white",
            borderBottomColor: 'transparent',
            display: 'flex',
            width: '100%',
            zIndex: 1000,
            top: 0,
            minHeight: '50px',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 1rem'
          }}
        >
          <Icon name={this.state.sidebarToggle ? 'close' : 'sidebar'}
                className={'buttonHover'}
                onClick={() => {
                  this.setState({sidebarToggle: !this.state.sidebarToggle});
                }}/>

          <Link to={'/'}>
            <img
              onClick={this.closeMenuBar}
              src={require('../../assets/128.png')}
              style={{
                width: '35px'
              }}/>
          </Link>
          <Icon name={this.state.sidebarToggle ? 'close' : 'sidebar'}
                className={'buttonHover'}
                style={{
                  color: 'white',
                }}/>
        </div>
      </Responsive>

      {/* 데스크탑 일때 < 765  */}
      <Responsive minWidth={765}>
        <Container>
          <Menu
            // widths={6}
            fixed="top"
            secondary
            pointing
            style={{
              backgroundColor: "white",
              borderBottomColor: 'transparent',
            }}
          >
            <Container>
              <Menu.Item as={Link} to="/" name="Home">
                <span style={{fontSize: 20, fontWeight: "900", color: Colors.mainColor}}>리더스</span>
              </Menu.Item>
             {/* <Menu.Item
                style={styles.button}
                as={NavLink}
                to="/book-club/list/recruiting"
                name="bookClubRecruiting">
                모집중
              </Menu.Item>
              <Menu.Item
                style={styles.button}
                as={NavLink}
                to="/book-club/list/ing"
                name="bookClubIng">
                진행중
              </Menu.Item>
              <Menu.Item
                style={styles.button}
                as={NavLink}
                to="/book-club/list/end"
                name={'bookClubEnded'}>
                종료
              </Menu.Item>*/}
              {/*<Menu.Menu position="right">*/}
              {/*  {isLoggedIn ? (*/}
              {/*    <Fragment>*/}

              {/*       USER */}
              {/*      <Menu.Menu>*/}
              {/*        <Dropdown text="마이페이지" className={"link item"}*/}
              {/*                  style={{color: Colors.mainBlack, fontWeight: '900', marginTop: 5}}*/}
              {/*        >*/}
              {/*          <Dropdown.Menu>*/}
              {/*            <Dropdown.Item*/}
              {/*              style={styles.button}*/}
              {/*              as={Link} to="/my/bookclub">*/}
              {/*              북클럽*/}
              {/*            </Dropdown.Item>*/}
              {/*            <Dropdown.Item*/}
              {/*            style={styles.button}*/}
              {/*            as={Link} to="/my/book">*/}
              {/*            서재*/}
              {/*          </Dropdown.Item>*/}
              {/*          <Dropdown.Item*/}
              {/*            style={styles.button}*/}
              {/*            as={Link} to="/my/info">*/}
              {/*            내 정보*/}
              {/*          </Dropdown.Item>*/}
              {/*          </Dropdown.Menu>*/}
              {/*        </Dropdown>*/}
              {/*      </Menu.Menu>*/}
              {/*      <Menu.Menu>*/}
              {/*        <Menu.Item*/}
              {/*          style={{color: Colors.mainBlack, fontWeight: '900', marginTop: 5}}*/}
              {/*          onClick={this.onSignOut}>로그아웃</Menu.Item>*/}
              {/*      </Menu.Menu>*/}
              {/*    </Fragment>*/}
              {/*  ) : (*/}
              {/*    <Menu.Item*/}
              {/*      style={styles.button}*/}
              {/*      as={NavLink}*/}
              {/*      to={{pathname: "/login"}}>*/}
              {/*      로그인/회원가입*/}
              {/*    </Menu.Item>*/}
              {/*  )}*/}
              {/*   ADMIN */}
              {/*  {isAdmin && (*/}
              {/*    <Menu.Item*/}
              {/*      style={styles.button}*/}
              {/*      onClick={this.toggleMenuBar}>*/}
              {/*      어드민 모드*/}
              {/*    </Menu.Item>*/}
              {/*  )}*/}
              {/*</Menu.Menu>*/}

            </Container>
          </Menu>
        </Container>
      </Responsive>
    </Fragment>
      ;
  }
}

const styles = {
  button: {
    color: Colors.mainBlack,
    // fontWeight: '900'
  }
};

const enhance = connect(
  state => ({
    auth: state.auth,
    admin: state.admin,
  }),
  {
    ...authActionCreators,
    ...adminActionCreators,
  }
);

export default enhance(MenuBar);
