import React, {Component} from 'react';
import {Sidebar,} from 'semantic-ui-react';
import MenuBar from "./MenuBar";
import Footer from "./Footer";

class Layout extends Component {
  render() {
    return (
      <div>
        <Sidebar.Pusher style={{paddingTop: 50}}>
          <MenuBar/>
          <div style={{minHeight:'60vh'}}>
          {this.props.children}
          </div>
          <Footer/>
        </Sidebar.Pusher>
      </div>
    );
  }
}


export default Layout;
