import {createLogic} from 'redux-logic';
import {bookClubActions, bookClubActionCreators} from "./bookClubStore";
import {auth, db} from "../../constants/firebase";
import Network from "../../library/Network";

const fetchMyBookClubsLogic = createLogic({
  type: bookClubActions.FETCH_MY_BOOK_CLUBS,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {

    dispatch(bookClubActionCreators.fetchMyBookClubsApproved());
    dispatch(bookClubActionCreators.fetchMyBookClubsBeforeApproval());
    dispatch(bookClubActionCreators.fetchMyBookClubsDenied());

    if (action.cb) action.cb();

    done();
  }
});

const fetchMyBookClubsApprovedLogic = createLogic({
  type: bookClubActions.FETCH_MY_BOOK_CLUBS_APPROVED,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const userId = auth.currentUser.uid;
    // const res = await Network.getBookClubsApprovedByUserId(userId);
    // if (!res.err) dispatch(bookClubActionCreators.fetchMyBookClubsApprovedSuccess(res));
    if (action.cb) action.cb();
    done();
  }
});

const fetchMyBookClubsBeforeApprovalLogic = createLogic({
  type: bookClubActions.FETCH_MY_BOOK_CLUBS_BEFORE_APPROVAL,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const userId = auth.currentUser.uid;

    // const res = await Network.getBookClubsBeforeApprovalByUserId(userId);
    // if (!res.err) dispatch(bookClubActionCreators.fetchMyBookClubsBeforeApprovalSuccess(res));

    done();
  }
});

const fetchMyBookClubsDeniedLogic = createLogic({
  type: bookClubActions.FETCH_MY_BOOK_CLUBS_DENIED,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {

    const userId = auth.currentUser.uid;
    // const res = await Network.getBookClubsDeniedByUserId(userId);
    // if (!res.err) dispatch(bookClubActionCreators.fetchMyBookClubsDeniedSuccess(res));

    done();
  }
});

const fetchAllBookClubsLogic = createLogic({
  type: bookClubActions.FETCH_ALL_BOOK_CLUBS,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {

    const res = await Network.getAllBookClubsIsPublic();
    if (!res.err) dispatch(bookClubActionCreators.fetchAllBookClubsSuccess(res));
    done();
  }
});


export default [
  fetchMyBookClubsLogic,
  fetchMyBookClubsApprovedLogic,
  fetchMyBookClubsBeforeApprovalLogic,
  fetchMyBookClubsDeniedLogic,

  fetchAllBookClubsLogic,
]