import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {db} from "../../constants/firebase";
import {Container, Segment, Image, Card,} from 'semantic-ui-react';
import BookClubManageScrapComponent from "../../components/bookClubManage/BookClubManageScrapComponent";

class AdminBookClubManageScrapContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bookClubBooks: [],
    }
  }

  componentDidMount() {
    this._loadBookClubBooks();
  }

  _loadBookClubBooks = async () => {
    const {bookClubId} = this.props.match.params;
    db.collection('bookClubBooks')
      .where('bookClubId', '==', bookClubId)
      .orderBy('order', 'asc')
      .get()
      .then(snapshot => {
        const bookClubBooks = [];
        snapshot.forEach(doc => {
          let bookClubBook = Object.assign(doc.data(), {id: doc.id});
          bookClubBooks.push(bookClubBook)
        });
        this.setState({
          bookClubBooks
        })
      })
      .catch(e => {
        console.log(e)
      })
  };

  render() {
    const {bookClubBooks} = this.state;
    const {bookClubId} = this.props.match.params;

    return (
      <Fragment>
        {
          bookClubBooks.map((book, i) => {
            return (
              <BookClubManageScrapComponent
                key={i}
                bookClubId={bookClubId}
                book={book}
                isbn={book.isbn}/>
            )
          })
        }
      </Fragment>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(AdminBookClubManageScrapContainer);