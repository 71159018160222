import React, {Component} from 'react';
import {connect} from 'react-redux'
import {authActionCreators} from "../../redux/auth/authStore";
import Colors from "../../constants/Colors";
import {Container, Image, Grid,} from 'semantic-ui-react';
import {Link} from 'react-router-dom'
import Analytics from "../../middleware/Analytics";

class AppMainContainer extends Component {

  componentDidMount() {
    Analytics.pageViewAll('/about/app');
  }

  render() {
    return (
      <div>
        <Container fluid style={{
          minHeight: '30rem',
          backgroundColor: Colors.backgroundColor,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Image src={require('../../assets/tabBarIcon/1b.png')} size={'small'}/>
        </Container>
        <Grid stackable>
          <Grid.Row
            columns={2}>
            <Grid.Column
              className={'mainDescriptionGrid TwoColumn'}
              style={{display: 'flex'}}>
              <div style={{
                flex: 1,
                backgroundColor: Colors.backgroundColor,
                padding: '1.3rem',
                backgroundImage: `url(/images/home/maxWidth:'70vw')`,
                backgroundPosition: 'center bottom',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              }}>
                <div className={'mainHeaderText'}>
                  서재
                </div>
                <div className={'subHeaderText'}>
                  누구에게나 멋진
                </div>
                <p className={'mainDescriptionText'}>
                  언제. 어디서나.<br/>
                  영감을 잊지않도록
                </p>
                <Link to={'/about/library'} className={'buttonHover'}>
                  <p className={'buttonHover'}>
                    더 알아보기 >
                  </p>
                </Link>
              </div>
            </Grid.Column>
            <Grid.Column
              className={'mainDescriptionGrid TwoColumn'}
              style={{display: 'flex'}}>
              <div style={{
                flex: 1,
                backgroundColor: Colors.backgroundColor,
                padding: '1.3rem',
                backgroundImage: `url(/images/home/main_essay.png)`,
                backgroundPosition: 'center bottom',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              }}>
                <div className={'mainHeaderText'}>
                  프로.
                </div>
                <p className={'subHeaderText'}>
                  더 깊게, 더 멀리.
                </p>
                <Link to={'/about/essay'} className={'buttonHover'}>
                  <p className={'buttonHover'}>
                    더 알아보기 >
                  </p>
                </Link>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    auth: state.auth
  }),
  {
    ...authActionCreators,
  }
);

export default enhance(AppMainContainer);
