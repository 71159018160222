import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux'
import {authActionCreators} from "../../redux/auth/authStore";
import GoogleMapComponent from '../../components/map/GoogleMapComponent'
import {GoogleMapKey} from "../../config/keys";
import {firebase} from "../../constants/firebase";
import {Container, Image, Icon, Segment, Card, Grid, Button, List, Header,} from 'semantic-ui-react';
import Colors from "../../constants/Colors";
import {Link} from 'react-router-dom'
import Footer from "../../components/shared/Footer";
import {homeActionCreators} from "../../redux/home/homeStore";
import Analytics from "../../middleware/Analytics";

class HomeMainContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {}
    this._loadCuration();
  }

  componentDidMount() {
    Analytics.pageViewAll('/');
    window.scrollTo(0, 0);
    console.log('HOME MAIN CONTAINER');
    console.log(new firebase.firestore.GeoPoint(0, 0))
  }


  _loadCuration = async () => {
    // this.props.fetchCurations();
  };

  render() {
    return (
      <Fragment>
        <Grid stackable style={{}}>
          <Grid.Row
            verticalAlign={'middle'}
            style={{minHeight: '70vh'}}>
            <Grid.Column style={{}}>
              <Header style={{textAlign: 'center', fontSize: '4rem'}}>
                완전히 새로운 방식의 독서.
              </Header>
              <div style={{display: 'flex', marginTop: '3rem',}}>
                {/*<div className={'roundButton'} style={{}}>
                  소개 영상 보기
                </div>*/}
                <Icon name={'chevron circle down'}
                      style={{margin: 'auto', color: Colors.mainColor, fontSize: '3rem'}}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={'mainDescriptionGrid'}
            verticalAlign={'middle'}
            style={{
              // paddingTop: '20vh'
            }}
          >
            <Grid.Column>
              <div className={'mainHeaderText'}>
                북클럽
              </div>
              <p className={'mainDescriptionText'}>
                오프라인 또는 온라인으로.<br/>
                같은 책.<br/>
                다른 생각.<br/>
              </p>
              {/*<Link to={'/about/bookclub'} className={'buttonHover'}>
                <p className={'buttonHover'}>
                  더 알아보기 >
                </p>
              </Link>*/}
              {/*<Image src={require('../../assets/appScreen01.png')} size={'medium'} centered/>*/}
              <Image
                src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Fmain_bookclub.png?alt=media&token=9180bd7f-a375-48e9-a461-b7a723f30d3c'}
                className={'mainImage'}
                // style={{
                //   maxWidth: '1000px'
                // }}
                centered/>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row
            style={{paddingTop: '20vh'}}
            className={'mainDescriptionGrid'}
            verticalAlign={'middle'}
            columns={1}>
            <Grid.Column>
              <div className={'mainHeaderText'}>
                카메라
              </div>
              <div className={'subHeaderText'}>
                잊어 버리지 않는 기록
              </div>
              <p className={'mainDescriptionText'}>
                기억하고 싶은 것을 찰칵<br/>
                찍고, 긋고, 끝.
              </p>
              {/*<Link to={'/about/camera'} className={'buttonHover'}>
                <p className={'buttonHover'}>
                  더 알아보기 >
                </p>
              </Link>*/}
              <Image
                src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Fmain_camera.png?alt=media&token=31dd90e0-ff8e-4c1a-b302-4d92af55b923'}
                className={'mainImage'}
                // style={{
                //   maxWidth: '1000px'
                // }}
                centered/>
              {/*<Image src={'/images/home/main_bookclub.png'} size={'small'} centered/>*/}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row
            style={{paddingTop: '20vh'}}
            className={'mainDescriptionGrid'}
            verticalAlign={'middle'}
            columns={1}>
            <Grid.Column>
              <div className={'mainHeaderText'}>
                서재
              </div>
              <div className={'subHeaderText'}>
                누구에게나 멋진
              </div>
              <p className={'mainDescriptionText'}>
                언제. 어디서나.<br/>
                영감을 잊지않도록
              </p>
              {/*<Link to={'/about/library'} className={'buttonHover'}>
                <p className={'buttonHover'}>
                  더 알아보기 >
                </p>
              </Link>*/}
              <Image
                src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Fmain_library.png?alt=media&token=64f28275-fc56-4b1b-a6cd-398ba98cf0cd'}
                className={'mainImage'}
                // style={{
                //   maxWidth: '1000px'
                // }}
                centered/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            columns={1}
            style={{minHeight: '70vh', textAlign: 'center', paddingTop: '20vh'}}
            verticalAlign={'bottom'}>
            <Grid.Column id={'noMargin'}>
              <div className={'mainHeaderText'}>
                샘솟는 영감과 즐거움을 위해.
              </div>
              <div style={{display: 'flex', marginTop: '3rem',}}>
                <a className={'roundButton'} href={'https://readersrn.page.link/fromhomepage'} target={'_blank'}>
                  앱 다운로드 (모바일에서만 열립니다.)
                </a>
              </div>
              <div style={{
                display: 'flex', flex: 1,
                marginTop: '3rem'
              }}>
                <div style={{
                  flex: 1,
                  // backgroundColor: Colors.backgroundColor,
                  width: '100%',
                  minHeight: '30rem',
                  backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Fmain_bottom.png?alt=media&token=7c642ddd-221f-4da2-a64a-ee227c91ddc4)`,
                  backgroundPosition: 'center bottom',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }}>

                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>

    );
  }
}

const styles = {
  button: {
    fontWeight: '900',
    fontSize: '1.2rem',
    color: Colors.mainColor,
  },
  headerText: {},
  subHeaderText: {}
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
    ...homeActionCreators,
  }
);

export default enhance(HomeMainContainer);
