import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card, Container, Icon, Button, Image, Header,} from "semantic-ui-react";
import _ from 'lodash';
import mockUpImages from "../../constants/mockUpImages";

class HomeMainContainer extends Component {
  render() {
    return (
      <div style={{marginTop: 50}}>
        <Container>
          <Card.Group itemsPerRow={5}>
            {
              _.times(5, (o => {
                return (
                  <Card>
                    <Image
                      src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/story%2F201911%2F3a8551a8-cbdc-4645-9a58-5ea8dbdd624e.png?alt=media&token=de0cc60e-e044-4db0-8da1-e2544a78b5fb'}/>
                    <Card.Content>
                      <Header>
                        북클럽 타이틀
                        <Header.Subheader>
                          북클럽 서브타이틀
                        </Header.Subheader>
                      </Header>
                      <Card.Description>
                        2010.01.20 - 2020.02.02
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <Button floated={'right'} color={'orange'} content={'마감임박'} size={'tiny'} compact/>
                      <Icon name='calendar alternate outline'/>4 Friends
                    </Card.Content>
                  </Card>
                )
              }))
            }
          </Card.Group>
          <Card.Group>
          </Card.Group>
        </Container>
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(HomeMainContainer);