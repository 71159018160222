import {createLogic} from 'redux-logic';
import {scrapActionCreators, scrapActions} from "./scrapStore";
import {auth, db} from "../../constants/firebase";
import Network from "../../library/Network";


const fetchAllMyScrapsLogic = createLogic({
  type: scrapActions.FETCH_ALL_MY_SCRAPS,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {


    const currentUser = auth.currentUser;
    const {uid: userId} = currentUser;
    const scraps = await db.collection('scraps')
      .where('isDeleted', '==', false)
      .where('userId', '==', userId)
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshot => {
        const scraps = [];
        snapshot.forEach(doc => {
          let scrap = Object.assign(doc.data(), {id: doc.id});
          scraps.push(scrap)
        });

        return scraps;
      })
      .catch(e => {
        console.log(e);

        return false;
      });


    if (scraps) {
      dispatch(scrapActionCreators.fetchAllMyScrapsSuccess(scraps))
    }


    done();
  }
});

const fetchAllPublicScrapsLogic = createLogic({
  type: scrapActions.FETCH_ALL_PUBLIC_SCRAPS,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const publicScraps = await Network.getPublicScraps();
    if (!publicScraps.err) {
      dispatch(scrapActionCreators.fetchAllPublicScrapsSuccess(publicScraps));
    }

    if (action.cb) action.cb();

    done();
  }
});


export default [
  fetchAllMyScrapsLogic,
  fetchAllPublicScrapsLogic,
]