import Colors from "../constants/Colors";
import {firebase} from "../constants/firebase";

export const NEW_DESCRIPTION_MODULE = {
  header: {
    content: '',
    align: 'left',
    color: Colors.mainBlack,
  },
  subHeader: {
    content: '',
    align: 'left',
    color: Colors.mainBlack,
  },
  description: {
    content: '',
    align: 'left',
    color: Colors.mainBlack,
  },
  bullet: '',
  youTubeId: '',
  hasYouTubeId: false,

  imageUrl: '',
  isImageFullWidth: false,

  hasButton: false,
  externalLink: '',
  buttonText: '',

  hasLocation: false,
  locationName: '',
  locationCoordinate: new firebase.firestore.GeoPoint(37.4980854357918, 127.028000275071)

  // location : new firebase.firestore.GeoPoint(127.028000275071,37.4980854357918)
};

