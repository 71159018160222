import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {firebaseDB} from "../../constants/firebase";
import {Segment, Message, Container, Button, Form, Radio, Card, Grid, Header,} from "semantic-ui-react";
import moment from 'moment';
import _ from 'lodash';
import {moveItemInArrayFromIndexToIndex} from "../../middleware/arrayHandler";

const MODLUE = {
  title: '',
  description: '',
  detail: '',
};

class AdminPrivacyPolicyContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      privacyPolicy: {
        modules:[],
      },
    }
  }

  componentDidMount() {
    this._loadAdminSettings();
  }

  _loadAdminSettings = async () => {
    await firebaseDB
      .adminSettings
      .doc('privacyPolicy')
      .get()
      .then(doc => {
        if (!doc.exists) return;
        const privacyPolicy = Object.assign(doc.data(), {id: doc.id});
        this.setState({
          privacyPolicy
        })
      })
      .catch(e => {
          console.log(e)
        }
      )
  };

  _updateAdminSetting = async (updatedPrivacyPolicy) => {
    await firebaseDB
      .adminSettings
      .doc('privacyPolicy')
      .set(updatedPrivacyPolicy, {merge: true})
      .then(() => {
        window.alert('반영 완료');
      })
      .catch(e => {
        console.log(e);
      })
  };

  _handleAddModule = () => {
    const{privacyPolicy} = this.state;
    privacyPolicy.modules.push(_.cloneDeep(MODLUE));
    this.setState({
      privacyPolicy,
    })
  };

  _handleEdit = async () => {
    const {editing} = this.state;
    if (editing) {
      // 완료 처리
      const a = window.confirm('변경사항을 반영하시겠습니까?\n앱 사용에 영향을 미칠 수 있으니, 확인해 주세요');
      if (!a) return;

      this.setState({
        editing: false,
      });
      this._updateAdminSetting(this.state.privacyPolicy);

    } else {
      // 편집 모드로 전환하기
      this.setState({
        editing: true
      })
    }

  }

  render() {
    const {privacyPolicy} = this.state;
    return (
      <Container as={Segment} vertical>
        <Header content={'개인정보수집방칙'}/>
        <Button content={this.state.editing ? '완료' : '편집'}
                color={this.state.editing ? 'blue' : 'red'}
                onClick={this._handleEdit}/>
        {
          privacyPolicy.modules.map((module, i) => {
            return (
              <Segment key={i}>
                <Form>
                  <Form.Input
                    disabled={!this.state.editing}
                    name={'Title'}
                    label={'Title'}
                    value={module.title}
                    onChange={(e, {value}) => {
                      privacyPolicy.modules[i].title = value;
                      this.setState({privacyPolicy})
                    }}
                  />
                  <Form.TextArea
                    disabled={!this.state.editing}
                    name={'description'}
                    label={'description'}
                    value={module.description}
                    onChange={(e, {value}) => {
                      privacyPolicy.modules[i].description = value;
                      this.setState({privacyPolicy})
                    }}
                  />
                  <Form.TextArea
                    disabled={!this.state.editing}
                    name={'detail'}
                    label={'detail'}
                    value={module.detail}
                    onChange={(e, {value}) => {
                      privacyPolicy.modules[i].detail = value;
                      this.setState({privacyPolicy})
                    }}
                  />
                </Form>
                <Button content={'위로'}
                        disabled={i === 0 || !this.state.editing}
                        onClick={() => {
                          privacyPolicy.modules = moveItemInArrayFromIndexToIndex(privacyPolicy.modules, i, i - 1)
                          this.setState({privacyPolicy});
                        }}/>
                <Button content={'아래로'}
                        disabled={i === privacyPolicy.modules.length - 1 || !this.state.editing}
                        onClick={() => {
                          privacyPolicy.modules = moveItemInArrayFromIndexToIndex(privacyPolicy.modules, i, i + 1)
                          this.setState({privacyPolicy});
                        }}/>
              </Segment>
            )
          })
        }
        <Button content={'추가'}
                disabled={!this.state.editing}
                onClick={this._handleAddModule}/>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    auth: state.auth,
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(AdminPrivacyPolicyContainer);