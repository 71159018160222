import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {authActionCreators} from "../../redux/auth/authStore";
import {
  Button,
  Form,
  Grid,
  Header,
  Segment,
  Message
} from "semantic-ui-react";
import {Redirect} from "react-router";
import Colors from "../../constants/Colors";
import Analytics from "../../middleware/Analytics";

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errorMessage: []
    };
  }

  componentDidMount() {
    Analytics.pageViewAll('/login');
    window.scrollTo(0, 0);
  }

  handleChange = (e, {name, value}) => this.setState({[name]: value});

  handleSubmit = e => {
    e.preventDefault();
    this.props.signIn(this.state.email, this.state.password, error => {
      const {errorMessage} = this.state;
      console.log(error);
      this.setState({
        errorMessage: [...errorMessage, error.message]
      });
      console.log(this.state.errorMessage);
    });
  };

  render() {
    const {isLoggedIn} = this.props;
    const {errorMessage} = this.state;
    if (isLoggedIn) {
      const {from} = this.props.location.state || {
        from: {pathname: "/my/bookclub"}
      };
      return <Redirect to={from}/>;
    }

    return (
      <div>
        <Grid
          stackable
          textAlign="center"
          style={{height: "100vh"}}
          verticalAlign="middle"
        >
          <Grid.Column style={{maxWidth: 450}}>
            <Header as="h2" textAlign="center" style={{fontWeight: '900'}}>
              시작하기
            </Header>
            <Form size="large" onSubmit={this.handleSubmit}>
              <Segment stacked>
                <Form.Input
                  fluid
                  icon="user"
                  name="email"
                  iconPosition="left"
                  placeholder="이메일"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="비밀번호"
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
                {errorMessage.length > 0 && (
                  <Message
                    size="mini"
                    negative
                    header="로그인 에러"
                    list={errorMessage}
                  />
                )}

                <Form.Button
                  onClick={() => {
                    this.setState({
                      errorMessage: []
                    });
                  }}
                  color="black"
                  fluid
                  size="large"
                  style={{
                    backgroundColor: Colors.tintColor,
                  }}
                >
                  로그인
                </Form.Button>
              </Segment>
            </Form>
            <Message style={{textAlign: 'left'}}>
              아직 회원 가입을 하지 않으셨나요? <Link to={{pathname: '/signup'}}>여기</Link>를 눌러 가입하세요 <br/>
              {/*비밀번호가 기억나시지 않나요? <Link to={{pathname: '/reset-password'}}>여기</Link>를 눌러 비밀번호를 재설정하세요*/}
            </Message>
            {/*<Message warning style={{textAlign: 'left'}}>
              <Message.Header>
                기존 리더스 회원이신가요?
              </Message.Header>
              <Message.Content>
                서비스를 이전 하면서, 기존 회원의 비밀번호 정보는 암호화되어 옮기지 않았습니다. <br/>
                <Link to={{pathname: '/reset-password'}}>이메일을 통해 비밀번호를 재설정</Link>하세요<br/>
              </Message.Content>
            </Message>*/}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    user: state.auth.user,
    isLoggedIn: state.auth.isLoggedIn
  }),
  {
    ...authActionCreators
  }
);

export default enhance(LoginContainer);
