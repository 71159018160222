import Colors from "../constants/Colors";

export const NEW_PARTNER = {
  order: 0,
  name: '',
  description: '',

  profileImage: '',
  topBannerImage: '',
  squareBannerImage: '',

  imageUrl: '',
  image: {
    square: '',
    horizontal: '',
    vertical: '',

    squareLarge: '',
    horizontalLarge: '',
    verticalLarge: '',
  },
  backgroundImage: '',
  backgroundImageLarge: '',
  backgroundColor: 'white',
  nameColor: Colors.mainBlack,
  descriptionColor: Colors.mainBlack,
  textColor: Colors.mainBlack,

  tags: [],

  isPublic: false,
  isDeleted: false,


  createdAt: new Date(),
  modifiedAt: new Date(),
};