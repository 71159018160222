import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from '../../redux/auth/authStore';
import {Link} from 'react-router-dom';
import {Table, Segment, Button, Container, Loader, Header} from 'semantic-ui-react';
import {db} from '../../constants/firebase';
import moment from 'moment'
import Network from "../../library/Network";


class AdminCouponCodeMainContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      couponCodes: [],
      isLoaded: false,
    }
  }

  componentDidMount() {
  }



  render() {
    const {couponCodes} = this.props.admin;

    return (
      <Container as={Segment} vertical>
        <Header>쿠폰코드 어드민페이지</Header>

        <Button as={Link} to={'/admin/couponCode/make'}>
          쿠폰코드 만들기
        </Button>

        <Table compact size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No</Table.HeaderCell>
              <Table.HeaderCell>쿠폰 코드명</Table.HeaderCell>
              <Table.HeaderCell>쿠폰 코드</Table.HeaderCell>
              <Table.HeaderCell>쿠폰 ID</Table.HeaderCell>
              <Table.HeaderCell>할인율</Table.HeaderCell>
              <Table.HeaderCell>쿠폰 생성일</Table.HeaderCell>
              <Table.HeaderCell>쿠폰 만료일</Table.HeaderCell>
              <Table.HeaderCell>버튼</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              couponCodes.map((couponCode, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>{i}</Table.Cell>
                    <Table.Cell>{couponCode.name}</Table.Cell>
                    <Table.Cell>{couponCode.code}</Table.Cell>
                    <Table.Cell>{couponCode.id}</Table.Cell>
                    <Table.Cell>{couponCode.discountAmountOrRate}</Table.Cell>
                    <Table.Cell>{moment(couponCode.createdAt.seconds * 1000).format('YYYY.MM.DD')}</Table.Cell>
                    <Table.Cell>{moment(couponCode.expireDate.seconds * 1000).format('YYYY.MM.DD')}</Table.Cell>
                    <Table.Cell>
                      <Button as={Link} to={{
                        pathname: `/admin/couponCode/detail/${couponCode.id}`,
                        state: {couponCode: couponCode}
                      }}>
                        상세보기
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);
export default enhance(AdminCouponCodeMainContainer);