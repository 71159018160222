// const tintColor = '#2f95dc';
const mainBlack = '#363636';
const tintColor = '#69FF46';
// const mainColor = '#aee0c6';
const mainColor = '#69FF46';
const messageBoxBackground = '#EFFFEB';
const mainGrey = '#A7A7A7';
const mainLightGrey = '#EDEDED';
const underLineColor = [
  "rgba(125,0,0,0.3)",
  "rgba(125,125,0,0.3)",
  "rgba(125,555,0,0.3)",
  "rgba(125,555,0,0.3)",
];

export const getColorCode = () => {
  return [
    {
      key: 0,
      text: 'mainBlack',
      value: mainBlack,
    },
    {
      key: 1,
      text: 'white',
      value: 'white',
    },
    {
      key: 2,
      text: 'buttonGreen',
      value: '#36cc12'
    },
    {
      key: 3,
      text: 'fontGrey',
      value: '#A7A7A7'
    },
    {
      key: 4,
      text: 'Blue',
      value: '#3678bc'
    },
    {
      key: 5,
      text: '핑크-Rury',
      value: '#F0445B'
    },
    {
      key: 6,
      text: '보라-민석',
      value: '#9B57D5'
    },
    {
      key: 7,
      text: '노랑-정훈',
      value: '#F7CD00'
    },
    {
      key: 8,
      text: '남색-왕수',
      value: '#40426E'
    },
    {
      key: 9,
      text: '스카이-혁준',
      value: '#50BBFF'
    },
    {
      key: 10,
      text: '오렌지-혜진',
      value: '#F46E0A'
    }
  ]
};

export default {
  tintColor: tintColor,
  inActiveTintColor: '#ccc',
  mainBlack,
  mainColor,
  mainGrey,
  mainLightGrey,

  fontGrey: '#A7A7A7',
  backgroundColor: '#F8F9FB',
  // backgroundColor: 'yellow',
  backgroundColor2: '#FDFDFD',
  backgroundGrey: '#efefef',
  messageBoxBackground: messageBoxBackground,

  tabIconDefault: mainLightGrey,
  tabIconSelected: mainColor,
  tabBarBackgroundColor: '#fefef',
  // tabIconDefault: 'grey',
  // tabIconSelected: 'white',
  // tabBarBackgroundColor: 'black',
  tabBar: '#fefefe',
  buttonColor: '#3678bc',

  underLineColor,
  mainUnderLinColorGreen: '#69FF46',
  mainUnderLinColorGreenOpacity: 'rgba(105,255,70, 0.85)',
  mainUnderLineColorFont: '#D0EA46',
  mainUnderLineColor: '#E2FE4D',
  mainUnderLineColorPink: '#FF0099',
  mainUnderLineColorBlue: '#40C7FF',
  mainUnderLineColorOrange: '#FF9839',
  mainUnderLineColorOpacity: 'rgba(226,254,77, 0.85)',
  mainUnderLineColorPinkOpacity: 'rgba(255,0,153, 0.85)',
  mainUnderLineColorBlueOpacity: 'rgba(64,199,255, 0.85)',
  mainUnderLineColorOrangeOpacity: 'rgba(255,152,57, 0.85)',
};
