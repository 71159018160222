import React, {Component} from "react";
import {connect} from "react-redux";
import {authActionCreators} from "../../redux/auth/authStore";
import {db} from "../../constants/firebase";
import {Link} from "react-router-dom";
import {Table, Segment, Button, Container, Header} from "semantic-ui-react";
import moment from "moment";
import AdminUserComponent from '../../components/user/AdminUserComponent';

class AdminUserMainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: []
    };
  }

  componentDidMount() {
  }

  render() {
    const {users} = this.props.admin;
    console.log(users);

    return (
      <Container as={Segment} vertical>
        <Header> 유저 어드민페이지</Header>
        <Table compact size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No</Table.HeaderCell>
              <Table.HeaderCell>Nickname</Table.HeaderCell>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Phone</Table.HeaderCell>
              <Table.HeaderCell>가입일</Table.HeaderCell>
              <Table.HeaderCell>최근로그인</Table.HeaderCell>
              <Table.HeaderCell>유저구분</Table.HeaderCell>
              <Table.HeaderCell>버튼</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              users.map((user, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>
                      {i}
                    </Table.Cell>
                    <Table.Cell>
                      <AdminUserComponent user={user}/>
                    </Table.Cell>
                    <Table.Cell>{user.detailProfile ? user.detailProfile.name : ''}</Table.Cell>
                    <Table.Cell>{user.id}</Table.Cell>
                    <Table.Cell>{user.email}</Table.Cell>
                    <Table.Cell>{user.detailProfile.phoneNumber}</Table.Cell>
                    <Table.Cell>{user.createdAt ? moment(user.createdAt.seconds * 1000).format('YYYY. MM. DD') : ''}</Table.Cell>
                    <Table.Cell>{user.metadata ? moment(user.metadata.lastSignInTime).format('YYYY. MM. DD') : ''}</Table.Cell>
                    <Table.Cell>{user.isAdmin ? '어드민' : '일반유저'}</Table.Cell>
                    <Table.Cell>
                      <Button
                        as={Link}
                        to={{pathname: `/admin/user/${user.id}`, state: {user: user}}}
                        size={"mini"}>상세보기</Button>
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators
  }
);
export default enhance(AdminUserMainContainer);
