import {ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY} from "../config/keys";

const algoliasearch = require('algoliasearch');


const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY, {
  timeout: 4000,
});


export default {
  client
}