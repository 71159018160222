import React, {Component} from 'react';
import {firebase} from "../../constants/firebase";
import Network from "../../library/Network";
import {Card, Button, Divider, Image, Header, Label, Modal, Form} from 'semantic-ui-react';
import Colors from "../../constants/Colors";
import LinesEllipsis from 'react-lines-ellipsis'
import moment from 'moment';
import AdminUserComponent from '../../components/user/AdminUserComponent';
import {
  getNewNotification,
  NOTIFICATION_TYPE_SCRAP
} from "../../dataStructure/notification";
import {getNewPushNotification, getPushNotificationDropDownList} from "../../dataStructure/pushNotification";
import mockUpImages from "../../constants/mockUpImages";
import axios from "axios/index";
import {API_NEW} from "../../constants/API";
import FileUploader from "react-firebase-file-uploader";

const storageRef = `pushNotification/${moment().format("YYYYMM")}`;

class ScrapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      book: {},
      user: {},
      showModal: false,
      pushNotification: {
        ...getNewPushNotification(),
        data: {
          ...getNewPushNotification().data,
          type: NOTIFICATION_TYPE_SCRAP,
          scrapId: props.scrap.id,
        }
      },
      notification: {
        ...getNewNotification(),
        image: props.scrap.photoURL,
        scrapId: props.scrap.id,
        type: NOTIFICATION_TYPE_SCRAP,
      },
      push: true,
      pushSoundOn: true,
    }
  }


  componentDidMount() {
    this._loadUserInfo();
    this._loadBookInfo();

  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.scrap.id !== this.props.scrap.id) {
      this._loadBookInfo();
      this._loadUserInfo();
    }
  }


  _loadBookInfo = async () => {
    if (this.props.showsBookInfo) {
      const {scrap} = this.props;
      const bookData = await Network.getBookByBookId(scrap.bookId, scrap.userId);
      if (bookData.err) return;
      this.setState({
        book: bookData,
      })
    }
  };

  _loadUserInfo = async () => {
    if (this.props.showsUserInfo) {
      const {scrap} = this.props;
      const user = await Network.getUserInfoByUserId(scrap.userId);
      if (user.err) return;
      this.setState({
        user,
      })
    }
  };

  _sendPush = async () => {

    const a = window.confirm('발송하시겠습니까?');
    if (!a) return;

    const notificationData = {
      notificationData: this.state.notification,
      targetUserId: this.state.user.id,
    };

    const b = await axios.post(`${API_NEW}/notification/notification`, notificationData)
      .then(res => {
        console.log(res);
        return true;
      })
      .catch(e => {
        console.log(e);
        return false;
      });

    if (!b) return window.alert('알림 생성에 실패하였습니다');

    if (this.state.push) {
      const data = {
        pushNotificationData: {
          ...this.state.pushNotification,
          notification: {
            ...this.state.pushNotification.notification,
            sound: this.state.pushSoundOn ? 'default' : '',
          }
        },
        targetUserId: this.state.user.id,
      };
      axios.post(`${API_NEW}/notification/push`, data,)
        .then(res => {
          console.log(res);
          window.alert('발송 완료되었습니다.')
        })
        .catch(e => {
          console.log(e);
          window.alert('발송에 실패하였습니다.');
        });
    }
  };

  render() {
    const {scrap} = this.props;
    const {book, user} = this.state;
    const {pushNotification, notification} = this.state;

    return (
      <Card raised>

        <div style={{position: 'relative', margin: '1rem'}}>
          {/*<Image src={user.photoURL} avatar/>*/}
          {/*{user.displayName}*/}
          <Image
            style={{borderRadius: 5,}}
            src={scrap.photoURL}
          />
          <img src={book.cover} width={'30px'} style={{
            position: 'absolute',
            display: 'block',
            bottom: '1rem',
            left: '1rem',
            borderRadius: 5,
          }}/>
        </div>
        <Card.Content style={{
          fontSize: '0.8rem',
          color: Colors.fontGrey,
          lineHeight: '1.2.rem',
          // textOverflow: 'ellipsis',
          // maxHeight: '5rem',
          // lineClamp:3,
          // overflow: 'hidden',
          // display: 'inline-block',
          // whiteSpace: 'nowrap',
        }}>
          <Card.Description>
            <p>
              <Label content={scrap.id} size={'mini'}/>
            </p>
            <p>
              <AdminUserComponent user={user}/>
            </p>
            <p>
              <Label content={user.id} size={'mini'}/>
            </p>
            <p>
              <Label content={scrap.status} size={'mini'}/>
            </p>
            {
              scrap.isDeleted && (
                <p>
                  <Label content={'삭제 됨'} color={'red'} size={'mini'}/>
                </p>
              )
            }
          </Card.Description>
          <Card.Description>
            <LinesEllipsis
              text={scrap.content}
              maxLine={3}
              ellipsis='...'
              trimRight
              basedOn='letters'
            />
            {scrap.createdAt.seconds ? moment(scrap.createdAt.seconds * 1000).fromNow() : moment(scrap.createdAt._seconds * 1000).fromNow()}
          </Card.Description>
          <Card.Description>
            {scrap.createdAt.seconds ? moment(scrap.createdAt.seconds * 1000).format('YYYY.MM.DD (ddd) HH:mm:ss') : moment(scrap.createdAt._seconds * 1000).format('YYYY.MM.DD (ddd) HH:mm:ss')}
          </Card.Description>
        </Card.Content>
        {
          this.props.showsPushButton && (
            <Card.Content>
              <Button content={'푸시 보내기'} size={'mini'} onClick={() => {
                this.setState({showModal: true})
              }}/>
              <Modal open={this.state.showModal}>
                <Header>
                  Notification 만들기 {user.displayName}
                </Header>
                <Modal.Content image>
                  <Image src={notification.image} size={'medium'}/>
                  <Modal.Description>
                    <Button as={'label'} size={'mini'}>
                      이미지 바꾸기
                      <FileUploader
                        hidden
                        accept="image/*"
                        name="square"
                        randomizeFilename
                        storageRef={firebase.storage().ref(storageRef)}
                        onUploadSuccess={filename => {
                          firebase.storage()
                            .ref(storageRef)
                            .child(filename)
                            .getDownloadURL()
                            .then(imageUrl => {
                              notification.image = imageUrl;
                              this.setState({notification: notification})
                            });
                        }}
                      />
                    </Button>
                    <Button
                      size={'mini'}
                      content={'이미지 초기화'}
                      disabled={notification.image === scrap.photoURL}
                      onClick={() => {
                        notification.image = scrap.photoURL;
                        this.setState({
                          notification,
                        })
                      }}
                    />
                    <Divider/>
                    <Form>
                      <Form.Radio
                        toggle
                        checked={this.state.push}
                        label={'PUSH ALARM 전송'}
                        onChange={(e, {value}) => {
                          this.setState({push: value})
                        }}
                      />
                      <Form.Radio
                        toggle
                        checked={this.state.pushSoundOn}
                        label={'PUSH ALARM 소리'}
                        onChange={(e, {value}) => {
                          this.setState({pushSoundOn: !this.state.pushSoundOn})
                        }}
                      />
                      <Form.Input
                        placeholder={'푸시 타이틀'}
                        value={pushNotification.notification.title}
                        onChange={(e, {value}) => {
                          pushNotification.notification.title = value;
                          this.setState({
                            pushNotification,
                          })
                        }}
                      />
                      <Form.TextArea
                        placeholder={'푸시 내용'}
                        value={pushNotification.notification.body}
                        onChange={(e, {value}) => {
                          pushNotification.notification.body = value;
                          this.setState({
                            pushNotification,
                          })
                        }}
                      />
                      <Form.TextArea
                        placeholder={'알람 내용'}
                        value={notification.content}
                        onChange={(e, {value}) => {
                          notification.content = value;
                          this.setState({
                            notification,
                          })
                        }}
                      />
                      <Form.Group>
                        <Form.Dropdown
                          width={16}
                          selection
                          search
                          value={pushNotification.data && pushNotification.data.type}
                          placeholder={'PUSH NOTIFICATION TYPE'}
                          options={getPushNotificationDropDownList()}
                          onChange={(e, {value}) => {
                            pushNotification.data.type = value;
                            notification.type = value;
                            this.setState({pushNotification, notification})
                          }}
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Description>
                </Modal.Content>
                <Modal.Content>
                  <Header content={'푸시 알람 메시지'}/>
                  <p>
                    <b> {this.state.pushNotification.notification.title}</b> <br/>
                    {this.state.pushNotification.notification.body}
                  </p>
                  <Header content={'알람 메시지'}/>
                  <p>
                    {this.state.notification.content}
                  </p>
                </Modal.Content>
                <Modal.Actions>
                  <Button content={'보내기'} onClick={this._sendPush}/>
                  <Button content={'닫기'} onClick={() => {
                    this.setState({showModal: false})
                  }}/>
                </Modal.Actions>
              </Modal>
            </Card.Content>
          )
        }
      </Card>
    );
  }
}



export default ScrapComponent;