import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import moment from "moment";
import {Loader, Container, Image, Button, Segment, Comment, Form} from 'semantic-ui-react';
import {withLineEnter} from "../../middleware/TextHandler";
import Network from "../../library/Network";


class EssayCommentComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      userLoaded: false,
    }
  }


  componentDidMount() {
    this._loadUserInfo()
  }

  _loadUserInfo = async () => {
    const {essayComment} = this.props;
    const {userId} = essayComment;
    const user = await Network.getUserInfoByUserId(userId);
    if (user.err) return;
    this.setState({
      user,
      userLoaded: true,
    })
  };


  render() {
    const {essayComment} = this.props;
    const {user, userLoaded} = this.state;
    return (
      <Comment>
        <Comment.Avatar src={user.photoURL}/>
        <Comment.Content>
          <Comment.Author as={'a'}>
            {user.displayName} {(this.props.showsName && user.detailProfile && user.detailProfile.name) ? "(" + user.detailProfile.name + ")" : ''}
          </Comment.Author>
          <Comment.Metadata>
            {moment(essayComment.createdAt.seconds * 1000).format('YYYY.MM.DD HH:mm')}
          </Comment.Metadata>
          <Comment.Text>
            {withLineEnter(essayComment.content)}
          </Comment.Text>

          {
            this.props.auth.user.id === essayComment.userId ? (
              <Comment.Actions
                onClick={() => {
                  this.props.removeComment && this.props.removeComment();
                }}>
                <Comment.Action>[삭제하기]</Comment.Action>
              </Comment.Actions>
            ) : (
              <Comment.Actions
                onClick={() => {
                  this.props.reportComment && this.props.reportComment();
                }}>
                <Comment.Action>[신고하기]</Comment.Action>
              </Comment.Actions>
            )
          }
        </Comment.Content>
      </Comment>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(EssayCommentComponent);