import {firebase} from "../constants/firebase";

export const UPDATE_REQUEST_STATUS_REQUEST = 'UPDATE_REQUEST_STATUS_REQUEST';
export const UPDATE_REQUEST_STATUS_IN_PROGRESS = 'UPDATE_REQUEST_STATUS_IN_PROGRESS';
export const UPDATE_REQUEST_STATUS_DONE = 'UPDATE_REQUEST_STATUS_DONE';

export const getUpdateRequestStatusList = () => {
  return [
    {
      value: UPDATE_REQUEST_STATUS_REQUEST,
      text: '요청',
      key: 0,
    },
    {
      value: UPDATE_REQUEST_STATUS_IN_PROGRESS,
      text: '진행중',
      key: 1,
    },
    {
      value: UPDATE_REQUEST_STATUS_DONE,
      text: '완료',
      key: 2,
    },
  ]
}

export const NEW_UPDATE_REQUEST = () => {
  return {

    userId: '',
    title: '',
    content: '',

    status: UPDATE_REQUEST_STATUS_REQUEST,

    likeUserIds: [],
    numberOfLikes: 0,

    commentIds: [],
    numberOfComments: 0,

    reportUserIds: [],
    numberOfReports: 0,

    isDeleted: false,
    isReported: false,
    isPublic: true,

    modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  }
};