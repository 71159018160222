import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";

class AdminTransactionDetailContainer extends Component {
  render() {
    return (
      <div>
        AdminTransactionDetailContainer
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    auth: state.auth,
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(AdminTransactionDetailContainer);