import {firebase} from "../constants/firebase";
import mockUpImages from "../constants/mockUpImages";
import Colors from "../constants/Colors";
import _ from 'lodash';

export const STORY_COVER_TYPE_1_SUB_MAIN_DESC = 'STORY_COVER_TYPE_1_SUB_MAIN_DESC';
export const STORY_COVER_TYPE_2_MIDDLE_MAIN = 'STORY_COVER_TYPE_2_MIDDLE_MAIN';
export const STORY_COVER_TYPE_3_SUB_MAIN_DESC = 'STORY_COVER_TYPE_3_SUB_MAIN_DESC';
export const STORY_COVER_TYPE_4_CONTENT_CENTERED = 'STORY_COVER_TYPE_4_CONTENT_CENTERED';
export const STORY_COVER_TYPE_5_CONTENT_LIST = 'STORY_COVER_TYPE_5_CONTENT_LIST';

export const STORY_LIST_CONTENT_TYPE_BOOK = 'STORY_LIST_CONTENT_TYPE_BOOK';
export const STORY_LIST_CONTENT_TYPE_BOOK_CLUB = 'STORY_LIST_CONTENT_TYPE_BOOK_CLUB';
export const STORY_LIST_CONTENT_TYPE_BOOK_CLUB_LEADER = 'STORY_LIST_CONTENT_TYPE_BOOK_CLUB_LEADER';
export const STORY_LIST_CONTENT_TYPE_ESSAY_ONE_BOOK = 'STORY_LIST_CONTENT_TYPE_ESSAY_ONE_BOOK';
export const STORY_LIST_CONTENT_TYPE_ESSAY_DIFFERENT_BOOK = 'STORY_LIST_CONTENT_TYPE_ESSAY_DIFFERENT_BOOK';
export const STORY_LIST_CONTENT_TYPE_SCRAP_ONE_BOOK = 'STORY_LIST_CONTENT_TYPE_SCRAP_ONE_BOOK';
export const STORY_LIST_CONTENT_TYPE_SCRAP_DIFFERENT_BOOK = 'STORY_LIST_CONTENT_TYPE_SCRAP_DIFFERENT_BOOK';
export const STORY_LIST_CONTENT_TYPE_PLACE = 'STORY_LIST_CONTENT_TYPE_SCRAP_ONE_BOOK';

export const storyCoverTypeList = [
  STORY_COVER_TYPE_1_SUB_MAIN_DESC,
  STORY_COVER_TYPE_2_MIDDLE_MAIN,
  STORY_COVER_TYPE_3_SUB_MAIN_DESC,
  STORY_COVER_TYPE_4_CONTENT_CENTERED,
  STORY_COVER_TYPE_5_CONTENT_LIST
];
export const storyListContentTypeList = [
  STORY_LIST_CONTENT_TYPE_BOOK,
  STORY_LIST_CONTENT_TYPE_BOOK_CLUB,
  // STORY_LIST_CONTENT_TYPE_BOOK_CLUB_LEADER,
  // STORY_LIST_CONTENT_TYPE_ESSAY_ONE_BOOK,
  // STORY_LIST_CONTENT_TYPE_ESSAY_DIFFERENT_BOOK,
  // STORY_LIST_CONTENT_TYPE_SCRAP_ONE_BOOK,
  // STORY_LIST_CONTENT_TYPE_SCRAP_DIFFERENT_BOOK,
  // STORY_LIST_CONTENT_TYPE_PLACE,
];

export const storyCoverTypeDropDownList = () => {
  return _.map(storyCoverTypeList, (coverType, index) => ({
    key: index,
    text: coverType,
    value: coverType,
  }))
};

export const storyListContentTypeDropDownList = () => {
  return _.map(storyListContentTypeList, (item, index) => ({
    key: index,
    text: item,
    value: item,
  }))
};


export const NEW_STORY = () => {
  return {
    //cover
    order: 0,
    coverType: '',
    coverTitle: {
      text: 'title',
      color: 'white',
      fontSize: 20,
    },
    coverSubTitle: {
      text: 'subTitle',
      color: 'white',
      fontSize: 12,
    },
    coverDescription: {
      text: '설명',
      color: 'white',
      fontSize: 12,
    },
    coverBackgroundImageUrl: mockUpImages.planeImage,
    coverBackgroundColor: 'rgba(0,0,0,0.5)',
    coverBottomBackgroundColor: 'white',

    centerContentImageUrl: mockUpImages.planeImage,
    centerContentImageRadius: 0,
    centerContentImageHeight: 60,
    centerContentText: {
      text: 'centerContentText',
      color: Colors.mainBlack,
      fontSize: 16,
    },

    contentTitle: {
      text: 'content title',
      color: Colors.mainBlack,
      fontSize: 18,
    },
    contentSubTitle: {
      text: 'content subTitle',
      color: Colors.mainBlack,
      fontSize: 14,
    },
    contentDescription: {
      text: 'content 설명',
      color: Colors.mainBlack,
      fontSize: 12,
    },
    contentBackgroundImageUrl: mockUpImages.planeImage,
    contentBackgroundColor: 'rgba(0,0,0,0.5)',

    contentHeaderColor: 'white',
    contentHeaderTextColor: Colors.mainBlack,


    listContentType: '',
    listItems: [],
    maxNumberOfListContents: 3,
    showButton: false,
    buttonText: '더보기',

    showUserInfo: false, // 에세이,  스크랩에서만 활성화,
    showRates: false, // 클럽장

    contentModules: [],

    readUserIds: [],
    numberOfReadUsers: 0,

    commentUserIds: [],
    storyCommentIds: [],
    numberOfComments: 0,

    likeUserIds: [],
    numberOfLikes: 0,

    reportUserIds: [],
    numberOfReports: 0,
    isReported: false,

    isPublic: false,
    isDeleted: false,
    onlyForNewUser: false,

    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
  }
};