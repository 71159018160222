import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {firebaseDB} from "../../constants/firebase";
import {contentTypeDropDownList, NEW_CONTENT_MODULE} from "../../dataStructure/contentModule";
import {moveItemInArrayFromIndexToIndex} from "../../middleware/arrayHandler";
import {Container, Divider, Radio, Image, Segment, Button, Header, Modal, Loader, Form} from 'semantic-ui-react';
import ContentModuleComponent from "../../components/contentModule/ContentModuleComponent";

const docRef = firebaseDB
  .adminSettings
  .doc('bookClubDescriptionOfflineMeeting');

class BookClubDescriptionOfflineMeetingContainer extends Component {


  constructor(props) {
    super(props);
    this.state = {
      bookClubDescriptionOfflineMeeting: {
        contentModules: []
      }
    }
  }


  componentDidMount() {
    this._loadSetting();
  }

  _loadSetting = async () => {
    await docRef.get()
      .then(doc => {
        if (!doc.exists) return;
        const bookClubDescriptionOfflineMeeting = Object.assign(doc.data(), {id: doc.id});
        this.setState({
          bookClubDescriptionOfflineMeeting
        })
      })
      .catch(e => {
        window.alert('오류');
        console.log(e);
      })
  };

  _updateSetting = async () => {
    const a = window.confirm('저장하시겠습니까?');
    if (!a) return;

    await docRef.set(this.state.bookClubDescriptionOfflineMeeting, {merge: true})
      .then(() => {
        window.alert('완료');
        this._loadSetting();
      })
      .catch(e => {
        console.log(e);
      })
  };



  render() {
    const {bookClubDescriptionOfflineMeeting} = this.state;

    return (
      <Container as={Segment} vertical>
        <Header content={'bookClubDescriptionOfflineMeeting'}/>

        <Button
          primary
          content={'저장하기'}
          onClick={() => {
            this._updateSetting();
          }}
        />
        <Divider/>
        {
          bookClubDescriptionOfflineMeeting.contentModules.map((contentModule, index) => {
            return (
              <ContentModuleComponent
                save={this._updateSetting}
                onChangeState={(module) => {
                  bookClubDescriptionOfflineMeeting.contentModules[index] = module;
                  this.setState({bookClubDescriptionOfflineMeeting});
                }}
                moveModuleFoward={() => {
                  if (index === 0) return window.alert('처음입니다');
                  bookClubDescriptionOfflineMeeting.contentModules = moveItemInArrayFromIndexToIndex(bookClubDescriptionOfflineMeeting.contentModules, index, index - 1);
                  this.setState({bookClubDescriptionOfflineMeeting})
                }}
                moveModuleBack={() => {
                  if (index === bookClubDescriptionOfflineMeeting.contentModules.length - 1) return window.alert('끝입니다');
                  bookClubDescriptionOfflineMeeting.contentModules = moveItemInArrayFromIndexToIndex(bookClubDescriptionOfflineMeeting.contentModules, index, index + 1);
                  this.setState({bookClubDescriptionOfflineMeeting})
                }}
                deleteModule={() => {
                  const a = window.confirm('삭제하시겠습니까?');
                  if (!a) return;
                  bookClubDescriptionOfflineMeeting.contentModules.splice(index, 1);
                  this.setState({bookClubDescriptionOfflineMeeting});
                }}
                addModule={() => {
                  this.setState({
                    modalOpen: true,
                    indexOfContentModuleAdded: index + 1,
                  })
                }}
                contentModule={contentModule}
                key={index}/>
            )
          })
        }
        <Button
          content={'추가하기'}
          onClick={() => {
            this.setState({
              modalOpen: true,
              indexOfContentModuleAdded: bookClubDescriptionOfflineMeeting.contentModules.length,
            })
          }}
        />
        <Button
          primary
          content={'저장하기'}
          onClick={() => {
            this._updateSetting();
          }}
        />
        <Modal open={this.state.modalOpen}>
          <Modal.Content>
            {
              contentTypeDropDownList().map((contentType, i) => {
                return (
                  <Button
                    fluid
                    content={contentType.text}
                    key={i}
                    style={{marginBottom: 5}}
                    onClick={() => {
                      bookClubDescriptionOfflineMeeting.contentModules.splice(this.state.indexOfContentModuleAdded, 0, NEW_CONTENT_MODULE(contentType.value));
                      this.setState({
                        bookClubDescriptionOfflineMeeting,
                        modalOpen: false,
                      })
                    }}
                  />
                )
              })
            }
          </Modal.Content>
          <Modal.Actions>
            <Button content={'닫기'}
                    negative
                    onClick={() => {
                      this.setState({modalOpen: false,})
                    }}/>
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(BookClubDescriptionOfflineMeetingContainer);