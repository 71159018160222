import React, {Component, Fragment} from 'react';
import {auth, db, firebase, firebaseDB} from "../../constants/firebase";
import {Button} from 'semantic-ui-react'
import {NEW_TRANSACTION, TRANSACTION_TYPE_RETURN_DEPOSIT} from "../../dataStructure/transaction";
import _ from 'lodash';
import numeral from 'numeral';

class BookClubDepositRefundButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refundAlready: false,
      transactionLoaded: false,
      transaction: {},
    }
  }

  componentDidMount() {
    // bookClubEssaySchedule={essaySchedule}
    // essay={essay}
    // bookClubApply={bcApply}


    this._checkRefund();
  }

  _checkRefund = async () => {
    const {bookClubEssaySchedule, bookClubApply} = this.props;

    const res = await firebaseDB
      .transactions
      .where('bookClubApplyId', '==', bookClubApply.id)
      .where('bookClubEssayScheduleId', '==', bookClubEssaySchedule.id)
      .get()
      .then(snapshot => {
        const transactions = [];
        snapshot.forEach(doc => {
          let transaction = Object.assign(doc.data(), {id: doc.id});
          transactions.push(transaction)
        });
        console.log(transactions);
        return transactions;
      })
      .catch(e => {
          console.log(e);
          return {
            err: true
          }
        }
      );

    if (res.err) return;
    if (res.length > 0) {
      const transaction = res[0];

      this.setState({
        transaction,
        refundAlready: true,
        transactionLoaded: true,
        loading: false,
      })
    } else {
      this.setState({
        refundAlready: false,
        transactionLoaded: true,
        loading: false,
      })
    }
  };

  _handleCancelReturn = () => {
    const {bookClubEssaySchedule, bookClubApply, bookClub} = this.props;
    const {transaction} = this.state;
    const a = window.confirm(`${transaction.cash}원을 환급을 취소하시겠습니까?`);
    if (!a) return;
    this.setState({
      loading: true,
    });
    const {userId} = bookClubApply;

    const transactionRef = firebaseDB.transactions.doc(transaction.id);

    const accountRef = firebaseDB.accounts.doc(userId);
    const accountUpdate = {
      cash: firebase.firestore.FieldValue.increment(Number(-transaction.cash))
    };

    const batch = db.batch();
    batch.delete(transactionRef);
    batch.update(accountRef, accountUpdate);

    batch.commit().then(() => {
      window.alert('완료');
      this._checkRefund()
    })
  };

  _handleReturn = () => {
    const {bookClubEssaySchedule, bookClubApply, bookClub} = this.props;
    const a = window.confirm(`${bookClub.depositPerEssay}원을 환급하시겠습니까?`);
    if (!a) return;
    this.setState({
      loading: true,
    });
    const {userId} = bookClubApply;

    const newTransactionRef = firebaseDB.transactions.doc();
    const newTransactionData = {
      ..._.cloneDeep(NEW_TRANSACTION),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),

      paymentCompletedAt: firebase.firestore.FieldValue.serverTimestamp(),
      cash: bookClub.depositPerEssay,
      transactionType: TRANSACTION_TYPE_RETURN_DEPOSIT,
      bookClubId: bookClub.id,
      bookClubEssayScheduleId: bookClubEssaySchedule.id,
      bookClubApplyId: bookClubApply.id,

      userId: userId,
    };


    const accountRef = firebaseDB.accounts.doc(userId);
    const accountUpdate = {
      cash: firebase.firestore.FieldValue.increment(Number(bookClub.depositPerEssay))
    };

    const batch = db.batch();
    batch.set(newTransactionRef, newTransactionData);
    batch.update(accountRef, accountUpdate);

    batch.commit().then(() => {
      window.alert('완료');
      this._checkRefund()
    })

  };

  _handleHalfReturn = () => {

    const {bookClubEssaySchedule, bookClubApply, bookClub} = this.props;
    const {userId, depositPerEssay, defaultMembership} = bookClubApply;

    const a = window.confirm(`${depositPerEssay / 2}원을 환급하시겠습니까?`);
    if (!a) return;
    this.setState({
      loading: true,
    });

    const newTransactionRef = firebaseDB.transactions.doc();

    const newTransactionData = {
      ..._.cloneDeep(NEW_TRANSACTION),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),

      paymentCompletedAt: firebase.firestore.FieldValue.serverTimestamp(),
      cash: depositPerEssay / 2,
      transactionType: TRANSACTION_TYPE_RETURN_DEPOSIT,
      bookClubId: bookClub.id,
      bookClubEssayScheduleId: bookClubEssaySchedule.id,
      bookClubApplyId: bookClubApply.id,

      userId: userId,
    };


    const accountRef = firebaseDB.accounts.doc(userId);
    const accountUpdate = {
      cash: firebase.firestore.FieldValue.increment(Number(depositPerEssay / 2))
    };

    const batch = db.batch();
    batch.set(newTransactionRef, newTransactionData);
    batch.update(accountRef, accountUpdate);

    batch.commit().then(() => {
      window.alert('완료');
      this._checkRefund()
    })

  };

  render() {
    const {transaction} = this.state;
    const {bookClubApply} = this.props;

    if (!this.state.transactionLoaded) return <div/>;
    const $registerInfo = (
      <div>
        <p style={{fontSize: '0.3rem'}}>
          {bookClubApply.defaultMembership} <br/>
          {bookClubApply.depositPerEssay} <br/>
          {bookClubApply.numberOfEssays} <br/>
          {bookClubApply.amountPaymentNeeded} <br/>
        </p>
      </div>
    );
    if (this.state.refundAlready) {
      return (
        <Fragment>
          <Button content={`${transaction.cash} 환급 완료`}
                  disabled={this.state.loading}
                  negative
                  size={'mini'}
                  compact
                  onClick={this._handleCancelReturn}/>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Button content={'전액'}
                  disabled={this.state.loading}
                  positive
                  size={'mini'}
                  compact
                  onClick={this._handleReturn}/>

          <Button content={'반액'}
                  disabled={this.state.loading}
                  primary
                  size={'mini'}
                  compact
                  onClick={this._handleHalfReturn}/>
        </Fragment>
      );
    }

  }
}


export default BookClubDepositRefundButton;
