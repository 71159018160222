import {auth, COLLECTION_NAME_BOOKSHELVES, db, firebase, firebaseDB} from "../constants/firebase";
import _ from 'lodash';
import {NEW_ESSAY} from "../dataStructure/essay";
import {NEW_TRANSACTION} from "../dataStructure/transaction";
import {NEW_INQUIRY} from "../dataStructure/inquiry";
import {NEW_BOOKSHELF} from "../dataStructure/bookshelf";
import {NEW_CURATION} from "../dataStructure/curation";
import {NEW_COUPON_CODE} from "../dataStructure/coupon";
import {NEW_USER} from "../dataStructure/user";

export const PER_PATE_CURATION = 5;
export const STORIES_PER_PAGE = 5;
export const SCRAPS_PER_PAGE = 50;
export const ESSAYS_PER_PAGE = 50;

class Network {

  constructor() {

  }

  onError(e, request, message) {
    console.log(e);

    return {
      err: '에러 발생'
    }
  }

  onNext = (data, requestName) => {
    console.log(requestName, data);
    return data;
  };


  // curations
  getCuration = () => {
    return firebaseDB
      .curations
      .where('isDeleted', '==', false)
      .where('isPublic', '==', true)
      .orderBy('order', 'desc')
      .limit(PER_PATE_CURATION)
      .get()
      .then(snapshot => {
        const curations = [];
        snapshot.forEach(doc => {
          let curation = Object.assign(doc.data(), {id: doc.id});
          curations.push(curation)
        });
        return {
          curationLastVisible: snapshot.docs[snapshot.docs.length - 1],
          curations
        }
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getCurationMore = (lastVisible) => {
    return firebaseDB
      .curations
      .where('isDeleted', '==', false)
      .where('isPublic', '==', true)
      .orderBy('order', 'desc')
      .startAfter(lastVisible)
      .limit(PER_PATE_CURATION)
      .get()
      .then(snapshot => {
        const curations = [];
        snapshot.forEach(doc => {
          let curation = Object.assign(doc.data(), {id: doc.id});
          curations.push(curation)
        });
        return {
          curationLastVisible: snapshot.docs[snapshot.docs.length - 1],
          curations
        }
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };


  getAllCurations = () => {
    return firebaseDB
      .curations
      .orderBy('isDeleted')
      .orderBy('isPublic', 'desc')
      .orderBy('order', 'desc')
      .get()
      .then(snapshot => {
        const curations = [];
        snapshot.forEach(doc => {
          let curation = Object.assign(_.cloneDeep(NEW_CURATION), doc.data(), {id: doc.id});
          curations.push(curation)
        });
        return curations
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getCurationByCurationId = curationId => {
    return firebaseDB
      .curations
      .doc(curationId)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('데이터가 존재하지 않습니다.');
          return {
            err: '데이터가 존재하지 않습니다.'
          }
        }
        return Object.assign(_.cloneDeep(NEW_CURATION), doc.data(), {id: doc.id});
      })
      .catch(e => {
          return this.onError(e)
        }
      )

  };
  postCuration = (newCuration) => {
    return firebaseDB
      .curations
      .add(newCuration)
      .then(doc => {
        return {
          id: doc.id,
          result: true
        }
      })
      .catch(e => {
        return this.onError(e);
      })
  };
  updateCuration = (curation) => {
    return firebaseDB
      .curations
      .doc(curation.id)
      .update(Object.assign(curation, {modifiedAt: new Date()}))
      .then(() => {
        return {
          result: true,
        }
      })
      .catch(e => {
        return this.onError(e);
      })
  };


  // 유저
  getAllUsers = () => {
    return firebaseDB
      .users
      .orderBy('createdAt')
      .get()
      .then(snapshot => {
        const users = [];
        snapshot.forEach(doc => {
          let user = Object.assign(_.cloneDeep(NEW_USER), doc.data(), {id: doc.id});
          users.push(user)
        });
        return this.onNext(users);
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getUserInfoByUserId = (userId) => {
    return firebaseDB
      .users
      .doc(userId)
      .get()
      .then(doc => {
        if (!doc.exists) return {err: '유저 정보가 없습니다.'};
        return Object.assign(doc.data(), {id: doc.id});
      })
      .catch(e => {
        return this.onError(e)
      })
  };
  getUsersByTag = (tag) => {
    return firebaseDB
      .users
      .where('tags', 'array-contains', tag)
      .get()
      .then(snapshot => {
        const users = [];
        snapshot.forEach(doc => {
          let user = Object.assign(doc.data(), {id: doc.id});
          users.push(user)
        });
        return users
      })
      .catch(e => {
          return this.onError(e)
        }
      )

  };

  getAccountByUserId = (userId) => {
    return firebaseDB
      .accounts
      .doc(userId)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('데이터가 존재하지 않습니다.');
          return {
            err: '데이터가 존재하지 않습니다.'
          };
        }
        return Object.assign(doc.data(), {id: doc.id});
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getUserEssayListByUserId = (userId) => {
    return firebaseDB
      .essays
      .where('isDeleted', '==', false)
      .where('userId', '==', userId)
      .get()
      .then(snapshot => {
        const essays = [];
        snapshot.forEach(doc => {
          let essay = Object.assign(_.cloneDeep(NEW_ESSAY), doc.data(), {id: doc.id});
          essays.push(essay)
        });
        return essays;
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };

  updateUserInfo = (userId, updateData) => {

    return firebaseDB
      .users
      .doc(userId)
      .update(Object.assign(updateData, {modifiedAt: new Date()}))
      .then(() => {
        return true;
      })
      .catch(e => {
        return false;
      })
  };

  // 유저 불클럽
  getBookClubsApprovedByUserId = (userId) => {
    return firebaseDB
      .bookClubs
      .where('isDeleted', '==', false)
      .where('isPublic', '==', true)
      .where('participantIds', 'array-contains', userId)
      .orderBy('endDate', 'desc')
      .get()
      .then(snapshot => {
        const bookClubs = [];
        snapshot.forEach(doc => {
          let bookClub = Object.assign(doc.data(), {id: doc.id});
          bookClubs.push(bookClub)
        });
        console.log({bookClubs});
        return bookClubs
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getBookClubsBeforeApprovalByUserId = (userId) => {
    return firebaseDB
      .bookClubs
      .where('isDeleted', '==', false)
      .where('isPublic', '==', true)
      .where('applicantIds', 'array-contains', userId)
      .orderBy('endDate', 'desc')
      .get()
      .then(snapshot => {
        const bookClubs = [];
        snapshot.forEach(doc => {
          let bookClub = Object.assign(doc.data(), {id: doc.id});
          bookClubs.push(bookClub)
        });
        console.log({bookClubs});
        return bookClubs
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getBookClubsDeniedByUserId = (userId) => {
    return firebaseDB
      .bookClubs
      .where('isDeleted', '==', false)
      .where('isPublic', '==', true)
      .where('deniedIds', 'array-contains', userId)
      .orderBy('endDate', 'desc')
      .get()
      .then(snapshot => {
        const bookClubs = [];
        snapshot.forEach(doc => {
          let bookClub = Object.assign(doc.data(), {id: doc.id});
          bookClubs.push(bookClub)
        });
        console.log({bookClubs});
        return bookClubs
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };


  // 유저 책장
  getBookshelfListByUserId = (userId = auth.currentUser.uid) => {

    return firebaseDB
      .libraries
      .doc(userId)
      .collection(COLLECTION_NAME_BOOKSHELVES)
      .where('isDeleted', '==', false)
      // .orderBy('order', 'asc')
      // .orderBy('modifiedAt', 'desc')
      .get()
      .then(snapshot => {
        const bookshelves = [];
        snapshot.forEach(doc => {
          let bookshelf = Object.assign(_.cloneDeep(NEW_BOOKSHELF), doc.data(), {id: doc.id});
          bookshelves.push(bookshelf)
        });
        console.log({bookshelves});
        return bookshelves
      })
      .catch(e => {
          return this.onError(e)
        }
      );

    // return firebaseDB
    //   .bookshelves
    //   .where('userId', '==', userId)
    //   .where('isDeleted', '==', false)
    //   .orderBy('order', 'asc')
    //   .orderBy('modifiedAt', 'desc')
    //   .get()
    //   .then(snapshot => {
    //     const bookshelves = [];
    //     snapshot.forEach(doc => {
    //       let bookshelf = Object.assign(doc.data(), {id: doc.id});
    //       bookshelves.push(bookshelf)
    //     });
    //     return bookshelves
    //   })
    //   .catch(e => {
    //       return this.onError(e)
    //     }
    //   )
  };

  addBookshelf = (newBookshelfData) => {
    return firebaseDB
      .libraries
      .doc(newBookshelfData.userId)
      .collection('bookshelves')
      .add(newBookshelfData)
      .then(doc => {
        return {
          id: doc.id,
          result: true
        };
      })
      .catch(e => {
        console.log(e);
        return this.onError(e);
      })


    // return firebaseDB
    //   .bookshelves
    //   .add(newBookshelfData)
    //   .then(doc => {
    //     return doc.id;
    //   })
    //   .catch(e => {
    //     console.log(e);
    //     return this.onError(e);
    //   })
  };
  updateBookshelfByBookshelf = (bookshelf) => {
    return firebaseDB
      .libraries
      .doc(bookshelf.userId)
      .collection(COLLECTION_NAME_BOOKSHELVES)
      .doc(bookshelf.id)
      .update(bookshelf)
      .then(() => {
        return {
          id: bookshelf.id
        }
      })
      .catch(e => {
        return this.onError(e);
      })
  };
  deleteBookshelfByBookshelf = (bookshelf) => {
    return firebaseDB
      .libraries
      .doc(bookshelf.userId)
      .collection(COLLECTION_NAME_BOOKSHELVES)
      .doc(bookshelf.id)
      .update({isDeleted: true})
      .then(() => {
        return {
          id: bookshelf.id
        }
      })
      .catch(e => {
        return this.onError(e);
      })
  };

  // 책
  getAllBooksByUserId = (userId = auth.currentUser.uid) => {
    return firebaseDB
      .libraries
      .doc(userId)
      .collection('books')
      // .where('userId', '==', userId)
      // .where('isDeleted', '==', false)
      // .orderBy('modifiedAt', 'desc')
      .get()
      .then(snapshot => {
        const books = [];
        snapshot.forEach(doc => {
          let book = Object.assign(doc.data(), {id: doc.id});
          books.push(book)
        });
        return books
      })
      .catch(e => {
          return this.onError(e)
        }
      );

    // return firebaseDB
    //   .books
    //   .where('userId', '==', userId)
    //   .where('isDeleted', '==', false)
    //   .orderBy('modifiedAt', 'desc')
    //   .get()
    //   .then(snapshot => {
    //     const books = [];
    //     snapshot.forEach(doc => {
    //       let book = Object.assign(doc.data(), {id: doc.id});
    //       books.push(book)
    //     });
    //     return books
    //   })
    //   .catch(e => {
    //       return this.onError(e)
    //     }
    //   )
  };
  addBookToBookshelf = (book, bookshelfId) => {

    const bookRef = firebaseDB.libraries.doc(auth.currentUser.uid).collection('books').doc(book.id);
    const bookUpdate = {
      ...book,
      bookshelfIds: firebase.firestore.FieldValue.arrayUnion(bookshelfId)
    };

    const bookshelfRef = firebaseDB.libraries.doc(auth.currentUser.uid).collection('bookshelves').doc(bookshelfId);
    const bookshelfUpdate = {
      bookIsbns: firebase.firestore.FieldValue.arrayUnion(book.isbn)
    };

    const batch = db.batch();
    batch.set(bookRef, bookUpdate, {merge: true});
    batch.update(bookshelfRef, bookshelfUpdate);

    return batch.commit().then(result => {
      return this.onNext({
        result: true,
        data: result
      });
    }).catch(e => {
      return this.onError(e);
    })

    // return Promise
    //   .all([
    //     firebaseDB
    //       .books
    //       .doc(book.id)
    //       .update({
    //         bookshelfId: firebase.firestore.FieldValue.arrayUnion(bookshelfId)
    //       })
    //       .then(() => {
    //         return true;
    //       })
    //       .catch(e => {
    //         console.log(e);
    //         return {
    //           err: '에러발생'
    //         }
    //       }),
    //     firebaseDB
    //       .bookshelves
    //       .doc(bookshelfId)
    //       .update({
    //         bookIsbns: firebase.firestore.FieldValue.arrayUnion(book.isbn)
    //       })
    //       .then(() => {
    //         return true;
    //       })
    //       .catch(e => {
    //         console.log(e);
    //         return {
    //           err: '에러발생'
    //         }
    //       }),
    //   ])
    //   .then(value => {
    //     console.log(value);
    //
    //     return {
    //       result: value
    //     }
    //   })
    //   .catch(e => {
    //     return this.onError(e);
    //   })

  };
  removeBookFromBookshelf = (book, bookshelfId) => {

    const bookRef = firebaseDB.libraries.doc(auth.currentUser.uid).collection('books').doc(book.id);
    const bookUpdate = {
      ...book,
      bookshelfIds: firebase.firestore.FieldValue.arrayRemove(bookshelfId)
    };

    const bookshelfRef = firebaseDB.libraries.doc(auth.currentUser.uid).collection('bookshelves').doc(bookshelfId);
    const bookshelfUpdate = {
      bookIsbns: firebase.firestore.FieldValue.arrayRemove(book.isbn)
    };

    const batch = db.batch();
    batch.update(bookRef, bookUpdate);
    batch.update(bookshelfRef, bookshelfUpdate);

    return batch.commit().then(result => {
      return this.onNext(result);
    }).catch(e => {
      return this.onError(e);
    })


    // return Promise
    //   .all([
    //     firebaseDB
    //       .books
    //       .doc(book.id)
    //       .update({
    //         bookshelfId: firebase.firestore.FieldValue.arrayRemove(bookshelfId)
    //       })
    //       .then(() => {
    //         return true;
    //       })
    //       .catch(e => {
    //         console.log(e);
    //         return {
    //           err: '에러발생'
    //         }
    //       }),
    //     firebaseDB
    //       .bookshelves
    //       .doc(bookshelfId)
    //       .update({
    //         bookIsbns: firebase.firestore.FieldValue.arrayRemove(book.isbn)
    //       })
    //       .then(() => {
    //         return true;
    //       })
    //       .catch(e => {
    //         console.log(e);
    //         return {
    //           err: '에러발생'
    //         }
    //       }),
    //   ])
    //   .then(value => {
    //     console.log(value);
    //
    //     return {
    //       result: value
    //     }
    //   })
    //   .catch(e => {
    //     return this.onError(e);
    //   })

  };
  getBooksByBookshelfId = (bookshelfId, userId = auth.currentUser.uid) => {
    return firebaseDB
      .libraries
      .doc(userId)
      .collection('books')
      .where('bookshelfId', 'array-contains', bookshelfId)
      .where('isDeleted', '==', false)
      .get()
      .then(snapshot => {
        const books = [];
        snapshot.forEach(doc => {
          let book = Object.assign(doc.data(), {id: doc.id});
          books.push(book)
        });
        return books
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };


  //에세이
  getEssayByEssayId = (essayId) => {
    return firebaseDB
      .essays
      .doc(essayId)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('데이터가 존재하지 않습니다.');
          return {
            err: '데이터가 존재하지 않습니다.'
          }
        }
        const essay = Object.assign(_.cloneDeep(NEW_ESSAY), doc.data(), {id: doc.id});
        return essay;
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getEssaysByTag = (tag) => {
    return firebaseDB
      .essays
      .where('curationTags', 'array-contains', tag)
      .get()
      .then(snapshot => {
        const essays = [];
        snapshot.forEach(doc => {
          let essay = Object.assign(_.cloneDeep(NEW_ESSAY), doc.data(), {id: doc.id});
          essays.push(essay)
        });
        return essays
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getEssaysByBookClubEssayScheduleId = bookClubEssayScheduleId => {
    return firebaseDB
      .essays
      .where('bookClubEssayScheduleId', '==', bookClubEssayScheduleId)
      .get()
      .then(snapshot => {
        const essays = [];
        snapshot.forEach(doc => {
          let essay = Object.assign(_.cloneDeep(NEW_ESSAY), doc.data(), {id: doc.id});
          essays.push(essay)
        });
        return essays
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getEssaysByBookClubId = bookClubId => {
    return firebaseDB
      .essays
      .where('bookClubId', '==', bookClubId)
      .get()
      .then(snapshot => {
        const essays = [];
        snapshot.forEach(doc => {
          let essay = Object.assign(_.cloneDeep(NEW_ESSAY), doc.data(), {id: doc.id});
          essays.push(essay)
        });
        return this.onNext(essays);
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  uploadEssay = (newEssay) => {
    return firebaseDB
      .essays
      .add(newEssay)
      .then(() => {
        return {
          result: true
        }
      })
      .catch(e => {
        return this.onError(e);
      })
  };
  updateEssay = (updatedEssay) => {
    return firebaseDB
      .essays
      .doc(updatedEssay.id)
      .update(updatedEssay)
      .then(() => {
        return {
          result: true
        }
      })
      .catch(e => {
        return this.onError(e)
      })
  };

  likeEssay = (essayId, userId = auth.currentUser.uid) => {
    return firebaseDB
      .essays
      .doc(essayId)
      .update({
        likeUserIds: firebase.firestore.FieldValue.arrayUnion(userId),
        numberOfLikes: firebase.firestore.FieldValue.increment(1),
      })
      .then(() => {
        return {
          result: true
        }
      })
      .catch(e => {
        return this.onError(e);
      })
  };

  unLikeEssay = (essayId, userId = auth.currentUser.uid) => {
    return firebaseDB
      .essays
      .doc(essayId)
      .update({
        likeUserIds: firebase.firestore.FieldValue.arrayRemove(userId),
        numberOfLikes: firebase.firestore.FieldValue.increment(-1),
      })
      .then(() => {
        return {
          result: true
        }
      })
      .catch(e => {
        return this.onError(e);
      })
  };

  //쿠폰
  getAllCoupons = () => {
    return firebaseDB
      .coupons
      .get()
      .then(snapshot => {
        const coupons = [];
        snapshot.forEach(doc => {
          let coupon = Object.assign(doc.data(), {id: doc.id});
          coupons.push(coupon)
        });
        return coupons
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getAllCouponsByUserId = (userId = auth.currentUser.uid) => {
    return firebaseDB
      .coupons
      .where('userId', '==', userId)
      .get()
      .then(snapshot => {
        const coupons = [];
        snapshot.forEach(doc => {
          let coupon = Object.assign(doc.data(), {id: doc.id});
          coupons.push(coupon)
        });
        console.log({coupons})
        return coupons
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getCouponByCouponId = (couponId) => {
    return firebaseDB
      .coupons
      .doc(couponId)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('데이터가 존재하지 않습니다.');
          return {
            err: '데이터가 존재하지 않습니다.'
          }
        }
        return Object.assign(doc.data(), {id: doc.id});
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  updateCoupon = (coupon) => {
    return firebaseDB
      .coupons
      .doc(coupon.id)
      .update(coupon)
      .then(() => {
        return {
          result: true
        }
      })
      .catch(e => {
        return this.onError(e)
      })
  };
  postCoupon = (newCoupon) => {
    return firebaseDB
      .coupons
      .add(Object.assign(newCoupon, {createdAt: new Date(), modifiedAt: new Date()}))
      .then(doc => {
        return {
          id: doc.id,
          result: true
        }
      })
      .catch(e => {
        return this.onError(e);
      })
  };

  getAllCouponCodes = () => {
    return firebaseDB
      .couponCodes
      .get()
      .then(snapshot => {
        const couponCodes = [];
        snapshot.forEach(doc => {
          let couponCode = Object.assign(_.cloneDeep(NEW_COUPON_CODE), doc.data(), {id: doc.id});
          couponCodes.push(couponCode)
        });
        return couponCodes
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getCouponCodeByCouponCodeId = (couponCodeId) => {
    return firebaseDB
      .couponCodes
      .doc(couponCodeId)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('데이터가 존재하지 않습니다.');
          return {
            err: '데이터가 존재하지 않습니다.'
          }
        }
        return this.onNext(Object.assign(_.cloneDeep(NEW_COUPON_CODE), doc.data(), {id: doc.id}),
          'getCouponCodeByCouponCodeId'
        )
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  updateCouponCode = (couponCode) => {
    return firebaseDB
      .couponCodes
      .doc(couponCode.id)
      .update(couponCode)
      .then(() => {
        return this.onNext({
          result: true
        }, 'updateCouponCode')
      })
  };
  postCouponCode = (newCouponCode) => {
    return firebaseDB
      .couponCodes
      .add(Object.assign(_.cloneDeep(NEW_COUPON_CODE), newCouponCode, {createdAt: new Date(), modifiedAt: new Date()}))
      .then(doc => {
        return this.onNext({
          id: doc.id,
          result: true,
        }, 'postCouponCode')
      })
      .catch(e => {
        return this.onError(e);
      })
  };

  // 책
  getAdminBooksByTag = (tag) => {
    return firebaseDB
      .adminBooks
      .where('tags', 'array-contains', tag)
      .get()
      .then(snapshot => {
        const books = [];
        snapshot.forEach(doc => {
          let book = Object.assign(doc.data(), {id: doc.id});
          books.push(book)
        });
        return books
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getBookByBookId = (bookId, userId) => {
    return firebaseDB
      .libraries
      .doc(userId)
      .collection('books')
      .doc(bookId)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('데이터가 존재하지 않습니다.');
          return {
            err: '데이터가 존재하지 않습니다.'
          }
        }
        const book = Object.assign(doc.data(), {id: doc.id});
        return book;
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };

  // Notice
  getNoticesByTag = (tag) => {
    return firebaseDB
      .notices
      .where('tags', 'array-contains', tag)
      .where('isDeleted', '==', false)
      .where('isPublic', '==', true)
      .orderBy('order')
      .get()
      .then(snapshot => {
        const notices = [];
        snapshot.forEach(doc => {
          let notice = Object.assign(doc.data(), {id: doc.id});
          notices.push(notice)
        });
        return notices
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getAllNotice = () => {
    return firebaseDB
      .notices
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshot => {
        const notices = [];
        snapshot.forEach(doc => {
          let notice = Object.assign(doc.data(), {id: doc.id});
          notices.push(notice)
        });
        return notices
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getNoticeById = noticeId => {
    return firebaseDB
      .notices
      .doc(noticeId)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('데이터가 존재하지 않습니다.');
          return {
            err: '데이터가 존재하지 않습니다.'
          }
        }
        return this.onNext(Object.assign(doc.data(), {id: doc.id}));
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };

  // Tip
  getTipsByTag = (tag) => {
    return firebaseDB
      .tips
      .where('tags', 'array-contains', tag)
      .where('isDeleted', '==', false)
      .where('isPublic', '==', true)
      .orderBy('order')
      .get()
      .then(snapshot => {
        const tips = [];
        snapshot.forEach(doc => {
          let tip = Object.assign(doc.data(), {id: doc.id});
          tips.push(tip)
        });
        return tips
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };

  // Banner
  getBannersByTag = (tag) => {
    return firebaseDB
      .banners
      .where('isPublic', '==', true)
      .where('tags', 'array-contains', tag)
      .orderBy('order', 'asc')
      .get()
      .then(snapshot => {
        const tags = [];
        snapshot.forEach(doc => {
          let tag = Object.assign(doc.data(), {id: doc.id});
          tags.push(tag)
        });
        return tags
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };

  // 북클럽
  getAllBookClubs = () => {
    return firebaseDB
      .bookClubs
      .orderBy('isDeleted')
      .orderBy('isPublic', 'desc')
      .orderBy('status', 'desc')
      .orderBy('endDate', 'desc')
      .get()
      .then(snapshot => {
        const bookClubs = [];
        snapshot.forEach(doc => {
          let bookClub = Object.assign(doc.data(), {id: doc.id});
          bookClubs.push(bookClub)
        });
        return bookClubs
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getAllBookClubsIsPublic = () => {
    return firebaseDB
      .bookClubs
      .where('isDeleted', '==', false)
      .where('isPublic', '==', true)
      .orderBy('startDate', 'desc')
      .get()
      .then(snapshot => {
        const bookClubs = [];
        snapshot.forEach(doc => {
          let bookClub = Object.assign(doc.data(), {id: doc.id});
          bookClubs.push(bookClub)
        });
        return bookClubs
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getBookClubsByTag = tag => {
    return firebaseDB
      .bookClubs
      .where('tags', 'array-contains', tag)
      .orderBy('endDate', 'desc')
      .get()
      .then(snapshot => {
        const bookClubs = [];
        snapshot.forEach(doc => {
          let bookClub = Object.assign(doc.data(), {id: doc.id});
          bookClubs.push(bookClub)
        });
        return bookClubs
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getBookClubsByStatus = status => {
    return firebaseDB
      .bookClubs
      .where('status', '==', status)
      .orderBy('endDate', 'desc')
      .get()
      .then(snapshot => {
        const bookClubs = [];
        snapshot.forEach(doc => {
          let bookClub = Object.assign(doc.data(), {id: doc.id});
          bookClubs.push(bookClub)
        });
        return bookClubs
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };

  // EssaySchedules
  getBookClubEssayScheduleByBookClubEssayScheduleId = bookClubEssayScheduleId => {
    return firebaseDB
      .bookClubEssaySchedules
      .doc(bookClubEssayScheduleId)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('데이터가 존재하지 않습니다.');
          return {
            err: '데이터가 존재하지 않습니다.'
          }
        }
        const bookClubEssaySchedule = Object.assign(doc.data(), {id: doc.id});
        return bookClubEssaySchedule;
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getBookClubEssaySchedulesByBookClubId = bookClubId => {
    return firebaseDB
      .bookClubEssaySchedules
      .where('bookClubId', '==', bookClubId)
      .where('isDeleted', '==', false)
      .orderBy('startDate', 'asc')
      .get()
      .then(snapshot => {
        const bookClubEssaySchedules = [];
        snapshot.forEach(doc => {
          let bookClubEssaySchedule = Object.assign(doc.data(), {id: doc.id});
          bookClubEssaySchedules.push(bookClubEssaySchedule)
        });
        return bookClubEssaySchedules
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };


  // 북클럽 - 하나
  getBookClubDataById = (bookClubId) => {
    return firebaseDB
      .bookClubs
      .doc(bookClubId)
      .get()
      .then(doc => {
        const bookClub = Object.assign(doc.data(), {id: doc.id});

        return this.onNext(bookClub, 'getBookClubDataById');
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getBookClubBooksByBookClubId = bookClubId => {
    return firebaseDB
      .bookClubBooks
      .where('bookClubId', '==', bookClubId)
      .orderBy('createdAt', 'asc')
      .get()
      .then(snapshot => {
        const bookClubBooks = [];
        snapshot.forEach(doc => {
          let bookClubBook = Object.assign(doc.data(), {id: doc.id});
          bookClubBooks.push(bookClubBook)
        });
        return bookClubBooks
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getBookClubApplyByBookClubIdAndUserId = (bookClubId, userId) => {
    return firebaseDB
      .bookClubApplies
      .doc(userId + '-' + bookClubId)
      .get()
      .then(doc => {
        if (!doc.exists) {
          return {
            apply: false
          }
        }
        return Object.assign(doc.data(), {id: doc.id}, {apply: true});
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getBookClubAppliesByBookClubId = (bookClubId) => {
    return firebaseDB.bookClubApplies
      .where('bookClubId', '==', bookClubId)
      // .where('isDeleted', '==', false)
      .get()
      .then(snapshot => {
        const bookClubApplies = [];
        snapshot.forEach(doc => {
          let bookClubApplie = Object.assign(doc.data(), {id: doc.id});
          bookClubApplies.push(bookClubApplie)
        });
        return this.onNext(bookClubApplies);
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  }
  updateBookClubApply = bookClubApply => {
    return firebaseDB
      .bookClubApplies
      .doc(bookClubApply.id)
      .update({
        ...bookClubApply,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        return this.onNext({
          result: true,
        }, 'updateBookClubApply')
      })
      .catch(e => {
        return this.onError(e);
      })
  };

  // 리더
  getAllLeaders = () => {
    return firebaseDB
      .leaders
      .get()
      .then(snapshot => {
        const leaders = [];
        snapshot.forEach(doc => {
          let leader = Object.assign(doc.data(), {id: doc.id});
          leaders.push(leader)
        });
        return this.onNext(leaders, 'getAllLeaders');
      })
      .catch(e => {
          return this.onError(e, 'getAllLeaders')
        }
      )
  };
  getLeaderByLeaderId = (leaderId) => {
    return firebaseDB
      .leaders
      .doc(leaderId)
      .get()
      .then(doc => {
        if (!doc.exists) return {
          err: '데이터가 존재하지 않습니다.'
        };
        const leader = Object.assign(doc.data(), {id: doc.id});
        return leader;
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getBookClubListHeldByLeaderByLeaderId = (leaderId) => {
    return firebaseDB
      .bookClubs
      .where('leaderId', '==', leaderId)
      .where('isDeleted', '==', false)
      .get()
      .then(snapshot => {
        const bookClubs = [];
        snapshot.forEach(doc => {
          let bookClub = Object.assign(doc.data(), {id: doc.id});
          bookClubs.push(bookClub)
        });
        return bookClubs
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  updateLeader = (leader) => {
    return firebaseDB
      .leaders
      .doc(leader.id)
      .update(leader)
      .then(() => {
        return {
          result: true
        }
      })
      .catch(e => {
        return this.onError(e)
      })
  };
  postLeader = (newLeader) => {
    return firebaseDB
      .leaders
      .add(Object.assign(newLeader, {createdAt: new Date(), modifiedAt: new Date()}))
      .then(doc => {
        return {
          id: doc.id,
          result: true
        }
      })
      .catch(e => {
        return this.onError(e);
      })
  };

  getAllLeaderReviews = (leaderId) => {
    return firebaseDB
      .leaderReviews
      .where('leaderId', '==', leaderId)
      .get()
      .then(snapshot => {
        const leaderReviews = [];
        snapshot.forEach(doc => {
          let leaderReview = Object.assign(doc.data(), {id: doc.id});
          leaderReviews.push(leaderReview)
        });
        return this.onNext(leaderReviews)
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };


  //유저 검색
  getUserByUserDisplayName = (displayName) => {
    return firebaseDB
      .users
      .where('displayName', '==', displayName)
      .get()
      .then(snapshot => {
        const users = [];
        snapshot.forEach(doc => {
          let user = Object.assign(doc.data(), {id: doc.id});
          users.push(user)
        });
        return users
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };


  // 파트너
  getAllPartners = () => {
    return firebaseDB
      .partners
      .get()
      .then(snapshot => {
        const partners = [];
        snapshot.forEach(doc => {
          let partner = Object.assign(doc.data(), {id: doc.id});
          partners.push(partner)
        });
        return this.onNext(partners, 'getAllPartners');
      })
      .catch(e => {
          return this.onError(e, 'getAllPartners')
        }
      )
  }
  getPartnerByPartnerId = (partnerId) => {
    return firebaseDB
      .partners
      .doc(partnerId)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('데이터가 존재하지 않습니다.');
          return {
            err: '데이터가 존재하지 않습니다.'
          }
        }
        return Object.assign(doc.data(), {id: doc.id});
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  updatePartner = (partner) => {
    return firebaseDB
      .partners
      .doc(partner.id)
      .update(partner)
      .then(() => {
        return {
          result: true
        }
      })
      .catch(e => {
        return this.onError(e)
      })
  };
  postPartner = (newPartner) => {
    return firebaseDB
      .partners
      .add(Object.assign(newPartner, {createdAt: new Date(), modifiedAt: new Date()}))
      .then(doc => {
        return {
          id: doc.id,
          result: true
        }
      })
      .catch(e => {
        return this.onError(e);
      })
  };


  // TRANSACTION
  getAllTransactions = () => {
    return firebaseDB
      .transactions
      .get()
      .then(snapshot => {
        const transactions = [];
        snapshot.forEach(doc => {
          let transaction = Object.assign(doc.data(), {id: doc.id});
          transactions.push(transaction)
        });
        return transactions
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getUserTransactionsByUserId = (userId = auth.currentUser.uid) => {
    return firebaseDB
      .transactions
      .where('userId', '==', userId)
      .get()
      .then(snapshot => {
        const transactions = [];
        snapshot.forEach(doc => {
          let transaction = Object.assign(_.cloneDeep(NEW_TRANSACTION), doc.data(), {id: doc.id});
          transactions.push(transaction)
        });
        return transactions
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getTransactionsByBookClubId = (bookClubId) => {
    return firebaseDB.transactions
      .where('bookClubId', '==', bookClubId)
      .get()
      .then(snapshot => {
        const transactions = [];
        snapshot.forEach(doc => {
          let transaction = Object.assign(doc.data(), {id: doc.id});
          transactions.push(transaction)
        });
        return this.onNext(transactions, 'getTransactionsByBookClubId');
      })
      .catch(e => {
          return this.onError(e, 'getTransactionsByBookClubId')
        }
      )
  }



  // 책장
  getAllBookshelves = () => {
    return firebaseDB
      .bookshelves
      .get()
      .then(snapshot => {
        const bookshelves = [];
        snapshot.forEach(doc => {
          let bookshelf = Object.assign(doc.data(), {id: doc.id});
          bookshelves.push(bookshelf)
        });
        return bookshelves
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };


  // Comment
  getEssayCommentsByEssayId = (essayId) => {
    return firebaseDB
      .essayComments
      .where('essayId', '==', essayId)
      .where('isDeleted', '==', false)
      .orderBy('createdAt')
      .get()
      .then(snapshot => {
        const essayComments = [];
        snapshot.forEach(doc => {
          let essayComment = Object.assign(doc.data(), {id: doc.id});
          essayComments.push(essayComment)
        });
        return essayComments
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  updateEssayComment = essayComment => {
    return firebaseDB
      .essayComments
      .doc(essayComment.id)
      .update(Object.assign(essayComment, {modifiedAt: new Date()}))
      .then(() => {
        return {
          result: true
        }
      })
      .catch(e => {
        return this.onError(e)
      })
  };
  postNewEssayComment = newEssayComment => {
    return firebaseDB
      .essayComments
      .add(Object.assign(newEssayComment, {createdAt: new Date(), modifiedAt: new Date()}))
      .then(doc => {
        return {
          id: doc.id,
          result: true,
        }
      })
      .catch(e => {
        return this.onError(e)
      })
  };


  // Inquiry

  getInquiriesByUserId = (userId = auth.currentUser.uid) => {
    return firebaseDB
      .inquiries
      .where('userId', '==', userId)
      .get()
      .then(snapshot => {
        const inquires = [];
        snapshot.forEach(doc => {
          let inquiry = Object.assign(NEW_INQUIRY(), doc.data(), {id: doc.id});
          inquires.push(inquiry)
        });
        return inquires
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  postNewInquiry = (newInquiry) => {
    return firebaseDB
      .inquiries
      .add(Object.assign(newInquiry, {createdAt: new Date(), modifiedAt: new Date()}))
      .then(doc => {
        return {
          id: doc.id,
          result: true,
        }
      })
      .catch(e => {
        return this.onError(e);
      })
  };
  getInquiryById = (inquiryId) => {
    return firebaseDB
      .inquiries
      .doc(inquiryId)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('데이터가 존재하지 않습니다.');
          return {
            err: '데이터가 존재하지 않습니다.'
          }
        }
        return Object.assign(doc.data(), {id: doc.id});
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  updateInquiry = (inquiry) => {
    return firebaseDB
      .inquiries
      .doc(inquiry.id)
      .update(inquiry)
      .then(() => {
        return this.onNext({
          id: inquiry.id,
          success: true,
        }, 'updateInquiry')
      })
      .catch(e => {
        this.onError(e, 'updateInquiry');
      })
  };



  // 책, 책장,NEW
  postBookshelfByUserId = (newBookshelf) => {
    return firebaseDB
      .libraries.doc(newBookshelf.userId || auth.currentUser.uid)
      .collection('bookshelves')
      .add(Object.assign(NEW_BOOKSHELF, newBookshelf))
      .then(doc => {
        return {
          id: doc.id,
          result: true
        }
      })
      .catch(e => {
        return this.onError(e);
      })
  };


  // 스크랩
  getPublicScraps = () => {
    return db
      .collection('scraps')
      .where('isPublic', '==', true)
      .where('isDeleted', '==', false)
      .orderBy('createdAt', 'desc')
      .limit(12)
      .get()
      .then(snapshot => {
        const scraps = [];
        snapshot.forEach(doc => {
          let scrap = Object.assign(doc.data(), {id: doc.id});
          scraps.push(scrap)
        });
        return this.onNext(scraps);
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };



  // update Request
  getUpdateRequestAll = () => {
    return db
      .collection('updateRequests')
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshot => {
        const updateRequests = [];
        snapshot.forEach(doc => {
          let updateRequest = Object.assign(doc.data(), {id: doc.id});
          updateRequests.push(updateRequest)
        });
        return this.onNext(updateRequests);
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  updateUpdateRequest = (updatedUpdateRequest) => {
    return db
      .collection('updateRequests')
      .doc(updatedUpdateRequest.id)
      .update(updatedUpdateRequest)
      .then(() => {
        return this.onNext({
          success: true,
        })
      })
      .catch(e => {
        return this.onError(e);
      })
  };

  getInquiriesAll = () => {
    return db
      .collection('inquiries')
      .where('isDeleted', '==', false)
      .orderBy('createdAt')
      .get()
      .then(snapshot => {
        const inquiries = [];
        snapshot.forEach(doc => {
          let inquiry = Object.assign(doc.data(), {id: doc.id});
          inquiries.push(inquiry)
        });
        return this.onNext(inquiries);
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };



  //bookClubBanner
  getAllBookClubBanners = () => {
    return firebaseDB
      .bookClubBanners
      .orderBy('createdAt')
      .get()
      .then(snapshot => {
        const bookClubBanners = [];
        snapshot.forEach(doc => {
          let bookClubBanner = Object.assign(doc.data(), {id: doc.id});
          bookClubBanners.push(bookClubBanner)
        });
        return this.onNext(bookClubBanners);
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getBookClubBannerByBookClubBannerId = (bookClubBannerId) => {
    return firebaseDB
      .bookClubBanners
      .doc(bookClubBannerId)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('데이터가 존재하지 않습니다.');
          return {
            err: '데이터가 존재하지 않습니다.'
          }
        }
        return this.onNext(Object.assign(doc.data(), {id: doc.id}), 'getBookClubBannerByBookClubBannerId');
      })
      .catch(e => {
          return this.onError(e, 'getBookClubBannerByBookClubBannerId')
        }
      )
  }
  postBookClubBanner = (newBookClubBanner) => {
    return firebaseDB
      .bookClubBanners
      .add(newBookClubBanner)
      .then(doc => {
        return this.onNext({
          id: doc.id,
          success: true,
        }, 'postBookClubBanner')
      })
      .catch(e => {
        return this.onError(e, 'postBookClubBanner')
      })
  };
  updateBookClubBanner = (bookClubBanner) => {
    return firebaseDB
      .bookClubBanners
      .doc(bookClubBanner.id)
      .update(bookClubBanner)
      .then(() => {
        return this.onNext({
          id: bookClubBanner.id,
          success: true,
        }, 'updateBookClubBanner')
      })
      .catch(e => {
        return this.onError(e, 'updateBookClubBanner')
      })
  }


  //story
  getAllStories = () => {
    return firebaseDB
      .stories
      // .where('isDeleted', '==', false)
      // .where('isPublic', '==', true)
      .orderBy('order', 'desc')
      .get()
      .then(snapshot => {
        const stories = [];
        snapshot.forEach(doc => {
          let story = Object.assign(doc.data(), {id: doc.id});
          stories.push(story)
        });
        return this.onNext(stories, 'getAllStories');
      })
      .catch(e => {
          return this.onError(e, 'getAllStories')
        }
      )
  };
  getStories = () => {
    return firebaseDB
      .stories
      .where('isDeleted', '==', false)
      .where('isPublic', '==', true)
      .orderBy('order', 'desc')
      .limit(STORIES_PER_PAGE)
      .get()
      .then(snapshot => {
        const stories = [];
        snapshot.forEach(doc => {
          let story = Object.assign(doc.data(), {id: doc.id});
          stories.push(story)
        });
        return {
          storyLastVisible: snapshot.docs[snapshot.docs.length - 1],
          stories
        }
      })
      .catch(e => {
          return this.onError(e)
        }
      )
  };
  getStoriesMore = (lastVisible) => {
    return firebaseDB
      .stories
      .where('isDeleted', '==', false)
      .where('isPublic', '==', true)
      .orderBy('order', 'desc')
      .startAfter(lastVisible)
      .limit(STORIES_PER_PAGE)
      .get()
      .then(snapshot => {
        const stories = [];
        snapshot.forEach(doc => {
          let story = Object.assign(doc.data(), {id: doc.id});
          stories.push(story)
        });
        return {
          storyLastVisible: snapshot.docs[snapshot.docs.length - 1],
          stories
        }
      })
      .catch(e => {
        return this.onError(e)
      })
  };
  getStoryById = (storyId) => {
    return firebaseDB
      .stories
      .doc(storyId)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('데이터가 존재하지 않습니다.');
          return {
            err: '데이터가 존재하지 않습니다.'
          }
        }
        return this.onNext(Object.assign(doc.data(), {id: doc.id}), 'getStoryById');
      })
      .catch(e => {
          return this.onError(e, 'getStoryById')
        }
      )
  };
  postStory = (newStory) => {
    return firebaseDB
      .stories
      .add(newStory)
      .then(doc => {
        return this.onNext({
          id: doc.id,
          success: true,
        }, 'postStory')
      })
      .catch(e => {
        return this.onError(e, 'postStory');
      })
  };

  updateStory = (story) => {
    return firebaseDB
      .stories
      .doc(story.id)
      .update(story)
      .then(() => {
        return this.onNext({
          id: story.id,
          success: true,
        }, 'updateStory')
      })
      .catch(e => {
        return this.onError(e, 'updateStory')
      })
  };



  // ADMIN
  adminGetAllEssays = () => {
    return firebaseDB
      .essays
      .get()
      .then(snapshot => {
        const essays = [];
        snapshot.forEach(doc => {
          let essay = Object.assign(doc.data(), {id: doc.id});
          essays.push(essay)
        });
        return this.onNext(essays, 'adminGetAllEssays');
      })
      .catch(e => {
          return this.onError(e, 'adminGetAllEssays')
        }
      )
  };
  adminGetEssayLimit = (limit = ESSAYS_PER_PAGE) => {
    return firebaseDB
      .essays
      .orderBy('createdAt', 'desc')
      .limit(limit)
      .get()
      .then(snapshot => {
        const essays = [];
        snapshot.forEach(doc => {
          let essay = Object.assign(doc.data(), {id: doc.id});
          essays.push(essay)
        });
        return this.onNext({
          essays,
          lastVisible: snapshot.docs[snapshot.docs.length - 1],
        }, 'adminGetEssayLimit');
      })
      .catch(e => {
          return this.onError(e, 'adminGetEssayLimit')
        }
      )
  };
  adminGetEssayLimitMore = (lastVisible, limit = ESSAYS_PER_PAGE) => {
    return firebaseDB
      .essays
      .orderBy('createdAt', 'desc')
      .startAfter(lastVisible)
      .limit(limit)
      .get()
      .then(snapshot => {
        const essays = [];
        snapshot.forEach(doc => {
          let essay = Object.assign(doc.data(), {id: doc.id});
          essays.push(essay)
        });
        return this.onNext({
          essays,
          lastVisible: snapshot.docs[snapshot.docs.length - 1],
        }, 'adminGetEssayLimitMore');
      })
      .catch(e => {
          return this.onError(e, 'adminGetEssayLimitMore')
        }
      )
  };

  adminGetAllScraps = () => {
    return firebaseDB
      .scraps
      .get()
      .then(snapshot => {
        const scraps = [];
        snapshot.forEach(doc => {
          let scrap = Object.assign(doc.data(), {id: doc.id});
          scraps.push(scrap)
        });
        return this.onNext(scraps, 'adminGetAllScraps');
      })
      .catch(e => {
          return this.onError(e, 'adminGetAllScraps')
        }
      )
  };
  adminGetScrapLimit = (limit = SCRAPS_PER_PAGE) => {
    return firebaseDB
      .scraps
      .orderBy('createdAt', 'desc')
      .limit(limit)
      .get()
      .then(snapshot => {
        const scraps = [];
        snapshot.forEach(doc => {
          let scrap = Object.assign(doc.data(), {id: doc.id});
          scraps.push(scrap)
        });
        return this.onNext({
          scraps: scraps,
          lastVisible: snapshot.docs[snapshot.docs.length - 1],
        }, 'adminGetScrapLimit');
      })
      .catch(e => {
          return this.onError(e, 'adminGetScrapLimit')
        }
      )
  };
  adminGetScrapLimitMore = (lastVisible, limit = SCRAPS_PER_PAGE) => {
    return firebaseDB
      .scraps
      .orderBy('createdAt', 'desc')
      .startAfter(lastVisible)
      .limit(limit)
      .get()
      .then(snapshot => {
        const scraps = [];
        snapshot.forEach(doc => {
          let scrap = Object.assign(doc.data(), {id: doc.id});
          scraps.push(scrap)
        });
        return this.onNext({
          scraps,
          lastVisible: snapshot.docs[snapshot.docs.length - 1],
        }, 'adminGetScrapLimitMore');
      })
      .catch(e => {
          return this.onError(e, 'adminGetScrapLimitMore')
        }
      )
  };
  updateScrap = scrap => {
    return firebaseDB
      .scraps
      .doc(scrap.id)
      .update(scrap)
      .then(() => {
        return this.onNext({
          id: scrap.id,
          success: true,
        }, 'updateScrap')
      })
      .catch(e => {
        return this.onError(e, 'updateScrap')
      })
  };

  adminGetAllAdminBooks = () => {
    return firebaseDB
      .adminBooks
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshot => {
        const adminBooks = [];
        snapshot.forEach(doc => {
          let adminBook = Object.assign(doc.data(), {id: doc.id});
          adminBooks.push(adminBook)
        });
        return this.onNext(adminBooks, 'adminGetAllAdminBooks');
      })
      .catch(e => {
          return this.onError(e, 'adminGetAllAdminBooks')
        }
      )
  };
  getAdminBookById = (adminBookId) => {
    return firebaseDB
      .adminBooks
      .doc(adminBookId)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('데이터가 존재하지 않습니다.');
          return {
            err: '데이터가 존재하지 않습니다.'
          }
        }
        return this.onNext(Object.assign(doc.data(), {id: doc.id}), 'getAdminBookById');
      })
      .catch(e => {
          return this.onError(e, 'getAdminBookById')
        }
      )
  };
  updateAdminBook = (adminBook) => {
    return firebaseDB
      .adminBooks
      .doc(adminBook.id)
      .update(adminBook)
      .then(() => {
        return this.onNext({
          id: adminBook.id,
          success: true,
        }, 'updateAdminBook')
      })
      .catch(e => {
        return this.onError(e, 'updateAdminBook')
      })
  };


  adminGetAllPlaces = () => {
    return firebaseDB
      .places
      .get()
      .then(snapshot => {
        const places = [];
        snapshot.forEach(doc => {
          let place = Object.assign(doc.data(), {id: doc.id});
          places.push(place)
        });
        return this.onNext(places, 'adminGetAllPlaces');
      })
      .catch(e => {
          return this.onError(e, 'adminGetAllPlaces')
        }
      )
  };
  postPlace = (place) => {
    return firebaseDB
      .places
      .add(place)
      .then(doc => {
        return this.onNext({
          id: doc.id,
          success: true,
        }, 'postPlace')
      })
      .catch(e => {
        return this.onError(e, 'postPlace')
      })
  };
  updatePlace = (place) => {
    return firebaseDB
      .places
      .doc(place.id)
      .update(place)
      .then(() => {
        return this.onNext({
          id: place.id,
          success: true,
        }, 'updatePlace')
      })
      .catch(e => {
        return this.onError(e, 'updatePlace')
      })
  }



}



export default new Network()


