import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {authActionCreators} from "../../redux/auth/authStore";
import {
  Button,
  Grid,
  Segment,
  Loader,
  Container,
  Table,
  Divider,
  Message,
  Header,
  Icon,
  Form,
  Radio,
  Image,
  List
} from "semantic-ui-react";
import {firebase} from "../../constants/firebase";
import FileUploader from "react-firebase-file-uploader";
import _ from 'lodash';
import {couponTypeDropDownList, NEW_COUPON_CODE} from "../../dataStructure/coupon";
import Network from "../../library/Network";
import moment from "moment";
import mockUpImages from "../../constants/mockUpImages";

const storageRef = `couponCode/${moment().format("YYYYMM")}`;


class AdminCouponCodeDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponCode: {},
      isLoaded: false,

    };
  }


  componentDidMount() {
    this._loadCouponCode();
  }

  _loadCouponCode = async () => {
    const isNewCouponCode = this.props.match.params.couponCodeId === undefined;

    if (isNewCouponCode) {
      this.setState({
        isNewCouponCode,
        couponCode: _.cloneDeep(NEW_COUPON_CODE),
        isLoaded: true,
      })
    } else {
      const {couponCodeId} = this.props.match.params;
      const couponCode = await Network.getCouponCodeByCouponCodeId(couponCodeId);
      if (couponCode.err) return window.alert('쿠폰 정보를 찾을 수 없습니다.');

      couponCode.createdAt = new Date(moment(couponCode.createdAt ? couponCode.createdAt.seconds * 1000 : null).toISOString());
      couponCode.modifiedAt = new Date(moment(couponCode.modifiedAt ? couponCode.modifiedAt.seconds * 1000 : null).toISOString());
      couponCode.expireDate = new Date(moment(couponCode.expireDate ? couponCode.expireDate.seconds * 1000 : null).toISOString());

      this.setState({
        isNewCouponCode,
        couponCode: Object.assign(_.cloneDeep(NEW_COUPON_CODE), couponCode),
        isLoaded: true,
      })
    }
  };


  handleChange = e => {
    const {couponCode} = this.state;
    const {name, value} = e.target;
    couponCode[name] = value;
    this.setState({
      couponCode
    });
  };

  handleSubmit = async () => {
    const confirm = window.confirm("업로드 하시겠습니까?");
    if (!confirm) return;

    const {couponCode} = this.state;
    couponCode.expireDate = moment(couponCode.expireDate).endOf('day').toDate();

    const isNewCouponCode = this.props.match.params.couponCodeId === undefined;

    let res;
    if (isNewCouponCode) {
      res = await Network.postCouponCode(couponCode);
      if (res.err) return window.alert('쿠폰 생성에 실패하였습니다.');
      window.alert('완료');
      this.props.history.replace(`/admin/couponCode/read/${res.id}`)

    } else {
      res = await Network.updateCouponCode(couponCode);
      if (res.err) return window.alert('쿠폰 수정에 오류가 발생하였습니다.');
      window.alert('완료');
    }



  };

  handleUploadSuccess = async (filename, imageCategory) => {
    const {couponCode} = this.state;

    await firebase.storage()
      .ref(storageRef)
      .child(filename)
      .getDownloadURL()
      .then(imageUrl => {
        couponCode.image[imageCategory] = imageUrl;
        this.setState({couponCode})
      });
  };

  deleteCouponCode = async () => {
    const confirm = window.confirm("삭제하시겠습니까?");
    if (!confirm) return;

    const res = await Network.updateCouponCode(Object.assign(this.state.couponCode, {isDeleted: true}));

    if (res.err) return window.alert('삭제에 실패하였습니다.');

    window.alert('삭제되었습니다.');
    this.props.history.replace('/admin/couponCode');
  };

  restore = async () => {
    const confirm = window.confirm("복구 하시겠습니까?");
    if (!confirm) return;

    const res = await Network.updateCouponCode(Object.assign(this.state.couponCode, {isDeleted: false}));

    if (res.err) return window.alert('복구에 실패하였습니다.');

    window.alert('복구되었습니다.');
    this._loadCouponCode();

  };

  render() {
    const {couponCode, isLoaded,} = this.state;
    if (!isLoaded) {
      return (
        <Loader active={true}/>
      )
    }
    return (
      <Fragment>
        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={`${couponCode.name}쿠폰 정보`} dividing/>
          <Button content={this.state.isNewCouponCode ? '만들기' : '수정하기'} primary onClick={this.handleSubmit}/>
          <Button content={'삭제'} negative disabled={this.state.isNewCouponCode || couponCode.isDeleted}
                  onClick={this.deleteCouponCode}/>
          <Button content={'복구'} positive disabled={!couponCode.isDeleted} onClick={this.restore}/>
        </Container>

        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'기본정보'} dividing/>
          <Form>
            <Form.Group widths={'equal'}>
              <Form.Input
                disabled={true}
                fluid
                label="쿠폰 ID"
                name="id"
                value={couponCode.id}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group widths={'equal'}>
              <Form.Input
                label="쿠폰명"
                name="name"
                value={couponCode.name}
                onChange={this.handleChange}
              />
              <Form.Dropdown
                placeholder={'COUPON CODE'}
                selection
                label="쿠폰코드"
                name="category"
                value={couponCode.type}
                options={couponTypeDropDownList()}
                onChange={(e, {value}) => {
                  couponCode.type = value;
                  this.setState({
                    couponCode
                  })
                }}
              />
            </Form.Group>
            <Form.Field>
              <Radio
                toggle
                label={couponCode.repeatPossible ? "중복 사용 가능" : "중복 사용 불가능"}
                checked={couponCode.repeatPossible}
                onClick={() => {
                  couponCode.repeatPossible = !couponCode.repeatPossible;
                  this.setState({
                    couponCode
                  });
                }}
                style={{margin: "10px 0"}}/>
            </Form.Field>
            <Form.Input
              // disabled={true}
              name={'expireDate'}
              label={'expireDate'}
              type={'datetime-local'}
              value={moment(couponCode.expireDate).format('YYYY-MM-DDThh:mm')}
              onChange={(e, {value, name}) => {
                couponCode.expireDate = moment(new Date(value)).endOf('day');
                console.log(value);
                this.setState({
                  couponCode
                })
              }}
            />
          </Form>
        </Container>


        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'태그'} dividing/>
          <Button
            onClick={() => {
              couponCode.tags.push("");
              this.setState({
                couponCode
              });
            }}>
            태그 추가
          </Button>
          <Form>
            {
              couponCode.tags.map((tag, i) => {
                return (
                  <Form.Input
                    key={i}
                    name={`tags${i}`}
                    value={tag}
                    onChange={e => {
                      couponCode.tags[i] = e.target.value;
                      this.setState({
                        couponCode
                      });
                    }}
                  >
                    <input/>
                    <Button
                      icon
                      onClick={() => {
                        const a = window.confirm('삭제하시겠습니까?');
                        if (!a) return;
                        couponCode.tags.splice(i, 1);
                        this.setState({
                          couponCode
                        })
                      }}>
                      <Icon name={'close'}/>
                    </Button>
                  </Form.Input>
                );
              })}
          </Form>
        </Container>
        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'배경 및 사진'} dividing/>
          <Form>
            <Form.Group>
              <Form.Input
                label={'배경 색'}
                name={'backgroundColor'}
                value={couponCode.backgroundColor}
                onChange={this.handleChange}
              />
              <Form.Input
                label={'텍스트 색'}
                name={'textColor'}
                value={couponCode.textColor}
                onChange={this.handleChange}
              />
              <Form.Input
                label={'이름 색'}
                name={'nameColor'}
                value={couponCode.nameColor}
                onChange={this.handleChange}
              />
              <Form.Input
                label={'설명 색'}
                name={'descriptionColor'}
                value={couponCode.descriptionColor}
                onChange={this.handleChange}
              />
            </Form.Group>
          </Form>

          <Divider/>

          <Grid>
            <Grid.Row columns={6}>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  MAIN
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          couponCode.imageUrl = imageUrl;
                          this.setState({
                            couponCode
                          })
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(couponCode.imageUrl)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    couponCode.imageUrl = '';
                    this.setState({
                      couponCode
                    })
                  }}/>
                <Image src={couponCode.imageUrl || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  배경
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          couponCode.backgroundImage = imageUrl;
                          this.setState({
                            couponCode
                          })
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(couponCode.backgroundImage)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    couponCode.backgroundImage = '';
                    this.setState({
                      couponCode
                    })
                  }}/>
                <Image src={couponCode.backgroundImage || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  배경 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          couponCode.backgroundImageLarge = imageUrl;
                          this.setState({
                            couponCode
                          })
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(couponCode.backgroundImageLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    couponCode.backgroundImageLarge = '';
                    this.setState({
                      couponCode
                    })
                  }}/>
                <Image src={couponCode.backgroundImageLarge || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={6}>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  정사각
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="square"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'square');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(couponCode.image && couponCode.image.square)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    couponCode.image.square = '';
                    this.setState({
                      couponCode
                    })
                  }}/>
                <Image src={(couponCode.image && couponCode.image.square) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  가로
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="horizontal"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'horizontal');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(couponCode.image && couponCode.image.horizontal)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    couponCode.image.horizontal = ''
                    this.setState({
                      couponCode
                    })
                  }}/>
                <Image src={(couponCode.image && couponCode.image.horizontal) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  세로
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="vertical"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'vertical');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(couponCode.image && couponCode.image.vertical)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    couponCode.image.vertical = ''
                    this.setState({
                      couponCode
                    })
                  }}/>
                <Image src={(couponCode.image && couponCode.image.vertical) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  정사각 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="imageSquareLarge"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'squareLarge');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(couponCode.image && couponCode.image.squareLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    couponCode.image.squareLarge = ''
                    this.setState({
                      couponCode
                    })
                  }}/>
                <Image src={(couponCode.image && couponCode.image.squareLarge) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  가로 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="imageHorizontalLarge"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'horizontalLarge');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(couponCode.image && couponCode.image.horizontalLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    couponCode.image.horizontalLarge = '';
                    this.setState({
                      couponCode
                    })
                  }}/>
                <Image src={(couponCode.image && couponCode.image.horizontalLarge) || mockUpImages.planeImage}
                       size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  세로 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="imageVerticalLarge"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'verticalLarge');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(couponCode.image && couponCode.image.verticalLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    couponCode.image.verticalLarge = ''
                    this.setState({
                      couponCode
                    })
                  }}/>
                <Image src={(couponCode.image && couponCode.image.verticalLarge) || mockUpImages.planeImage}
                       size={'medium'}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

const styles = {
  verticalModule: {
    marginBottom: 50,
    marginTop: 50
  },
  header: {
    marginTop: 40,
  }
};

const enhance = connect(
  state => ({
    auth: state.auth
  }),
  {
    ...authActionCreators
  }
);
export default enhance(AdminCouponCodeDetailContainer);
