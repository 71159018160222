import {firebase} from "../constants/firebase";

export const TRANSACTION_TYPE_REGISTER = 'TRANSACTION_TYPE_REGISTER';  // 등록
export const TRANSACTION_TYPE_REGISTER_CANCEL = 'TRANSACTION_TYPE_REGISTER_CANCEL'; //등록 취소
export const TRANSACTION_TYPE_REGISTER_FAIL = 'TRANSACTION_TYPE_REGISTER_FAIL'; // 등록 실패
export const TRANSACTION_TYPE_TRANSFER = 'TRANSACTION_TYPE_TRANSFER'; // 충전
export const TRANSACTION_TYPE_WITHDRAW = 'TRANSACTION_TYPE_WITHDRAW'; // 출금
export const TRANSACTION_TYPE_RETURN_DEPOSIT = 'TRANSACTION_TYPE_RETURN_DEPOSIT'; //환급


export const transactionTypeInKorean = (transactionType) => {
  switch (transactionType) {
    case TRANSACTION_TYPE_REGISTER:
      return "북클럽 신청";
    case TRANSACTION_TYPE_REGISTER_CANCEL:
      return "북클럽 신청 취소";
    case TRANSACTION_TYPE_REGISTER_FAIL:
      return "북클럽 신청 실패";
    case TRANSACTION_TYPE_TRANSFER:
      return "충전";
    case TRANSACTION_TYPE_WITHDRAW:
      return "출금";
    case TRANSACTION_TYPE_RETURN_DEPOSIT:
      return "환급";
    default :
      return transactionType

  }
};

export const NEW_TRANSACTION = {
  cash: 0,
  discountAmount: 0,
  deposit: 0,
  amountPaymentNeeded: 0,
  transactionType: '',

  bookClubId: '',
  bookClubEssayScheduleId: '',
  bookClubApplyId: '',
  couponId: '',

  userId: '',

  withDrawCompleted: false,
  bankAccount : '',
  bankName: '',

  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),

  paymentCompletedAt: firebase.firestore.FieldValue.serverTimestamp(),
};