const DEV = true;
// const DEV = false;

const API = DEV ? 'http://localhost:5000/readers-api/us-central1/api' : 'https://us-central1-readers-api.cloudfunctions.net/api';
const API_READERS_V1 = DEV ? 'http://localhost:5000/readers-api/us-central1/api/readers/v1' : 'https://us-central1-readers-api.cloudfunctions.net/api/readers/v1';

const API_NEW = 'https://us-central1-readers-prod.cloudfunctions.net/api';

export {API, API_READERS_V1, API_NEW};


