import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {db} from "../../constants/firebase";
import {Container, Segment, Table, Button, Form, Loader, Dropdown, Radio, Label} from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import {adminActionCreators} from "../../redux/admin/adminStore";

class AdminEssayMainScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      essays: [],
      isLoaded: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._loadEssays();
  }

  _loadEssays = async () => {
    if (!this.props.admin.essayIsLoaded) {
      this.props.fetchEssaysAdmin(() => {
        this.setState({isLoaded: true});
      });
    } else {
      this.setState({isLoaded: true});
    }
  };

  _loadMore = async () => {
    this.props.fetchEssaysMoreAdmin();
  };

  _refresh = async () => {
    this.setState({isLoaded: false});
    this.props.fetchEssaysAdmin(() => {
      this.setState({isLoaded: true,});
    })
  };

  render() {
    const {essays} = this.props.admin;
    if (!this.state.isLoaded) return <Loader active/>;
    return (
      <Container as={Segment} vertical>
        AdminEssayMainScreen
        <Button
          onClick={this._refresh}
          content={'새로고침'}
        />
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>
                no.
              </Table.HeaderCell>
              <Table.HeaderCell width={7}>
                title
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                status
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                userDisplayName
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                status
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              essays.map((essay, i) => {
                const {users} = this.props.admin;
                const user = users.find(u => u.id === essay.userId);
                console.log(user);
                return (
                  <Table.Row key={i}>
                    <Table.Cell>
                      {i + 1}
                    </Table.Cell>
                    <Table.Cell>
                      {essay.title}
                    </Table.Cell>
                    <Table.Cell>
                      {essay.status} {essay.isDeleted && <Label size={'mini'} content={'삭제됨'}/>}
                    </Table.Cell>
                    <Table.Cell>
                      {user && user.id ? user.displayName : ''}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(essay.createdAt.seconds * 1000).format('YYYY.MM.DD(ddd) HH:mm:ss')}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        size={'mini'}
                        content={'보기'}
                        onClick={() => {
                          this.props.history.push(`/admin/essay/read/${essay.id}`)
                        }}
                      />
                    </Table.Cell>

                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
        <Button
          content={'Load More'}
          onClick={this._loadMore}
          disabled={!this.props.admin.essayIsLoaded}
        />
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
    ...adminActionCreators,
  }
);


export default enhance(AdminEssayMainScreen);