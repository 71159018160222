const planeImage = 'https://firebasestorage.googleapis.com/v0/b/readers-api.appspot.com/o/profileImage%2F201907%2Fimage.png?alt=media';
const essayPlaneImage = 'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Fessay_image.png?alt=media';
const profileImageDaniel = 'https://firebasestorage.googleapis.com/v0/b/readers-dev.appspot.com/o/mockup%2Fdaniel.jpg?alt=media';
const profileImageElliot = 'https://firebasestorage.googleapis.com/v0/b/readers-dev.appspot.com/o/mockup%2Felliot.jpg?alt=media';
const profileImageMolly = 'https://firebasestorage.googleapis.com/v0/b/readers-dev.appspot.com/o/mockup%2Fmolly.jpg?alt=media';
export default {
  planeImage,
  essayPlaneImage,
  profileImageDaniel,
  profileImageMolly,
  profileImageElliot,
}