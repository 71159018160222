import axios from 'axios';
import {API_NEW} from "../constants/API";
import {db, firebase, auth} from "../constants/firebase";
import uuid from 'uuid';
import {getNewNotification} from "../dataStructure/notification";

export const sendPushNotificationMany = (tokens, pushNotificationData) => {
  if (!tokens || (tokens && tokens.length < 1)) {
    window.alert('오류가 발생했습니다');
    return {
      err: '토큰이 없습니다.',
    }
  }
  return axios
    .post(`${API_NEW}/notification/push-many`, {
      tokens,
      pushNotificationData,
    })
    .then(res => {
      return res;
    })
    .catch(e => {
      console.log(e);
      return {
        ...e,
        err: '에러가 발생했습니다.'
      }
    })
};


export const addNotifications = (userIds, notificationData) => {
  const batch = db.batch();

  userIds.forEach((userId, i) => {
    const userNotificationRef = db
      .collection('notifications')
      .doc(userId)
      .collection('notifications')
      .doc(uuid.v4());
    batch.set(userNotificationRef, {
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      ...notificationData
    });
  });

  return batch.commit()
    .then(res => {
      console.log({res});
      return {
        success: true,
      }
    })
    .catch(e => {
      console.log(e);
      return {
        err: true,
      }
    })
};