import {firebase} from "../constants/firebase";

export const NOTIFICATION_TYPE_BOOK_CLUB = 'NOTIFICATION_TYPE_BOOK_CLUB';
export const NOTIFICATION_TYPE_BOOK_CLUB_APPLY_STATUS = 'NOTIFICATION_TYPE_BOOK_CLUB_APPLY_STATUS';
export const NOTIFICATION_TYPE_BOOK_CLUB_MEMBER = 'NOTIFICATION_TYPE_BOOK_CLUB_MEMBER';

export const NOTIFICATION_TYPE_ESSAY = 'NOTIFICATION_TYPE_ESSAY';
export const NOTIFICATION_TYPE_ESSAY_COMMENT = 'NOTIFICATION_TYPE_ESSAY_COMMENT';
export const NOTIFICATION_TYPE_ESSAY_LIKE = 'NOTIFICATION_TYPE_ESSAY_LIKE';

export const NOTIFICATION_TYPE_SCRAP = 'NOTIFICATION_TYPE_SCRAP';
export const NOTIFICATION_TYPE_SCRAP_COMMENT = 'NOTIFICATION_TYPE_SCRAP_COMMENT';
export const NOTIFICATION_TYPE_SCRAP_LIKE = 'NOTIFICATION_TYPE_SCRAP_LIKE';

export const NOTIFICATION_TYPE_CASH = 'NOTIFICATION_TYPE_CASH';

export const NOTIFICATION_TYPE_USER = 'NOTIFICATION_TYPE_USER';
export const NOTIFICATION_TYPE_FOLLOW = 'NOTIFICATION_TYPE_FOLLOW';
export const NOTIFICATION_TYPE_INQUIRY = 'NOTIFICATION_TYPE_INQUIRY';



export const getNewNotification = () => {
  return {
    // createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    // modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),

    read: false,
    click: false,

    image: '',
    type: '',
    content: '',

    bookClubId: '',
    essayId: '',
    inquiryId: '',
    scrapId: '',
    userId: '',
    transactionId: '',
    storyId: '',
  }
};