import React from 'react';

const removeHtmlTag = (text) => {
  if (!text) return "";

  return text.replace(/\n/gi, '')
    .replace(/\t/g, '')
    .replace(/&nbsp;/gi, '')
    .replace(/&amp;/gi, '&')
    .replace(/&quot;/gi, '')
    .replace(/&#x0D;/gi, '')
    .replace(/&lsquo;/gi, '')
    .replace(/&rsquo;/gi, '')
    .replace(/&lt;/gi, '')
    .replace(/&gt;/gi, '')
    .replace(/&#10;/gi, '')
    .replace(/<(?:.|\n)*?>/gm, '')
};

const removeOnlyHtmlTag = (text) => {
  if (!text) return "";

  return text
    .replace(/&nbsp;/gi, '')
    .replace(/&amp;/gi, '&')
    .replace(/&quot;/gi, '')
    .replace(/&#x0D;/gi, '')
    .replace(/&lsquo;/gi, '')
    .replace(/&rsquo;/gi, '')
    .replace(/&lt;/gi, '')
    .replace(/&gt;/gi, '')
    .replace(/&#10;/gi, '')
    .replace(/<(?:.|\n)*?>/gm, '')
};



export const removeHtmlTagWithLineChange = (text) => {
  if (!text) return "";

  return text
    .replace(/<p>/gi, '\n')
    .replace(/<br>/gm, '\n')
    .replace(/\t/g, ' ')
    .replace(/&nbsp;/gi, '')
    .replace(/&amp;/gi, '&')
    .replace(/&#39;/gi, '`')
    .replace(/&quot;/gi, '')
    .replace(/&lsquo;/gi, '"')
    .replace(/&rsquo;/gi, '"')
    .replace(/&#x0D;/gi, '\n')
    .replace(/&lt;/gi, '<')
    .replace(/&gt;/gi, '>')
    .replace(/&#10;/gi, '')
    .replace(/<(?:.|\n)*?>/gm, '')

};


export const withLineEnter = string => {
  if (!string) {
    return '';
  } else {
    return string
      .split('\n')
      .map((line, i) => {
        return <span key={i}>{line}<br/></span>
      });
  }
};



const removeHtmlTagSubString = (text, number) => {
  if (!text) return "";

  return removeHtmlTag(text).substr(0, number);
};

export {
  removeHtmlTag,
  removeOnlyHtmlTag,
  removeHtmlTagSubString
}