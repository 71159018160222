import React, {Component} from 'react';
import {connect} from 'react-redux';

class InquiryMainContainer extends Component {
  render() {
    return (
      <div>
        InquiryMainContainer
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(InquiryMainContainer);