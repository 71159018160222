import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {getNewNotification} from "../../dataStructure/notification";
import {getNewPushNotification, getPushNotificationDropDownList} from "../../dataStructure/pushNotification";
import {Container, Header, Segment, Image, Button, Form, Grid} from 'semantic-ui-react';
import axios from 'axios';
import {sendPushNotificationMany} from "../../library/API_EndPoint";
import uuid from 'uuid';
import {db, firebase} from "../../constants/firebase";
import FileUploader from "react-firebase-file-uploader";
import moment from "moment/moment";
import mockUpImages from "../../constants/mockUpImages";


const storageRef = `pushNotification/${moment().format("YYYYMM")}`;

class AdminPushMainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: {
        ...getNewNotification(),
        image: mockUpImages.planeImage,
      },
      pushNotification: {
        ...getNewPushNotification(),
      },

      pushSoundOn: true,
    }
  }


  componentDidMount() {

  }

  addNotification = () => {
    const a = window.confirm('알람을 추가할까요?');
    if (!a) return;
    const {notification} = this.state;
    const {users} = this.props.admin;
    const newNotificationId = uuid.v4();
    const notificationData = {
      ...notification,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    console.log(notificationData);

    users.forEach((user, i) => {
      db.collection('notifications')
        .doc(user.id)
        .collection('notifications')
        .doc(newNotificationId)
        .set(notificationData)
        .then(() => {
          console.log(user.displayName, i, 'success')
        })
        .catch(e => {
          console.log(user.displayName, i, 'failure', e);
        })
    })

  };

  sendPushNotification = async () => {
    const a = window.confirm('푸시를 보낼까요? ');
    if (!a) return;


    const {pushNotification} = this.state;
    console.log(pushNotification);

    const {users} = this.props.admin;
    const tokens = [];
    users.forEach(user => {
      if (user.token && user.token.length > 0) {
        tokens.push(...user.token);
      }
    });
    console.log({'tokenLength': tokens.length});

    let tokenDivided = [];
    for (let i = 1; i <= tokens.length; i++) {

      if ((i % 100 === 0) || (i === tokens.length)) {
        tokenDivided.push(tokens[i - 1]);
        console.log({tokenDivided, length: tokenDivided.length});

        const a = await sendPushNotificationMany(tokenDivided, pushNotification);

        if (a.err) {
          window.alert('에러 발생');
        } else {
          window.alert(`${i}/${tokens.length} 까지 발송`);
          console.log('SUCCESS', a, i);
        }

        tokenDivided = [];

      } else if (i % 100 !== 0) {
        tokenDivided.push(tokens[i - 1]);
      }
    }
    window.alert('보내기 성공');
  };

  render() {
    const {
      pushNotification,
      notification,
    } = this.state;
    return (
      <Fragment>
        <Container>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Header content={'전체 알림 추가'}/>
                <p>
                  {this.props.admin.users.length}명의 유저
                </p>
                <Button content={'알림 추가'} onClick={this.addNotification}/>
                <Form>
                  <Form.Dropdown
                    selection
                    search
                    value={notification.type}
                    placeholder={'NOTIFICATION TYPE'}
                    options={getPushNotificationDropDownList()}
                    onChange={(e, {value}) => {
                      notification.type = value;
                      this.setState({notification})
                    }}
                  />
                  <Image src={notification.image} size={'tiny'}/>
                  <Button as={'label'} size={'mini'}>
                    이미지 바꾸기
                    <FileUploader
                      hidden
                      accept="image/*"
                      name="square"
                      randomizeFilename
                      storageRef={firebase.storage().ref(storageRef)}
                      onUploadSuccess={filename => {
                        firebase.storage()
                          .ref(storageRef)
                          .child(filename)
                          .getDownloadURL()
                          .then(imageUrl => {
                            notification.image = imageUrl;
                            this.setState({notification: notification})
                          });
                      }}
                    />
                  </Button>
                  <Button
                    size={'mini'}
                    content={'이미지 초기화'}
                    disabled={notification.image === mockUpImages.planeImage}
                    onClick={() => {
                      notification.image = mockUpImages.planeImage;
                      this.setState({
                        notification,
                      })
                    }}
                  />
                  <Form.TextArea
                    placeholder={'알람 내용'}
                    value={notification.content}
                    onChange={(e, {value}) => {
                      notification.content = value;
                      this.setState({notification,})
                    }}
                  />
                  <Form.Input
                    value={notification.bookClubId}
                    label={'bookClubId'}
                    onChange={(e, {value}) => {
                      notification.bookClubId = value;
                      this.setState({notification})
                    }}
                  />
                  <Form.Input
                    value={notification.essayId}
                    label={'essayId'}
                    onChange={(e, {value}) => {
                      notification.essayId = value;
                      this.setState({notification})
                    }}
                  />
                  <Form.Input
                    value={notification.inquiryId}
                    label={'inquiryId'}
                    onChange={(e, {value}) => {
                      notification.inquiryId = value;
                      this.setState({notification})
                    }}
                  />
                  <Form.Input
                    value={notification.scrapId}
                    label={'scrapId'}
                    onChange={(e, {value}) => {
                      notification.scrapId = value;
                      this.setState({notification})
                    }}
                  />
                  <Form.Input
                    value={notification.userId}
                    label={'userId'}
                    onChange={(e, {value}) => {
                      notification.userId = value;
                      this.setState({notification})
                    }}
                  />
                  <Form.Input
                    value={notification.transactionId}
                    label={'transactionId'}
                    onChange={(e, {value}) => {
                      notification.transactionId = value;
                      this.setState({notification})
                    }}
                  />
                  <Form.Input
                    value={notification.storyId}
                    label={'storyId'}
                    onChange={(e, {value}) => {
                      notification.storyId = value;
                      this.setState({notification})
                    }}
                  />
                </Form>
              </Grid.Column>
              <Grid.Column>
                <Header content={'전체 푸시 보내기'}/>
                <Button content={'푸시 보내기'} onClick={this.sendPushNotification}/>
                <Form>
                  <Form.Radio
                    toggle
                    label={'소리를 키나요?'}
                    checked={this.state.pushSoundOn}
                    onClick={() => {
                      this.setState({
                        pushSoundOn: !this.state.pushSoundOn
                      })
                    }}
                  />
                  <Form.Input
                    value={pushNotification.notification.title}
                    label={'푸시 타이틀'}
                    onChange={(e, {value}) => {
                      pushNotification.notification.title = value;
                      this.setState({pushNotification})
                    }}
                  />
                  <Form.TextArea
                    value={pushNotification.notification.body}
                    label={'푸시 본문'}
                    onChange={(e, {value}) => {
                      pushNotification.notification.body = value;
                      this.setState({pushNotification})
                    }}
                  />

                  <Form.Dropdown
                    selection
                    search
                    value={pushNotification.data && pushNotification.data.type}
                    placeholder={'PUSH NOTIFICATION TYPE'}
                    options={getPushNotificationDropDownList()}
                    onChange={(e, {value}) => {
                      pushNotification.data.type = value;
                      this.setState({pushNotification})
                    }}
                  />
                  <Form.Input
                    value={pushNotification.data.bookClubId}
                    label={'bookClubId'}
                    onChange={(e, {value}) => {
                      pushNotification.data.bookClubId = value;
                      this.setState({pushNotification})
                    }}
                  />
                  <Form.Input
                    value={pushNotification.data.essayId}
                    label={'essayId'}
                    onChange={(e, {value}) => {
                      pushNotification.data.essayId = value;
                      this.setState({pushNotification})
                    }}
                  />
                  <Form.Input
                    value={pushNotification.data.inquiryId}
                    label={'inquiryId'}
                    onChange={(e, {value}) => {
                      pushNotification.data.inquiryId = value;
                      this.setState({pushNotification})
                    }}
                  />
                  <Form.Input
                    value={pushNotification.data.scrapId}
                    label={'scrapId'}
                    onChange={(e, {value}) => {
                      pushNotification.data.scrapId = value;
                      this.setState({pushNotification})
                    }}
                  />
                  <Form.Input
                    value={pushNotification.data.userId}
                    label={'userId'}
                    onChange={(e, {value}) => {
                      pushNotification.data.userId = value;
                      this.setState({pushNotification})
                    }}
                  />
                  <Form.Input
                    value={pushNotification.data.transactionId}
                    label={'transactionId'}
                    onChange={(e, {value}) => {
                      pushNotification.data.transactionId = value;
                      this.setState({pushNotification})
                    }}
                  />
                  <Form.Input
                    value={pushNotification.data.storyId}
                    label={'storyId'}
                    onChange={(e, {value}) => {
                      pushNotification.data.storyId = value;
                      this.setState({pushNotification})
                    }}
                  />
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(AdminPushMainContainer);