import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from '../../redux/auth/authStore';
import {db} from '../../constants/firebase';
import {Link} from 'react-router-dom';
import {Table, Segment, Button, Container, Header, Image} from 'semantic-ui-react';
import moment from 'moment'

class AdminLeaderMainContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      leaders: []
    }
  }

  componentDidMount() {
    this._loadLeaderData()
  }


  _loadLeaderData = async () => {
    return;
    await db.collection('leaders')
      .orderBy('order', 'asc')
      .get()
      .then(snapshot => {
        const leaders = []
        snapshot.forEach(doc => {
          const leader = Object.assign(doc.data(), {id: doc.id})
          leaders.push(leader)
        })
        this.setState({
          leaders
        })
      })
  }

  render() {
    const {leaders} = this.props.admin;
    // <Container as={Segment} vertical>
    // <Header>북클럽 어드민페이지</Header>
    // <Table compact size={'small'}></Table>
    return (
      <Container as={Segment} vertical>
        <Header>리더 어드민페이지</Header>
        <Button as={Link} to={'/admin/leader/write'}>
          리더 만들기
        </Button>

        <Table compact size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No</Table.HeaderCell>
              <Table.HeaderCell>이미지</Table.HeaderCell>
              <Table.HeaderCell>이름</Table.HeaderCell>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>가입일</Table.HeaderCell>
              <Table.HeaderCell>오더</Table.HeaderCell>
              <Table.HeaderCell>버튼</Table.HeaderCell>
              <Table.HeaderCell>삭제 여부</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              leaders.map((leader, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>{i}</Table.Cell>
                    <Table.Cell><Image size="mini" src={leader.imageUrl}/></Table.Cell>
                    <Table.Cell>{leader.name}</Table.Cell>
                    <Table.Cell>{leader.id}</Table.Cell>
                    <Table.Cell>{moment(leader.createdAt.seconds * 1000).format('YYYY.MM.DD')}</Table.Cell>
                    <Table.Cell>{leader.order}</Table.Cell>
                    <Table.Cell>
                      <Button as={Link}
                              to={{pathname: `/admin/leader/read/${leader.id}`, state: {leader: leader}}}>상세보기</Button>
                    </Table.Cell>
                    <Table.Cell>
                      {leader.isDeleted ? '삭제됨' : ''}
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
  }
);
export default enhance(AdminLeaderMainContainer);