export const NEW_USER = {
  isPublic: true,

  followerIds: [],
  followingIds: [],
  pendingFollowerIds: [],
  pendingFollowingIds: [],

  blockingIds: [], // 이 유저가 차단한 사람
  blockedByIds: [], // 이 유저를 차단한 사람

  description: '',
  isWrittenDetailProfile: false,

  detailProfile: {
    name: '',
    birthday: '',
    phoneNumber: '',
    description: '',
    career: '',
    education: '',
  },

  notificationPermissions: {
    bookClub: true,
    comment: true,
    like: true,
    follow: true,
    news: true,

    defaultPermission: false,
  },
  token: [],
};