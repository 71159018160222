import React, {Component} from "react";
import {connect} from "react-redux";
import {authActionCreators} from "../../redux/auth/authStore";
import {Form, Grid, Header, Message} from "semantic-ui-react";
import {db, auth, firebase} from "../../constants/firebase";
import {Redirect} from "react-router";
import {NEW_USER} from "../../dataStructure/user";
import _ from 'lodash';
import {Link} from 'react-router-dom'
import Colors from "../../constants/Colors";
import Analytics from "../../middleware/Analytics";

class SignUpContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password1: "",
      password2: "",
      nickName: "",
      isValidEmail: false,
      isValidNickName: false,
      isValidPassword: false,
      errorMessage: []
    };
  }


  componentDidMount() {
    Analytics.pageViewAll('/signup');
    window.scrollTo(0, 0)
  }


  handleChange = (e, {name, value}) => {
    this.setState({
      [name]: value
    });
  };

  handleValidate = async () => {
    await this.validateEmail();
    await this.validatePassword();
    await this.validateNickName();
    this.handleSubmit();
  };

  handleSubmit = async () => {
    if (this.state.isValidEmail & this.state.isValidPassword & this.state.isValidNickName) {
      await auth
        .createUserWithEmailAndPassword(this.state.email.toLocaleLowerCase().trim(), this.state.password1)
        .then(async result => {
          console.log(result);
          const user = auth.currentUser;

          await user.updateProfile({
            displayName: this.state.nickName.toLocaleLowerCase().trim(),
            photoURL: 'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/user%2Fprofile.png?alt=media',
          });

          // await user
          //   .sendEmailVerification()
          //   .then(() => {})
          //   .catch(e => {
          //     console.log(e);
          //   });

          let newUserData = Object.assign(
            _.cloneDeep(NEW_USER),
            user.toJSON(),
            {
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
              displayName: this.state.nickName.toLocaleLowerCase().trim(),
              photoURL: 'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/user%2Fprofile.png?alt=media'
            });


          db.collection("users")
            .doc(user.uid)
            .set(newUserData)
            .then(() => {
              this.props.signInSuccess(newUserData, {id: user.uid});
              // return <Redirect to="/my"/>;
            });
        })
        .catch(error => {
          const {errorMessage} = this.state;
          console.log(error);
          this.setState({
            errorMessage: [...errorMessage, error.message]
          });
        });
      // this.props.signIn(this.state.email, this.state.password1);
    } else {
      const {errorMessage} = this.state;
      const error = "전반적인 로그인정보를 확인해주세요";
      console.log(error);
      this.setState({
        errorMessage: [...errorMessage, error]
      });
      console.log(this.state.errorMessage);
    }
  };

  validateEmail = async () => {
    const inputEmail = this.state.email.toLocaleLowerCase().trim();
    await db
      .collection("users")
      .where("email", "==", inputEmail)
      .get()
      .then(snapshot => {
        const users = [];
        snapshot.forEach(doc => {
          users.push(doc.data());
        });
        if (users.length === 0) {
          this.setState({
            isValidEmail: true,
            emailErrorMessage: ""
          });
        } else {
          const {errorMessage} = this.state;
          const error = "중복된 이메일 주소 입니다.";
          this.setState({
            errorMessage: [...errorMessage, error]
          });
          console.log(this.state.errorMessage);
        }
      });
  };

  validatePassword = () => {
    const {password1, password2} = this.state;
    if (password1 === password2) {
      this.setState({
        isValidPassword: true,
        passwordErrorMessage: ""
      });
    } else {
      const {errorMessage} = this.state;
      const error = "비밀번호가 일치하지 않습니다.";
      this.setState({
        errorMessage: [...errorMessage, error]
      });
      console.log(this.state.errorMessage);
    }
  };

  validateNickName = async () => {
    const nickName = this.state.nickName.toLocaleLowerCase().trim();
    await db
      .collection("users")
      .where("displayName", "==", nickName)
      .get()
      .then(snapshot => {
        const userNickName = [];
        snapshot.forEach(doc => {
          console.log(doc.data());
          userNickName.push(doc.data());
        });
        if (userNickName.length === 0) {
          this.setState({
            isValidNickName: true,
            nickNameErrorMessage: ""
          });
        } else {
          const {errorMessage} = this.state;
          const error = "중복된 닉네임입니다.";
          this.setState({
            errorMessage: [...errorMessage, error]
          });
          console.log(this.state.errorMessage);
        }
      });
  };

  // post
  render() {
    const {isLoggedIn} = this.props;
    const {errorMessage} = this.state;
    if (isLoggedIn) {
      const {from} = this.props.location.state || {
        from: {pathname: "/my"}
      };
      return <Redirect to={from}/>;
    }
    return (
      <div>
        <Grid
          textAlign="center"
          style={{height: "100vh"}}
          verticalAlign="middle"
        >
          <Grid.Column style={{maxWidth: 450}}>
            <Header as="h2" textAlign="center" style={{fontWeight: '900'}}>
              회원가입
            </Header>
            {/*<Form size="large">
              <Form.Input
                fluid
                icon="user"
                name="email"
                iconPosition="left"
                placeholder="이메일"
                value={this.state.email}
                onChange={this.handleChange}
              />

              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="비밀번호"
                type="password"
                name="password1"
                value={this.state.password1}
                onChange={this.handleChange}
              />

              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="비밀번호를 한번 더 입력해주세요"
                type="password"
                name="password2"
                value={this.state.password2}
                onChange={this.handleChange}
              />

              <Form.Input
                fluid
                icon="user outline"
                name="nickName"
                iconPosition="left"
                placeholder="닉네임"
                value={this.state.nickName}
                onChange={this.handleChange}
              />
              {errorMessage.length > 0 && (
                <Message
                  size="mini"
                  negative
                  header="회원정보 에러"
                  list={errorMessage}
                />
              )}

              <Form.Button
                onClick={() => {
                  this.handleValidate();
                  this.setState({
                    errorMessage: []
                  });
                  // console.log(this.state.errorMessage);
                }}
                color="black"
                fluid
                size="large"
                style={{
                  backgroundColor: Colors.tintColor
                }}
              >
                회원가입
              </Form.Button>
            </Form>*/}
            <Message>
              현재 회원 가입은 앱으로만 진행할 수 있습니다. 앱을 통해 가입해 주세요.
              이미 가입이 되어 있으신가요? <Link to={{pathname: '/login'}}>여기</Link>를 눌러 로그인 하세요.
            </Message>
            <br/>
            <a className={'roundButton'} href={'https://readersrn.page.link/fromhomepage'} target={'_blank'}>
              앱 다운로드
            </a>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    auth: state.auth,
    isLoggedIn: state.auth.isLoggedIn
  }),
  {
    ...authActionCreators
  }
);

export default enhance(SignUpContainer);
