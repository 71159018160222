import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {db, firebaseDB} from "../../constants/firebase";
import {Link} from "react-router-dom";
import {Segment, Message, Container, Button, Form, Radio, Card, Grid, Header,} from "semantic-ui-react";
import moment from "moment/moment";

class AdminSettingMainContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      setting: {
        minBuildNoIOS: 0,
        minBuildNoAndroid: 0,
        currentBuildNoIOS: 0,
        currentBuildNoAndroid: 0,
        isUnderConstructionIOS: false,
        isUnderConstructionAndroid: false,

        underConstructionStartDateTimeIOS: new Date(),
        underConstructionEndDateTimeIOS: new Date(),
        underConstructionMessageIOS: '',

        underConstructionStartDateTimeAndroid: new Date(),
        underConstructionEndDateTimeAndroid: new Date(),
        underConstructionMessageAndroid: '',
      },
    }
  }

  componentDidMount() {
    this._loadAdminSettings();
  }

  _loadAdminSettings = async () => {
    await firebaseDB
      .adminSettings
      .doc('GENERAL')
      .get()
      .then(doc => {
        if (!doc.exists) return;
        const setting = Object.assign(doc.data(), {id: doc.id});
        setting.underConstructionStartDateTimeIOS = moment(setting.underConstructionStartDateTimeIOS.seconds*1000).toDate();
        setting.underConstructionEndDateTimeIOS = moment(setting.underConstructionEndDateTimeIOS.seconds*1000).toDate();
        setting.underConstructionStartDateTimeAndroid = moment(setting.underConstructionStartDateTimeAndroid.seconds*1000).toDate();
        setting.underConstructionEndDateTimeAndroid = moment(setting.underConstructionEndDateTimeAndroid.seconds*1000).toDate();
        this.setState({
          setting
        })
      })
      .catch(e => {
          console.log(e)
        }
      )
  };

  _updateAdminSetting = async (updatedSettingValue) => {
    await firebaseDB
      .adminSettings
      .doc('GENERAL')
      .set(updatedSettingValue, {merge: true})
      .then(() => {
        window.alert('반영 완료');
        return {
          result: true,
        }
      })
      .catch(e => {
        console.log(e);
        return {
          err: true
        }
      })
  };

  _handleEdit = async () => {
    const {editing} = this.state;
    if (editing) {
      // 완료 처리
      const a = window.confirm('변경사항을 반영하시겠습니까?\n앱 사용에 영향을 미칠 수 있으니, 확인해 주세요');
      if (!a) return;

      this.setState({
        editing: false,
      });
      this._updateAdminSetting(this.state.setting);

    } else {
      // 편집 모드로 전환하기
      this.setState({
        editing: true
      })
    }

  }

  render() {
    const {setting} = this.state;
    return (
      <Container as={Segment} vertical>
        <Header content={'앱 기본 기능 편집'}/>
        <Button content={this.state.editing ? '완료' : '편집'}
                color={this.state.editing ? 'blue' : 'red'}
                onClick={this._handleEdit}/>
        <Grid as={Segment}>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Segment>
                <Header content={'UnderConsturction-iOS'}/>
                <Form>
                  <Form.Radio
                    label={'isUnderConstructionIOS'}
                    disabled={!this.state.editing}
                    checked={setting.isUnderConstructionIOS}
                    onChange={() => {
                      setting.isUnderConstructionIOS = !setting.isUnderConstructionIOS;
                      this.setState({setting})
                    }}
                    toggle/>
                  <Form.Group widths={'equal'}>
                    <Form.Input
                      disabled={!this.state.editing}
                      label={'underConstructionStartDateTimeIOS'}
                      name={'underConstructionStartDateTimeIOS'}
                      value={moment(setting.underConstructionStartDateTimeIOS).format('YYYY-MM-DD')}
                      type={'date'}
                      onChange={(e, {value, name}) => {
                        console.log(value);
                        setting.underConstructionStartDateTimeIOS = moment(value).toDate();
                        this.setState({setting},()=>{
                          console.log(setting.underConstructionStartDateTimeIOS)
                        })
                      }}
                    />
                    <Form.Input
                      disabled={!this.state.editing}
                      label={'시:분'}
                      name={'underConstructionStartDateTimeIOS'}
                      type={'time'}
                      value={moment(setting.underConstructionStartDateTimeIOS).format('HH:mm')}
                      onChange={(e, {value, name}) => {
                        console.log(value);
                        const currentDate = moment(setting.underConstructionStartDateTimeIOS).format('YYYY.MM.DD')
                        setting.underConstructionStartDateTimeIOS = moment(currentDate + ' ' + value, 'YYYY.MM.DD HH:mm').toDate();
                        this.setState({setting})
                      }}
                    />
                  </Form.Group>
                  <Form.Group widths={'equal'}>
                    <Form.Input
                      disabled={!this.state.editing}
                      label={'underConstructionEndDateTimeIOS'}
                      name={'underConstructionEndDateTimeIOS'}
                      value={moment(setting.underConstructionEndDateTimeIOS).format('YYYY-MM-DD')}
                      type={'date'}
                      onChange={(e, {value, name}) => {
                        console.log(value);
                        setting.underConstructionEndDateTimeIOS = moment(value).toDate();
                        this.setState({setting})
                      }}
                    />
                    <Form.Input
                      disabled={!this.state.editing}
                      label={'시:분'}
                      name={'underConstructionEndDateTimeIOS'}
                      type={'time'}
                      value={moment(setting.underConstructionEndDateTimeIOS).format('HH:mm')}
                      onChange={(e, {value, name}) => {
                        const currentDate = moment(setting.underConstructionEndDateTimeIOS).format('YYYY.MM.DD')
                        setting.underConstructionEndDateTimeIOS = moment(currentDate + ' ' + value, 'YYYY.MM.DD HH:mm').toDate();
                        this.setState({setting})
                      }}
                    />
                  </Form.Group>
                  <Form.TextArea
                    disabled={!this.state.editing}
                    label={'underConstructionMessageIOS'}
                    name={'underConstructionMessageIOS'}
                    value={setting.underConstructionMessageIOS}
                    onChange={(e, {value, name}) => {
                      setting.underConstructionMessageIOS = value;
                      this.setState({setting})
                    }}
                  />
                </Form>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <Header content={'UnderConsturction-Android'}/>
                <Form>
                  <Form.Radio
                    disabled={!this.state.editing}
                    label={'isUnderConstructionAndroid'}
                    checked={setting.isUnderConstructionAndroid}
                    onChange={() => {
                      setting.isUnderConstructionAndroid = !setting.isUnderConstructionAndroid;
                      this.setState({setting})
                    }}
                    toggle/>
                  <Form.Group widths={'equal'}>
                    <Form.Input
                      disabled={!this.state.editing}
                      label={'underConstructionStartDateTimeAndroid'}
                      name={'underConstructionStartDateTimeAndroid'}
                      value={moment(setting.underConstructionStartDateTimeAndroid).format('YYYY-MM-DD')}
                      type={'date'}
                      onChange={(e, {value, name}) => {
                        console.log(value);
                        setting.underConstructionStartDateTimeAndroid = moment(value).toDate();
                        this.setState({setting},()=>{
                          console.log(setting.underConstructionStartDateTimeAndroid)
                        })
                      }}
                    />
                    <Form.Input
                      disabled={!this.state.editing}
                      label={'시:분'}
                      name={'underConstructionStartDateTimeAndroid'}
                      type={'time'}
                      value={moment(setting.underConstructionStartDateTimeAndroid).format('HH:mm')}
                      onChange={(e, {value, name}) => {
                        console.log(value);
                        const currentDate = moment(setting.underConstructionStartDateTimeAndroid).format('YYYY.MM.DD')
                        setting.underConstructionStartDateTimeAndroid = moment(currentDate + ' ' + value, 'YYYY.MM.DD HH:mm').toDate();
                        this.setState({setting})
                      }}
                    />
                  </Form.Group>
                  <Form.Group widths={'equal'}>
                    <Form.Input
                      disabled={!this.state.editing}
                      label={'underConstructionEndDateTimeAndroid'}
                      name={'underConstructionEndDateTimeAndroid'}
                      value={moment(setting.underConstructionEndDateTimeAndroid).format('YYYY-MM-DD')}
                      type={'date'}
                      onChange={(e, {value, name}) => {
                        console.log(value);
                        setting.underConstructionEndDateTimeAndroid = moment(value).toDate();
                        this.setState({setting})
                      }}
                    />
                    <Form.Input
                      disabled={!this.state.editing}
                      label={'시:분'}
                      name={'underConstructionEndDateTimeAndroid'}
                      type={'time'}
                      value={moment(setting.underConstructionEndDateTimeAndroid).format('HH:mm')}
                      onChange={(e, {value, name}) => {
                        const currentDate = moment(setting.underConstructionEndDateTimeAndroid).format('YYYY.MM.DD')
                        setting.underConstructionEndDateTimeAndroid = moment(currentDate + ' ' + value, 'YYYY.MM.DD HH:mm').toDate();
                        this.setState({setting})
                      }}
                    />
                  </Form.Group>
                  <Form.TextArea
                    disabled={!this.state.editing}
                    label={'underConstructionMessageAndroid'}
                    name={'underConstructionMessageAndroid'}
                    value={setting.underConstructionMessageAndroid}
                    onChange={(e, {value, name}) => {
                      setting.underConstructionMessageAndroid = value;
                      this.setState({setting})
                    }}
                  />
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Segment>
                <Header content={'버전 관리-iOS'}/>
                <Form>
                  <Form.Input
                    disabled={!this.state.editing}
                    type={'number'}
                    name={'minBuildNoIOS'}
                    label={'minBuildNoIOS'}
                    value={this.state.setting.minBuildNoIOS.toString()}
                    onChange={(e, {value, name}) => {
                      setting.minBuildNoIOS = Number(value);
                      this.setState({setting})
                    }}
                  />
                  <Form.Input
                    disabled={!this.state.editing}
                    type={'number'}
                    name={'currentBuildNoIOS'}
                    label={'currentBuildNoIOS'}
                    value={this.state.setting.currentBuildNoIOS.toString()}
                    onChange={(e, {value, name}) => {
                      setting.currentBuildNoIOS = Number(value);
                      this.setState({setting})
                    }}
                  />
                  <Message content={'최소 버전을 설정하면, 이하 버전에서는 앱이 동작하지 않습니다.'}/>
                </Form>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <Header content={'버전 관리-Android'}/>
                <Form>
                  <Form.Input
                    disabled={!this.state.editing}
                    type={'number'}
                    name={'minBuildNoAndroid'}
                    label={'minBuildNoAndroid'}
                    value={this.state.setting.minBuildNoAndroid.toString()}
                    onChange={(e, {value, name}) => {
                      setting.minBuildNoAndroid = Number(value);
                      this.setState({setting})
                    }}
                  />
                  <Form.Input
                    disabled={!this.state.editing}
                    type={'number'}
                    name={'currentBuildNoAndroid'}
                    label={'currentBuildNoAndroid'}
                    value={this.state.setting.currentBuildNoAndroid.toString()}
                    onChange={(e, {value, name}) => {
                      setting.currentBuildNoAndroid = Number(value);
                      this.setState({setting})
                    }}
                  />
                  <Message content={'최소 버전을 설정하면, 이하 버전에서는 앱이 동작하지 않습니다.'}/>
                </Form>
              </Segment>
            </Grid.Column>

          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    auth: state.auth,
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(AdminSettingMainContainer);