export const homeActions = {
  FETCH_CURATIONS: 'FETCH_CURATIONS',
  FETCH_CURATIONS_SUCCESS: 'FETCH_CURATIONS_SUCCESS',
  REFRESH_CURATIONS: 'REFRESH_CURATIONS',
  FETCH_CURATIONS_MORE: 'FETCH_CURATIONS_MORE',

};


export const homeActionCreators = {
  fetchCurations: (cb) => ({type: homeActions.FETCH_CURATIONS, cb}),
  fetchCurationsMore: (cb) => ({type: homeActions.FETCH_CURATIONS_MORE, cb}),
  fetchCurationsSuccess: (curations, curationLastVisible, reachEnd) => ({
    type: homeActions.FETCH_CURATIONS_SUCCESS,
    curations,
    curationLastVisible,
    reachEnd
  }),
  refreshCurations: (cb) => ({type: homeActions.REFRESH_CURATIONS, cb}),
};

const initialState = {
  curations: [],
  curationLastVisible: {},
  reachEnd: false,
  refreshing: false,
  isLoaded: false,
  fetching: false,
};


export const homeReducer = (state = initialState, action) => {
  // console.log("HOME REDUCER", {actionType :action.type});

  switch (action.type) {
    case homeActions.FETCH_CURATIONS:
    case homeActions.FETCH_CURATIONS_MORE:
      return {
        ...state,
        fetching: true,
      };
    case homeActions.FETCH_CURATIONS_SUCCESS:
      return {
        ...state,
        curations: action.curations,
        curationLastVisible: action.curationLastVisible,
        reachEnd: action.reachEnd,
        isLoaded: true,
        refreshing: false,
        fetching: false,
      };

    case homeActions.REFRESH_CURATIONS:
      return {
        ...state,
        refreshing: true,
      };

    default:
      return state;
  }
};

