import React, {Component} from "react";
import {connect} from "react-redux";
import {authActionCreators} from "../../redux/auth/authStore";
import {Link} from "react-router-dom";
import {Table, Segment, Button, Container, Loader, Label, Header} from "semantic-ui-react";
import Network from "../../library/Network";
import {adminActionCreators} from "../../redux/admin/adminStore";

class AdminStoryMainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    this._loadCurationData();
  }

  _loadCurationData = async () => {
    this.props.fetchAllStoriesAdmin(() => {
      this.setState({
        isLoaded: true,
      })
    });
  };

  render() {
    const {isLoaded} = this.state;
    const {stories} = this.props.admin;
    if (!isLoaded) return <Loader active/>
    return (
      <Container as={Segment} vertical>
        <Header>스토리 어드민페이지</Header>
        <Button as={Link} to={'/admin/story/write'}>
          스토리 만들기
        </Button>
        <Table compact={'very'} size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>order</Table.HeaderCell>
              <Table.HeaderCell>title/subTitle</Table.HeaderCell>
              <Table.HeaderCell>isDeleted</Table.HeaderCell>
              <Table.HeaderCell>isPublic</Table.HeaderCell>
              <Table.HeaderCell>coverType</Table.HeaderCell>
              <Table.HeaderCell>layout</Table.HeaderCell>
              <Table.HeaderCell>readUserIds</Table.HeaderCell>
              <Table.HeaderCell width={1}>버튼</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {stories.map((story, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>{story.order}</Table.Cell>
                  <Table.Cell>
                    {story.coverTitle.text}<br/>
                    {story.coverSubTitle.text}
                  </Table.Cell>
                  <Table.Cell>
                    {story.isDeleted ? '삭제됨' : ''}
                  </Table.Cell>
                  <Table.Cell>
                    {story.isPublic ? '공개' : ''}
                  </Table.Cell>
                  <Table.Cell>
                    {story.coverType}
                  </Table.Cell>
                  <Table.Cell>
                    {story.readUserIds && story.readUserIds.length}
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      as={Link}
                      content={'수정'}
                      to={`/admin/story/detail/${story.id}`}
                    />
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
    ...adminActionCreators,
  }
);
export default enhance(AdminStoryMainContainer);
