import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {authActionCreators} from "../../redux/auth/authStore";
import {Card, Icon, Image, Container, Header, Message, Divider} from "semantic-ui-react";
import {Link} from "react-router-dom";
import moment from "moment";
import "moment/locale/ko";
import _ from 'lodash';
import {BOOKCLUB_STATUS_ENDED, BOOKCLUB_STATUS_ING, BOOKCLUB_STATUS_RECRUITING} from "../../dataStructure/bookClub";
import Analytics from "../../middleware/Analytics";

class MyBookClubMainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    Analytics.pageViewAll('/my/bookclub');
    window.scrollTo(0, 0);
  }

  render() {


    return (
      <div style={{
        paddingTop: '50px',
        paddingBottom: '50px',
      }}>
        {
          this.props.auth.bookClubsBeforeApproval.length > 0 && (
            <Container style={{marginTop: 50}}>
              <Header>승인 전 </Header>
              <Card.Group itemsPerRow={4} doubling>
                {
                  this.props.auth.bookClubsBeforeApproval.map((bookClub, i) => {
                    return (
                      <Card
                        as={Link}
                        to={this.props.to || `/my/book-club/${bookClub.id}`}
                        key={i}>
                        <Image src={bookClub.coverImageUrl}/>
                        <Card.Content>
                          <Header content={bookClub.title}/>
                        </Card.Content>
                      </Card>
                    );
                  })}
              </Card.Group>
            </Container>
          )
        }

        <Container style={{}}>
          <Header>진행중인 북클럽</Header>
          <Divider/>
          <Card.Group itemsPerRow={4} stackable>
            {
              _.filter(this.props.auth.bookClubs, o => o.status !== BOOKCLUB_STATUS_ENDED).map((bookClub, i) => {
                return (
                  <Card
                    as={Link}
                    to={this.props.to || `/my/book-club/${bookClub.id}`}
                    key={i}>
                    <Image src={bookClub.coverImageUrl}/>
                    <Card.Content>
                      <Card.Header content={bookClub.title} size={'small'}/>
                      <Card.Description>
                        {moment(bookClub.startDate).format('YYYY.MM.DD(ddd)')}-{moment(bookClub.endDate).format('YYYY.MM.DD(ddd)')}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                );
              })}
          </Card.Group>
        </Container>


        {
          _.filter(this.props.auth.bookClubs, o => o.status === BOOKCLUB_STATUS_ENDED).length > 0 && (
            <Container style={{marginTop: 50}}>
              <Header>종료된 북클럽</Header>
              <Card.Group itemsPerRow={4} doubling>
                {
                  _.filter(this.props.auth.bookClubs, o => o.status === BOOKCLUB_STATUS_ENDED).map((bookClub, i) => {
                    return (
                      <Card
                        as={Link}
                        to={this.props.to || `/my/book-club/${bookClub.id}`}
                        key={i}>
                        <Image src={bookClub.coverImageUrl}/>
                        <Card.Content>
                          <Header content={bookClub.title}/>
                        </Card.Content>
                      </Card>
                    );
                  })}
              </Card.Group>
            </Container>
          )
        }

      </div>
    );
  }
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
  }
);

export default enhance(MyBookClubMainContainer);
