import mockUpImages from "../constants/mockUpImages";
import Colors from "../constants/Colors";

export const NEW_LEADER = {
  order: 0,
  name: '',
  description: '',


  imageUrl: '',
  image: {
    square: '',
    horizontal: '',
    vertical: '',

    squareLarge: '',
    horizontalLarge: '',
    verticalLarge: '',
  },
  backgroundImage: '',
  backgroundImageLarge: '',
  backgroundColor: 'white',
  nameColor: Colors.mainBlack,
  descriptionColor: Colors.mainBlack,
  textColor: Colors.mainBlack,


  userId: '',

  descriptionModules: [],
  tags: [],

  isPublic: false,
  isDeleted: false,

  createdAt: new Date(),
  modifiedAt: new Date(),
};

