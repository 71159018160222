import React, {Component} from 'react';
import {Table, Button, Modal, Header, Form} from 'semantic-ui-react';
import {withLineEnter} from "../../middleware/TextHandler";
import AdminUserComponent from '../../components/user/AdminUserComponent';
import Network from "../../library/Network";
import moment from 'moment';
import {firebase} from "../../constants/firebase";
import LinesEllipsis from 'react-lines-ellipsis'

class AdminInquiryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inquiry: props.inquiry,
      uploading: false,
      modalOpen: false,
    }
  }


  componentDidMount() {

  }


  componentDidUpdate(prevProps, prevState) {
    if (this.props.inquiry.modifiedAt.seconds !== prevProps.inquiry.modifiedAt.seconds) {
      this.setState({
        inquiry: this.props.inquiry
      })
    }
    if (this.props.inquiry.id !== prevProps.inquiry.id) {
      this.setState({
        inquiry: this.props.inquiry
      })
    }
  }

  modifiedInquiry = async () => {
    if (!window.confirm('답변을 다시겠습니까?')) return;
    const {inquiry} = this.state;
    this.setState({uploading: true});
    const a = await Network.updateInquiry({
      ...inquiry,
      isAnswered: true,
      modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      answeredAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    if (a.err) window.alert('오류');
    this.props.refresh();
    this.setState({uploading: false, modalOpen: false})
  };

  deleteInquiry = async () => {
    if (!window.confirm('삭제하시겠습니까?')) return;
    const {inquiry} = this.state;
    this.setState({uploading: true});
    const a = await Network.updateInquiry({
      id: inquiry.id,
      isDeleted: true,
      modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    if (a.err) window.alert('오류');
    this.props.refresh();
    this.setState({uploading: false, modalOpen: false})
  };

  openModal = () => {
    this.setState({modalOpen: true})
  };
  closeModal = () => {
    this.setState({modalOpen: false})
  };

  render() {
    const {user} = this.props;
    const {inquiry} = this.state;
    if (!inquiry) return <div/>;
    return (
      <Table.Row compact={'very'} size={'small'}>
        <Table.Cell width={1} style={{fontSize: '0.5rem'}}>
          {moment(inquiry.createdAt.seconds * 1000).format('YY.MM.DD ddd HH:mm:ss')}
        </Table.Cell>

        <Table.Cell width={2}>
          {
            user && (
              <AdminUserComponent user={user}/>
            )
          }
        </Table.Cell>

        <Table.Cell width={5}>
          <LinesEllipsis
            text={withLineEnter(inquiry.question)}
            maxLine={4}
            ellipsis='...'
            trimRight
            basedOn='letters'
          />
        </Table.Cell>
        <Table.Cell width={1} style={{fontSize: '0.5rem'}}>
          {moment(inquiry.answeredAt.seconds * 1000).format('YY.MM.DD ddd HH:mm:ss')}
        </Table.Cell>
        <Table.Cell width={6}>
          {withLineEnter(inquiry.answer)}
        </Table.Cell>
        <Table.Cell width={1}>
          <Button size={'mini'} content={'답변'} onClick={this.openModal}/>
        </Table.Cell>
        <Modal open={this.state.modalOpen}>
          <Modal.Content>
            <p>
              {withLineEnter(inquiry.question)}
            </p>
            <Form>
              <Form.TextArea
                value={inquiry.answer}
                onChange={(e, {value}) => {
                  inquiry.answer = value;
                  this.setState({inquiry})
                }}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button disabled={this.state.uploading} content={'답변하기'} positive onClick={this.modifiedInquiry}/>
            <Button disabled={this.state.uploading} content={'삭제'} negative onClick={this.deleteInquiry}/>
            <Button disabled={this.state.uploading} content={'취소'} onClick={this.closeModal}/>
          </Modal.Actions>
        </Modal>
      </Table.Row>
    );
  }
}


export default AdminInquiryComponent;
