import {createLogic} from 'redux-logic';
import Network from "../../library/Network";
import {adminActionCreators, adminActions} from "./adminStore";


const adminInitializeLogic = createLogic({
  type: adminActions.ADMIN_INITIALIZE,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    dispatch(adminActionCreators.fetchAllUsers());
    dispatch(adminActionCreators.fetchAllBookClubsAdmin());
    dispatch(adminActionCreators.fetchAllAdminBooks());

    dispatch(adminActionCreators.fetchAllLeadersAdmin());
    dispatch(adminActionCreators.fetchAllPartnersAdmin());
    dispatch(adminActionCreators.fetchAllCoupons());
    dispatch(adminActionCreators.fetchAllCouponCodes());
    dispatch(adminActionCreators.fetchAllStoriesAdmin());
    done()
  }
});

const fetchAllUsers = createLogic({
  type: adminActions.FETCH_ALL_USERS,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const users = await Network.getAllUsers();
    if (users.err) {

    } else {
      dispatch(adminActionCreators.fetchAllUsersSuccess(users));
    }

    done();
  }
});

const fetchAllCouponCodesLogic = createLogic({
  type: adminActions.FETCH_ALL_COUPON_CODE,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const couponCodes = await Network.getAllCouponCodes();
    if (couponCodes.err) {
    } else {
      dispatch(adminActionCreators.fetchAllCouponCodesSuccess(couponCodes));
    }
    done()
  }
});

const fetchAllCouponsLogic = createLogic({
  type: adminActions.FETCH_ALL_COUPONS,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const coupons = await Network.getAllCoupons();
    if (coupons.err) {
    } else {
      dispatch(adminActionCreators.fetchAllCouponsSuccess(coupons));
    }
    done()
  }
});

const fetchAllBookClubsAdminLogic = createLogic({
  type: adminActions.FETCH_ALL_BOOK_CLUBS_ADMIN,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const bookClubs = await Network.getAllBookClubs();
    if (bookClubs.err) {
      done();
    } else {
      dispatch(adminActionCreators.fetchAllBookClubsAdminSuccess(bookClubs));
      if (action.cb) action.cb();
      done();
    }
  }
});

const fetchBookClubBannersAdminLogic = createLogic({
  type: adminActions.FETCH_BOOK_CLUB_BANNERS_ADMIN,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const res = await Network.getAllBookClubBanners();
    if (!res.err) dispatch(adminActionCreators.fetchBookClubBannersAdminSuccess(res));
    if (action.cb) action.cb();
    done();
  }
});

const fetchScrapsAdminLogic = createLogic({
  type: adminActions.FETCH_SCRAPS_ADMIN,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const res = await Network.adminGetScrapLimit();
    if (!res.err) {
      dispatch(adminActionCreators.fetchScrapsAdminSuccess(res.scraps, res.lastVisible))
    }

    if (action.cb) action.cb();
    done();
  }
});

const fetchScrapsMoreAdminLogic = createLogic({
  type: adminActions.FETCH_SCRAPS_MORE_ADMIN,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const {scrapLastVisible, scraps, scrapReachToEnd} = getState().admin;

    if (scrapReachToEnd) {
      done();
      return;
    }

    const res = await Network.adminGetScrapLimitMore(scrapLastVisible);
    if (!res.err) {
      dispatch(adminActionCreators.fetchScrapsAdminSuccess([...scraps, ...res.scraps], res.lastVisible))
    }
    if (action.cb) action.cb();
    done();
  }
});

const fetchEssaysAdminLogic = createLogic({
  type: adminActions.FETCH_ESSAYS_ADMIN,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const res = await Network.adminGetEssayLimit();
    if (!res.err) {
      dispatch(adminActionCreators.fetchEssaysAdminSuccess(res.essays, res.lastVisible));
    }
    if (action.cb) action.cb();
    done();
  }
});

const fetchEssaysMoreAdminLogic = createLogic({
  type: adminActions.FETCH_ESSAYS_MORE_ADMIN,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const {essays, essayLastVisible, essayReachToEnd} = getState().admin;

    if (essayReachToEnd) {
      done();
      return;
    }
    const res = await Network.adminGetEssayLimitMore(essayLastVisible);
    if (!res.err) {
      dispatch(adminActionCreators.fetchEssaysAdminSuccess([...essays, ...res.essays], res.lastVisible));
    }
    if (action.cb) action.cb();
    done();
  }
});

const fetchAllPlacesAdminLogic = createLogic({
  type: adminActions.FETCH_ALL_PLACE_ADMIN,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const res = await Network.adminGetAllPlaces();
    if (!res.err) dispatch(adminActionCreators.fetchAllPlacesAdminSuccess(res));
    if (action.cb) action.cb();
    done();
  }
});

const fetchAllAdminBooksLogic = createLogic({
  type: adminActions.FETCH_ALL_ADMIN_BOOKS,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const res = await Network.adminGetAllAdminBooks();
    if (!res.err) dispatch(adminActionCreators.fetchAllAdminBooksSuccess(res));
    if (action.cb) action.cb();
    done();
  }
});

const fetchAllLeadersAdminLogic = createLogic({
  type: adminActions.FETCH_ALL_LEADERS_ADMIN,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const res = await Network.getAllLeaders();
    if (!res.err) dispatch(adminActionCreators.fetchAllLeadersAdminSuccess(res));
    if (action.cb) action.cb();
    done();
  }
});

const fetchAllPartnersAdminLogic = createLogic({
  type: adminActions.FETCH_ALL_PARTNERS_ADMIN,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const res = await Network.getAllPartners();
    if (!res.err) dispatch(adminActionCreators.fetchAllPartnersAdminSuccess(res));
    if (action.cb) action.cb();
    done();
  }
});

const fetchAllStoriesAdminLogic = createLogic({
  type: adminActions.FETCH_ALL_STORIES_ADMIN,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const res = await Network.getAllStories();
    if (!res.err) dispatch(adminActionCreators.fetchAllStoriesAdminSuccess(res));
    if (action.cb) action.cb();
    done();
  }
});

const fetchAllInquiriesAdminLogic = createLogic({
  type: adminActions.FETCH_ALL_INQUIRIES_ADMIN,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const res = await Network.getInquiriesAll();
    if (!res.err) dispatch(adminActionCreators.fetchAllInquiriesAdminSuccess(res));
    if (action.cb) action.cb();
    done();
  }
});

export default [
  fetchAllUsers,
  adminInitializeLogic,
  fetchAllCouponCodesLogic,
  fetchAllCouponsLogic,
  fetchAllBookClubsAdminLogic,
  fetchBookClubBannersAdminLogic,
  fetchScrapsAdminLogic,
  fetchScrapsMoreAdminLogic,
  fetchEssaysAdminLogic,
  fetchEssaysMoreAdminLogic,
  fetchAllPlacesAdminLogic,
  fetchAllAdminBooksLogic,
  fetchAllLeadersAdminLogic,
  fetchAllPartnersAdminLogic,
  fetchAllStoriesAdminLogic,
  fetchAllInquiriesAdminLogic,
]