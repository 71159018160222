export const bookshelfActions = {
  FETCH_ALL_BOOKS: 'FETCH_ALL_BOOKS',
  FETCH_ALL_BOOKS_SUCCESS: 'FETCH_ALL_BOOKS_SUCCESS',
  ADD_BOOK: 'ADD_BOOK ',
  DELETE_BOOK: 'DELETE_BOOK',

  FETCH_ALL_BOOKSHELVES: 'FETCH_ALL_BOOKSHELVES',
  FETCH_ALL_BOOKSHELVES_SUCCESS: 'FETCH_ALL_BOOKSHELVES_SUCCESS',
  ADD_BOOKSHELF: 'ADD_BOOKSHELF',
  MODIFY_BOOKSHELF: 'MODIFY_BOOKSHELF',
  DELETE_BOOKSHELF: 'DELETE_BOOKSHELF',

};


export const bookshelfActionCreators = {
  //책
  fetchBooks: (cb) => ({type: bookshelfActions.FETCH_ALL_BOOKS, cb}),
  fetchBooksSuccess: (books) => ({type: bookshelfActions.FETCH_ALL_BOOKS_SUCCESS, books}),

  addBookToBookshelf: (book, bookshelf) => ({type: bookshelfActions.ADD_BOOK, book, bookshelf}),
  deleteBookFromBookshelf: (book, bookshelf) => ({type: bookshelfActions.DELETE_BOOK, book, bookshelf}),

  // 책장
  fetchBookshelves: (cb) => ({type: bookshelfActions.FETCH_ALL_BOOKSHELVES, cb}),
  fetchBookshelvesSuccess: (bookshelves) => ({type: bookshelfActions.FETCH_ALL_BOOKSHELVES_SUCCESS, bookshelves}),

  addBookshelf: (bookshelf, cb) => ({type: bookshelfActions.ADD_BOOKSHELF, bookshelf, cb}),
  modifyBookshelf: (bookshelf, cb) => ({type : bookshelfActions.MODIFY_BOOKSHELF, bookshelf, cb}),
  deleteBookshelf: (bookshelf, cb) => ({type: bookshelfActions.DELETE_BOOKSHELF, bookshelf, cb}),
};



const initialState = {
  bookshelves: [],
  books: [],
};


export const bookshelfReducer = (state = initialState, action) => {
  // console.log("BOOKSHELF REDUCER", {actionType :action.type});

  switch (action.type) {
    case bookshelfActions.FETCH_ALL_BOOKS_SUCCESS:
      return {
        ...state,
        books: [
          ...action.books
        ]
      };
    case bookshelfActions.FETCH_ALL_BOOKSHELVES_SUCCESS:
      return {
        ...state,
        bookshelves: [
          ...action.bookshelves
        ]

      };
    default:
      return state;
  }
}