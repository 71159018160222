import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {Segment, Divider, Grid, Image, Container, Button,} from 'semantic-ui-react'
import Colors from "../../constants/Colors";
import {Link} from 'react-router-dom'
import Analytics from "../../middleware/Analytics";

class AboutBookClubContainer extends Component {


  componentDidMount() {
    Analytics.pageViewAll('/about/bookclub');
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div style={{}}>
        <Container fluid style={{
          minHeight: '30rem',
          backgroundColor: Colors.backgroundColor,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Image src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Fbooclub_icon.png?alt=media'} size={'small'}/>
        </Container>
        <Grid stackable>
          <Grid.Row
            verticalAlign={'middle'}>
            <Grid.Column style={{
              minHeight: '70vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                북클럽.
              </div>
              <div className={'subHeaderText'}>
                함께 읽는 즐거움
              </div>
              <div className={'mainDescriptionText'}>
                같은 책.<br/>
                다른 생각.<br/>
              </div>
              <p className={'detailDescriptionText'}>
                책은 혼자 읽습니다.<br/>
                하지만 배우는 것은 함께 읽을 때, 더 강력하죠.<br/>
                나의 생각과 다른 생각이 만날 때, 생각이 확장됩니다.<br/>
              </p>
              <div className={'mainHeaderText'} style={{fontWeight: '100'}}>
                리더스에서 <span style={{textDecoration: 'wavy underline #69FF46', fontWeight: 'bold'}}>입체적</span> 책읽기를 해보세요.
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            columns={3}
            verticalAlign={'middle'}>
            <Grid.Column style={{
              minHeight: '40vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                함께 읽고.
              </div>
              <Image
                centered
                size={'medium'}
                src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Fbookclub_1.png?alt=media'}
              />
            </Grid.Column>
            <Grid.Column style={{
              minHeight: '40vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                쓰고.
              </div>
              <Image
                centered
                size={'medium'}
                src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Fbookclub_2.png?alt=media'}
              />
            </Grid.Column>
            <Grid.Column style={{
              minHeight: '40vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                생각의 확장.
              </div>
              <Image
                centered
                size={'medium'}
                src={'https://firebasestorage.googleapis.com/v0/b/readers-prod.appspot.com/o/etc%2Fbookclub_3.png?alt=media'}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            verticalAlign={'middle'}>
            <Grid.Column style={{
              minHeight: '70vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                언제, 어디서나.
              </div>
              <div className={'subHeaderText'}>
                지적인 대화를 나눠보세요.
              </div>
              <div className={'mainDescriptionText'}>
                오프라인에서<br/>
                또는<br/>
                온라인으로.
              </div>
              <p className={'detailDescriptionText'}>
                같은 책을 읽고,<br/>
                다른 직업, 다른 배경, 다른 견해를 가진 사람들은<br/>
                어떻게 생각하는지.<br/>
                북클럽에 최적화된 시스템을 제공하니까요
              </p>
              <div className={'mainHeaderText'} style={{fontWeight: '100'}}>
                게다가
                <span style={{textDecoration: 'wavy underline #69FF46', fontWeight: 'bold'}}> 좋은 사람들을 </span>알게 되는 덤까지.

              </div>
            </Grid.Column>
          </Grid.Row>
          {/*<Grid.Row
            columns={3}
            verticalAlign={'middle'}>
            <Grid.Column style={{
              minHeight: '40vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                다양한 직업.
              </div>
              <Image
                centered
                size={'medium'}
                src={require('../../assets/appScreen01.png')}
              />
            </Grid.Column>
            <Grid.Column style={{
              minHeight: '40vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                다양한 전공.
              </div>
              <Image
                centered
                size={'medium'}
                src={require('../../assets/appScreen01.png')}
              />
            </Grid.Column>
            <Grid.Column style={{
              minHeight: '40vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                알아가기.
              </div>
              <Image
                centered
                size={'medium'}
                src={require('../../assets/appScreen01.png')}
              />
            </Grid.Column>
          </Grid.Row>*/}
          <Grid.Row
            verticalAlign={'middle'}>
            <Grid.Column style={{
              minHeight: '70vh',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <div className={'mainHeaderText'}>
                어때요?
              </div>
              <div className={'mainHeaderText'} style={{fontWeight: '100'}}>
                이제 <span style={{textDecoration: 'wavy underline #69FF46', fontWeight: 'bold'}}>함께 읽는 즐거움</span>을 느껴볼까요?
              </div>
              <div style={{display: 'flex', marginTop: '3rem',}}>
                <a className={'roundButton'} href={'https://readersrn.page.link/fromhomepage'} target={'_blank'}>
                  앱 다운로드
                </a>
              </div>
              {/*<Link to={'/bookclub'} className={'buttonHover'} style={{marginTop:'3rem'}}>
                <p className={'buttonHover'}>
                  북클럽 보러가기 >
                </p>
              </Link>*/}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(AboutBookClubContainer);