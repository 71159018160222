export const authActions = {

  APP_INITIALIZE: 'APP_INITIALIZE',

  SIGN_IN: 'SIGN_IN',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_OUT: 'SIGN_OUT',

  FETCH_USER_INFO_MY: 'FETCH_USER_INFO_MY',
  FETCH_USER_INFO_MY_SUCCESS: 'FETCH_USER_INFO_MY_SUCCESS',

  FETCH_MY_BOOK_CLUBS_API_V1: 'FETCH_MY_BOOK_CLUBS_API_V1',
  FETCH_MY_BOOK_CLUBS_API_V1_SUCCESS: 'FETCH_MY_BOOK_CLUBS_API_V1_SUCCESS',

  FETCH_MY_BOOK_CLUBS_BEFORE_APPROVAL_API_V1: 'FETCH_MY_BOOK_CLUBS_BEFORE_APPROVAL_API_V1',
  FETCH_MY_BOOK_CLUBS_BEFORE_APPROVAL_API_V1_SUCCESS: 'FETCH_MY_BOOK_CLUBS_BEFORE_APPROVAL_API_V1_SUCCESS',

  FETCH_MY_BOOK_CLUBS_DENIED_API_V1: 'FETCH_MY_BOOK_CLUBS_DENIED_API_V1',
  FETCH_MY_BOOK_CLUBS_DENIED_API_V1_SUCCESS: 'FETCH_MY_BOOK_CLUBS_DENIED_API_V1_SUCCESS',
};


export const authActionCreators = {
  appInitialize: (cb) => ({type: authActions.APP_INITIALIZE, cb}),

  signIn: (email, password, cb) => ({type: authActions.SIGN_IN, email, password, cb}),
  signInSuccess: (user, cb) => ({type: authActions.SIGN_IN_SUCCESS, user, cb}),
  signOut: (cb) => ({type: authActions.SIGN_OUT, cb}),
  fetchUserInfoMy: (cb) => ({type: authActions.FETCH_USER_INFO_MY, cb}),
  fetchUserInfoMySuccess: (user, cb) => ({type: authActions.FETCH_USER_INFO_MY_SUCCESS, user, cb}),

  fetchMyBookClubsApiV1: (cb) => ({type: authActions.FETCH_MY_BOOK_CLUBS_API_V1, cb}),
  fetchMyBookClubsApiV1Success: (bookClubs, cb) => ({
    type: authActions.FETCH_MY_BOOK_CLUBS_API_V1_SUCCESS,
    bookClubs,
    cb
  }),

  fetchMyBookClubsBeforeApprovalApiV1: (cb) => ({
    type: authActions.FETCH_MY_BOOK_CLUBS_BEFORE_APPROVAL_API_V1,
    cb
  }),
  fetchMyBookClubsBeforeApprovalApiV1Success: (bookClubs, cb) => ({
    type: authActions.FETCH_MY_BOOK_CLUBS_BEFORE_APPROVAL_API_V1_SUCCESS,
    bookClubs,
    cb
  }),

  fetchMyBookClubsDeniedApiV1: (cb) => ({type: authActions.FETCH_MY_BOOK_CLUBS_DENIED_API_V1, cb}),
  fetchMyBookClubsDeniedApiV1Success: (bookClubs, cb) => ({
    type: authActions.FETCH_MY_BOOK_CLUBS_DENIED_API_V1_SUCCESS,
    bookClubs,
    cb
  }),

};


const initialState = {
  user: {},
  isLoggedIn: false,


  // 북클럽
  bookClubs: [],
  isBookClubLoaded: false,

  bookClubsBeforeApproval: [],
  isBookClubBeforeApprovalLoaded: false,

  bookClubsDenied: [],
  isBookClubDeniedLoaded: false,

  account: {},
};



export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActions.SIGN_IN :
      return {
        ...state
      };
    case authActions.SIGN_IN_SUCCESS:
      return {
        ...state,
        user: action.user,

      };
    case authActions.SIGN_OUT:
      return initialState;

    case authActions.FETCH_USER_INFO_MY_SUCCESS:
      return {
        ...state,
        user: {...action.user},
        isLoggedIn: true,
      };

    case authActions.FETCH_MY_BOOK_CLUBS_API_V1_SUCCESS:
      return {
        ...state,
        bookClubs: action.bookClubs,
        isBookClubLoaded: true,
      };
    case authActions.FETCH_MY_BOOK_CLUBS_BEFORE_APPROVAL_API_V1_SUCCESS:
      return {
        ...state,
        bookClubsBeforeApproval: action.bookClubs,
        isBookClubBeforeApprovalLoaded: true,
      };
    case authActions.FETCH_MY_BOOK_CLUBS_DENIED_API_V1_SUCCESS:
      return {
        ...state,
        bookClubsDenied: action.bookClubs,
        isBookClubDeniedLoaded: true,
      };

    default:
      return state;
  }
}