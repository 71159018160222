import React, {Component} from 'react';
import {connect} from 'react-redux';
import NetworkApiV1 from "../../library/NetworkApiV1";
import {Container, Header, Button, Grid, Card, Statistic, Image, Table, Loader, Segment} from "semantic-ui-react";
import moment from 'moment';
import ContentModuleComponent from "../../components/content-module/ContentModuleComponent";
import {
  BOOKCLUB_STATUS_BEFORE_RECRUIT, BOOKCLUB_STATUS_ENDED,
  BOOKCLUB_STATUS_ING,
  BOOKCLUB_STATUS_RECRUITING
} from "../../dataStructure/bookClub";
import {Link} from "react-router-dom";

class BookClubDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookClub: {},
      isLoaded: false,
    }
  }

  componentDidMount() {
    this._loadBookClub();
  }

  _loadBookClub = async () => {
    const {bookClubId} = this.props.match.params;
    const {err, bookClub} = await NetworkApiV1.getPublicBookClub(bookClubId);
    if (!err) {
      this.setState({bookClub, isLoaded: true,})
    }
  };

  renderButton = () => {
    const {bookClub} = this.state;
    const {isLoggedIn} = this.props.auth;
    switch (bookClub.status) {
      case BOOKCLUB_STATUS_BEFORE_RECRUIT:
        return (
          <Button content={'모집 예정'}/>
        );
      case BOOKCLUB_STATUS_RECRUITING:
        return (
          <Button content={'신청하기'} as={Link} to={{pathname: `/my/book-club/apply/${bookClub.id}`}}/>
        );
      case BOOKCLUB_STATUS_ING:
        return (
          <Button content={'진행중인 북클럽입니다.'}/>
        );
      case BOOKCLUB_STATUS_ENDED:
        return (
          <Button content={'종료된 북클럽입니다.'}/>
        );
      default:
        return <div/>;
    }
  };


  render() {
    if (!this.state.isLoaded) return <Loader active/>;
    const {bookClub} = this.state;
    return (
      <>
        <Container as={Segment} vetical basic>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Image src={bookClub.coverImageUrl}/>
              </Grid.Column>
              <Grid.Column style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <div>
                  <Header content={bookClub.title} size={'large'}/>
                  <Header.Subheader content={bookClub.subTitle} size={'large'}/>
                  <p>
                    {moment(bookClub.startDate).format('YYYY.MM.DD(ddd)')} - {moment(bookClub.endDate).format('YYYY.MM.DD(ddd)')}
                  </p>
                </div>
                {this.renderButton()}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Container as={Segment} vetical basic>
          <Grid>
            <Grid.Row columns={4} doubling>
              <Grid.Column style={{width: '100%'}}>
                <Segment style={{
                  width: '100%',
                  height: '150px',
                  borderRadius: 10,
                  verticalAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <Header size={'large'}>
                    {moment(bookClub.endDate).diff(bookClub.startDate, 'week')} 주
                  </Header>
                  <p>
                    참여기간
                  </p>
                </Segment>
              </Grid.Column>
              <Grid.Column style={{width: '100%'}}>
                <Segment style={{
                  width: '100%',
                  height: '150px',
                  borderRadius: 10,
                  verticalAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <Header size={'large'}>
                    {bookClub.maxNumberOfMembers} 명
                  </Header>
                  <p>
                    정원
                  </p>
                </Segment>
              </Grid.Column>
              <Grid.Column style={{width: '100%'}}>
                <Segment style={{
                  width: '100%',
                  height: '150px',
                  borderRadius: 10,
                  verticalAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <Header size={'large'}>
                    {bookClub.numberOfBooks} 권
                  </Header>
                  <p>
                    읽는 책
                  </p>
                </Segment>
              </Grid.Column>
              <Grid.Column style={{width: '100%'}}>
                <Segment style={{
                  width: '100%',
                  height: '150px',
                  borderRadius: 10,
                  verticalAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <Header size={'large'}>
                    {bookClub.numberOfEssays} 편
                  </Header>
                  <p>
                    작성 에세이
                  </p>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Container as={Segment} vetical basic text>
          <Header content={`읽는 책(${bookClub.numberOfBooks})`}/>
          <div style={{margin: 'auto', display: 'flex', justifyContent: 'center'}}>
            {bookClub.books && bookClub.books.map((book, i) => {
              return (
                <div style={{padding: '1rem', backgroundColor: 'white', borderRadius: '10px', margin: '0px 1rem'}}>
                  <Image src={book.coverImageUrl} size={'tiny'} style={{}}/>
                </div>
              )
            })}
          </div>
        </Container>
        <Container as={Segment} vetical basic text>
          <Table size={'small'} compact={'very'}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell> </Table.HeaderCell>
                <Table.HeaderCell> </Table.HeaderCell>
                <Table.HeaderCell> </Table.HeaderCell>
                <Table.HeaderCell> </Table.HeaderCell>
                <Table.HeaderCell> </Table.HeaderCell>
                <Table.HeaderCell> </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {bookClub.essaySchedules.map((bookClubEssaySchedule, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>
                      {i + 1}
                    </Table.Cell>
                    <Table.Cell>
                      <Image src={bookClubEssaySchedule.coverImageUrl} size={'mini'}/>
                    </Table.Cell>
                    <Table.Cell>
                      {bookClubEssaySchedule.title.substring(bookClubEssaySchedule.title, bookClubEssaySchedule.title.indexOf(` - ${bookClubEssaySchedule.subTitle}`)).trim()}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(bookClubEssaySchedule.startDate).format('YYYY.MM.DD (ddd)')}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(bookClubEssaySchedule.endDate).format('YYYY.MM.DD (ddd)')}
                    </Table.Cell>
                    <Table.Cell>

                    </Table.Cell>

                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </Container>

        {
          bookClub.contentModules && bookClub.contentModules.map((contentModule, i) => {
            return (
              <ContentModuleComponent key={i} contentModule={contentModule}/>
            )
          })
        }
      </>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BookClubDetailContainer);