import Colors from "../constants/Colors";
import _ from "lodash";

const BOOKCLUB_STATUS_BEFORE_RECRUIT = 'BOOKCLUB_STATUS_BEFORE_RECRUIT';
const BOOKCLUB_STATUS_RECRUITING = 'BOOKCLUB_STATUS_RECRUITING';
const BOOKCLUB_STATUS_ING = 'BOOKCLUB_STATUS_ING';
const BOOKCLUB_STATUS_ENDED = 'BOOKCLUB_STATUS_ENDED';

const MockUpBookClubCoverImage = 'https://firebasestorage.googleapis.com/v0/b/readers-api.appspot.com/o/bookclubimage%2F201904%2F20190403211302.jpg?alt=media&token=56cf6d4e-2606-4521-a58f-54bf4d49fc64';


const CARD_SHAPE_SQUARE = 'CARD_SHAPE_SQUARE';
const CARD_SHAPE_HORIZONTAL = 'CARD_SHAPE_HORIZONTAL';
const CARD_SHAPE_GRID = 'CARD_SHAPE_GRID';
export const BOOK_CLUB_SHAPE_DEFAULT = 'BOOK_CLUB_SHAPE_DEFAULT';

const HELD_BY_READERS = 'HELD_BY_READERS';
const HELD_BY_PARTNER = 'HELD_BY_PARTNER';
const HELD_BY_USER = 'HELD_BY_USER';

export const bookClubStatusInText = (status) => {
  switch (status) {
    case BOOKCLUB_STATUS_ING:
      return '진행중';
    case BOOKCLUB_STATUS_BEFORE_RECRUIT:
      return '모집예정';
    case BOOKCLUB_STATUS_RECRUITING:
      return '모집중';
    case BOOKCLUB_STATUS_ENDED:
      return '종료';
    default:
      return status;
  }
};

export const bookClubStatusList = [
  BOOKCLUB_STATUS_BEFORE_RECRUIT,
  BOOKCLUB_STATUS_RECRUITING,
  BOOKCLUB_STATUS_ING,
  BOOKCLUB_STATUS_ENDED,
];

export const bookClubHolderList = [
  HELD_BY_READERS,
  HELD_BY_PARTNER,
  HELD_BY_USER,
];

export const bookClubStatusDropDownList = () => {
  return _.map(bookClubStatusList, (status, index) => ({
    key: index,
    text: status,
    value: status,
  }));
};

export const bookClubHolderDropDownList = () => {
  return _.map(bookClubHolderList, (holder, index) => ({
    key: index,
    text: holder,
    value: holder,
  }));
};

const NEW_BOOK_CLUB = {
  color: '',

  title: '', //Depreciated
  subTitle: '',//Depreciated
  description: '',//Depreciated

  header: {
    content: '',
    align: 'left',
    color: Colors.mainBlack,
  },
  subHeader: {
    content: '',
    align: 'left',
    color: Colors.mainBlack,
  },
  descriptionNew: {
    content: '',
    align: 'left',
    color: Colors.mainBlack,
  },
  backgroundColor: 'grey',

  status: BOOKCLUB_STATUS_BEFORE_RECRUIT,

  coverImageUrl: '',
  image: {
    square: '',
    horizontal: '',
    vertical: '',

    squareLarge: '',
    horizontalLarge: '',
    verticalLarge: '',
  },

  startDate: new Date(),
  endDate: new Date(),
  registerStartDate: new Date(),
  registerEarlyBirdEndDate: new Date(),
  registerEndDate: new Date(),


  descriptionModules: [],
  contentModules: [],

  maxNumOfMembers: 15,
  showAvailable: false,

  defaultMembership: 35000,
  hasEarlyBirdPromotion: false,
  earlyBirdMembership: 35000,

  isPrivate: false,
  privatePassword: '1234',

  hasLeader: false,
  leaderId: '',
  leaderUserId: '',

  numberOfBooks: 0,

  hasOffline: false,


  hasOfflineMeeting: false,
  numberOfOfflineMeetings: 0,
  hasOnlineMeeting: false,
  numberOfOnlineMeetings: 0,

  hasDeposit: true,

  scrapMandatory: false,
  depositPerScrap: 0,
  numberOfScraps: 0,
  scrapDaily: false,

  essayMandatory: false,
  depositPerEssay: 0,
  numberOfEssays: 0,


  heldBy: HELD_BY_READERS,
  partnerId: '',

  alarmUserIds: [],

  participantIds: [],
  participantEmails: [],

  applicantIds: [],
  applicantEmails: [],

  deniedIds: [],
  deniedEmails: [],

  tags: [],
  categories: [],

  createdAt: new Date(),
  modifiedAt: new Date(),

  isPublic: false,
  isDeleted: false,

};


export {
  BOOKCLUB_STATUS_BEFORE_RECRUIT,
  BOOKCLUB_STATUS_ENDED,
  BOOKCLUB_STATUS_ING,
  BOOKCLUB_STATUS_RECRUITING,

  CARD_SHAPE_HORIZONTAL,
  CARD_SHAPE_SQUARE,
  CARD_SHAPE_GRID,

  HELD_BY_PARTNER,
  HELD_BY_READERS,
  HELD_BY_USER,

  MockUpBookClubCoverImage,
  NEW_BOOK_CLUB
}