import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux'
import {authActionCreators} from "../../redux/auth/authStore";
import Network from "../../library/Network";
import {Segment, Loader, Card, Grid, Image, Icon, Table, Item, Button, Feed, Container,} from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import UserComponent from "../../components/user/UserComponent";
import Analytics from "../../middleware/Analytics";
import NetworkApiV1 from "../../library/NetworkApiV1";
import {Link} from "react-router-dom";

class MyBookClubDetailContainer extends Component {


  constructor(props) {
    super(props);
    this.state = {
      bookClub: {},
      isLoaded: false,
      essaySchedules: [],
      essaySchedulesLoaded: false,
    }
  }

  componentDidMount() {
    const {bookClubId} = this.props.match.params;
    Analytics.pageViewAll(`/my/bookclub/${bookClubId}`);
    window.scrollTo(0, 0);
    this._loadBookClub();
    this._loadEssaySchedule();
  }

  // 북클럽 정보
  _loadBookClub = async () => {
    const {bookClubId} = this.props.match.params;
    const res = await NetworkApiV1.getBookClubById(bookClubId);
    if (!res.err) {
      this.setState({
        bookClub: res,
        isLoaded: true,
      })
    }
  };

  _loadEssaySchedule = async () => {
    const {bookClubId} = this.props.match.params;
    const res = await NetworkApiV1.getBookClubDetailEssaySchedule(bookClubId);
    if (!res.err) {
      this.setState({
        essaySchedules: res,
        essaySchedulesLoaded: true,
      })
    }
  };

  handleWriteButton = async (essaySchedule) => {
    if (!essaySchedule.submitted) {
      this.props.history.push({
        pathname: `/my/bookclub-essay/write`,
        state: {
          bookClubEssayScheduleId: essaySchedule.id,
          bookId: essaySchedule.bookId,
        }
      });

    } else {
      this.props.history.push({
        pathname: `/my/bookclub-essay/read/${essaySchedule.id}`,
        state: {
          bookClubEssayScheduleId: essaySchedule.id,
          bookId: essaySchedule.bookId,
        }
      });
    }
  };

  render() {
    const {bookClubId} = this.props.match.params;
    const {
      isLoaded, essaySchedulesLoaded
    } = this.state;
    if (!isLoaded || !essaySchedulesLoaded) return (
      <div style={{minHeight: '100vh'}}>
        <Loader active/>;
      </div>
    );
    const {bookClub, essaySchedules} = this.state;

    return (
      <div style={{
        maxWidth: '700px',
        margin: 'auto'
      }}>
        <Container>
          <Card>
            <Image src={bookClub.coverImageUrl}/>
          </Card>
          <Item.Group>
            {
              essaySchedules.map((essaySchedule, i) => {
                return (
                  <Item key={i}>
                    <Item.Image src={essaySchedule.coverImageUrl} size={'tiny'}/>
                    <Item.Content>
                      <Item.Header content={essaySchedule.title}/>
                      <Item.Description
                        content={`${essaySchedule.essays && essaySchedule.essays.length}개의 에세이가 있습니다.`}/>
                      <Item.Extra>
                        <Button
                          onClick={() => {
                            this.handleWriteButton(essaySchedule);
                          }}
                          content={essaySchedule.submitted ? '에세이보기' : '작성하기'}/>
                      </Item.Extra>
                    </Item.Content>
                  </Item>
                )
              })
            }
          </Item.Group>
        </Container>
      </div>
    );
  }
}



const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
  }
);

export default enhance(MyBookClubDetailContainer);
