import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {Container, Image, Message, Divider, Button, Modal, Checkbox, Form, Header} from 'semantic-ui-react';
import {getNewNotification, NOTIFICATION_TYPE_BOOK_CLUB_MEMBER} from "../../dataStructure/notification";
import mockUpImages from "../../constants/mockUpImages";
import {
  getNewPushNotification,
  getPushNotificationDropDownList, NOTIFICATION_TYPE_ESSAY,
  NOTIFICATION_TYPE_SCRAP, NOTIFICATION_TYPE_STORY
} from "../../dataStructure/pushNotification";
import {firebase} from "../../constants/firebase";
import moment from "moment/moment";
import {addNotifications, sendPushNotificationMany} from "../../library/API_EndPoint";
import FileUploader from "react-firebase-file-uploader";

const storageRef = `pushNotification/${moment().format("YYYYMM")}`;

class BookClubPushNotificationComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,

      toApplicants: false,
      toParticipants: false,
      toDenied: false,
      toAlarmUsers: false,

      targetUserIds: [],
      pushNotification: {
        ...getNewPushNotification(),
        data: {
          ...getNewPushNotification().data,
          type: NOTIFICATION_TYPE_BOOK_CLUB_MEMBER,
          bookClubId: props.bookClubData.id,
        }
      },
      notification: {
        ...getNewNotification(),
        image: (props.bookClubData && props.bookClubData.coverImageUrl !== '') ? props.bookClubData.coverImageUrl : mockUpImages.planeImage,
        bookClubId: props.bookClubData.id,
        type: NOTIFICATION_TYPE_BOOK_CLUB_MEMBER,
      },
      push: true,
      pushSoundOn: true,

    }
  }

  componentDidMount() {

  }

  _sendPushNotification = async (targetUserIds) => {
    const tokens = [];
    const users = this.props.admin.users;
    targetUserIds.forEach((userId, i) => {
      const user = users.find(us => userId === us.id);
      if (user && user.token && user.token.length > 0) {
        tokens.push(...user.token);
      }
    });
    console.log(tokens);

    const a = await sendPushNotificationMany(tokens, this.state.pushNotification);
    if (a.err) {
      window.alert('에러 발생');
      return false;
    } else {
      console.log('SUCCESS', a);
      return true;
    }
  };

  _sendNotification = async (targetUserIds) => {
    const a = await addNotifications(targetUserIds, this.state.notification);
    if (a.err) {
      window.alert('알람 생성 중 에러가 발생했습니다.\n' + a.err);
      return false;
    } else {
      console.log('알람생성 성공', a);
      return true;
    }
  };

  _send = async () => {
    const targetUserIds = this.getTargetUserIds();
    if (targetUserIds.length < 1) return window.alert('타겟 유저가 설정되지 않았습니다.');
    const a = window.confirm(`${targetUserIds.length}명의 유저에게 보내시겠습니까?`);
    if (!a) return;

    const b = await this._sendNotification(targetUserIds);

    if (!b) return;

    const c = await this._sendPushNotification(targetUserIds);

    if (!c) return;

    window.alert('완료되었습니다');

  };

  getTargetUserIds = () => {
    const targetUserIds = [];
    if (this.state.toParticipants) targetUserIds.push(...this.props.bookClubData.participantIds);
    if (this.state.toAlarmUsers) targetUserIds.push(...this.props.bookClubData.alarmUserIds);
    if (this.state.toDenied) targetUserIds.push(...this.props.bookClubData.deniedIds);
    if (this.state.toApplicants) targetUserIds.push(...this.props.bookClubData.applicantIds);

    return targetUserIds
  };

  render() {
    const {pushNotification, notification} = this.state;
    const {bookClubData} = this.props;
    return (
      <Fragment>
        <Button
          content={'푸시 보내기'}
          onClick={() => {
            this.setState({modalOpen: true})
          }}/>
        <Modal open={this.state.modalOpen}>
          <Header content={'전체 푸시 보내기'}/>
          <Modal.Content>
            <Message warning>
              <Message.Content>
                notification 종류를 선택할 때 조심!
              </Message.Content>
            </Message>
            <Form>
              <Form.Dropdown
                width={16}
                selection
                search
                value={pushNotification.data && pushNotification.data.type}
                placeholder={'PUSH NOTIFICATION TYPE'}
                options={getPushNotificationDropDownList()}
                onChange={(e, {value}) => {
                  pushNotification.data.type = value;
                  notification.type = value;
                  this.setState({pushNotification, notification})
                }}
              />
              <Form.Radio
                toggle
                checked={this.state.push}
                label={'푸시알림도 보내기'}
                onChange={() => {
                  this.setState({push: !this.state.push})
                }}
              />
              <Form.Radio
                toggle
                checked={this.state.pushSoundOn}
                label={'푸시 보낼 때, 소리 켜기'}
                onChange={() => {
                  this.setState({push: !this.state.pushSoundOn})
                }}
              />
              <Divider/>
              <Form.Group>
                <Form.Field>
                  <Checkbox
                    checked={this.state.toParticipants}
                    label={'참여자'}
                    onChange={(e, {value}) => {
                      this.setState({toParticipants: !this.state.toParticipants})
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    checked={this.state.toApplicants}
                    label={'지원자'}
                    onChange={(e, {value}) => {
                      this.setState({toApplicants: !this.state.toApplicants})
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    checked={this.state.toDenied}
                    label={'알람 유저'}
                    onChange={(e, {value}) => {
                      this.setState({toDenied: !this.state.toDenied})
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    checked={this.state.toAlarmUsers}
                    label={'거부한 사람'}
                    onChange={(e, {value}) => {
                      this.setState({toAlarmUsers: !this.state.toAlarmUsers})
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Divider/>
              <Form.Input
                disabled={!this.state.push}
                label={'push Title(선택)'}
                value={pushNotification.notification.title}
                onChange={(e, {value}) => {
                  pushNotification.notification.title = value;
                  this.setState({
                    pushNotification,
                  })
                }}
              />
              <Form.TextArea
                disabled={!this.state.push}
                label={'push body(필수)'}
                value={pushNotification.notification.body}
                onChange={(e, {value}) => {
                  pushNotification.notification.body = value;
                  this.setState({
                    pushNotification,
                  })
                }}
              />
              <Divider/>
              <Form.TextArea
                label={'Notification content(필수)'}
                value={notification.content}
                onChange={(e, {value}) => {
                  notification.content = value;
                  this.setState({
                    notification,
                  })
                }}
              />
            </Form>
          </Modal.Content>
          <Divider/>
          <Modal.Content>
            <Form>
              <Form.Group>
                <Form.Input
                  disabled={notification.type !== NOTIFICATION_TYPE_SCRAP}
                  label={'scrapId'}
                  value={pushNotification.notification.scrapId}
                  onChange={(e, {value}) => {
                    pushNotification.data.scrapId = value;
                    notification.scrapId = value;
                    this.setState({pushNotification, notification})
                  }}
                />
                <Form.Input
                  disabled={notification.type !== NOTIFICATION_TYPE_ESSAY}
                  label={'essayId'}
                  value={pushNotification.notification.essayId}
                  onChange={(e, {value}) => {
                    pushNotification.data.essayId = value;
                    notification.essayId = value;
                    this.setState({pushNotification, notification})
                  }}
                />
                <Form.Input
                  disabled={notification.type !== NOTIFICATION_TYPE_STORY}
                  label={'storyId'}
                  value={pushNotification.notification.storyId}
                  onChange={(e, {value}) => {
                    pushNotification.data.storyId = value;
                    notification.storyId = value;
                    this.setState({pushNotification, notification})
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Content>
          <Divider/>
          <Modal.Content>
            <Image src={notification.image} size={'tiny'}/>
            <Button as={'label'} size={'mini'}>
              이미지 바꾸기
              <FileUploader
                hidden
                accept="image/*"
                name="square"
                randomizeFilename
                storageRef={firebase.storage().ref(storageRef)}
                onUploadSuccess={filename => {
                  firebase.storage()
                    .ref(storageRef)
                    .child(filename)
                    .getDownloadURL()
                    .then(imageUrl => {
                      notification.image = imageUrl;
                      this.setState({notification: notification})
                    });
                }}
              />
            </Button>
            <Button
              size={'mini'}
              content={'이미지 초기화'}
              disabled={notification.image === bookClubData.coverImageUrl}
              onClick={() => {
                notification.image = bookClubData.coverImageUrl;
                this.setState({
                  notification,
                })
              }}
            />
            <Header content={'푸시 알람 메시지'}/>
            <p>
              <b> {this.state.pushNotification.notification.title}</b> <br/>
              {this.state.pushNotification.notification.body}
            </p>
            <Header content={'알람 메시지'}/>
            <p>
              {this.state.notification.content}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              content={'보내기'}
              onClick={this._send}
            />
            <Button
              negative
              content={'닫기(취소)'}
              onClick={() => {
                this.setState({modalOpen: false})
              }}
            />
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(BookClubPushNotificationComponent);