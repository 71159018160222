import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {Loader, Container, Image, Header, Button, Icon, Divider, Segment, Comment, Form} from 'semantic-ui-react';
import Network from "../../library/Network";
import UserComponent from "../../components/user/UserComponent";
import moment from 'moment';
import {removeHtmlTagWithLineChange, withLineEnter} from "../../middleware/TextHandler";
import {NEW_ESSAY_COMMENT} from "../../dataStructure/essayComment";
import _ from 'lodash';
import {db, firebase, firebaseDB} from "../../constants/firebase";
import EssayCommentComponent from '../../components/essayComment/EssayCommentComponent';
import uuid from 'uuid';
import Colors from "../../constants/Colors";
import NetworkApiV1 from "../../library/NetworkApiV1";

class MyBookClubEssayScheduleDetailContainer extends Component {


  constructor(props) {
    super(props);
    this.state = {
      essays: [],
      essayComments: [],

      uploadingComment: false,

      newComment: '',


      bookClubEssaySchedule: {},
      isLoaded: false,
      selectedEssayIndex: -1,

    }
  }


  componentDidMount() {
    this._loadBookClubEssaySchedule();
  }

  _loadBookClubEssaySchedule = async () => {
    const {bookClubEssayScheduleId} = this.props.match.params;

    const res = await NetworkApiV1.getBookClubEssayScheduleAndEssays(bookClubEssayScheduleId);
    if (!res.err) {
      if (!this.state.isLoaded) {
        this.setState({selectedEssayIndex: res.submitted ? 0 : -1,})
      }
      this.setState({
        bookClubEssaySchedule: res,
        isLoaded: true,
      });
    }
  };

  _loadEssayComments = async (essayId) => {
    // const essayComments = await Network.getEssayCommentsByEssayId(essayId);
    // if (essayComments.err) return;
    // this.setState({essayComments,})
  };


  _handleSelectUser = (index) => {
    const {bookClubEssaySchedule} = this.state;
    if (bookClubEssaySchedule.submitted) {
      this.setState({selectedEssayIndex: index,});
      // this._loadSelectedEssayUserInfo(essay.userId);
      window.scrollTo(0, 0);
    } else {
      window.alert('에세이를 작성해 주세요.')
    }
  };

  _handleChangeEssay = essay => {
    this.setState({
      selectedEssay: essay,
      essayComments: [],
      selectedEssayUser: {},
    });
    this._loadEssayComments(essay.id);
    this._loadSelectedEssayUser(essay.userId);
  };
  _loadSelectedEssayUser = async (userId) => {
    const selectedEssayUser = await Network.getUserInfoByUserId(userId);
    if (selectedEssayUser.err) return;
    this.setState({
      selectedEssayUser,
    })
  };

  _goToModify = () => {
    const {bookClubEssayScheduleId} = this.props.match.params;
    const {bookClubEssaySchedule, selectedEssayIndex} = this.state;
    this.props.history.push({
      pathname: `/my/bookclub-essay/write`,
      state: {
        bookClubEssayScheduleId,
        bookId: bookClubEssaySchedule.bookId,
        essayId: bookClubEssaySchedule.essays[selectedEssayIndex].id
      }
    })
  };

  render() {
    const {essays, isLoaded, essayComments, bookClubEssaySchedule, selectedEssayIndex} = this.state;
    if (!isLoaded) return <Loader active/>;

    const selectedEssay = selectedEssayIndex > -1 ? bookClubEssaySchedule.essays[selectedEssayIndex] : {user: {}};

    return (
      <div>
        <Container text as={Segment} vertical basic>
          {
            bookClubEssaySchedule.essays.map((essay, i) => {
              const selected = this.state.selectedEssayIndex === i;
              return (
                <Image
                  key={i}
                  onClick={() => {
                    console.log('2');
                    this._handleSelectUser(i);
                  }}
                  avatar
                  style={{
                    border: selected ? `2px solid ${Colors.mainColor}` : '',
                    // borderWidth: this.props.selected ? '1px' : '0px',
                    // borderColor: this.props.selected ? Colors.mainColor : 'transparent',
                  }}
                  src={essay.user.photoUrl}
                />
              )

            })
          }
        </Container>

        {
          (selectedEssayIndex > -1) && (
            <Fragment>
              <Container text as={Segment} vertical basic
                         style={{marginTop: '2rem'}}>
                <Comment.Group size={'large'}>
                  <Comment>
                    <Comment.Avatar as='a'
                                    src={selectedEssay.user.photoUrl}/>
                    <Comment.Content>
                      <Comment.Author as='a'>
                        {selectedEssay.user.displayName}
                      </Comment.Author>
                      <Comment.Text>
                        {moment(selectedEssay.createdAt).format('YYYY. MM. DD(ddd) HH:mm:ss')}
                      </Comment.Text>
                    </Comment.Content>
                  </Comment>
                </Comment.Group>
              </Container>
              <Container text as={Segment} vertical
                         basic
                         style={{lineHeight: '2rem', marginTop: '2rem'}}
                         key={selectedEssay.id}>
                <Header content={selectedEssay.title}/>
                <div>
                  {/*<Button
                    onClick={this._handleLikeToggle}
                    basic
                    style={{
                      fontWeight: '900',
                    }}
                  >
                    <Icon name='heart outline'
                          color={(selectedEssay.likeUserIds && selectedEssay.likeUserIds.includes(this.props.auth.user.id)) ? 'red' : 'grey'}
                    />
                    {selectedEssay.numberOfLikes}
                  </Button>*/}
                  {
                    selectedEssay.userId === this.props.auth.user.id && (
                      <Button
                        onClick={this._goToModify}
                        basic
                        style={{
                          fontWeight: '900'
                        }}
                      >
                        수정하기
                      </Button>
                    )
                  }
                </div>
                <Divider hidden/>
                {withLineEnter(selectedEssay.content)}
              </Container>
            </Fragment>
          )
        }


       {/* <Container text as={Segment} vertical
                   style={{marginTop: '2rem'}}>

          <Comment.Group>
            {
              essayComments.map((comment, i) => {
                return (
                  <EssayCommentComponent
                    showsName
                    essayComment={comment}
                    reportComment={() => {
                      this._reportComment(comment);
                    }}
                    removeComment={() => {
                      this._removeComment(comment);
                    }}
                    key={i}
                  />
                )

              })
            }
            <Form reply>
              <Form.TextArea
                placeholder="댓글을 달아보세요"
                rows={10}
                value={this.state.newComment}
                onChange={(e, {value}) => {
                  this.setState({
                    newComment: value
                  })
                }}/>
              <Button content={'댓글 달기'}
                      active={this.state.uploadingComment}
                      disabled={this.state.uploadingComment}
                      labelPosition={'left'}
                      icon={'edit'}
                      onClick={e => this.addComment(e)}
                      primary/>
            </Form>
          </Comment.Group>
        </Container>*/}
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(MyBookClubEssayScheduleDetailContainer);