import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from '../../redux/auth/authStore';
import {Link} from 'react-router-dom';
import {Table, Segment, Button, Container, Label, Header} from 'semantic-ui-react';
import {db} from '../../constants/firebase';
import moment from 'moment'
import Network from "../../library/Network";


class AdminCouponMainContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      coupons: [],
    }
  }

  componentDidMount() {

  }


  _loadCouponData = async () => {

  };

  render() {
    const {coupons, couponCodes} = this.props.admin;
    return (
      <Container as={Segment} vertical>
        <Header>쿠폰 어드민페이지</Header>

        <Button as={Link} to={'/admin/coupon/make'}>
          쿠폰 발행하기
        </Button>

        <Table compact size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No</Table.HeaderCell>
              <Table.HeaderCell>이름</Table.HeaderCell>
              <Table.HeaderCell>쿠폰코드 ID</Table.HeaderCell>
              <Table.HeaderCell>쿠폰 생성일</Table.HeaderCell>
              <Table.HeaderCell>쿠폰 만료일</Table.HeaderCell>
              <Table.HeaderCell>사용여부</Table.HeaderCell>
              <Table.HeaderCell>버튼</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              coupons.map((coupon, i) => {
                const couponCode = couponCodes.find(code=>code.id === coupon.couponCodeId);
                return (
                  <Table.Row key={i}>
                    <Table.Cell>{i}</Table.Cell>
                    <Table.Cell>
                      <Label>
                      {couponCode && couponCode.name}<br/>
                      </Label>
                      {coupon.couponCodeId}
                      </Table.Cell>
                    <Table.Cell>{coupon.id}</Table.Cell>
                    {/*<Table.Cell>{coupon.discountAmountOrRate}</Table.Cell>*/}
                    <Table.Cell>{moment(coupon.createdAt.seconds * 1000).format('YYYY.MM.DD')}</Table.Cell>
                    <Table.Cell>{moment(couponCode && couponCode.expireDate.seconds * 1000).format('YYYY.MM.DD')}</Table.Cell>
                    <Table.Cell>
                      {coupon.isUsed? '사용':''}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        size={'mini'}
                        compact
                        content={'상세보기'}
                        as={Link} to={{pathname: `/admin/coupon/detail/${coupon.id}`, state: {coupon: coupon}}}/>
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);
export default enhance(AdminCouponMainContainer);