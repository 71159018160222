import axios from 'axios';
import {kakaoAPIKey} from "../config/keys";



export const kakaoMapSearch = async (query, page = 1) => {
  return axios
    .get('https://dapi.kakao.com//v2/local/search/keyword.json', {
      headers: {
        Authorization: kakaoAPIKey,
      },
      params: {
        query,
        page
      }
    })
}