import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";

class AdminInquiryDetailContainer extends Component {

  render() {
    return (
      <div>
        AdminInquiryDetailContainer
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(AdminInquiryDetailContainer);