import axios from 'axios';
import {auth, db, firebase,} from "../constants/firebase";


const defaultFirebaseUid = '';
const defaultAuthorization = '';
// const API = 'http://localhost:5000/readers-prod/us-central1/apiV1';
// const API = 'https://us-central1-readers-prod.cloudfunctions.net/apiV1';

let devMode = false;
devMode = true;
let API = 'http://localhost:5000/readers-prod/asia-northeast1/apiV1';

API = 'https://asia-northeast1-readers-prod.cloudfunctions.net/apiV1';


class NetworkAPI_V1 {

  constructor() {
    this.headers = {
      firebaseUid: auth.currentUser ? auth.currentUser.uid : '',//defaultFirebaseUid,
      Authorization: defaultAuthorization,
    }
  }

  setFirebaseUid(uid) {
    this.headers['firebaseUid'] = uid || defaultFirebaseUid;
    console.log('firebaseUid :', this.headers['firebaseUid']);
  }

  getFirebaseUid(uid) {
    return this.headers['firebaseUid'];
  }

  onError(e, url, method, data) {
    let errorMessage = '오류가 발생하였습니다.\n고객센터에 문의해주세요';
    if (e.response) {
      devMode && console.log(`[RESPONSE] ${url} ${e.response.status}`, e.response.data);

      if (e.response.data && e.response.data.userMessage) {
        errorMessage = e.response.data.userMessage;
      }
    } else {
      devMode && console.log('[RESPONSE]', url, e);
    }

    // try {
    //   // db.collection('error')
    //   //   .add({
    //   //     firebaseUid: this.headers['firebaseUid'],
    //   //     rawMessage: e.message,
    //   //     userMessage: errorMessage,
    //   //   })
    // } catch (e) {
    //   console.log(e);
    // }

    window.alert(errorMessage);
    return {
      err: true,
      errorMessage,
    }
  }

  _sendRequest(url, params, method, headers) {
    devMode && console.log('[Request]', method.toUpperCase(), url, params);

    return axios[method](API + url, {headers: headers || this.headers, params}).then(response => {
      devMode && console.log('[Response]', method.toUpperCase(), url, response.data);

      return response.data;
    }).catch(e => this.onError(e, url, method, params));
  }

  // post, put
  _sendRequestForData(url, data, method, headers) {
    devMode && console.log('[Request]', method.toUpperCase(), url, data);

    return axios[method](API + url, data, {headers: {...this.headers, ...headers}}).then(response => {
      devMode && console.log('[Response]', method.toUpperCase(), url, response.data);

      return response.data;
    }).catch(e => this.onError(e, url, method, data));
  }

  _get = (url, params, headers) => this._sendRequest(url, params, 'get', headers);
  _post = (url, data) => this._sendRequestForData(url, data, 'post');

  _delete = (url, params) => this._sendRequest(url, params, 'delete');
  _put = (url, data, headers) => this._sendRequestForData(url, data, 'put', headers);

  // 회원 가입 절차
  checkEmailExist = (email) => this._post('/auth/check-email', {email});
  checkDisplayNameExist = (displayName) => this._post('/auth/check-display-name', {displayName});
  signUpByEmail = (email, password, displayName) => this._post('/auth/sign-up/email', {email, password, displayName});
  signUpByAuth = (token, provider, displayName) => this._post('/auth/sign-up/auth', {token, provider, displayName});
  signInByAuth = (token, provider) => this._post('/auth/sign-in/auth', {token, provider});
  loginWithKakao = (kakaoToken) => this._post('/auth/kakao/verify-token', {token: kakaoToken});

  findEmail = (email) => this._post('/auth/password-reset', {email});

  // 나의 정보 Auth
  getMyUserInfo = () => this._get('/user/my');
  updateMyInfo = (data) => this._put('/user/my', data);
  closeMyAccount = () => this._delete('/user/my');
  checkDetailInfoNeeded = () => this._get('/user/check-detail-info-needed');

  getMyFollow = () => this._get('/follow/my');
  postPushToken = (token) => this._post('/user/token', {token});
  deletePushToken = (token) => this._delete('/user/token', {token});

  // 유저
  getSearchUser = (displayName) => this._get('/user/search', {displayName});
  getUserInfo = (userId) => this._get('/user', {userId, showDetail: true});

  // FEED
  getInstantFeedUsers = () => this._get('/feed/instant-feed');
  getFeedFollower = (lastVisibleItemCreatedAt, limit) => this._get('/feed/scrap-follower', {
    lastVisibleItemCreatedAt,
    limit
  });
  getFeedPublic = (lastVisibleItemCreatedAt, limit) => this._get('/feed/scrap-public', {
    lastVisibleItemCreatedAt,
    limit
  });
  getFeedRecommend = (lastVisibleItemCreatedAt, limit) => this._get('/feed/scrap-recommend', {
    lastVisibleItemCreatedAt,
    limit
  });

  getScrapsInSpecificPeriod = (userId, startDate, endDate, detail = false) => this._get('/scrap/specific-period', {
    userId,
    startDate,
    endDate,
    detail,
  });
  getEssaysInSpecificPeriod = (userId, startDate, endDate, detail = false) => this._get('/essay/specific-period', {
    userId,
    startDate,
    endDate,
    detail,
  });

  // SCRAP
  getScrapById = (scrapId) => this._get('/scrap', {scrapId});
  postScrap = (data) => this._post('/scrap', data);
  updateScrap = (scrapId, updatedData) => this._put('/scrap', {scrapId, updatedData});
  deleteScrap = (scrapId) => this._delete('/scrap', {scrapId});

  getScrapsUser = (userId, lastVisibleItemCreatedAt, limit) => this._get('/scrap/user', {
    userId,
    lastVisibleItemCreatedAt,
    limit,
  });
  getScrapsUserByBookId = (userId, bookId, lastVisibleItemCreatedAt, limit) => this._get('/scrap/user/book', {
    userId,
    bookId,
    lastVisibleItemCreatedAt,
    limit
  });


  getScrapComments = (scrapId) => this._get('/scrap/comment', {scrapId});
  postScrapComment = (comment, scrapId, scrapCommentId) => this._post('/scrap/comment', {
    comment,
    scrapId,
    scrapCommentId
  });
  deleteScrapComment = (scrapCommentId) => this._delete('/scrap/comment', {scrapCommentId});
  reportScrapComment = (scrapCommentId) => this._delete('/scrap/comment/report', {scrapCommentId});

  getScrapLikeUsers = (scrapId) => this._get('/scrap/like', {scrapId});
  likeScrap = (scrapId) => this._post('/scrap/like', {scrapId});
  unlikeScrap = (scrapId) => this._delete('/scrap/like', {scrapId});

  reportScrap = (scrapId) => this._post('/scrap/report', {scrapId});
  readScrap = (scrapId, startTime, endTime, milliseconds) => axios.post(API + '/scrap/read', {
    scrapId,
    startTime,
    endTime,
    milliseconds
  }, {
    headers: this.headers
  });
  readScrapImage = (scrapImageId, startTime, endTime, milliseconds) => axios.post(API + '/scrap/image/read', {
    scrapImageId,
    startTime,
    endTime,
    milliseconds
  }, {
    headers: this.headers
  }).then(response => response.data).catch(e => console.log(e));


  // ESSAY
  getEssayById = (id) => this._get('/essay', {id});
  postEssay = (data) => this._post('/essay', data);
  updateEssay = (essayId, updatedData) => this._put('/essay', {essayId, updatedData});
  deleteEssay = (essayId) => this._delete('/essay', {essayId});

  getEssaysUser = (userId, lastVisibleItemCreatedAt, limit, showsDetail = true) => this._get('/essay/user', {
    userId,
    lastVisibleItemCreatedAt,
    limit,
    showsDetail
  });

  getEssaysMyByBookId = (bookId) => this._get('/essay/my/book', {bookId});
  getEssaysUserByBookId = (userId, bookId) => this._get('/essay/user/book', {userId, bookId});


  getEssayComments = (essayId) => this._get('/essay/comment', {essayId});
  postEssayComment = (comment, essayId, essayCommentId) => this._post('/essay/comment', {
    comment,
    essayId,
    essayCommentId
  });
  deleteEssayComment = (essayCommentId) => this._delete('/essay/comment', {essayCommentId});
  reportEssayComment = (essayCommentId) => this._post('/essay/comment/report', {essayCommentId})

  getEssayLikeUsers = (essayId) => this._get('/essay/like', {essayId});
  likeEssay = (essayId) => this._post('/essay/like', {essayId});
  unLikeEssay = (essayId) => this._delete('/essay/like', {essayId});

  reportEssay = (essayId) => this._post('/essay/report', {essayId});


  // bookmark
  addBookmark = (itemId, itemType) => this._post('/bookmark', {itemId, itemType});
  cancelBookMark = (itemId, itemType) => this._delete('/bookmark', {itemId, itemType});


  // BOOK
  getSearchBookByKeyword = (keyword) => this._get('/book/search', {keyword});
  getUserBooks = (userId) => this._get('/book/user', {userId});
  getUserBooksOnlyHasScrap = (userId) => this._get('/book/has-scrap', {userId});
  getUserBooksOnlyHasEssay = (userId) => this._get('/book/has-essay', {userId});

  getMyBooks = () => this._get('/book/my',);


  getBookUserList = (bookId, filter) => this._get('/book/user-list', {bookId, filter});
  getReviews = (bookId, filter) => this._get('/book/review', {bookId, filter});

  getBooksByUserIdAndCategoryGroupId = (userId, bookCategoryGroupId) => this._get('/book/user/book-category-group', {
    userId,
    bookCategoryGroupId
  });

  getBooksReadingNowByUserId = (userId) => this._get('/book/now-reading', {userId});
  getBooksMyPickByUserId = (userId) => this._get('/book/my-pick', {userId});

  addBookToList = () => this._post('/book');
  getRelationUserBook = (bookId) => this._get('/book/relation-user-book', {bookId});
  updateRelationUserBook = (bookId, updatedData) => this._put('/book/my', {bookId, updatedData});
  deleteRelationUserBook = (bookId) => this._delete('/book/my', {bookId});

  getBookDetail = (isbn, bookId) => this._get('/book', {isbn, bookId});


  // FOLLOW
  getFollowerList = (userId) => this._get('/follow/list/follower', {userId});
  getFollowingList = (userId) => this._get('/follow/list/following', {userId});
  requestFollow = (targetUserId) => this._post('/follow', {targetUserId});
  cancelFollow = (targetUserId) => this._delete('/follow', {targetUserId});
  approveFollower = (targetUserId) => this._put('/follow/approve', {targetUserId});
  removeFollower = (targetUserId) => this._put('/follow/remove', {targetUserId});

  getFollowersHavingEssayByIsbn = (isbn) => this._get('/follow/essay/isbn', {isbn});
  getFollowersHavingScrapByIsbn = (isbn) => this._get('/follow/scrap/isbn', {isbn});
  getFollowersHavingEssayByBookId = (bookId) => this._get('/follow/essay/book', {bookId});
  getFollowersHavingScrapByBookId = (bookId) => this._get('/follow/scrap/book', {bookId});

  // BOOKSHELF
  getUserBookshelves = (userId) => this._get('/bookshelf/user', {userId});
  getBookshelf = (bookshelfId) => this._get('/bookshelf', {bookshelfId});
  makeNewBookshelf = (bookshelf) => this._post('/bookshelf', bookshelf);
  updateOneBookshelf = (bookshelf) => this._put('/bookshelf', bookshelf);
  deleteBookshelf = (bookshelfId) => this._delete('/bookshelf', {bookshelfId});

  geBookshelfListAndCheckBookIncluded = (bookId) => this._get('/bookshelf/book/check', {bookId});
  addBookToBookshelf = (bookId, bookshelfId) => this._post('/bookshelf/book', {bookId, bookshelfId});
  removeBookFromBookshelf = (bookId, bookshelfId) => this._delete('/bookshelf/book', {bookId, bookshelfId});


  // BOOKMARK
  getBookmarkMy = (lastVisibleItemCreatedAt, limit) => this._get('/bookmark/my', {lastVisibleItemCreatedAt, limit});


  // BOOK CLUB - 전체
  getBookClubAllSearchable = () => this._get('/book-club/list/searchable');

  // BOOK CLUB - MY
  getBookClubMyApproved = () => this._get('/book-club/my/approved');
  getBookClubMyBeforeApproved = () => this._get('/book-club/my/before-approval');
  getBookClubMyDenied = () => this._get('/book-club/my/denied');

  // BOOK CLUB - 하나
  getBookClubById = (bookClubId) => this._get('/book-club', {bookClubId});
  getBookClubDetailMembers = (bookClubId) => this._get('/book-club/detail/member', {bookClubId});
  getBookClubDetailEssaySchedule = (bookClubId) => this._get('/book-club/detail/essay-schedule', {bookClubId});
  getBookClubDetailScrap = (bookClubId) => this._get('/book-club/detail/scrap', {bookClubId});
  getScrapsAboutOneBookInBookClub = (bookClubId, bookId, lastVisibleItemCreatedAt, limit, userId,) => this._get('/book-club/scrap/book', {
    bookClubId,
    bookId,
    userId,
    lastVisibleItemCreatedAt,
    limit
  });
  getUserListUploadedScrapsAboutABookByBookIdInBookClub = (bookClubId, bookId) => this._get('/book-club/scrap/book/user', {
    bookClubId, bookId
  });

  getBookClubEssayScheduleAndEssays = (bookClubEssayScheduleId) => this._get('/book-club/essay-schedule', {bookClubEssayScheduleId});

  // 북클럽 신청 관련
  applyBookClub = (data) => this._post('/book-club/apply', data);
  cancelRelationUserBookClub = (relationUserBookClubId) => this._delete('/book-club/apply', {relationUserBookClubId});
  updateRelationUserBookClub = (relationUserBookClubId, updatedData) => this._put('/book-club/apply', {
    relationUserBookClubId,
    updatedData
  });

  // Coupon
  getMyCoupon = () => this._get('/coupon/my');
  requestCouponByCode = (code) => this._get('/coupon/code', {code});

  // INQUIRY
  getMyInquiries = () => this._get('/inquiry/my');
  getInquiryById = (inquiryId) => this._get('/inquiry', {inquiryId});
  postNewInquiry = (data) => this._post('/inquiry', data);


  // STORY
  getStories = (lastVisibleOrder) => this._get('/story/list', {lastVisibleOrder});
  getStoryById = (storyId) => this._get('/story', {storyId});

  postStoryRead = (storyId, startTime, endTime, milliseconds) => this._post('/story/read', {
    storyId,
    startTime,
    endTime,
    milliseconds
  });

  getStoryLikeUser = (storyId) => this._get('/story/like', {storyId});
  likeStory = (storyId) => this._post('/story/like', {storyId});
  unLikeStory = (storyId) => this._delete('/story/like', {storyId});

  getStoryComments = (storyId) => this._get('/story/comment', {storyId});
  postStoryComment = (comment, storyId, storyCommentId) => this._post('/story/comment', {
    comment,
    storyId,
    storyCommentId
  });
  deleteStoryComment = (storyCommentId) => this._delete('/story/comment', {storyCommentId});
  reportStoryComment = (storyCommentId) => this._post('/story/comment/report', {storyCommentId});


  // TRANSACTION
  requestWithdraw = (cash, bankAccount, bankName) => this._post('/account/withdraw', {cash, bankAccount, bankName});
  getTransactions = (lastVisibleItemCreatedAt, limit) => this._get('/transaction/my', {lastVisibleItemCreatedAt, limit})

  // LEADER
  getLeader = (leaderId) => this._get('/leader', {leaderId});


  // NOTICE
  getNoticeList = (lastVisibleOrder, limit) => this._get('/notice/list', {lastVisibleOrder, limit});
  getNoticeById = (noticeId) => this._get('/notice', {noticeId});
  postNoticeRead = (noticeId, startTime, endTime, milliseconds, isIgnored) => axios.post(API + '/notice/read', {
    noticeId,
    startTime,
    endTime,
    milliseconds,
    isIgnored
  }, {
    headers: this.headers
  });
  getNoticePopUp = () => this._get('/notice/pop-up');


  // UPDATE REQUEST
  getUpdateRequestListByStatus = (status) => this._get('/update-request/list', {status});
  getUpdateRequestById = (updateRequestId) => this._get('/update-request', {updateRequestId});
  postUpdateRequest = (title, content) => this._post('/update-request', {title, content});

  postUpdateRequestComment = (comment, updateRequestId, updateRequestCommentId) => this._post('/update-request/comment', {
    comment,
    updateRequestId,
    updateRequestCommentId
  });
  deleteUpdateRequestComment = (updateRequestCommentId) => this._delete('/update-request/comment', {updateRequestCommentId});
  reportUpdateRequestComment = (updateRequestCommentId) => this._post('/update-request/comment/report', {updateRequestCommentId});

  likeUpdateRequest = (updateRequestId) => this._post('/update-request/like', {updateRequestId});
  unLikeUpdateRequest = (updateRequestId) => this._delete('/update-request/like', {updateRequestId});


  // JOB CATEGORY
  getJobCategory = () => this._get('/job-category/list');

  // interest
  getInterestCategory = () => this._get('/interest-category/list');
  addInterestCategory = (interestCategoryId) => this._post('/interest-category', {interestCategoryId});
  removeInterestCategory = (interestCategoryId) => this._delete('/interest-category', {interestCategoryId});


  // analysis
  getCategoryAnalysis = (userId) => this._get('/user/category-analysis', {userId});
  getCategoryAnalysisDetail = (userId, requestUserId) => this._get('/user/category-analysis/detail', {
    userId,
    requestUserId
  });

  // monthly ReadHistoryDone


  // read History
  getReadHistories = (bookId, userId) => this._get('/read-history', {bookId});
  updateReadHistory = (readHistoryId, updatedData) => this._put('/read-history', {readHistoryId, updatedData});
  postReadHistory = (bookId, startDate, endDate) => this._post('/read-history', {bookId}, {bookId, startDate, endDate});
  deleteReadHistory = (readHistoryId) => this._delete('/read-history', {readHistoryId});

  getReadHistoryYear = (userId, selectedYear) => this._get('/read-history/user/year', {userId, selectedYear});


  // PARTNER
  getPartner = (partnerId) => this._get('/partner', {partnerId});
  getPartnerList = () => this._get('/partner/list',);


  // NOTIFICATION
  getNotificationList = (lastVisibleItemCreatedAt, limit) => this._get('/notification/list', {
    lastVisibleItemCreatedAt,
    limit
  });
  getNotification = (notificationId,) => this._get('/notification', {notificationId});
  postNotificationReadAll = () => this._post('/notification/read-all');
  postNotificationClickAll = () => this._post('/notification/click-all');


  // SEARCH
  getSearch = (keyword, filter) => this._get('/search', {keyword, filter});
  getSearchRecommend = (selectedFilter) => this._get('/search/recommend', {selectedFilter});


  // 어디 쓰이는 지 아직 모르는
  getFollowersRecentScraps = (offset, limit) => this._get('/scrap/follower', {offset, limit});


  // PUBLIC
  getPublicBookClubList = (status) => this._get('/public/book-club/list', {status});
  getPublicBookClub = (bookClubId) => this._get('/public/book-club', {bookClubId});
}

export default new NetworkAPI_V1();
