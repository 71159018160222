import React from 'react';
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps"

const MyMapComponent = (props) => (
  <div>
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{
        lat: 37.486956,
        lng: 127.053035,
      }}
      center={props.location && props.location.latitude > 0 ? {
        lat: Number(props.location.latitude),
        lng: Number(props.location.longitude),
      } : {
        lat: 37.486956,
        lng: 127.053035,
      }}
    >
      {
        props.location && (
          <Marker
            position={{
              lat: Number(props.location && props.location.latitude),
              lng: Number(props.location && props.location.longitude)
            }}/>
        )
      }
    </GoogleMap>
  </div>
);


export default withScriptjs(withGoogleMap(MyMapComponent));