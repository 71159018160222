import React, {Component, Fragment} from 'react';
import {Button, Modal, Header, Image} from 'semantic-ui-react';
import {withLineEnter} from "../../middleware/TextHandler";
import AdminUserComponent from '../../components/user/AdminUserComponent';
import moment from 'moment';

class AdminEssayButtonComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    }
  }

  openModal = () => {
    this.setState({modalOpen: true})
  }

  closeModal = () => {
    this.setState({modalOpen: false})
  }

  render() {

    const {essay, user} = this.props;

    return (
      <Fragment>
        <Button content={'에세이 보기'} onClick={this.openModal} size={'mini'}/>

        <Modal open={this.state.modalOpen}>
          <Header content={essay.title}/>
          <Modal.Content>
            <AdminUserComponent user={user}/> <br/>
            <p>{moment(essay.createdAt.seconds * 1000).format('YYYY.MM.DD HH:mm:ss')}</p>
            {withLineEnter(essay.content)}
          </Modal.Content>
          <Modal.Actions>
            <Button content={'닫기'} onClick={this.closeModal}/>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}


export default AdminEssayButtonComponent;
