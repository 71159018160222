import Colors from "../constants/Colors";
import {firebase} from "../constants/firebase";
import _ from 'lodash';

export const CONTENT_TYPE_TEXT = 'CONTENT_TYPE_TEXT';
export const CONTENT_TYPE_PHOTOS = 'CONTENT_TYPE_PHOTOS';
export const CONTENT_TYPE_MAPS = 'CONTENT_TYPE_MAPS';
export const CONTENT_TYPE_LEADERS = 'CONTENT_TYPE_LEADERS';
export const CONTENT_TYPE_USERS = 'CONTENT_TYPE_USERS';
export const CONTENT_TYPE_YOUTUBERS = 'CONTENT_TYPE_YOUTUBERS';
export const CONTENT_TYPE_PARTNERS = 'CONTENT_TYPE_PARTNERS';
export const CONTENT_TYPE_BOOK_CLUB = 'CONTENT_TYPE_BOOK_CLUB';
export const CONTENT_TYPE_BOOKS = 'CONTENT_TYPE_BOOKS';
export const CONTENT_TYPE_ESSAYS = 'CONTENT_TYPE_ESSAYS';
export const CONTENT_TYPE_SCRAPS = 'CONTENT_TYPE_SCRAPS';
export const CONTENT_TYPE_PLACES = 'CONTENT_TYPE_PLACES';
export const CONTENT_TYPE_PRODUCTS = 'CONTENT_TYPE_PRODUCTS';
export const CONTENT_TYPE_WEB_LINK = 'CONTENT_TYPE_WEB_LINK';
export const CONTENT_TYPE_STORY = 'CONTENT_TYPE_STORY';
export const CONTENT_TYPE_GAP = 'CONTENT_TYPE_GAP';
export const CONTENT_TYPE_QUOTE = 'CONTENT_TYPE_QUOTE';

export const contentTypeList = [
  CONTENT_TYPE_TEXT,
  CONTENT_TYPE_PHOTOS,
  CONTENT_TYPE_MAPS,
  CONTENT_TYPE_LEADERS,
  CONTENT_TYPE_USERS,
  CONTENT_TYPE_PARTNERS,
  CONTENT_TYPE_BOOK_CLUB,
  CONTENT_TYPE_BOOKS,
  CONTENT_TYPE_ESSAYS,
  CONTENT_TYPE_SCRAPS,
  CONTENT_TYPE_STORY,
  CONTENT_TYPE_WEB_LINK,
  CONTENT_TYPE_GAP,
  CONTENT_TYPE_QUOTE,

  CONTENT_TYPE_YOUTUBERS,
  CONTENT_TYPE_PLACES,
  CONTENT_TYPE_PRODUCTS,

];

export const contentTypeDropDownList = () => {
  return _.map(contentTypeList, (item, index) => {
    return {
      key: index,
      text: item,
      value: item,
    }
  })
};

export const CONTENT_VIEW_TYPE_BOOK_CLUB_DEFAULT = 'CONTENT_VIEW_TYPE_BOOK_CLUB_DEFAULT';
export const CONTENT_VIEW_TYPE_BOOK_CLUB_PADDING = 'CONTENT_VIEW_TYPE_BOOK_CLUB_PADDING';
export const CONTENT_VIEW_TYPE_BOOK_CLUB_COVER = 'CONTENT_VIEW_TYPE_BOOK_CLUB_COVER';
export const CONTENT_VIEW_TYPE_BOOK_CLUB_COVER_SHOW_BOOKS = 'CONTENT_VIEW_TYPE_BOOK_CLUB_COVER_SHOW_BOOKS';

export const contentViewTypeOfBookClub = [
  CONTENT_VIEW_TYPE_BOOK_CLUB_DEFAULT,
  CONTENT_VIEW_TYPE_BOOK_CLUB_PADDING,
  CONTENT_VIEW_TYPE_BOOK_CLUB_COVER,
  CONTENT_VIEW_TYPE_BOOK_CLUB_COVER_SHOW_BOOKS,
];

export const contentViewTypeOfBookClubDropDownList = () => {
  return _.map(contentViewTypeOfBookClub, (item, index) => {
    return {
      key: index,
      text: item,
      value: item,
    }
  })
};

export const horizontalGapDropDownList = () => {
  return [
    {
      key: 0,
      value: 0,
      text: '0(없음)'
    },
    {
      key: 0.2,
      value: 2,
      text: '2(아주약간)'
    },
    {
      key: 1,
      value: 10,
      text: '10(청록)'
    },
    {
      key: 2,
      value: 20,
      text: '20(보라)'
    },
    {
      key: 3,
      value: 25,
      text: '25(파랑)'
    },
    {
      key: 4,
      value: 55,
      text: '55(분홍)'
    },
    {
      key: 5,
      value: 80,
      text: '80'
    },
  ]
}

export const NEW_CONTENT_MODULE = (contentType) => {
  switch (contentType) {
    case CONTENT_TYPE_TEXT:
      return {
        contentType,
        text: 'TEXT',
        color: Colors.mainBlack,
        fontSize: 14,
        lineHeight: 25,
        textAlign: 'left',
        fontFamily: 'regular',
        bottomGap: 0,
      };
    case CONTENT_TYPE_PHOTOS:
      return {
        contentType,
        fullWidth: false,
        imageUrls: [],
        hasCaption: false,
        captions: [],
        imageRatio: 1,
        bottomGap: 0,
      };
    case CONTENT_TYPE_MAPS:
      return {
        contentType,
        locationCoordinate: new firebase.firestore.GeoPoint(37.4980854357918, 127.028000275071),
        locationName: '',
        address: '',
        phone: '',
        showMap: false,
        bottomGap: 0,
      };
    case CONTENT_TYPE_LEADERS:
      return {
        contentType,
        leaderIds: [],
        showRate: false,
        descriptionBottom: false,
        horizontal: false,

        hasButton: false,
        buttonText: '',
        buttonColor: Colors.mainBlack,
        bottomGap: 0,
      };
    case CONTENT_TYPE_USERS:
      return {
        contentType,
        userIds: [],
        descriptionBottom: false,
        horizontal: false,

        hasButton: false,
        buttonText: '',
        buttonColor: Colors.mainBlack,
        bottomGap: 0,
      };
    case CONTENT_TYPE_YOUTUBERS:
      return {
        contentType,
        youTuberIds: [],
        descriptionBottom: false,
        horizontal: false,

        hasButton: false,
        buttonText: '',
        buttonColor: Colors.mainBlack,
        bottomGap: 0,
      };
    case CONTENT_TYPE_PARTNERS:
      return {
        contentType,
        partnerIds: [],
        descriptionBottom: false,
        horizontal: false,

        hasButton: false,
        buttonText: '',
        buttonColor: Colors.mainBlack,
        bottomGap: 0,
      };
    case CONTENT_TYPE_BOOK_CLUB:
      return {
        contentType,
        bookClubIds: [],
        viewType: '',
        backgroundColor: 'rgba(0,0,0,0.5)',
        textColor: Colors.mainBlack,
        bottomGap: 0,
      };
    case CONTENT_TYPE_BOOKS:
      return {
        contentType,
        bookIds: [],
        horizontal: true,
        hasPadding: false,
        bookHeight: 100,
        bottomGap: 0,
      };
    case CONTENT_TYPE_ESSAYS :
      return {
        contentType,
        essayIds: [],
        showsBookInfo: false,
        hasPadding: false,
        bottomGap: 0,
      };
    case CONTENT_TYPE_SCRAPS:
      return {
        contentType,
        scrapIds: [],
        horizontal: false,
        showUserInfo: false,
        itemsPerRow: 2,
        bottomGap: 0,
      };
    case CONTENT_TYPE_PLACES:
      return {
        contentType,
        placeIds: [],
        bottomGap: 0,
      };
    case CONTENT_TYPE_PRODUCTS:
      return {
        contentType,
        productIds: [],
        bottomGap: 0,
      };
    case CONTENT_TYPE_WEB_LINK :
      return {
        contentType,
        uri: '',
        buttonText: '링크보기',
        buttonColor: Colors.mainBlack,
        fontSize: 14,
        fontFamily: 'bold',
        textAlign: 'left',
        showButton: true,
        bottomGap: 0,
      };
    case CONTENT_TYPE_STORY:
      return {
        contentType,
        storyIds: [],
        bottomGap: 0,
      };
    case CONTENT_TYPE_GAP:
      return {
        contentType,
        height: 25,
        bottomGap: 0,
      };
    case CONTENT_TYPE_QUOTE :
      return {
        contentType,
        text: '',
        textAlign: 'left',
        fontSize: 16,
        fontFamily: 'regular',
        color: Colors.mainBlack,

        topImageTintColor: Colors.mainBlack,
        topImageSize: 25,
        topImageAlign: 'center',
        topImageShow: true,

        bottomImageTintColor: Colors.mainBlack,
        bottomImageSize: 25,
        bottomImageAlign: 'center',
        bottomImageShow: true,
        bottomGap: 0,
      };
    default:
      return {
        contentType,
        bottomGap: 0,
      }
  }
};