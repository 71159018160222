import {createLogic} from 'redux-logic';
import {homeActionCreators, homeActions} from "./homeStore";
import Network, {PER_PATE_CURATION} from "../../library/Network";


const fetchCurationsLogic = createLogic({
  type: homeActions.FETCH_CURATIONS,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {

    const res = await Network.getCuration();
    if (!res.err) {
      const reachEnd = res.curations.length < PER_PATE_CURATION;
      dispatch(homeActionCreators.fetchCurationsSuccess(res.curations, res.curationLastVisible, reachEnd));

    } else {

    }
    if (action.cb) action.cb();

    done();
  }
});

const fetchCurationsMoreLogic = createLogic({
  type: homeActions.FETCH_CURATIONS_MORE,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const {curationLastVisible, curations, reachEnd} = getState().home;
    if (reachEnd) {

    } else {
      const res = await Network.getCurationMore(curationLastVisible);
      if (!res.err) {
        const reachEnd = res.curations.length < PER_PATE_CURATION;
        dispatch(homeActionCreators.fetchCurationsSuccess([...curations, ...res.curations], res.curationLastVisible, reachEnd))
      }
    }
    if (action.cb) action.cb();
    done();
  }
});


const refreshCurationsLogic = createLogic({
  type: homeActions.REFRESH_CURATIONS,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    dispatch(homeActionCreators.fetchCurations());

    done()
  }
});


export default [
  fetchCurationsLogic,
  fetchCurationsMoreLogic,
  refreshCurationsLogic,
]