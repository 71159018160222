import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {
  STORY_LIST_CONTENT_TYPE_BOOK,
  STORY_LIST_CONTENT_TYPE_BOOK_CLUB,
  STORY_LIST_CONTENT_TYPE_BOOK_CLUB_LEADER,
  STORY_LIST_CONTENT_TYPE_ESSAY_DIFFERENT_BOOK,
  STORY_LIST_CONTENT_TYPE_ESSAY_ONE_BOOK,
  STORY_LIST_CONTENT_TYPE_PLACE,
  STORY_LIST_CONTENT_TYPE_SCRAP_DIFFERENT_BOOK,
  STORY_LIST_CONTENT_TYPE_SCRAP_ONE_BOOK
} from "../../dataStructure/story";
import {Container, Segment, Card, Form, Button} from 'semantic-ui-react';
import _ from "lodash";

class ContentListItemComponent extends Component {
  render() {
    const {story} = this.props;

    switch (story.listContentType) {
      case STORY_LIST_CONTENT_TYPE_BOOK:
        return (
          <Segment>
            <Form>
              <Form.Dropdown
                multiple
                selection
                search
                value={story.listItems}
                placeholder={'listItems'}
                options={_.map(this.props.admin.adminBooks, (book, i) => ({
                  key: i,
                  text: book.title,
                  value: book.id
                }))}
                onChange={(e, {value}) => {
                  story.listItems = value;
                  this.props.onChangeState(story)
                }}
              />
            </Form>
          </Segment>
        );
      case STORY_LIST_CONTENT_TYPE_BOOK_CLUB:
        return (
          <Segment>
            <Form>
              <Form.Dropdown
                multiple
                selection
                search
                value={story.listItems}
                placeholder={'listItems'}
                options={_.map(this.props.admin.bookClubs, (bookClub, i) => ({
                  key: i,
                  text: bookClub.header.content,
                  value: bookClub.id
                }))}
                onChange={(e, {value}) => {
                  story.listItems = value;
                  this.props.onChangeState(story)
                }}
              />
            </Form>
          </Segment>
        );
      // case STORY_LIST_CONTENT_TYPE_BOOK_CLUB_LEADER:
      // case STORY_LIST_CONTENT_TYPE_ESSAY_DIFFERENT_BOOK:
      // case STORY_LIST_CONTENT_TYPE_ESSAY_ONE_BOOK:
      // case STORY_LIST_CONTENT_TYPE_PLACE:
      // case STORY_LIST_CONTENT_TYPE_SCRAP_ONE_BOOK:
      // case STORY_LIST_CONTENT_TYPE_SCRAP_DIFFERENT_BOOK:
      default:
        return (
          <div>
            ContentListItemComponent
          </div>
        )
    }
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(ContentListItemComponent);
// export default ContentListItemComponent;