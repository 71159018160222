import _ from "lodash";
import Colors from "../constants/Colors";

export const COUPON_TYPE_FIXED_RATE = 'COUPON_TYPE_FIXED_RATE';
export const COUPON_TYPE_FIXED_AMOUNT = 'COUPON_TYPE_FIXED_AMOUNT';

export const couponTypeList = [
  COUPON_TYPE_FIXED_RATE,
  COUPON_TYPE_FIXED_AMOUNT,
];

export const couponTypeDropDownList = () => {
  return _.map(couponTypeList, (status, index) => ({
    key: index,
    text: status,
    value: status,
  }));
};

export const NEW_COUPON = {

  userId: '',
  couponCodeId: null,

  isUsed: false,
  useDate: new Date(),
  expireDate: new Date(),

  modifiedAt: new Date(),
  createdAt: new Date(),

  isDeleted: false,
};


export const NEW_COUPON_CODE = {
  name: '',
  type: COUPON_TYPE_FIXED_RATE,
  discountAmountOrRate: 0.3,
  code: '',

  repeatPossible: false,

  imageUrl: '',
  image: {
    square: '',
    horizontal: '',
    vertical: '',

    squareLarge: '',
    horizontalLarge: '',
    verticalLarge: '',
  },
  backgroundImage: '',
  backgroundImageLarge: '',
  backgroundColor: 'white',
  nameColor: Colors.mainBlack,
  descriptionColor: Colors.mainBlack,
  textColor: Colors.mainBlack,

  tags: [],

  expireDate: new Date(),

  isDeleted: false,
  modifiedAt: new Date(),
  createdAt: new Date(),
};


