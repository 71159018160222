import React, {Component} from 'react';
import Analytics from "../../middleware/Analytics";

class AboutReadersContainer extends Component {

  componentDidMount() {
    Analytics.pageViewAll('/about/readers');
  }

  render() {
    return (
      <div>
        어바웃 리더스
      </div>
    );
  }
}


export default AboutReadersContainer;
