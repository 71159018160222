import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {authActionCreators} from "../../redux/auth/authStore";
import {
  Button,
  Grid,
  Segment,
  Loader,
  Container,
  Table,
  Divider,
  Message,
  Header,
  Icon,
  Form,
  Radio,
  Image,
  List
} from "semantic-ui-react";
import {db, storage} from "../../constants/firebase";
import {firebase} from "../../constants/firebase";
import FileUploader from "react-firebase-file-uploader";
import _ from 'lodash';
import {NEW_LEADER} from "../../dataStructure/leader";
import Network from "../../library/Network";
import moment from "moment";
import mockUpImages from "../../constants/mockUpImages";

const storageRef = `leader/${moment().format("YYYYMM")}`;


class AdminLeaderDetailContatiner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leader: {},
      isLoaded: false,

      searchUserValue: "",
      users: [],
      leaderUserInfo: {},

    };
  }


  componentDidMount() {
    this._loadLeader();
  }

  _loadLeader = async () => {
    const isNewLeader = this.props.match.params.leaderId === undefined;

    if (isNewLeader) {
      this.setState({
        isNewLeader,
        leader: _.cloneDeep(NEW_LEADER),
        isLoaded: true,
      })
    } else {
      const {leaderId} = this.props.match.params;
      const res = await Network.getLeaderByLeaderId(leaderId);
      if (res.err) return window.alert('리더 정보를 찾을 수 없습니다.');

      if (res.userId) {
        this._loadLeaderUserInfo(res.userId);
      }

      this.setState({
        isNewLeader,
        leader: Object.assign(_.cloneDeep(NEW_LEADER), res),
        isLoaded: true,
      })
    }
  };

  _loadLeaderUserInfo = async (userId) => {
    const res = await Network.getUserInfoByUserId(userId);
    if (res.err) return window.alert('리더의 유저 정보를 찾을 수 없습니다.');
    this.setState({
      leaderUserInfo: res
    })
  };


  handleChange = e => {
    const {leader} = this.state;
    const {name, value} = e.target;
    leader[name] = value;
    this.setState({
      leader
    });
  };

  handleSubmit = async () => {
    const confirm = window.confirm("업로드 하시겠습니까?");
    if (!confirm) return;

    const {leader} = this.state;
    console.log(leader);

    const isNewLeader = this.props.match.params.leaderId === undefined;

    let res;
    if (isNewLeader) {
      res = await Network.postLeader(leader);
      if (res.err) return window.alert('리더 생성에 실패하였습니다.');
      window.alert('완료');
      this.props.history.replace(`/admin/leader/read/${res.id}`)

    } else {
      res = await Network.updateLeader(leader);
      if (res.err) return window.alert('리더 수정에 오류가 발생하였습니다.');
      window.alert('완료');
    }



  };

  handleSearchUserByUserDisplayName = async () => {
    if (this.state.searchUserValue === '') return window.alert('검색어를 입력해 주세요');
    const res = await Network.getUserByUserDisplayName(this.state.searchUserValue);
    if (res.err) return window.alert('검색에 실패하였습니다.');
    this.setState({
      users: res
    })
  };


  handleUploadSuccess = async (filename, imageCategory) => {
    const {leader} = this.state;

    await firebase.storage()
      .ref(storageRef)
      .child(filename)
      .getDownloadURL()
      .then(imageUrl => {
        leader.image[imageCategory] = imageUrl;
        this.setState({leader})
      });
  };

  deleteLeader = async () => {
    const confirm = window.confirm("삭제하시겠습니까?");
    if (!confirm) return;

    const res = await Network.updateLeader(Object.assign(this.state.leader, {isDeleted: true}));

    if (res.err) return window.alert('삭제에 실패하였습니다.');

    window.alert('삭제되었습니다.');
    this.props.history.replace('/admin/leader');
  };

  restore = async () => {
    const confirm = window.confirm("복구 하시겠습니까?");
    if (!confirm) return;

    const res = await Network.updateLeader(Object.assign(this.state.leader, {isDeleted: false}));

    if (res.err) return window.alert('복구에 실패하였습니다.');

    window.alert('복구되었습니다.');
    this._loadLeader();

  };

  render() {
    const {leader, isLoaded, leaderUserInfo} = this.state;
    if (!isLoaded) {
      return (
        <Loader active={true}/>
      )
    }
    return (
      <Fragment>
        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={`${leader.name}리더 정보`} dividing/>
          <Button content={this.state.isNewLeader ? '만들기' : '수정하기'} primary onClick={this.handleSubmit}/>
          <Button content={'삭제'} negative disabled={this.state.isNewLeader || leader.isDeleted}
                  onClick={this.deleteLeader}/>
          <Button content={'복구'} positive disabled={!leader.isDeleted} onClick={this.restore}/>
        </Container>
        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'기본정보'} dividing/>
          <Form>
            <Form.Field>
              <Radio
                toggle
                label={leader.isPublic ? "공개" : "비공개"}
                checked={leader.isPublic}
                onClick={() => {
                  leader.isPublic = !leader.isPublic;
                  this.setState({
                    leader
                  });
                }}
                style={{margin: "10px 0"}}/>
            </Form.Field>
            <Form.Group widths={'equal'}>
              <Form.Input
                fluid
                label="Order"
                name="order"
                value={leader.order}
                onChange={this.handleChange}
              />
              <Form.Input
                disabled={true}
                fluid
                label="리더 ID"
                name="id"
                value={leader.id}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group widths={'equal'}>
              <Form.Input
                label="리더명"
                name="name"
                value={leader.name}
                onChange={this.handleChange}
              />
              <Form.TextArea
                label="유저설명"
                name="description"
                value={leader.description}
                onChange={this.handleChange}
              />
            </Form.Group>
          </Form>
        </Container>
        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'유저 등록'} dividing/>
          <Message info>
            <Message.Header> {leaderUserInfo.displayName || '등록된 유저가 없습니다.'} </Message.Header>
            <Image src={leaderUserInfo.photoURL} size={'tiny'} style={{borderRadius: 30}}/>
            <p>
              {leaderUserInfo.description}
            </p>
            <Button
              disabled={leader.userId === ''}
              icon
              onClick={() => {
                const a = window.confirm('삭제하시겠습니까?');
                if (!a) return;
                leader.userId = '';
                this.setState({
                  leader,
                  leaderUserInfo: {},
                })
              }}>
              삭제
              <Icon name={'close'}/>
            </Button>
          </Message>
          <Form>
            <Form.Input
              width={4}
              label="유저검색"
              name="searchUserValue"
              value={this.state.searchUserValue}
              onChange={(e, {value: searchUserValue}) => {
                this.setState({searchUserValue})
              }}
            >
              <input/>
              <Button icon onClick={this.handleSearchUserByUserDisplayName}>
                <Icon name={'search'}/>
              </Button>
            </Form.Input>
            <List>
              {
                this.state.users.map((user, i) => {
                  return (
                    <List.Item key={i}>
                      <Image avatar src={user.photoURL}/>
                      <List.Content>
                        <List.Header>{user.displayName}</List.Header>
                        <List.Description>{user.description}</List.Description>
                        <Button size={'mini'} onClick={() => {
                          leader.userId = user.id;
                          this.setState({
                            leader,
                            users: [],
                            searchUserValue: '',
                            leaderUserInfo: user,
                          });
                        }}>
                          추가
                        </Button>
                      </List.Content>
                    </List.Item>
                  )
                })
              }
            </List>
          </Form>
        </Container>


        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'태그'} dividing/>
          <Button
            onClick={() => {
              leader.tags.push("");
              this.setState({
                leader
              });
            }}>
            태그 추가
          </Button>
          <Form>
            {
              leader.tags.map((tag, i) => {
                return (
                  <Form.Input
                    key={i}
                    name={`tags${i}`}
                    value={tag}
                    onChange={e => {
                      leader.tags[i] = e.target.value;
                      this.setState({
                        leader
                      });
                    }}
                  >
                    <input/>
                    <Button
                      icon
                      onClick={() => {
                        const a = window.confirm('삭제하시겠습니까?');
                        if (!a) return;
                        leader.tags.splice(i, 1);
                        this.setState({
                          leader
                        })
                      }}>
                      <Icon name={'close'}/>
                    </Button>
                  </Form.Input>
                );
              })}
          </Form>
        </Container>
        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'배경 및 사진'} dividing/>
          <Form>
            <Form.Group>
              <Form.Input
                label={'배경 색'}
                name={'backgroundColor'}
                value={leader.backgroundColor}
                onChange={this.handleChange}
              />
              <Form.Input
                label={'텍스트 색'}
                name={'textColor'}
                value={leader.textColor}
                onChange={this.handleChange}
              />
              <Form.Input
                label={'이름 색'}
                name={'nameColor'}
                value={leader.nameColor}
                onChange={this.handleChange}
              />
              <Form.Input
                label={'설명 색'}
                name={'descriptionColor'}
                value={leader.descriptionColor}
                onChange={this.handleChange}
              />
            </Form.Group>
          </Form>

          <Divider/>

          <Grid>
            <Grid.Row columns={6}>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  MAIN
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          leader.imageUrl = imageUrl;
                          this.setState({
                            leader
                          })
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(leader.imageUrl)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    leader.imageUrl = '';
                    this.setState({
                      leader
                    })
                  }}/>
                <Image src={leader.imageUrl || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  배경
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          leader.backgroundImage = imageUrl;
                          this.setState({
                            leader
                          })
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(leader.backgroundImage)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    leader.backgroundImage = '';
                    this.setState({
                      leader
                    })
                  }}/>
                <Image src={leader.backgroundImage || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  배경 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          leader.backgroundImageLarge = imageUrl;
                          this.setState({
                            leader
                          })
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(leader.backgroundImageLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    leader.backgroundImageLarge = '';
                    this.setState({
                      leader
                    })
                  }}/>
                <Image src={leader.backgroundImageLarge || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={6}>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  정사각
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="square"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'square');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(leader.image && leader.image.square)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    leader.image.square = '';
                    this.setState({
                      leader
                    })
                  }}/>
                <Image src={(leader.image && leader.image.square) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  가로
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="horizontal"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'horizontal');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(leader.image && leader.image.horizontal)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    leader.image.horizontal = ''
                    this.setState({
                      leader
                    })
                  }}/>
                <Image src={(leader.image && leader.image.horizontal) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  세로
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="vertical"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'vertical');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(leader.image && leader.image.vertical)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    leader.image.vertical = ''
                    this.setState({
                      leader
                    })
                  }}/>
                <Image src={(leader.image && leader.image.vertical) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  정사각 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="imageSquareLarge"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'squareLarge');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(leader.image && leader.image.squareLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    leader.image.squareLarge = ''
                    this.setState({
                      leader
                    })
                  }}/>
                <Image src={(leader.image && leader.image.squareLarge) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  가로 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="imageHorizontalLarge"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'horizontalLarge');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(leader.image && leader.image.horizontalLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    leader.image.horizontalLarge = '';
                    this.setState({
                      leader
                    })
                  }}/>
                <Image src={(leader.image && leader.image.horizontalLarge) || mockUpImages.planeImage}
                       size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  세로 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="imageVerticalLarge"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'verticalLarge');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(leader.image && leader.image.verticalLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    leader.image.verticalLarge = ''
                    this.setState({
                      leader
                    })
                  }}/>
                <Image src={(leader.image && leader.image.verticalLarge) || mockUpImages.planeImage}
                       size={'medium'}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

const styles = {
  verticalModule: {
    marginBottom: 50,
    marginTop: 50
  },
  header: {
    marginTop: 40,
  }
};

const enhance = connect(
  state => ({
    auth: state.auth
  }),
  {
    ...authActionCreators
  }
);
export default enhance(AdminLeaderDetailContatiner);
