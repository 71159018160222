import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from '../../redux/auth/authStore';
import {Link} from 'react-router-dom';
import {db} from "../../constants/firebase";
import {Table, Segment, Button, Container, Image, Header} from 'semantic-ui-react';
import moment from 'moment';

class AdminTipMainContaier extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tips: []
    }
  }

  componentDidMount() {
    this._loadTipData()
  }

  _loadTipData = async () => {
    await db.collection('tips')
      .where('isDeleted', '==', false)
      .orderBy('order')
      .get()
      .then(snapshot => {
        const tips = []
        snapshot.forEach(doc => {
          const tip = Object.assign(doc.data(), {id: doc.id})
          tips.push(tip)
        })
        this.setState({
          tips
        })
        // console.log(tips)
      })
  }

  render() {
    const {tips} = this.state
    return (
      <Container as={Segment} vertical>
        <Header>팁 어드민페이지</Header>
        <Button as={Link} to={'/admin/tip/write'}>
          팁 만들기
        </Button>
        <Table compact size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>제목</Table.HeaderCell>
              <Table.HeaderCell>Order</Table.HeaderCell>
              <Table.HeaderCell>Tags</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>생성일</Table.HeaderCell>
              <Table.HeaderCell>수정일</Table.HeaderCell>
              <Table.HeaderCell>공개 여부</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              tips.map((tip, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>
                      <Button
                        size={'mini'}
                        compact
                        as={Link}
                        to={`/admin/tip/read/${tip.id}`}>
                        {tip.id}
                      </Button>
                    </Table.Cell>
                    <Table.Cell>{tip.title.content}</Table.Cell>
                    <Table.Cell>{tip.order}</Table.Cell>
                    <Table.Cell>
                      {tip.tags}
                    </Table.Cell>
                    <Table.Cell>{tip.description.content.slice(0, 30)}</Table.Cell>
                    <Table.Cell>{moment(tip.createdAt.seconds * 1000).format('YYYY. MM. DD HH:mm')}</Table.Cell>
                    <Table.Cell>{moment(tip.modifiedAt.seconds * 1000).format('YYYY. MM. DD HH:mm')}</Table.Cell>
                    <Table.Cell>
                      {tip.isPublic ? '공개' : '비공개'}
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    auth: state.auth,
  }),
  {
    ...authActionCreators,
  }
);
export default enhance(AdminTipMainContaier);