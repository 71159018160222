import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {Link} from 'react-router-dom';
import {db} from "../../constants/firebase";
import {Table, Label, Segment, Button, Container, Header} from 'semantic-ui-react';
import moment from 'moment';
import numeral from 'numeral';
import {bookClubActionCreators} from "../../redux/bookClub/bookClubStore";
import {adminActionCreators} from "../../redux/admin/adminStore";
import _ from 'lodash';
import {
  BOOKCLUB_STATUS_BEFORE_RECRUIT,
  BOOKCLUB_STATUS_ENDED,
  BOOKCLUB_STATUS_ING,
  BOOKCLUB_STATUS_RECRUITING
} from "../../dataStructure/bookClub";

class AdminBookClubMainContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bookClubs: [],
    }
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.fetchAllBookClubs();
    this.props.fetchAllBookClubsAdmin();
  }

  render() {
    const {bookClubs} = this.props.admin;

    const $tableHeader = () => {
      return (
        <Table.Row>
          <Table.HeaderCell width={1}>No</Table.HeaderCell>
          <Table.HeaderCell width={1}>공개</Table.HeaderCell>
          <Table.HeaderCell width={1}>삭제</Table.HeaderCell>
          <Table.HeaderCell width={3}>이름</Table.HeaderCell>
          <Table.HeaderCell width={1}>
            모집시작<br/>
            종료
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>
            시작<br/>
            종료
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>클럽장</Table.HeaderCell>
          <Table.HeaderCell width={1}>참가</Table.HeaderCell>
          <Table.HeaderCell width={1}>지원</Table.HeaderCell>
          <Table.HeaderCell width={1}>거부</Table.HeaderCell>
          <Table.HeaderCell width={1}>회비</Table.HeaderCell>
          <Table.HeaderCell width={1}>관리</Table.HeaderCell>
          <Table.HeaderCell width={1}>버튼</Table.HeaderCell>
        </Table.Row>
      )
    }

    const $bookClub = (bookClub, i) => {
      return (
        <Table.Row key={i}>
          <Table.Cell>{i}</Table.Cell>
          <Table.Cell>
            {bookClub.isPublic ? "공개" : "비공개"}
          </Table.Cell>
          <Table.Cell>{bookClub.isDeleted ? '삭제됨' : ''}</Table.Cell>
          <Table.Cell>
            {/*<Label size={'mini'} content={bookClub.status}/>*/}
            {/*<br/>*/}
            {bookClub.header ? bookClub.header.content : bookClub.title}
            {/*<br/>
                        {bookClub.tags.map((tag, i) => (<Label size={'mini'} color={'teal'} content={tag} key={i}/>))}*/}
          </Table.Cell>
          <Table.Cell style={{fontSize:'0.5rem'}}>
            {moment(bookClub.registerStartDate.seconds * 1000).format('YY.MM.DD ddd')}<br/>
            {moment(bookClub.registerEndDate.seconds * 1000).format('YY.MM.DD ddd')}
          </Table.Cell>
          <Table.Cell style={{fontSize:'0.5rem'}}>
            {moment(bookClub.startDate.seconds * 1000).format('YY.MM.DD')}<br/>
            {moment(bookClub.endDate.seconds * 1000).format('YY.MM.DD')}
          </Table.Cell>
          <Table.Cell>
            {bookClub.hasLeader ? <Label content='O'/> : ''}
          </Table.Cell>
          <Table.Cell>{bookClub.participantIds && bookClub.participantIds.length}</Table.Cell>
          <Table.Cell>
            {
              bookClub.applicantIds && bookClub.applicantIds.length > 0 ?
                <Label size={'mini'} color={'blue'} content={bookClub.applicantIds.length}/> :
                ''
            }
          </Table.Cell>
          <Table.Cell>{bookClub.deniedIds && bookClub.deniedIds.length}</Table.Cell>
          <Table.Cell textAlign={'right'}>
            {numeral(bookClub.defaultMembership + (bookClub.hasDeposit ? bookClub.depositPerEssay * bookClub.numberOfEssays : 0)).format('0,0')}
            <br/>
            <p style={{fontSize: 8, color: 'grey'}}>
              ({numeral(bookClub.defaultMembership).format('0,0')})
              <br/>
              ({numeral(bookClub.depositPerEssay).format('0,0')} x {bookClub.numberOfEssays})
            </p>
          </Table.Cell>
          <Table.Cell>
            <Button as={Link}
                    to={{pathname: `/admin/bookclub/manage/${bookClub.id}`, state: {bookClub: bookClub}}}
                    size={'mini'}>
              >
            </Button>
          </Table.Cell>
          <Table.Cell>
            <Button as={Link}
                    to={{pathname: `/admin/bookclub/read/${bookClub.id}`, state: {bookClub: bookClub}}}
                    size={'mini'}>
              >
            </Button>
          </Table.Cell>
        </Table.Row>
      )
    }
    return (
      <Fragment>
        <Container as={Segment}>
          <Header content={'북클럽 어드민페이지'}/>
          <Button as={Link} to={'/admin/bookclub/write'}>
            북클럽 만들기
          </Button>

        </Container>
        <Container as={Segment}>
          <Header content={'모집중'}/>
          <Table compact size={'small'} celled>
            <Table.Header>
              {$tableHeader()}
            </Table.Header>
            <Table.Body>
              {
                _.filter(bookClubs, bc => bc.status === BOOKCLUB_STATUS_RECRUITING).map((bookClub, i) => {
                  return $bookClub(bookClub, i)
                })
              }
            </Table.Body>
          </Table>
        </Container>
        <Container as={Segment}>
          <Header content={'모집전'}/>
          <Table compact size={'small'} celled>
            <Table.Header>
              {$tableHeader()}
            </Table.Header>
            <Table.Body>
              {
                _.filter(bookClubs, bc => bc.status === BOOKCLUB_STATUS_BEFORE_RECRUIT).map((bookClub, i) => {
                  return $bookClub(bookClub, i)
                })
              }
            </Table.Body>
          </Table>
        </Container>
        <Container as={Segment}>
          <Header content={'진행중'}/>
          <Table compact size={'small'} celled>
            <Table.Header>
              {$tableHeader()}
            </Table.Header>
            <Table.Body>
              {
                _.filter(bookClubs, bc => bc.status === BOOKCLUB_STATUS_ING).map((bookClub, i) => {
                  return $bookClub(bookClub, i)
                })
              }
            </Table.Body>
          </Table>
        </Container>
        <Container as={Segment}>
          <Header content={'종료'}/>
          <Table compact size={'small'} celled>
            <Table.Header>
              {$tableHeader()}
            </Table.Header>
            <Table.Body>
              {
                _.filter(bookClubs, bc => bc.status === BOOKCLUB_STATUS_ENDED).map((bookClub, i) => {
                  return $bookClub(bookClub, i);
                })
              }
            </Table.Body>
          </Table>
        </Container>

      </Fragment>
    );
  }
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
    ...bookClubActionCreators,
    ...adminActionCreators,
  }
);


export default enhance(AdminBookClubMainContainer);