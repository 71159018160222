import {createLogic} from 'redux-logic';
import {authActions, authActionCreators} from "./authStore";
import {firebase, auth, db} from "../../constants/firebase";
import {adminActionCreators} from "../admin/adminStore";
import NetworkApiV1 from "../../library/NetworkApiV1";


const appInitializeLogic = createLogic({
  type: authActions.APP_INITIALIZE,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    dispatch(authActionCreators.fetchUserInfoMy());
    // dispatch(authActionCreatorAPIV1.fetchMyBooks());
    // dispatch(authActionCreatorAPIV1.fetchMyBookshelf());
    dispatch(authActionCreators.fetchMyBookClubsApiV1());
    dispatch(authActionCreators.fetchMyBookClubsBeforeApprovalApiV1());
    dispatch(authActionCreators.fetchMyBookClubsDeniedApiV1());
    done();
    if (action.cb) action.cb();
  }
});
const signInLogic = createLogic({
  type: authActions.SIGN_IN,
  latest: true,
  process: async ({getState, action, firebase}, dispatch, done) => {
    await auth.signInWithEmailAndPassword(action.email, action.password)
      .then(user => {
        console.log(user);
      })
      .catch(e => {
        console.log(e);
        if (action.cb) action.cb(e);
      });
    done();
  }
});
const signInSuccessLogic = createLogic({
  type: authActions.SIGN_IN_SUCCESS,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const user = action.user;
    if (user.isAdmin) {

      dispatch(adminActionCreators.toggleAdminMode());
      dispatch(adminActionCreators.adminInitialize());
    }
    done()
  }
});
const signOutLogic = createLogic({
  type: authActions.SIGN_OUT,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    auth.signOut();
    done();
  }
});

const fetchUserInfoMyLogic = createLogic({
  type: authActions.FETCH_USER_INFO_MY,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const {err, user} = await NetworkApiV1.getMyUserInfo();
    if (!err) {
      dispatch(authActionCreators.fetchUserInfoMySuccess(user))
    }
    if (action.cb) {
      action.cb();
    }
    done();
  }
});


const fetchMyBookClubsApiV1Logic = createLogic({
  type: authActions.FETCH_MY_BOOK_CLUBS_API_V1,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const res = await NetworkApiV1.getBookClubMyApproved();
    if (!res.err) {
      dispatch(authActionCreators.fetchMyBookClubsApiV1Success(res));
    }
    if (action.cb) {
      action.cb();
    }
    done();
  }
});
const fetchMyBookClubsBeforeApprovalApiV1 = createLogic({
  type: authActions.FETCH_MY_BOOK_CLUBS_BEFORE_APPROVAL_API_V1,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const res = await NetworkApiV1.getBookClubMyBeforeApproved();
    if (!res.err) {
      dispatch(authActionCreators.fetchMyBookClubsBeforeApprovalApiV1Success(res));
    }
    if (action.cb) action.cb();
    done();
  }
});
const fetchMyBookClubsDeniedApiV1 = createLogic({
  type: authActions.FETCH_MY_BOOK_CLUBS_DENIED_API_V1,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const res = await NetworkApiV1.getBookClubMyDenied();
    if (!res.err) {
      dispatch(authActionCreators.fetchMyBookClubsDeniedApiV1Success(res));
    }
    if (action.cb) action.cb();
    done();
  }
});


export default [
  appInitializeLogic,

  signInLogic,
  signInSuccessLogic,
  signOutLogic,
  fetchUserInfoMyLogic,

  fetchMyBookClubsApiV1Logic,
  fetchMyBookClubsBeforeApprovalApiV1,
  fetchMyBookClubsDeniedApiV1,
];