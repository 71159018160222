import {createLogic} from 'redux-logic';
import {bookshelfActions, bookshelfActionCreators} from './bookshelfStore'
import {auth, db, firebase} from "../../constants/firebase";
import Network from "../../library/Network";


const fetchBookshelvesLogic = createLogic({
  type: bookshelfActions.FETCH_ALL_BOOKSHELVES,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {

    const currentUser = auth.currentUser;
    const {uid: userId} = currentUser;

    const res = await Network.getBookshelfListByUserId(userId);
    if (!res.err) dispatch(bookshelfActionCreators.fetchBookshelvesSuccess(res));
    if (action.cb) action.cb();

    done();
  }
});

const addBookshelfLogic = createLogic({
  type: bookshelfActions.ADD_BOOKSHELF,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const currentUser = auth.currentUser;
    const {uid: userId, email: userEmail} = currentUser;

    const {bookshelves} = getState().library;
    let lastOrder = bookshelves.length + 1;
    bookshelves.forEach(bs => {
      if (bs.order > lastOrder) lastOrder = bs.order + 1;
    });

    const newBookshelf = Object.assign(action.bookshelf, {
      userId,
      userEmail,
      order: lastOrder,
      modifiedAt: new Date(),
      createdAt: new Date(),
    });

    const res = await Network.addBookshelfAndGetBookshelfIdByUserId(userId, newBookshelf);

    dispatch(bookshelfActionCreators.fetchBookshelves());
    if (action.cb) action.cb();
    done();

  }
});
const modifyBookshelfLogic = createLogic({
  type: bookshelfActions.MODIFY_BOOKSHELF,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const {bookshelves} = getState().library;
    const {bookshelf} = action;
    const bookshelfIndex = bookshelves.findIndex(o => {
      return o.id === bookshelf.id
    });
    bookshelves[bookshelfIndex] = bookshelf;
    dispatch(bookshelfActionCreators.fetchBookshelvesSuccess(bookshelves));

    const res = await Network.updateBookshelfByBookshelf(bookshelf);

    if (action.cb) action.cb();
    done();
  }
});

const deleteBookshelfLogic = createLogic({
  type: bookshelfActions.DELETE_BOOKSHELF,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const {bookshelves} = getState().library;
    const {bookshelf} = action;
    const bookshelfIndex = bookshelves.findIndex(o => {
      return o.id === bookshelf.id
    });
    bookshelves.splice(bookshelfIndex, 1);

    dispatch(bookshelfActionCreators.fetchBookshelvesSuccess(bookshelves));

    const res = await Network.deleteBookshelfByBookshelf(bookshelf);
    if (action.cb) action.cb();
    done();
  }
});


const fetchBooksLogic = createLogic({
  type: bookshelfActions.FETCH_ALL_BOOKS,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const userId = auth.currentUser.uid;

    const res = await Network.getBooksByUserId(userId);
    if (!res.err) dispatch(bookshelfActionCreators.fetchBooksSuccess(res));

    if (action.cb) action.cb();
    done();
  }
});
const addBookToBookshelfLogic = createLogic({
  type: bookshelfActions.ADD_BOOK,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const state = getState();
    const {book, bookshelf} = action;

    const res = await Network.addBookToBookshelf(book, bookshelf.id);

    done();
  }
});
const deleteBookFromBookshelfLogic = createLogic({
  type: bookshelfActions.DELETE_BOOK,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const state = getState();
    const {books, bookshelves} = state.library;
    const {book, bookshelf} = action;

    const bookIndex = books.findIndex(item => item.id === book.id);
    const indexOfSelectedBookshelfInBook = books[bookIndex].bookshelfId.findIndex(item => item === bookshelf.id);
    books[bookIndex].bookshelfId.splice(indexOfSelectedBookshelfInBook, 1);
    dispatch(bookshelfActionCreators.fetchBooksSuccess(books));

    const bookshelfIndex = bookshelves.findIndex(item => item.id === bookshelf.id);
    const indexOfSelectedBookInBookshelf = bookshelves[bookshelfIndex].bookIsbns.findIndex(item => item === book.isbn);
    bookshelves[bookshelfIndex].bookIsbns.splice(indexOfSelectedBookInBookshelf, 1);
    dispatch(bookshelfActionCreators.fetchBookshelvesSuccess(bookshelves));

    const res = await Network.removeBookFromBookshelf(book, bookshelf.id);
    done();
  }
});



export default [
  fetchBookshelvesLogic,
  addBookshelfLogic,
  modifyBookshelfLogic,
  deleteBookshelfLogic,

  fetchBooksLogic,
  addBookToBookshelfLogic,
  deleteBookFromBookshelfLogic,
]