import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {authActionCreators} from "../../redux/auth/authStore";
import {
  Button,
  Grid,
  Segment,
  Loader,
  Container,
  Table,
  Divider,
  Message,
  Header,
  Icon,
  Form, Card,
  Radio,
  Image,
  List
} from "semantic-ui-react";
import {db, storage} from "../../constants/firebase";
import {firebase} from "../../constants/firebase";
import FileUploader from "react-firebase-file-uploader";
import _ from 'lodash';
import {NEW_COUPON, NEW_COUPON_CODE} from "../../dataStructure/coupon";
import Network from "../../library/Network";
import moment from "moment";
import mockUpImages from "../../constants/mockUpImages";

const storageRef = `coupon/${moment().format("YYYYMM")}`;


class AdminCouponDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coupon: {},
      isLoaded: false,
      isNew: props.match.params.couponId === undefined,
      targetUserIds: [],
      selectedCouponCode: _.cloneDeep(NEW_COUPON_CODE),

    };
  }


  componentDidMount() {
    this._loadCoupon();
  }

  _loadCoupon = async () => {
    const isNewCoupon = this.props.match.params.couponId === undefined;

    if (isNewCoupon) {
      this.setState({
        isNewCoupon,
        coupon: _.cloneDeep(NEW_COUPON),
        isLoaded: true,
      })
    } else {
      const {couponId} = this.props.match.params;
      const res = await Network.getCouponByCouponId(couponId);
      if (res.err) return window.alert('쿠폰 정보를 찾을 수 없습니다.');

      this.setState({
        isNewCoupon,
        coupon: Object.assign(_.cloneDeep(NEW_COUPON), res),
        isLoaded: true,
      })
    }
  };


  handleChange = e => {
    const {coupon} = this.state;
    const {name, value} = e.target;
    coupon[name] = value;
    this.setState({
      coupon
    });
  };

  handleSubmit = async () => {
    const confirm = window.confirm("업로드 하시겠습니까?");
    if (!confirm) return;

    const {coupon} = this.state;
    console.log(coupon);

    const isNewCoupon = this.props.match.params.couponId === undefined;

    let res;
    if (isNewCoupon) {
      res = await Network.postCoupon(coupon);
      if (res.err) return window.alert('쿠폰 생성에 실패하였습니다.');
      window.alert('완료');
      this.props.history.replace(`/admin/coupon/read/${res.id}`)

    } else {
      res = await Network.updateCoupon(coupon);
      if (res.err) return window.alert('쿠폰 수정에 오류가 발생하였습니다.');
      window.alert('완료');
    }



  };

  handleUploadSuccess = async (filename, imageCategory) => {
    const {coupon} = this.state;

    await firebase.storage()
      .ref(storageRef)
      .child(filename)
      .getDownloadURL()
      .then(imageUrl => {
        coupon.image[imageCategory] = imageUrl;
        this.setState({coupon})
      });
  };

  deleteCoupon = async () => {
    const confirm = window.confirm("삭제하시겠습니까?");
    if (!confirm) return;

    const res = await Network.updateCoupon(Object.assign(this.state.coupon, {isDeleted: true}));

    if (res.err) return window.alert('삭제에 실패하였습니다.');

    window.alert('삭제되었습니다.');
    this.props.history.replace('/admin/coupon');
  };

  restore = async () => {
    const confirm = window.confirm("복구 하시겠습니까?");
    if (!confirm) return;

    const res = await Network.updateCoupon(Object.assign(this.state.coupon, {isDeleted: false}));

    if (res.err) return window.alert('복구에 실패하였습니다.');

    window.alert('복구되었습니다.');
    this._loadCoupon();

  };

  render() {
    const {coupon, isLoaded, isNew,} = this.state;
    if (!isLoaded) {
      return (
        <Loader active={true}/>
      )
    }

    const couponCode = this.props.admin.couponCodes.find(item => item.id === coupon.id);

    const {targetUserIds, selectedCouponCode} = this.state;

    return (
      <Fragment>
        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={`쿠폰 정보`} dividing/>
          <Button content={this.state.isNewCoupon ? '만들기' : '수정하기'} primary onClick={this.handleSubmit}/>
          <Button content={'삭제'} negative disabled={this.state.isNewCoupon || coupon.isDeleted}
                  onClick={this.deleteCoupon}/>
          <Button content={'복구'} positive disabled={!coupon.isDeleted} onClick={this.restore}/>
        </Container>

        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'기본정보'} dividing/>
          <Form>
            <Form.Field>
              <Radio
                toggle
                label={coupon.isUsed ? "사용 완료" : "미사용"}
                checked={coupon.isUsed}
                onClick={() => {
                  const a = window.confirm('변경하시겠습니까?, 복잡한 연산이 필요합니다.');
                  if (!a) return;
                  coupon.isUsed = !coupon.isUsed;
                  this.setState({
                    coupon
                  });
                }}
                style={{margin: "10px 0"}}/>
            </Form.Field>
            <Form.Group widths={'equal'}>
              <Form.Dropdown
                selection
                disabled={!isNew}
                value={selectedCouponCode}
                placeholder={'CATEGORY'}
                options={_.map(this.props.admin.couponCodes, (code, i) => ({
                  key: i,
                  text: code.name,
                  value: code
                }))}
                onChange={(e, {value}) => {
                  this.setState({
                    selectedCouponCode: value,
                  })
                }}
              />
              <Form.Dropdown
                multiple
                selection
                disabled={!isNew}
                value={this.state.targetUserIds}
                placeholder={'CATEGORY'}
                options={_.map(this.props.admin.users, (user, i) => ({
                  key: i,
                  text: user.displayName,
                  value: user.id
                }))}
                onChange={(e, {value}) => {
                  this.setState({
                    targetUserIds: value,
                  })
                }}
              />
            </Form.Group>
          </Form>
        </Container>
        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Card>
            <Card.Content>
              {selectedCouponCode.name}
            </Card.Content>
            <Card.Content>
              {selectedCouponCode.repeatPossible ? '중복발급 가능' : '중복발급 불가'} <br/>
              {selectedCouponCode.type} <br/>
              {selectedCouponCode.discountAmountOrRate}
            </Card.Content>
            <Card.Content>
              사용기한: {selectedCouponCode.id && moment(selectedCouponCode.expireDate.seconds * 1000).format('YYYY.MM.DD')}
            </Card.Content>
          </Card>
        </Container>
      </Fragment>
    );
  }
}

const styles = {
  verticalModule: {
    marginBottom: 50,
    marginTop: 50
  },
  header: {
    marginTop: 40,
  }
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators
  }
);
export default enhance(AdminCouponDetailContainer);
