import React, {Component} from 'react';
import {connect} from "react-redux";

import {
  CONTENT_TYPE_BOOK_CLUB,
  CONTENT_TYPE_BOOKS,
  CONTENT_TYPE_ESSAYS, CONTENT_TYPE_GAP,
  CONTENT_TYPE_LEADERS,
  CONTENT_TYPE_MAPS,
  CONTENT_TYPE_PARTNERS,
  CONTENT_TYPE_PHOTOS,
  CONTENT_TYPE_PLACES,
  CONTENT_TYPE_PRODUCTS, CONTENT_TYPE_QUOTE,
  CONTENT_TYPE_SCRAPS, CONTENT_TYPE_STORY,
  CONTENT_TYPE_TEXT,
  CONTENT_TYPE_USERS,
  CONTENT_TYPE_WEB_LINK,
  CONTENT_TYPE_YOUTUBERS, CONTENT_VIEW_TYPE_BOOK_CLUB_COVER, CONTENT_VIEW_TYPE_BOOK_CLUB_COVER_SHOW_BOOKS,
  contentViewTypeOfBookClubDropDownList, horizontalGapDropDownList
} from "../../dataStructure/contentModule";
import {
  Header,
  List,
  Table,
  Grid,
  Divider,
  Input,
  Card,
  Button,
  Segment,
  Form,
  Image,
  Label
} from 'semantic-ui-react';
import {getColorCode} from "../../constants/Colors";
import FileUploader from "react-firebase-file-uploader";
import {firebase} from "../../constants/firebase";
import mockUpImages from "../../constants/mockUpImages";
import moment from "moment/moment";
import GoogleMapComponent from '../../components/map/GoogleMapComponent';
import {GoogleMapKey} from "../../config/keys";
import {kakaoMapSearch} from "../../middleware/SearchLocation";
import _ from "lodash";
import {authActionCreators} from "../../redux/auth/authStore";
import {adminActionCreators} from "../../redux/admin/adminStore";
import {moveItemInArrayFromIndexToIndex} from "../../middleware/arrayHandler";
import AlgoliaSearch from "../../middleware/AlgoliaSearch";

const getFontFamilyDropDownList = () => {
  return [
    {
      key: 0,
      value: 'regular',
      text: 'regular'
    },
    {
      key: 1,
      value: 'bold',
      text: 'bold'
    },
  ]
};
const getTextAlignDropDownList = () => {
  return [
    {
      key: 0,
      value: 'left',
      text: 'left'
    },
    {
      key: 1,
      value: 'center',
      text: 'center'
    },
    {
      key: 2,
      value: 'right',
      text: 'right'
    }
  ]
};
const getFlexAlignDropDownList = () => {
  return [
    {
      key: 0,
      value: 'flex-start',
      text: 'flex-start'
    },
    {
      key: 1,
      value: 'center',
      text: 'center'
    },
    {
      key: 2,
      value: 'flex-end',
      text: 'flex-end'
    }
  ]
};
const getFontSizeDropDownList = () => {
  return [
    {
      key: 0,
      value: 10,
      text: '10',
    },
    {
      key: 1,
      value: 12,
      text: '12',
    },
    {
      key: 2,
      value: 14,
      text: '14',
    },
    {
      key: 3,
      value: 16,
      text: '16',
    },
    {
      key: 4,
      value: 18,
      text: '18',
    },
    {
      key: 5,
      value: 20,
      text: '20',
    },
    {
      key: 6,
      value: 24,
      text: '24',
    },
    {
      key: 7,
      value: 30,
      text: '30',
    },
  ]
};
const getLineHightDropDownList = () => {
  return [
    {
      key: 0,
      value: 16,
      text: '16(10)',
    },
    {
      key: 1,
      value: 18,
      text: '18(12)',
    },
    {
      key: 2,
      value: 14,
      text: '14',
    },
    {
      key: 3,
      value: 16,
      text: '16',
    },
    {
      key: 4,
      value: 18,
      text: '18',
    },
    {
      key: 5,
      value: 20,
      text: '20',
    },
    {
      key: 6,
      value: 24,
      text: '24',
    },
    {
      key: 7,
      value: 30,
      text: '30',
    },
  ]
};


const storageRef = `story/${moment().format("YYYYMM")}`;


class ContentModuleComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchValueLocation: '',
      searchedLocations: [],

      searchValueEssay: '',
      searchedEssays: [],

      searchValueScrap: '',
      searchedScraps: [],
    }
  }


  componentDidMount() {

  }

  handleSearchEssay = async () => {
    const index = AlgoliaSearch.client.initIndex('essays');
    index
      .search({
        query: this.state.searchValueEssay
      })
      .then(response => {
        const {hits: searchedEssays} = response;
        console.log({searchedEssays});
        this.setState({
          searchedEssays: searchedEssays
        })

      })
      .catch(e => {
        console.log(e);
      })
  };

  handleSearchScrap = async () => {
    const index = AlgoliaSearch.client.initIndex('scraps');
    index
      .search({
        query: this.state.searchValueScrap
      })
      .then(response => {
        const {hits: searchedScraps} = response;
        console.log({searchedScraps});
        this.setState({
          searchedScraps: searchedScraps
        })

      })
      .catch(e => {
        console.log(e);
      })
  };

  render() {
    const {contentModule} = this.props;
    const $button = (
      <Button.Group
        size={'small'}>
        <Button
          size={'small'}
          icon='arrow alternate circle up'
          onClick={() => {
            this.props.moveModuleFoward && this.props.moveModuleFoward()
          }}
        />
        <Button
          size={'small'}
          icon='arrow alternate circle down'
          onClick={() => {
            this.props.moveModuleBack && this.props.moveModuleBack()
          }}
        />
        <Button
          negative
          size={'small'}
          icon={'remove circle'}
          onClick={() => {
            this.props.deleteModule && this.props.deleteModule()
          }}
        />
        <Button
          positive
          size={'small'}
          icon={'add circle'}
          onClick={() => {
            this.props.addModule && this.props.addModule()
          }}
        />
        <Button
          primary
          size={'small'}
          icon={'save'}
          onClick={() => {
            this.props.save && this.props.save()
          }}
        />
      </Button.Group>
    );
    switch (contentModule.contentType) {
      case CONTENT_TYPE_TEXT:
        return (
          <Segment>
            {$button}
            <Divider/>
            <Label content={contentModule.contentType}/>
            <Divider/>
            <Form size={'small'}>
              <Form.TextArea
                value={contentModule.text}
                label={'TEXT'}
                onChange={(e, {value}) => {
                  contentModule.text = value;
                  this.props.onChangeState(contentModule)
                }}
              />
              <Form.Group>
                <Form.Dropdown
                  width={3}
                  placeholder={'color'}
                  selection
                  label="color"
                  value={contentModule.color}
                  options={getColorCode()}
                  onChange={(e, {value}) => {
                    contentModule.color = value;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={2}
                  value={contentModule.color}
                  label={'color'}
                  onChange={(e, {value}) => {
                    contentModule.color = value;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Dropdown
                  width={3}
                  placeholder={'fontSize'}
                  selection
                  label="fontSize"
                  value={contentModule.fontSize}
                  options={getFontSizeDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.fontSize = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                {/*<Form.Input
                  width={2}
                  type={'number'}
                  value={contentModule.fontSize}
                  label={'fontSize'}
                  onChange={(e, {value}) => {
                    contentModule.fontSize = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />*/}
                <Form.Input
                  width={2}
                  type={'number'}
                  value={contentModule.lineHeight}
                  label={'lineHeight'}
                  onChange={(e, {value}) => {
                    contentModule.lineHeight = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Dropdown
                  width={3}
                  placeholder={'textAlign'}
                  selection
                  label="textAlign"
                  value={contentModule.textAlign}
                  options={[
                    {
                      key: 0,
                      value: 'left',
                      text: 'left'
                    },
                    {
                      key: 1,
                      value: 'center',
                      text: 'center'
                    },
                    {
                      key: 2,
                      value: 'right',
                      text: 'right'
                    }
                  ]}
                  onChange={(e, {value}) => {
                    contentModule.textAlign = value;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Dropdown
                  width={3}
                  placeholder={'fontWeight'}
                  selection
                  label="fontWeight"
                  value={contentModule.fontFamily}
                  options={[
                    {
                      key: 0,
                      value: 'regular',
                      text: 'regular'
                    },
                    {
                      key: 1,
                      value: 'bold',
                      text: 'bold'
                    },
                  ]}
                  onChange={(e, {value}) => {
                    contentModule.fontFamily = value;
                    this.props.onChangeState(contentModule)
                  }}
                />
              </Form.Group>
            </Form>
            <Form>
              <Form.Group>
                <Form.Dropdown
                  width={4}
                  selection
                  search
                  name={'bottomGap'}
                  value={contentModule.bottomGap}
                  placeholder={'height'}
                  options={horizontalGapDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={4}
                  name={'bottomGap'}
                  type={'number'}
                  value={contentModule.bottomGap}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
              </Form.Group>
            </Form>
          </Segment>
        );
      case CONTENT_TYPE_PHOTOS:
        return (
          <Segment>
            {$button}
            <Divider/>
            <Label content={contentModule.contentType}/>
            <Divider/>
            <Form>
              <Form.Group>
                <Form.Radio
                  toggle
                  label={contentModule.fullWidth ? 'Padding: 0' : 'Padding: default'}
                  checked={contentModule.fullWidth}
                  onChange={() => {
                    contentModule.fullWidth = !contentModule.fullWidth;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Radio
                  toggle
                  label={contentModule.hasCaption ? 'CAPTION O' : 'CAPTION X'}
                  checked={contentModule.hasCaption}
                  onChange={() => {
                    contentModule.hasCaption = !contentModule.hasCaption;
                    this.props.onChangeState(contentModule)
                  }}
                />
              </Form.Group>
              <Form.Input
                width={3}
                type={'number'}
                label={'imageRatio'}
                value={contentModule.imageRatio}
                onChange={(e, {value}) => {
                  contentModule.imageRatio = Number(value);
                  this.props.onChangeState(contentModule);
                }}
              />
            </Form>
            <Divider/>
            <Card.Group>
              {
                contentModule.imageUrls.map((imageUrl, i) => {
                  return (
                    <Card key={i}>
                      <Image src={imageUrl || mockUpImages.planeImage}/>
                      <Card.Content>
                        <Input
                          disabled={!contentModule.hasCaption}
                          value={contentModule.captions[i]}
                          onChange={(e, {value}) => {
                            contentModule.captions[i] = value;
                            this.props.onChangeState(contentModule)
                          }}
                        />
                      </Card.Content>
                      <Card.Content>
                        <Button as={'label'} size={'mini'}>
                          이미지 업로드
                          <FileUploader
                            hidden
                            accept="image/*"
                            name="square"
                            randomizeFilename
                            storageRef={firebase.storage().ref(storageRef)}
                            onUploadSuccess={filename => {
                              firebase.storage()
                                .ref(storageRef)
                                .child(filename)
                                .getDownloadURL()
                                .then(imageUrl => {
                                  contentModule.imageUrls[i] = imageUrl;
                                  this.props.onChangeState(contentModule)
                                });
                            }}
                          />
                        </Button>
                        <Button
                          size={'mini'}
                          disabled={!(imageUrl)}
                          content={'삭제'}
                          negative
                          onClick={() => {
                            contentModule.imageUrls.splice(i, 1);
                            contentModule.captions.splice(i, 1);
                            this.props.onChangeState(contentModule)
                          }}/>

                      </Card.Content>
                    </Card>
                  )
                })
              }
            </Card.Group>
            <Divider/>
            <Button
              content={'추가'}
              onClick={() => {
                contentModule.imageUrls.push('');
                contentModule.captions.push('');
                this.props.onChangeState(contentModule);
              }}/>
            <Form>
              <Form.Group>
                <Form.Dropdown
                  width={4}
                  selection
                  search
                  name={'bottomGap'}
                  value={contentModule.bottomGap}
                  placeholder={'height'}
                  options={horizontalGapDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={4}
                  name={'bottomGap'}
                  type={'number'}
                  value={contentModule.bottomGap}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
              </Form.Group>
            </Form>
          </Segment>
        );
      case CONTENT_TYPE_MAPS:
        return (
          <Segment>
            {$button}
            <Divider/>
            <Label content={contentModule.contentType}/>
            <Divider/>
            <Form>
              <Form.Radio
                toggle
                label={contentModule.showMap ? '지도 보여줌' : '지도 보여주지 않음'}
                checked={contentModule.showMap}
                onChange={() => {
                  contentModule.showMap = !contentModule.showMap;
                  this.props.onChangeState(contentModule)
                }}
              />
              <Form.Group>
                <Form.Input
                  width={6}
                  label={'장소명'}
                  value={contentModule.locationName}
                  onChange={(e, {value}) => {
                    contentModule.locationName = value;
                    this.props.onChangeState(contentModule);
                  }}
                />
                <Form.Input
                  width={6}
                  label={'도로명 주소'}
                  value={contentModule.address}
                  onChange={(e, {value}) => {
                    contentModule.address = value;
                    this.props.onChangeState(contentModule);
                  }}
                />
                <Form.Input
                  width={4}
                  label={'전화번호'}
                  value={contentModule.phone}
                  onChange={(e, {value}) => {
                    contentModule.phone = value;
                    this.props.onChangeState(contentModule);
                  }}
                />

              </Form.Group>
            </Form>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Input
                    value={this.state.searchValueLocation}
                    onChange={(e, {value}) => {
                      this.setState({
                        searchValueLocation: value,
                      })
                    }}
                  />
                  <Button
                    content={'검색'}
                    onClick={async () => {
                      if (this.state.searchValueLocation === '') return window.alert('검색어를 입력해주세요');
                      const res = await kakaoMapSearch(this.state.searchValueLocation);
                      console.log(res);
                      this.setState({
                        searchedLocations: res.data && res.data.documents,
                        isEnd: res.data && res.data.meta.is_end,
                      })
                    }}
                  />
                  <List>
                    {
                      this.state.searchedLocations.map((location, j) => {
                        return (
                          <List.Item key={j}>
                            <List.Header onClick={() => {
                              const locationCoordinate = new firebase.firestore.GeoPoint(Number(location.y), Number(location.x));
                              console.log(locationCoordinate.latitude);
                              console.log(locationCoordinate.longitude);
                              contentModule.locationCoordinate = locationCoordinate;
                              contentModule.address = location.road_address_name;
                              contentModule.locationName = location.place_name;
                              this.props.onChangeState(contentModule)
                            }}>
                              {location.place_name}
                            </List.Header>
                          </List.Item>
                        )
                      })
                    }
                  </List>
                </Grid.Column>
                <Grid.Column>
                  <GoogleMapComponent
                    isMarkerShown
                    location={contentModule.locationCoordinate}
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapKey}&v=3.exp&libraries=geometry,drawing,places`}
                    loadingElement={<div style={{height: `100%`}}/>}
                    containerElement={<div style={{height: `400px`}}/>}
                    mapElement={<div style={{height: `100%`}}/>}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Form>
              <Form.Group>
                <Form.Dropdown
                  width={4}
                  selection
                  search
                  name={'bottomGap'}
                  value={contentModule.bottomGap}
                  placeholder={'height'}
                  options={horizontalGapDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={4}
                  name={'bottomGap'}
                  type={'number'}
                  value={contentModule.bottomGap}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
              </Form.Group>
            </Form>
          </Segment>
        );
      case CONTENT_TYPE_LEADERS:
        return (
          <Segment>
            {$button}
            <Divider/>
            <Label content={contentModule.contentType}/>
            <Divider/>
            <Form>
              <Form.Group>
                <Form.Radio
                  width={4}
                  toggle
                  label={contentModule.showRate ? '별점 보여줌' : '별점 보여주지 않음'}
                  checked={contentModule.showRate}
                  onChange={() => {
                    contentModule.showRate = !contentModule.showRate;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Radio
                  width={4}
                  toggle
                  label={contentModule.descriptionBottom ? '상세설명 하단' : '상세설명 우측'}
                  checked={contentModule.descriptionBottom}
                  onChange={() => {
                    contentModule.descriptionBottom = !contentModule.descriptionBottom;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Radio
                  width={4}
                  toggle
                  label={contentModule.horizontal ? 'HorizontalList O' : 'HorizontalList X'}
                  checked={contentModule.horizontal}
                  onChange={() => {
                    contentModule.horizontal = !contentModule.horizontal;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Radio
                  width={4}
                  toggle
                  label={contentModule.hasButton ? '하단 버튼 O' : '하단 버튼 X'}
                  checked={contentModule.hasButton}
                  onChange={() => {
                    contentModule.hasButton = !contentModule.hasButton;
                    this.props.onChangeState(contentModule)
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  width={6}
                  disabled={!contentModule.hasButton}
                  label={'buttonText'}
                  value={contentModule.buttonText}
                  onChange={(e, {value}) => {
                    contentModule.buttonText = value;
                    this.props.onChangeState(contentModule);
                  }}
                />
                <Form.Dropdown
                  width={4}
                  disabled={!contentModule.hasButton}
                  placeholder={'color'}
                  selection
                  label="color"
                  value={contentModule.buttonColor}
                  options={getColorCode()}
                  onChange={(e, {value}) => {
                    contentModule.buttonColor = value;
                    this.props.onChangeState(contentModule)
                  }}
                />
              </Form.Group>
              <Form.Dropdown
                multiple
                selection
                value={contentModule.leaderIds}
                placeholder={'leaderIds'}
                options={_.map(this.props.admin.leaders, (leader, i) => ({
                  key: i,
                  text: leader.name,
                  value: leader.id
                }))}
                onChange={(e, {value}) => {
                  contentModule.leaderIds = value;
                  this.props.onChangeState(contentModule)
                }}
              />
            </Form>
            <Form>
              <Form.Group>
                <Form.Dropdown
                  width={4}
                  selection
                  search
                  name={'bottomGap'}
                  value={contentModule.bottomGap}
                  placeholder={'height'}
                  options={horizontalGapDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={4}
                  name={'bottomGap'}
                  type={'number'}
                  value={contentModule.bottomGap}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
              </Form.Group>
            </Form>
          </Segment>
        );
      case CONTENT_TYPE_USERS:
        return (
          <Segment>
            {$button}
            <Divider/>
            <Label content={contentModule.contentType}/>
            <Divider/>
            <Form>
              <Form.Group>
                <Form.Radio
                  width={4}
                  toggle
                  label={contentModule.descriptionBottom ? '상세설명 하단' : '상세설명 우측'}
                  checked={contentModule.descriptionBottom}
                  onChange={() => {
                    contentModule.descriptionBottom = !contentModule.descriptionBottom;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Radio
                  width={4}
                  toggle
                  label={contentModule.horizontal ? 'HorizontalList O' : 'HorizontalList X'}
                  checked={contentModule.horizontal}
                  onChange={() => {
                    contentModule.horizontal = !contentModule.horizontal;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Radio
                  width={4}
                  toggle
                  label={contentModule.hasButton ? '하단 버튼 O' : '하단 버튼 X'}
                  checked={contentModule.hasButton}
                  onChange={() => {
                    contentModule.hasButton = !contentModule.hasButton;
                    this.props.onChangeState(contentModule)
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  width={6}
                  disabled={!contentModule.hasButton}
                  label={'buttonText'}
                  value={contentModule.buttonText}
                  onChange={(e, {value}) => {
                    contentModule.buttonText = value;
                    this.props.onChangeState(contentModule);
                  }}
                />
                <Form.Dropdown
                  width={4}
                  disabled={!contentModule.hasButton}
                  placeholder={'color'}
                  selection
                  label="color"
                  value={contentModule.buttonColor}
                  options={getColorCode()}
                  onChange={(e, {value}) => {
                    contentModule.buttonColor = value;
                    this.props.onChangeState(contentModule)
                  }}
                />
              </Form.Group>
              <Form.Dropdown
                multiple
                selection
                search
                value={contentModule.userIds}
                placeholder={'userIds'}
                options={_.map(this.props.admin.users, (user, i) => ({
                  key: i,
                  text: `${user.displayName}(${user.detailProfile.name})`,
                  value: user.id
                }))}
                onChange={(e, {value}) => {
                  contentModule.userIds = value;
                  this.props.onChangeState(contentModule)
                }}
              />
            </Form>
            <Form>
              <Form.Group>
                <Form.Dropdown
                  width={4}
                  selection
                  search
                  name={'bottomGap'}
                  value={contentModule.bottomGap}
                  placeholder={'height'}
                  options={horizontalGapDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={4}
                  name={'bottomGap'}
                  type={'number'}
                  value={contentModule.bottomGap}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
              </Form.Group>
            </Form>
          </Segment>
        );
      case CONTENT_TYPE_PARTNERS:
        return (
          <Segment>
            {$button}
            <Divider/>
            <Label content={contentModule.contentType}/>
            <Divider/>
            <Form>
              <Form.Group>
                <Form.Radio
                  width={4}
                  toggle
                  label={contentModule.descriptionBottom ? '상세설명 하단' : '상세설명 우측'}
                  checked={contentModule.descriptionBottom}
                  onChange={() => {
                    contentModule.descriptionBottom = !contentModule.descriptionBottom;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Radio
                  width={4}
                  toggle
                  label={contentModule.horizontal ? 'HorizontalList O' : 'HorizontalList X'}
                  checked={contentModule.horizontal}
                  onChange={() => {
                    contentModule.horizontal = !contentModule.horizontal;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Radio
                  width={4}
                  toggle
                  label={contentModule.hasButton ? '하단 버튼 O' : '하단 버튼 X'}
                  checked={contentModule.hasButton}
                  onChange={() => {
                    contentModule.hasButton = !contentModule.hasButton;
                    this.props.onChangeState(contentModule)
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  width={6}
                  disabled={!contentModule.hasButton}
                  label={'buttonText'}
                  value={contentModule.buttonText}
                  onChange={(e, {value}) => {
                    contentModule.buttonText = value;
                    this.props.onChangeState(contentModule);
                  }}
                />
                <Form.Dropdown
                  width={4}
                  disabled={!contentModule.hasButton}
                  placeholder={'color'}
                  selection
                  label="color"
                  value={contentModule.buttonColor}
                  options={getColorCode()}
                  onChange={(e, {value}) => {
                    contentModule.buttonColor = value;
                    this.props.onChangeState(contentModule)
                  }}
                />
              </Form.Group>
              <Form.Dropdown
                multiple
                selection
                search
                value={contentModule.partnerIds}
                placeholder={'partnerIds'}
                options={_.map(this.props.admin.partners, (partner, i) => ({
                  key: i,
                  text: `${partner.name}`,
                  value: partner.id
                }))}
                onChange={(e, {value}) => {
                  contentModule.partnerIds = value;
                  this.props.onChangeState(contentModule)
                }}
              />
            </Form>
            <Form>
              <Form.Group>
                <Form.Dropdown
                  width={4}
                  selection
                  search
                  name={'bottomGap'}
                  value={contentModule.bottomGap}
                  placeholder={'height'}
                  options={horizontalGapDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={4}
                  name={'bottomGap'}
                  type={'number'}
                  value={contentModule.bottomGap}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
              </Form.Group>
            </Form>
          </Segment>
        );
      case CONTENT_TYPE_BOOK_CLUB:
        return (
          <Segment>
            {$button}
            <Divider/>
            <Label content={contentModule.contentType}/>
            <Divider/>
            <Form>
              <Form.Group>
                <Form.Input
                  width={6}
                  label={'backgroundColor'}
                  disabled={!(contentModule.viewType === CONTENT_VIEW_TYPE_BOOK_CLUB_COVER_SHOW_BOOKS || contentModule.viewType === CONTENT_VIEW_TYPE_BOOK_CLUB_COVER)}
                  value={contentModule.backgroundColor}
                  onChange={(e, {value}) => {
                    contentModule.backgroundColor = value;
                    this.props.onChangeState(contentModule);
                  }}
                />
                <Form.Dropdown
                  width={4}
                  placeholder={'color'}
                  disabled={!(contentModule.viewType === CONTENT_VIEW_TYPE_BOOK_CLUB_COVER_SHOW_BOOKS || contentModule.viewType === CONTENT_VIEW_TYPE_BOOK_CLUB_COVER)}
                  selection
                  label="color"
                  value={contentModule.textColor}
                  options={getColorCode()}
                  onChange={(e, {value}) => {
                    contentModule.textColor = value;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Dropdown
                  width={6}
                  placeholder={'viewType'}
                  selection
                  label="viewType"
                  value={contentModule.viewType}
                  options={contentViewTypeOfBookClubDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.viewType = value;
                    this.props.onChangeState(contentModule)
                  }}
                />
              </Form.Group>
              <Form.Dropdown
                multiple
                selection
                search
                value={contentModule.bookClubIds}
                placeholder={'bookClubIds'}
                options={_.map(this.props.admin.bookClubs, (bookClub, i) => ({
                  key: i,
                  text: `${bookClub.header.content}`,
                  value: bookClub.id
                }))}
                onChange={(e, {value}) => {
                  contentModule.bookClubIds = value;
                  this.props.onChangeState(contentModule)
                }}
              />
            </Form>
            <Form>
              <Form.Group>
                <Form.Dropdown
                  width={4}
                  selection
                  search
                  name={'bottomGap'}
                  value={contentModule.bottomGap}
                  placeholder={'height'}
                  options={horizontalGapDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={4}
                  name={'bottomGap'}
                  type={'number'}
                  value={contentModule.bottomGap}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
              </Form.Group>
            </Form>
          </Segment>
        );
      case CONTENT_TYPE_BOOKS:
        return (
          <Segment>
            {$button}
            <Divider/>
            <Label content={contentModule.contentType}/>
            <Divider/>
            <Form>
              <Form.Group>
                <Form.Radio
                  toggle
                  label={contentModule.horizontal ? 'horizontal' : 'horizontal X'}
                  checked={contentModule.horizontal}
                  onChange={() => {
                    contentModule.horizontal = !contentModule.horizontal;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Radio
                  toggle
                  label={contentModule.hasPadding ? 'Padding O' : 'Padding X'}
                  checked={contentModule.hasPadding}
                  onChange={() => {
                    contentModule.hasPadding = !contentModule.hasPadding;
                    this.props.onChangeState(contentModule)
                  }}
                />
              </Form.Group>
              <Form.Dropdown
                multiple
                search
                selection
                value={contentModule.bookIds}
                placeholder={'bookIds'}
                options={_.map(this.props.admin.adminBooks, (book, i) => ({
                  key: i,
                  text: book.title,
                  value: book.id
                }))}
                onChange={(e, {value}) => {
                  contentModule.bookIds = value;
                  this.props.onChangeState(contentModule)
                }}
              />
            </Form>
            <Form>
              <Form.Group>
                <Form.Dropdown
                  width={4}
                  selection
                  search
                  name={'bottomGap'}
                  value={contentModule.bottomGap}
                  placeholder={'height'}
                  options={horizontalGapDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={4}
                  name={'bottomGap'}
                  type={'number'}
                  value={contentModule.bottomGap}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
              </Form.Group>
            </Form>
          </Segment>
        );
      case CONTENT_TYPE_ESSAYS:
        return (
          <Segment>
            {$button}
            <Divider/>
            <Label content={contentModule.contentType}/>
            <Divider/>
            <Form>
              <Form.Group>
                <Form.Radio
                  toggle
                  label={contentModule.showsBookInfo ? '책정보 보여주기' : '책정보 보여주기 X'}
                  checked={contentModule.showsBookInfo}
                  onChange={() => {
                    contentModule.showsBookInfo = !contentModule.showsBookInfo;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Radio
                  toggle
                  label={contentModule.hasPadding ? 'Padding O' : 'Padding X'}
                  checked={contentModule.hasPadding}
                  onChange={() => {
                    contentModule.hasPadding = !contentModule.hasPadding;
                    this.props.onChangeState(contentModule)
                  }}
                />
              </Form.Group>
            </Form>
            <Divider/>
            <Table>
              <Table.Body>
                {
                  contentModule.essayIds && contentModule.essayIds.map((essayId, i) => {

                    return (
                      <Table.Row key={i}>
                        <Table.Cell>
                          {essayId}
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            content={'삭제'}
                            onClick={() => {
                              const a = window.confirm('삭제하시겠습니까?');
                              if (!a) return;
                              contentModule.essayIds.splice(i, 1);
                              this.props.onChangeState(contentModule)
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )
                  })
                }
              </Table.Body>
            </Table>
            <Divider/>
            <Form>
              <Form.Input
                value={this.state.searchValueEssay}
                onChange={(e, {value}) => {
                  this.setState({searchValueEssay: value})
                }}
              />
              <Button
                content={'검색'}
                onClick={this.handleSearchEssay}
              />
            </Form>
            <Table size={'small'} compact={'very'} style={{fontSize: '0.8rem'}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={8}>제목</Table.HeaderCell>
                  <Table.HeaderCell width={4}>
                    userId <br/>
                    isbn <br/>
                    상태
                  </Table.HeaderCell>
                  <Table.HeaderCell width={4}>버튼</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  this.state.searchedEssays.map((essay, i) => {
                    const user = this.props.admin.users.find(u => u.id === essay.userId);
                    return (
                      <Table.Row key={i}>
                        <Table.Cell>{essay.title}</Table.Cell>
                        <Table.Cell>
                          {user ? user.displayName : ''} ({essay.userId}) <br/>
                          {essay.isbn} <br/>
                          {essay.status}
                        </Table.Cell>

                        <Table.Cell>
                          <Button
                            size={'mini'}
                            content={'추가'}
                            onClick={() => {
                              contentModule.essayIds.push(essay.objectID);
                              this.props.onChangeState(contentModule)
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )
                  })
                }
              </Table.Body>
            </Table>
            <Form>
              <Form.Group>
                <Form.Dropdown
                  width={4}
                  selection
                  search
                  name={'bottomGap'}
                  value={contentModule.bottomGap}
                  placeholder={'height'}
                  options={horizontalGapDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={4}
                  name={'bottomGap'}
                  type={'number'}
                  value={contentModule.bottomGap}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
              </Form.Group>
            </Form>
          </Segment>
        );
      case CONTENT_TYPE_SCRAPS:
        return (
          <Segment>
            {$button}
            <Divider/>
            <Label content={contentModule.contentType}/>
            <Divider/>
            <Form>
              <Form.Group>
                <Form.Input
                  width={3}
                  type={'number'}
                  label={'itemsPerRow'}
                  value={contentModule.itemsPerRow}
                  onChange={(e, {value}) => {
                    contentModule.itemsPerRow = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
                <Form.Radio
                  width={3}
                  toggle
                  label={contentModule.horizontal ? 'Horizontal O' : 'Horizontal X'}
                  checked={contentModule.horizontal}
                  onChange={() => {
                    contentModule.horizontal = !contentModule.horizontal;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Radio
                  width={3}
                  toggle
                  label={contentModule.showUserInfo ? 'showUserInfo O' : 'showUserInfo X'}
                  checked={contentModule.showUserInfo}
                  onChange={() => {
                    contentModule.showUserInfo = !contentModule.showUserInfo;
                    this.props.onChangeState(contentModule)
                  }}
                />
              </Form.Group>
            </Form>
            <Divider/>
            <Table>
              <Table.Body>
                {
                  contentModule.scrapIds && contentModule.scrapIds.map((scrapId, i) => {
                    return (
                      <Table.Row key={i}>
                        <Table.Cell>
                          {scrapId}
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            content={'삭제'}
                            onClick={() => {
                              const a = window.confirm('삭제하시겠습니까?');
                              if (!a) return;
                              contentModule.scrapIds.splice(i, 1);
                              this.props.onChangeState(contentModule)
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )
                  })
                }
              </Table.Body>
            </Table>
            <Divider/>
            <Form>
              <Form.Input
                value={this.state.searchValueScrap}
                onChange={(e, {value}) => {
                  this.setState({searchValueScrap: value})
                }}
              />
              <Button
                content={'검색'}
                onClick={this.handleSearchScrap}
              />
            </Form>
            <Card.Group>
              {
                this.state.searchedScraps.map((scrap, i) => {
                  return (
                    <Card key={i}>
                      <Image src={scrap.photoURL}/>
                      <Card.Content>
                        {scrap.content} <br/>
                        {scrap.bookId} <br/>
                        {scrap.status}
                      </Card.Content>
                      <Card.Content>
                        <Button
                          fluid
                          size={'mini'}
                          content={'추가'}
                          onClick={() => {
                            contentModule.scrapIds.push(scrap.objectID);
                            this.props.onChangeState(contentModule)
                          }}
                        />
                      </Card.Content>
                    </Card>
                  )
                })
              }
            </Card.Group>
            <Divider/>
            <Form>
              <Form.Group>
                <Form.Dropdown
                  width={4}
                  selection
                  search
                  name={'bottomGap'}
                  value={contentModule.bottomGap}
                  placeholder={'height'}
                  options={horizontalGapDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={4}
                  name={'bottomGap'}
                  type={'number'}
                  value={contentModule.bottomGap}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
              </Form.Group>
            </Form>
          </Segment>
        );

      case CONTENT_TYPE_WEB_LINK:
        return (
          <Segment>
            {$button}
            <Divider/>
            <Label content={contentModule.contentType}/>
            <Divider/>
            <Form>
              <Form.Group>
                <Form.Input
                  width={4}
                  label={'buttonText'}
                  value={contentModule.buttonText}
                  onChange={(e, {value}) => {
                    contentModule.buttonText = value;
                    this.props.onChangeState(contentModule);
                  }}
                />
                <Form.Dropdown
                  width={3}
                  placeholder={'color'}
                  selection
                  label="color"
                  value={contentModule.buttonColor}
                  options={getColorCode()}
                  onChange={(e, {value}) => {
                    contentModule.buttonColor = value;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Dropdown
                  width={3}
                  placeholder={'fontFamily'}
                  selection
                  label="fontFamily"
                  value={contentModule.fontFamily}
                  options={[
                    {
                      key: 0,
                      value: 'regular',
                      text: 'regular'
                    },
                    {
                      key: 1,
                      value: 'bold',
                      text: 'bold'
                    },
                  ]}
                  onChange={(e, {value}) => {
                    contentModule.fontFamily = value;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={3}
                  type={'number'}
                  label={'fontSize'}
                  value={contentModule.fontSize}
                  onChange={(e, {value}) => {
                    contentModule.fontSize = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
                <Form.Dropdown
                  width={3}
                  // disabled={!contentModule.viewType}
                  placeholder={'textAlign'}
                  selection
                  label="textAlign"
                  value={contentModule.textAlign}
                  options={[
                    {
                      key: 0,
                      value: 'left',
                      text: 'left'
                    },
                    {
                      key: 1,
                      value: 'center',
                      text: 'center'
                    },
                    {
                      key: 2,
                      value: 'right',
                      text: 'right'
                    }
                  ]}
                  onChange={(e, {value}) => {
                    contentModule.textAlign = value;
                    this.props.onChangeState(contentModule)
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  width={6}
                  label={'uri'}
                  value={contentModule.uri}
                  onChange={(e, {value}) => {
                    contentModule.uri = value;
                    this.props.onChangeState(contentModule);
                  }}
                />
                <Form.Dropdown
                  width={3}
                  // disabled={!contentModule.viewType}
                  placeholder={'showButton'}
                  selection
                  label="showButton"
                  value={contentModule.showButton}
                  options={[
                    {
                      key: 0,
                      value: true,
                      text: 'O'
                    },
                    {
                      key: 1,
                      value: false,
                      text: 'X'
                    }
                  ]}
                  onChange={(e, {value}) => {
                    contentModule.showButton = value;
                    this.props.onChangeState(contentModule)
                  }}
                />
              </Form.Group>

            </Form>
            <Form>
              <Form.Group>
                <Form.Dropdown
                  width={4}
                  selection
                  search
                  name={'bottomGap'}
                  value={contentModule.bottomGap}
                  placeholder={'height'}
                  options={horizontalGapDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={4}
                  name={'bottomGap'}
                  type={'number'}
                  value={contentModule.bottomGap}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
              </Form.Group>
            </Form>
          </Segment>
        );
      case CONTENT_TYPE_STORY:
        return (
          <Segment>
            {$button}
            <Divider/>
            <Label content={contentModule.contentType}/>
            <Divider/>
            <Form>
              <Form.Dropdown
                multiple
                selection
                search
                value={contentModule.storyIds}
                placeholder={'storyIds'}
                options={_.map(this.props.admin.stories, (story, i) => ({
                  key: i,
                  text: `${story.coverTitle.text}`,
                  value: story.id
                }))}
                onChange={(e, {value}) => {
                  contentModule.storyIds = value;
                  this.props.onChangeState(contentModule)
                }}
              />
            </Form>
            <Form>
              <Form.Group>
                <Form.Dropdown
                  width={4}
                  selection
                  search
                  name={'bottomGap'}
                  value={contentModule.bottomGap}
                  placeholder={'height'}
                  options={horizontalGapDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={4}
                  name={'bottomGap'}
                  type={'number'}
                  value={contentModule.bottomGap}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
              </Form.Group>
            </Form>
          </Segment>
        );
      case CONTENT_TYPE_GAP:
        return (
          <Segment style={{backgroundColor: 'lightgrey'}}>
            {$button}
            <Divider/>
            <Label content={contentModule.contentType}/>
            <Divider/>
            <Form>
              <Form.Group>
                <Form.Dropdown
                  width={8}
                  selection
                  search
                  value={contentModule.height}
                  placeholder={'height'}
                  options={horizontalGapDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.height = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={8}
                  type={'number'}
                  value={contentModule.height}
                  onChange={(e, {value}) => {
                    contentModule.height = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
              </Form.Group>
            </Form>
          </Segment>
        );
      case CONTENT_TYPE_QUOTE:
        return (
          <Segment>
            {$button}
            <Divider/>
            <Label content={contentModule.contentType}/>
            <Divider/>
            <Header content={'상단 " 그림 '}/>
            <Form.Radio
              width={3}
              toggle
              label="이미지 노출"
              checked={contentModule.topImageShow}
              onChange={(e, {value}) => {
                contentModule.topImageShow = !contentModule.topImageShow;
                this.props.onChangeState(contentModule)
              }}
            />
            {
              contentModule.topImageShow && (
                <Form>
                  <Form.Group>
                    <Form.Dropdown
                      width={3}
                      placeholder={'topImageTintColor'}
                      selection
                      label="topImageTintColor"
                      value={contentModule.topImageTintColor}
                      options={getColorCode()}
                      onChange={(e, {value}) => {
                        contentModule.topImageTintColor = value;
                        this.props.onChangeState(contentModule)
                      }}
                    />
                    <Form.Input
                      width={2}
                      value={contentModule.topImageTintColor}
                      label={'topImageTintColor'}
                      onChange={(e, {value}) => {
                        contentModule.topImageTintColor = value;
                        this.props.onChangeState(contentModule)
                      }}
                    />
                    <Form.Input
                      width={2}
                      type={'number'}
                      value={contentModule.topImageSize}
                      label={'topImageSize'}
                      onChange={(e, {value}) => {
                        contentModule.topImageSize = Number(value);
                        this.props.onChangeState(contentModule)
                      }}
                    />
                    <Form.Dropdown
                      width={3}
                      placeholder={'topImageAlign:'}
                      selection
                      label="topImageAlign"
                      value={contentModule.topImageAlign}
                      options={getFlexAlignDropDownList()}
                      onChange={(e, {value}) => {
                        contentModule.topImageAlign = value;
                        this.props.onChangeState(contentModule)
                      }}
                    />
                  </Form.Group>
                </Form>
              )
            }
            <Divider/>
            <Header content={'TEXT'}/>
            <Form>
              <Form.TextArea
                value={contentModule.text}
                label={'TEXT'}
                onChange={(e, {value}) => {
                  contentModule.text = value;
                  this.props.onChangeState(contentModule)
                }}
              />
              <Form.Group>
                <Form.Dropdown
                  width={3}
                  placeholder={'color'}
                  selection
                  label="color"
                  value={contentModule.color}
                  options={getColorCode()}
                  onChange={(e, {value}) => {
                    contentModule.color = value;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={2}
                  value={contentModule.color}
                  label={'color'}
                  onChange={(e, {value}) => {
                    contentModule.color = value;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={2}
                  type={'number'}
                  value={contentModule.fontSize}
                  label={'fontSize'}
                  onChange={(e, {value}) => {
                    contentModule.fontSize = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Dropdown
                  width={3}
                  placeholder={'textAlign'}
                  selection
                  label="textAlign"
                  value={contentModule.textAlign}
                  options={getTextAlignDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.textAlign = value;
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Dropdown
                  width={3}
                  placeholder={'fontFamily'}
                  selection
                  label="fontFamily"
                  value={contentModule.fontFamily}
                  options={getFontFamilyDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.fontFamily = value;
                    this.props.onChangeState(contentModule)
                  }}
                />
              </Form.Group>
            </Form>
            <Divider/>
            <Header content={'하단 " 그림 '}/>
            <Form.Radio
              width={3}
              toggle
              label="이미지 노출"
              checked={contentModule.bottomImageShow}
              onChange={(e, {value}) => {
                contentModule.bottomImageShow = !contentModule.bottomImageShow;
                this.props.onChangeState(contentModule)
              }}
            />
            {
              contentModule.bottomImageShow && (
                <Form>
                  <Form.Group>
                    <Form.Dropdown
                      width={3}
                      placeholder={'topImageTintColor'}
                      selection
                      label="topImageTintColor"
                      value={contentModule.bottomImageTintColor}
                      options={getColorCode()}
                      onChange={(e, {value}) => {
                        contentModule.bottomImageTintColor = value;
                        this.props.onChangeState(contentModule)
                      }}
                    />
                    <Form.Input
                      width={2}
                      value={contentModule.bottomImageTintColor}
                      label={'topImageTintColor'}
                      onChange={(e, {value}) => {
                        contentModule.bottomImageTintColor = value;
                        this.props.onChangeState(contentModule)
                      }}
                    />
                    <Form.Input
                      width={2}
                      type={'number'}
                      value={contentModule.bottomImageSize}
                      label={'bottomImageSize'}
                      onChange={(e, {value}) => {
                        contentModule.bottomImageSize = Number(value);
                        this.props.onChangeState(contentModule)
                      }}
                    />
                    <Form.Dropdown
                      width={3}
                      placeholder={'topImageAlign:'}
                      selection
                      label="topImageAlign"
                      value={contentModule.bottomImageAlign}
                      options={getFlexAlignDropDownList()}
                      onChange={(e, {value}) => {
                        contentModule.bottomImageAlign = value;
                        this.props.onChangeState(contentModule)
                      }}
                    />
                  </Form.Group>
                </Form>
              )
            }
            <Form>
              <Form.Group>
                <Form.Dropdown
                  width={4}
                  selection
                  search
                  name={'bottomGap'}
                  value={contentModule.bottomGap}
                  placeholder={'height'}
                  options={horizontalGapDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={4}
                  name={'bottomGap'}
                  type={'number'}
                  value={contentModule.bottomGap}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
              </Form.Group>
            </Form>
          </Segment>
        );

      case CONTENT_TYPE_YOUTUBERS:
        return (
          <Segment>
            <Button.Group
              size={'small'}>
              <Button
                size={'small'}
                icon='arrow alternate circle up'
                onClick={() => {
                  this.props.moveModuleFoward && this.props.moveModuleFoward()
                }}
              />
              <Button
                size={'small'}
                icon='arrow alternate circle down'
                onClick={() => {
                  this.props.moveModuleBack && this.props.moveModuleBack()
                }}
              />
              <Button
                negative
                size={'small'}
                icon={'remove circle'}
                onClick={() => {
                  this.props.deleteModule && this.props.deleteModule()
                }}
              />
              <Button
                positive
                size={'small'}
                icon={'add circle'}
                onClick={() => {
                  this.props.addModule && this.props.addModule()
                }}
              />
            </Button.Group>
            <Divider/>
            <Label content={contentModule.contentType}/>
            <Divider/>
            <Form>
              <Form.Group>
                <Form.Dropdown
                  width={4}
                  selection
                  search
                  name={'bottomGap'}
                  value={contentModule.bottomGap}
                  placeholder={'height'}
                  options={horizontalGapDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={4}
                  name={'bottomGap'}
                  type={'number'}
                  value={contentModule.bottomGap}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
              </Form.Group>
            </Form>
          </Segment>
        );
      case CONTENT_TYPE_PRODUCTS:
        return (
          <Segment>
            <Button.Group
              size={'small'}>
              <Button
                size={'small'}
                icon='arrow alternate circle up'
                onClick={() => {
                  this.props.moveModuleFoward && this.props.moveModuleFoward()
                }}
              />
              <Button
                size={'small'}
                icon='arrow alternate circle down'
                onClick={() => {
                  this.props.moveModuleBack && this.props.moveModuleBack()
                }}
              />
              <Button
                negative
                size={'small'}
                icon={'remove circle'}
                onClick={() => {
                  this.props.deleteModule && this.props.deleteModule()
                }}
              />
              <Button
                positive
                size={'small'}
                icon={'add circle'}
                onClick={() => {
                  this.props.addModule && this.props.addModule()
                }}
              />
            </Button.Group>
            <Divider/>
            <Label content={contentModule.contentType}/>
            <Form>
              <Form.Group>
                <Form.Dropdown
                  width={4}
                  selection
                  search
                  name={'bottomGap'}
                  value={contentModule.bottomGap}
                  placeholder={'height'}
                  options={horizontalGapDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={4}
                  name={'bottomGap'}
                  type={'number'}
                  value={contentModule.bottomGap}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
              </Form.Group>
            </Form>
          </Segment>
        );
      case CONTENT_TYPE_PLACES:
        return (
          <Segment>
            <Button.Group
              size={'small'}>
              <Button
                size={'small'}
                icon='arrow alternate circle up'
                onClick={() => {
                  this.props.moveModuleFoward && this.props.moveModuleFoward()
                }}
              />
              <Button
                size={'small'}
                icon='arrow alternate circle down'
                onClick={() => {
                  this.props.moveModuleBack && this.props.moveModuleBack()
                }}
              />
              <Button
                negative
                size={'small'}
                icon={'remove circle'}
                onClick={() => {
                  this.props.deleteModule && this.props.deleteModule()
                }}
              />
              <Button
                positive
                size={'small'}
                icon={'add circle'}
                onClick={() => {
                  this.props.addModule && this.props.addModule()
                }}
              />
            </Button.Group>
            <Divider/>
            <Label content={contentModule.contentType}/>
            <Form>
              <Form.Group>
                <Form.Dropdown
                  width={4}
                  selection
                  search
                  name={'bottomGap'}
                  value={contentModule.bottomGap}
                  placeholder={'height'}
                  options={horizontalGapDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={4}
                  name={'bottomGap'}
                  type={'number'}
                  value={contentModule.bottomGap}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
              </Form.Group>
            </Form>
          </Segment>
        );
      default:
        return (
          <div>
            <Button.Group
              size={'small'}>
              <Button
                size={'small'}
                icon='arrow alternate circle up'
                onClick={() => {
                  this.props.moveModuleFoward && this.props.moveModuleFoward()
                }}
              />
              <Button
                size={'small'}
                icon='arrow alternate circle down'
                onClick={() => {
                  this.props.moveModuleBack && this.props.moveModuleBack()
                }}
              />
              <Button
                negative
                size={'small'}
                icon={'remove circle'}
                onClick={() => {
                  this.props.deleteModule && this.props.deleteModule()
                }}
              />
              <Button
                positive
                size={'small'}
                icon={'add circle'}
                onClick={() => {
                  this.props.addModule && this.props.addModule()
                }}
              />
            </Button.Group>
            <Divider/>
            <Label content={contentModule.contentType}/>
            <Form>
              <Form.Group>
                <Form.Dropdown
                  width={4}
                  selection
                  search
                  name={'bottomGap'}
                  value={contentModule.bottomGap}
                  placeholder={'height'}
                  options={horizontalGapDropDownList()}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule)
                  }}
                />
                <Form.Input
                  width={4}
                  name={'bottomGap'}
                  type={'number'}
                  value={contentModule.bottomGap}
                  onChange={(e, {value}) => {
                    contentModule.bottomGap = Number(value);
                    this.props.onChangeState(contentModule);
                  }}
                />
              </Form.Group>
            </Form>
          </div>
        )

    }
  }
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
    ...adminActionCreators,
  }
);
export default enhance(ContentModuleComponent);

