import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Loader, Segment, Button, Header, Message} from "semantic-ui-react";
import NetworkApiV1 from "../../library/NetworkApiV1";
import {firebaseDB} from "../../constants/firebase";
import numeral from 'numeral';
import MyBookClubDetailContainer from "../myBookClub/MyBookClubDetailContainer";

class BookClubMyDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      bookClub: {},
      bankAccount: {
        bankAccount: '',
        bankName: '',
        accountHolder: '',
      },
    }
  }

  componentDidMount() {
    this._loadBookClub();
    this._loadBankAccount()
  }

  _loadBankAccount = async () => {
    await firebaseDB.adminSettings.doc('bankAccount')
      .get()
      .then(doc => {
        if (!doc.exists) return window.alert('계좌 로딩 중 오류가 발생했습니다. 재시도 해 주세요.');
        const bankAccount = Object.assign(doc.data(), {id: doc.id});
        this.setState({
          bankAccount
        })
      })
  };

  _loadBookClub = async () => {
    const {bookClubId} = this.props.match.params;
    const res = await NetworkApiV1.getBookClubById(bookClubId);
    if (!res.err) {
      this.setState({
        bookClub: res,
        isLoaded: true,
      })
    }
  };

  _goToInquiry = async () => {
    this.props.history.push('/inquiry');
  };

  _handleConfirm = async () => {
    const a = window.confirm('입금 확인을 요청합니다');
    if (!a) return;
    const {relationUserBookClub} = this.state.bookClub;
    this.setState({uploading: true,});
    const res = await NetworkApiV1.updateRelationUserBookClub(relationUserBookClub.id, {paymentCompleted: true});
    if (!res.err) {
      this._loadBookClub();
    } else {
      this.setState({
        uploading: false
      })
    }
  };
  _handleCancel = async () => {
    const a = window.confirm('북클럽을 취소하시겠습니까?');
    if (!a) return;

    const {relationUserBookClub} = this.state.bookClub;
    this.setState({uploading: true,});
    const res = await NetworkApiV1.cancelRelationUserBookClub(relationUserBookClub.id);
    if (!res.err) {
      this.props.history.replace(`/book-club/${this.state.bookClub.id}`)
    } else {
      window.alert('오류가 발생하였습니다.\n문의하기에 남겨주시면 신속히 처리해 드리도록 하겠습니다.');
    }
  };


  render() {
    if (!this.state.isLoaded) return <Loader active/>;
    const {bookClub} = this.state;
    const {relationUserBookClub} = bookClub;
    if (!relationUserBookClub) {
      window.alert('신청 내역이 없습니다.');
      this.props.history.replace(`/book-club/${bookClub.id}`)
    }
    if (relationUserBookClub.isDenied) {
      const items = [
        '관리자 또는 클럽장에 의해 참여가 제한되었습니다.',
        '보다 상세한 이유는 문의하기로 문의 부탁드립니다.'
      ];
      return (
        <>
          <Container as={Segment} basic vertical text>
            <Message
              warning
              header={'참여가 제한되었습니다.'}
              list={items}
              icon={'exclamation circle'}
            />
            <Button fluid content={'문의하기'} onClick={this._goToInquiry}/>
          </Container>
          <Container as={Segment} basic vertical text>
          </Container>
        </>
      )
    } else if (!relationUserBookClub.paymentCompleted) {
      const items = [
        '입금 후, 입금확인 버튼을 눌러주세요.',
        `계좌주: ${this.state.bankAccount?.accountHolder}`,
        `${this.state.bankAccount?.accountHolderDescription}`,
        `입금계좌: ${this.state.bankAccount?.bankAccount} ${this.state.bankAccount?.bankName}`,
        `입금금액: ${numeral(relationUserBookClub.amountPaymentNeeded).format('0,0')} 원`
      ];
      return (
        <>
          <Container as={Segment} basic vertical text>
            <Message
              warning
              header={'입금 대기중'}
              list={items}
              icon={'won sign'}
            />
            <Button primary content={'입금확인'} onClick={this._handleConfirm}/>
            <Button primary basic content={'신청취소'} onClick={this._handleCancel}/>
          </Container>
        </>
      )
    } else if (!relationUserBookClub.paymentConfirmedByAdmin) {
      const items = [
        '입금 확인 후 클럽장 최종 승인 절차가 있습니다.',
        `입금 후 24시간 이내에 처리 됩니다.`
      ];
      return (
        <>
          <Container as={Segment} basic vertical text>
            <Message
              warning
              header={'입금 확인 중'}
              list={items}
              icon={'check circle outline'}
            />
            <Button primary content={'문의하기'} onClick={this._goToInquiry}/>
          </Container>
        </>
      )
    } else if (!relationUserBookClub.isApproved) {
      const items = [
        '클럽장 승인 후 해당 북클럽 가입이 완료됩니다.',
      ];
      return (
        <>
          <Container as={Segment} basic vertical text>
            <Message
              warning
              header={'클럽장 승인 대기'}
              list={items}
              icon={'wait'}
            />
            <Button primary content={'문의하기'} onClick={this._goToInquiry}/>
          </Container>
        </>
      )
    } else {
      return (
        <MyBookClubDetailContainer {...this.props}/>
      );
    }
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BookClubMyDetailContainer);