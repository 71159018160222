import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import Network from "../../library/Network";
import {Segment, Header, Loader, Container, Table, Button,} from 'semantic-ui-react'
import {Link} from "react-router-dom";

class AdminBookshelfMainContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bookshelves: [],
      isLoaded: false,
    }
  }


  componentDidMount() {
    this._loadBookshelves();
  }

  _loadBookshelves = async () => {
    const res = await Network.getAllBookshelves();
    if (res.err) return window.alert('책장을 불러오는데 실패하였습니다.');

    this.setState({
      bookshelves: res,
      isLoaded: true,
    })
  };

  render() {
    const {bookshelves, isLoaded} = this.state;
    if (!isLoaded) return <Loader active/>
    return (
      <Container as={Segment} vertical>
        <Header>책장 어드민페이지</Header>
        <Table>
          <Table.Body>
            {
              bookshelves.map((bookshelf, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>
                      {bookshelf.title}
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    auth: state.auth,
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(AdminBookshelfMainContainer);