import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import Network from "../../library/Network";
import {Table, Segment, Button, Loader, Radio, Container, Label, Header, Image} from 'semantic-ui-react';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';
import {TRANSACTION_TYPE_REGISTER, TRANSACTION_TYPE_WITHDRAW} from "../../dataStructure/transaction";
import {db, firebase} from "../../constants/firebase";

class AdminTransactionMainContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      transactions: [],
    }
  }



  componentDidMount() {
    this._loadTransactions();
  }

  _loadTransactions = async () => {
    const res = await Network.getAllTransactions();
    if (res.err) return window.alert('거래 리스트를 불러오는 데 실패하였습니다.');
    this.setState({
      isLoaded: true,
      transactions: _.orderBy(res, ['transactionType', 'createdAt.seconds', 'withDrawCompleted'], ['asc', 'desc', 'asc']),
    })
  };

  _handleReturnDeposit = async (transaction) => {
    db.collection('transactions')
      .doc(transaction.id)
      .update({
        withDrawCompleted: true,
        paymentCompletedAt: firebase.firestore.FieldValue.serverTimestamp(),
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        window.alert('완료');
        this._loadTransactions()
      })
      .catch(e => {
        window.alert('에러');
        console.log(e);
      })
  };
  _handleCancelReturnDeposit = async (transaction) => {
    db.collection('transactions')
      .doc(transaction.id)
      .update({
        withDrawCompleted: false,
        paymentCompletedAt: firebase.firestore.FieldValue.serverTimestamp(),
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        window.alert('완료');
        this._loadTransactions()
      })
      .catch(e => {
        window.alert('에러');
        console.log(e);
      })
  }


  render() {
    const {isLoaded, transactions} = this.state;
    const {users, bookClubs} = this.props.admin;
    if (!isLoaded) return <Loader active/>;
    return (
      <Fragment>
        <Container as={Segment} vertical>
          <Header>거래 어드민페이지</Header>
          <Table compact={'very'} size={'small'}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  transactionId
                </Table.HeaderCell>
                <Table.HeaderCell>
                  userId
                </Table.HeaderCell>
                <Table.HeaderCell>
                  discount<br/>
                  Amount
                </Table.HeaderCell>
                <Table.HeaderCell>
                  deposit
                </Table.HeaderCell>
                <Table.HeaderCell>
                  cash
                </Table.HeaderCell>
                <Table.HeaderCell>
                  amount<br/>
                  Payment<br/>
                  Needed
                </Table.HeaderCell>
                <Table.HeaderCell>
                  transactionType
                </Table.HeaderCell>
                <Table.HeaderCell>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  bookClubId
                </Table.HeaderCell>

              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                transactions.map((it, i) => {
                  const user = users.find(u => u.id === it.userId);
                  const bookClub = bookClubs.find(bc => bc.id === it.bookClubId);

                  return (
                    <Table.Row key={i}>
                      <Table.Cell>
                      <span style={{fontSize: '0.5rem'}}>
                      {it.id}
                      </span> <br/>
                        {moment(it.createdAt.seconds * 1000).format('YYMMDD HH:mm')}
                      </Table.Cell>
                      <Table.Cell>
                        {user && (
                          <Fragment>
                            <Image src={user.photoURL} avatar/>
                            {user.displayName}
                            {user.detailProfile && (
                              <Fragment>
                                <br/>
                                <span>
                                ({user.detailProfile.name})
                              </span>
                              </Fragment>
                            )}
                          </Fragment>
                        )}
                      </Table.Cell>
                      <Table.Cell>{numeral(it.discountAmount).format('0,0')}</Table.Cell>
                      <Table.Cell>{numeral(it.deposit).format('0,0')}</Table.Cell>
                      <Table.Cell>{numeral(it.cash).format('0,0')}</Table.Cell>
                      <Table.Cell>{numeral(it.amountPaymentNeeded).format('0,0')}</Table.Cell>
                      <Table.Cell>
                        <Label content={it.transactionType} size={'mini'}/> <br/>
                        {it.bankAccount} {it.bankName}

                      </Table.Cell>
                      <Table.Cell>
                        {(it.transactionType === TRANSACTION_TYPE_WITHDRAW) && (
                          it.withDrawCompleted ? (
                            <Button content={'환급 취소'} negative size={'mini'} onClick={() => {
                              const a = window.confirm('환급을 취소하시겠습니까?');
                              if (!a) return;
                              this._handleCancelReturnDeposit(it)
                            }}/>
                          ) : (
                            <Button content={'환급'} positive size={'mini'} onClick={() => {
                              const a = window.confirm('환급시겠습니까?');
                              if (!a) return;
                              this._handleReturnDeposit(it)
                            }}/>
                          )
                        )
                        }
                      </Table.Cell>
                      <Table.Cell>
                        {bookClub && bookClub.header && bookClub.header.content}
                      </Table.Cell>

                    </Table.Row>
                  )
                })
              }
            </Table.Body>
          </Table>
        </Container>
        <Container as={Segment} vertical>
          <Table compact={'very'} size={'small'}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  신청 내역
                </Table.HeaderCell>
                <Table.HeaderCell>
                  userId
                </Table.HeaderCell>
                <Table.HeaderCell>
                  discount<br/>
                  Amount
                </Table.HeaderCell>
                <Table.HeaderCell>
                  deposit
                </Table.HeaderCell>
                <Table.HeaderCell>
                  cash
                </Table.HeaderCell>
                <Table.HeaderCell>
                  amount<br/>
                  Payment<br/>
                  Needed
                </Table.HeaderCell>
                <Table.HeaderCell>
                  transactionType
                </Table.HeaderCell>
                <Table.HeaderCell>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  bookClubId
                </Table.HeaderCell>

              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                _.filter(transactions, tr => tr.transactionType === TRANSACTION_TYPE_REGISTER).map((it, i) => {
                  const user = users.find(u => u.id === it.userId);
                  const bookClub = bookClubs.find(bc => bc.id === it.bookClubId);

                  return (
                    <Table.Row key={i}>
                      <Table.Cell>
                      <span style={{fontSize: '0.5rem'}}>
                      {it.id}
                      </span> <br/>
                        {moment(it.createdAt.seconds * 1000).format('YYMMDD HH:mm')}
                      </Table.Cell>
                      <Table.Cell>
                        {user && (
                          <Fragment>
                            <Image src={user.photoURL} avatar/>
                            {user.displayName}
                            {user.detailProfile && (
                              <Fragment>
                                <br/>
                                <span>
                                ({user.detailProfile.name})
                              </span>
                              </Fragment>
                            )}
                          </Fragment>
                        )}
                      </Table.Cell>
                      <Table.Cell>{numeral(it.discountAmount).format('0,0')}</Table.Cell>
                      <Table.Cell>{numeral(it.deposit).format('0,0')}</Table.Cell>
                      <Table.Cell>{numeral(it.cash).format('0,0')}</Table.Cell>
                      <Table.Cell>{numeral(it.amountPaymentNeeded).format('0,0')}</Table.Cell>
                      <Table.Cell>
                        <Label content={it.transactionType} size={'mini'}/> <br/>
                        {it.bankAccount} {it.bankName}

                      </Table.Cell>
                      <Table.Cell>
                        {(it.transactionType === TRANSACTION_TYPE_WITHDRAW) && (
                          it.withDrawCompleted ? (
                            <Button content={'환급 취소'} negative size={'mini'} onClick={() => {
                              const a = window.confirm('환급을 취소하시겠습니까?');
                              if (!a) return;
                              this._handleCancelReturnDeposit(it)
                            }}/>
                          ) : (
                            <Button content={'환급'} positive size={'mini'} onClick={() => {
                              const a = window.confirm('환급시겠습니까?');
                              if (!a) return;
                              this._handleReturnDeposit(it)
                            }}/>
                          )
                        )
                        }
                      </Table.Cell>
                      <Table.Cell>
                        {bookClub && bookClub.header && bookClub.header.content}
                      </Table.Cell>

                    </Table.Row>
                  )
                })
              }
            </Table.Body>
          </Table>
        </Container>
      </Fragment>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(AdminTransactionMainContainer);