import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import Network from "../../library/Network";
import {Image, Container, Form, Select, Loader, Message, Radio} from 'semantic-ui-react';
import {ESSAY_STATUS_PRIVATE, ESSAY_STATUS_PUBLIC, essayStatusList, NEW_ESSAY} from "../../dataStructure/essay";
import _ from 'lodash';
import moment from 'moment';
import Colors from "../../constants/Colors";
import {firebase} from "../../constants/firebase";
import NetworkApiV1 from "../../library/NetworkApiV1";

class EssayWriteAndModifyContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      book: {},
      bookClubEssaySchedule: {},

      essay: {
        title: '',
        content: '',
        status: ESSAY_STATUS_PUBLIC,
      },

      uploading: false,
      isLoaded: false,
      essayLoaded: false,
    }
  }


  componentDidMount() {
    console.log(this.props.match);
    this._loadBookInfo();
    this._loadEssay();
    window.scrollTo(0, 0);
  }

  _loadEssay = async () => {
    const {essayId} = this.props.location.state;
    let essay;
    if (essayId) {
      essay = await NetworkApiV1.getEssayById(essayId);
      if (essay.err) {
        window.alert('에세이 데이터를 불러오는 중 오류가 발생했습니다.');
        this.props.goBack();
        return;
      }

      this.setState({essay, essayLoaded: true})
    } else {
      this.setState({essayLoaded: true})
    }


  };

  _loadBookInfo = async () => {
    const {bookClubEssayScheduleId, bookId} = this.props.location.state;
    if (!bookClubEssayScheduleId && !bookId) {
      window.alert('책 정보를 찾을 수 없습니다.');
      this.props.history.goBack();
      return;
    }

    if (bookClubEssayScheduleId) {
      // const res
      const res = await NetworkApiV1.getBookClubEssayScheduleAndEssays(bookClubEssayScheduleId);
      this.setState({bookClubEssaySchedule: res})
    }

    if (bookId) {
      const res = await NetworkApiV1.getBookDetail(null, bookId);
      this.setState({book: res, isLoaded: true,})
    } else {
      this.setState({isLoaded: true})
    }
  };

  _handleComplete = async (e) => {
    e.preventDefault();
    const {essay} = this.state;
    if (essay.title.length < 3) {
      return window.alert('제목을 입력해 주세요');
    }

    if (essay.content.length < 10) {
      return window.alert('내용을 입력해 주세요');
    }

    if (this.state.essay.id) {
      // 에세이 수정 ,
      this._handleUpdate()

    } else {
      // 새로운 에세이 작성
      this._handleUpload();

    }
  };

  _handleUpdate = async () => {
    this.setState({uploading: true});
    const {bookClubEssayScheduleId, bookId, essayId} = this.props.location.state;
    const data = {
      content: this.state.essay.content,
      title: this.state.essay.title,
      status: this.state.essay.status,

      bookId: this.state.book ? this.state.book.id : null,
      bookClubEssayScheduleId,
    };

    const res = await NetworkApiV1.updateEssay(essayId, data);
    if (res.err) {
      window.alert('오류\n업로드 중 오류가 발생했습니다. 재시도 해 주세요.');
      this.setState({uploading: false});
    } else {
      window.alert('업로드가 완료되었습니다');
      if (bookClubEssayScheduleId) {
        this.props.history.replace(`/my/bookclub-essay/read/${bookClubEssayScheduleId}`);
      } else {
        this.props.history.replace(`/my/bookclub`);
      }
    }

  };

  _handleUpload = async () => {
    this.setState({uploading: true});
    const {bookClubEssayScheduleId, bookId} = this.props.location.state;
    const data = {
      content: this.state.essay.content,
      title: this.state.essay.title,
      status: this.state.essay.status,

      bookId: this.state.book ? this.state.book.id : null,
      bookClubEssayScheduleId,
    };

    const res = await NetworkApiV1.postEssay(data);
    if (res.err) {
      window.alert('오류\n업로드 중 오류가 발생했습니다. 재시도 해 주세요.');
      this.setState({uploading: false});
    } else {
      window.alert('업로드가 완료되었습니다');
      this.props.history.replace(`/my/bookclub-essay/read/${bookClubEssayScheduleId}`);
    }

  };

  render() {
    const {book, essay, isLoaded, essayLoaded} = this.state;
    if (!essayLoaded || !isLoaded) return <Loader active/>;
    return (
      <Container text style={{marginBottom: '3rem'}}>
        <div style={{display: 'flex', margin: '1rem 0rem'}}>
          <Image src={book.coverImageUrl} size={'tiny'}/>
          <div style={{flex: 1, paddingLeft: '1rem'}}>
            <p style={{fontSize: '1.5rem', fontWeight: 'bold'}}>
              {book.title}
            </p>
            {
              book.bookClubId ? (
                <p>
                  시작일 : {moment(book.startDate).format('YYYY.MM.DD (ddd)')} <br/>
                  종료일 : {moment(book.endDate).format('YYYY.MM.DD (ddd)')} <br/>
                </p>
              ) : (
                <p>

                </p>
              )
            }

          </div>
        </div>
        <Form>
          {
            essayStatusList.map((status, i) => {
              return (
                <Form.Field key={i}>
                  <Radio
                    label={status.text}
                    value={status.value}
                    checked={essay.status === status.value}
                    onChange={() => {
                      essay.status = status.value;
                      this.setState({
                        essay
                      })
                    }}
                  />
                </Form.Field>
              )
            })
          }
          <Message size={'small'}>
            <Message.Content>
              다양한 사람들과 생각을 나눌 수 있도록, 에세이를 공개해 주세요. <br/>
              북클럽 에세이의 경우, 비공개로 설정해도 북클럽 멤버들은 에세이를 볼 수 있습니다.
            </Message.Content>
          </Message>
          <Form.Input
            value={essay.title}
            placeholder={'제목을 입력해 주세요.'}
            label={'제목'}
            onChange={(e, {value}) => {
              essay.title = value;
              this.setState({
                essay
              })
            }}
          />
          <Form.TextArea
            value={essay.content}
            placeholder={'내용을 입력해 주세요.'}
            rows={20}
            label={'본문'}
            onChange={(e, {value}) => {
              essay.content = value;
              this.setState({
                essay
              })
            }}
          />
          <Form.Button
            disabled={this.state.uploading}
            onClick={this._handleComplete}
            style={{
              // border: `1px solid ${Colors.tintColor}`,
              color: 'white',
              backgroundColor: Colors.tintColor,
              fontWeight: '900'
            }}>
            올리기
          </Form.Button>
        </Form>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(EssayWriteAndModifyContainer);