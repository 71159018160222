import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import Network from "../../library/Network";
import {Image, Comment} from 'semantic-ui-react';
import Colors from "../../constants/Colors";

class UserComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {},
    }
  }


  componentDidMount() {
    this._loadUserInfo();

  }


  componentDidUpdate(prevProps, prevState) {
    if(prevProps.userId!==this.props.userId){
      this._loadUserInfo();
    }
  }



  _loadUserInfo = async () => {
    const {userId} = this.props;
    const user = await Network.getUserInfoByUserId(userId);
    if (user.err) return;
    this.setState({
      user,
    })
  };

  render() {

    const {user} = this.state;
    switch (this.props.layout) {
      case 'avatar':
        return (
          <Image
            onClick={() => {
              console.log('2');
              this.props.onClick && this.props.onClick();
            }}
            avatar
            style={{
              border : this.props.selected ? `2px solid ${Colors.mainColor}` : '',
              // borderWidth: this.props.selected ? '1px' : '0px',
              // borderColor: this.props.selected ? Colors.mainColor : 'transparent',
            }}
            src={user.photoURL}
          />
        );


      default:
        return (
          <div>
            UserComponent
          </div>
        )
    }

  }
}

const enhance = connect(
  state => ({
    auth: state.auth,
  }),
  {
    ...authActionCreators,
  }
);


// export default enhance(UserComponent);
export default UserComponent;