import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {authActionCreators} from "../../redux/auth/authStore";
import {
  Button,
  List,
  Container,
  Header,
  Form,
  Divider,
  Grid,
  Image,
  Icon,
  Card,
  Loader,
  Segment,
  Radio,

} from "semantic-ui-react";
import {db, firebase} from "../../constants/firebase";
import _ from 'lodash';
import moment from 'moment';
import {
  curationCategoryDropDownList,
  curationLayoutDropDownList,
  NEW_CURATION
} from "../../dataStructure/curation";
import {moveItemInArrayFromIndexToIndex} from "../../middleware/arrayHandler";
import mockUpImages from "../../constants/mockUpImages";
import {NEW_DESCRIPTION_MODULE} from "../../dataStructure/descriptionModule";
import FileUploader from "react-firebase-file-uploader";
import Network from "../../library/Network";
import {kakaoMapSearch} from "../../middleware/SearchLocation";
import GoogleMapComponent from '../../components/map/GoogleMapComponent';
import {GoogleMapKey} from "../../config/keys";


const storageRef = `curation/${moment().format("YYYYMM")}`;


class AdminCurationDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curation: props.location.state ? props.location.state.curation : {},
      isLoaded: false,
      isNewCuration: null,
      isUploading: false,
      uploadProgress: 0,

      locationSearchKeyword: '',
      searchedLocations: [],
      isEnd: false,
      pageableCount: 0,
      totalCount: 0,
    };
  }


  componentDidMount() {
    this._loadCuration();
  }


  _loadCuration = async () => {
    const isNewCuration = this.props.match.params.curationId === undefined;
    console.log({
      isNewCuration
    });

    if (isNewCuration) {
      this.setState({
        isNewCuration,
        curation: _.cloneDeep(NEW_CURATION),
        isLoaded: true,
      })
    } else {
      const {curationId} = this.props.match.params;

      const curation = await Network.getCurationByCurationId(curationId);
      if (curation.err) return window.alert('경고\n 큐레이션을 불러올 수 없습니다.');

      curation.createdAt = new Date(moment(curation.createdAt ? curation.createdAt.seconds * 1000 : null).toISOString());
      curation.modifiedAt = new Date(moment(curation.modifiedAt ? curation.modifiedAt.seconds * 1000 : null).toISOString());

      this.setState({
        curation,
        isNewCuration,
        isLoaded: true,
      })
    }
  };

  handleChange = e => {
    const {curation} = this.state;
    const {name, value} = e.target;
    curation[name] = value;
    this.setState({
      curation
    });
  };

  _updateOrAddCuration = async () => {

    const confirm = window.confirm('업로드 하시겠습니까?');
    if (!confirm) return;

    const isNewCuration = this.props.match.params.curationId === undefined;
    const {curation} = this.state;

    curation.order = Number(curation.order);

    if (isNewCuration) {

      curation.createdAt = new Date();
      curation.modifiedAt = new Date();

      const res = await Network.postCuration(curation);
      if (res.err) return window.alert('큐레이션 등록이 싈패하였습니다.');

      window.alert('큐레이션이 등록되었습니다.');
      this.props.history.replace(`/admin/curation/read/${res.id}`);


    } else {
      curation.modifiedAt = new Date();
      const res = await Network.updateCuration(curation);
      if (res.err) return window.alert('큐레이션 수정에 실패하였습니다.');
      window.alert('수정되었습니다.')
      this._loadCuration();
    }

  };

  _deleteCuration = async () => {
    const confirm = window.confirm("삭제하시겠습니까?");
    if (!confirm) return;
    const res = await Network.updateCuration({
      id: this.state.curation.id,
      isDeleted: true
    });
    if (res.err) return window.alert('삭제에 실패하였습니다.');

    window.alert('삭제되었습니다');
    this.props.history.replace('/admin/curation')
  };

  _restoreCuration = async () => {
    const confirm = window.confirm("복구하시겠습니까?");
    if (!confirm) return;

    const res = await Network.updateCuration({
      id: this.state.curation.id,
      isDeleted: false
    });
    if (res.err) return window.alert('실패하였습니다.');

    window.alert('복구 되었습니다');
    this._loadCuration();
  };

  handleLocationSearch = async () => {
    if (this.state.locationSearchKeyword === '') return window.alert('검색어를 입력해주세요');
    const res = await kakaoMapSearch(this.state.locationSearchKeyword);
    console.log(res);
    this.setState({
      searchedLocations: res.data && res.data.documents,
      isEnd: res.data && res.data.meta.is_end,
    })
  };



  handleUploadSuccess = async (filename, imageCategory) => {
    const {curation} = this.state;
    this.setState({progress: 100, isUploading: false});
    await firebase.storage()
      .ref(storageRef)
      .child(filename)
      .getDownloadURL()
      .then(imageUrl => {
        curation.image[imageCategory] = imageUrl;
        console.log(curation);
        this.setState({curation})
      });
  };

  render() {
    const {curation, isLoaded, isNewCuration} = this.state;
    if (!isLoaded) {
      return (
        <Loader active/>
      )
    }
    return (
      <Fragment>
        <Container as={Segment} vertical basic>
          <Button
            content={isNewCuration ? '만들기' : '수정하기'}
            onClick={this._updateOrAddCuration}
            primary/>
          <Button
            content={'삭제하기'}
            onClick={this._deleteCuration}
            disabled={isNewCuration}
            negative/>
          <Button
            content={'복구하기'}
            onClick={this._restoreCuration}
            disabled={isNewCuration || !curation.isDeleted}
            positive/>
        </Container>
        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'정보'} dividing size={'huge'}/>
          <Form>
            <Form.Field>
              <Radio
                label={curation.isPublic ? '공개' : '비공개'}
                toggle
                name='radioGroup'
                value='right'
                checked={curation.isPublic}
                onChange={e => {
                  curation.isPublic = !curation.isPublic;
                  this.setState({
                    curation
                  })
                }}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label={curation.onlyForNewUser ? '신규 회원 전용' : '모든 회원용'}
                toggle
                name='onlyForNewUser'
                value='right'
                checked={curation.onlyForNewUser}
                onChange={e => {
                  curation.onlyForNewUser = !curation.onlyForNewUser;
                  this.setState({
                    curation
                  })
                }}
              />
            </Form.Field>

            {/* 날자 */}
            <Fragment>
              <Form.Group>
                <Form.Input
                  disabled={true}
                  width={4}
                  name={'createdAt'}
                  label={'createdAt'}
                  type={'datetime-local'}
                  value={moment(curation.createdAt).format('YYYY-MM-DDThh:mm')}
                  onChange={(e, {value, name}) => {
                    curation.createdAt = new Date(value);
                    this.setState({
                      curation
                    })
                  }}
                />
                <Form.Input
                  disabled={true}
                  width={4}
                  name={'modifiedAt'}
                  label={'modifiedAt'}
                  type={'datetime-local'}
                  value={moment(curation.modifiedAt).format('YYYY-MM-DDThh:mm')}
                  onChange={(e, {value, name}) => {
                    curation.modifiedAt = new Date(value);
                    this.setState({
                      curation
                    })
                  }}
                />
                <Form.Input
                  width={4}
                  label="Order"
                  name="order"
                  value={curation.order.toString()}
                  onChange={(e, {value}) => {
                    curation.order = Number(value);
                    this.setState({
                      curation
                    })
                  }}
                />
                <Form.Input
                  width={4}
                  name={'tag'}
                  label={'tag'}
                  value={curation.tag}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Fragment>

            <Fragment>
              <Form.Group widths={'equal'}>
                <Form.Dropdown
                  placeholder={'CURATION CATEGORY'}
                  selection
                  label="카테고리"
                  name="category"
                  value={curation.category}
                  options={curationCategoryDropDownList()}
                  onChange={(e, {value}) => {
                    curation.category = value;
                    this.setState({
                      curation
                    })
                  }}
                />
                <Form.Dropdown
                  placeholder={'LAYOUT'}
                  selection
                  label="LAYOUT"
                  name="layout"
                  value={curation.layout}
                  options={curationLayoutDropDownList()}
                  onChange={(e, {value}) => {
                    curation.layout = value;
                    this.setState({
                      curation
                    })
                  }}
                />
              </Form.Group>

            </Fragment>

            <Fragment>
              <Form.Group>
                <Form.Input
                  name={'title'}
                  label={'title'}
                  value={curation.title.content}
                  onChange={e => {
                    curation.title.content = e.target.value;
                    this.setState({curation})
                  }}
                  width={12}
                />
                <Form.Input
                  name={'titleColor'}
                  label={'titleColor'}
                  value={curation.title.color}
                  onChange={e => {
                    curation.title.color = e.target.value;
                    this.setState({curation})
                  }}
                  width={4}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <Radio
                    label='왼쪽 정렬'
                    name='title'
                    value='left'
                    checked={curation.title.align === 'left'}
                    onChange={(e) => {
                      curation.title.align = 'left';
                      this.setState({
                        curation
                      })
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label='가운데 정렬'
                    name='title'
                    value='center'
                    checked={curation.title.align === 'center'}
                    onChange={e => {
                      curation.title.align = 'center';
                      this.setState({
                        curation
                      })
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label='오른쪽 정렬'
                    name='title'
                    value='right'
                    checked={curation.title.align === 'right'}
                    onChange={e => {
                      curation.title.align = 'right';
                      this.setState({
                        curation
                      })
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Fragment>

            <Fragment>

              <Form.Group>
                <Form.Input
                  name={'subTitle'}
                  label={'subTitle'}
                  value={curation.subTitle.content}
                  onChange={e => {
                    curation.subTitle.content = e.target.value;
                    this.setState({curation})
                  }}
                  width={12}
                />
                <Form.Input
                  name={'subTitleColor'}
                  label={'subTitleColor'}
                  value={curation.subTitle.color}
                  onChange={e => {
                    curation.subTitle.color = e.target.value;
                    this.setState({curation})
                  }}
                  width={4}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <Radio
                    label='왼쪽 정렬'
                    name='subTitle'
                    value='left'
                    checked={curation.subTitle.align === 'left'}
                    onChange={(e) => {
                      curation.subTitle.align = 'left';
                      this.setState({
                        curation
                      })
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label='가운데 정렬'
                    name='subTitle'
                    value='center'
                    checked={curation.subTitle.align === 'center'}
                    onChange={e => {
                      curation.subTitle.align = 'center';
                      this.setState({
                        curation
                      })
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label='오른쪽 정렬'
                    name='subTitle'
                    value='right'
                    checked={curation.subTitle.align === 'right'}
                    onChange={e => {
                      curation.subTitle.align = 'right';
                      this.setState({
                        curation
                      })
                    }}
                  />
                </Form.Field>
              </Form.Group>

            </Fragment>

            <Fragment>
              <Form.Group>
                <Form.TextArea
                  name={'description'}
                  label={'description'}
                  rows={10}
                  style={{
                    textAlign: curation.description.align,
                    color: curation.description.color,

                  }}
                  value={curation.description.content}
                  onChange={e => {
                    curation.description.content = e.target.value;
                    this.setState({curation})
                  }}
                  width={12}
                />
                <Form.Input
                  name={'descriptionColor'}
                  label={'descriptionColor'}
                  value={curation.description.color}
                  onChange={e => {
                    curation.description.color = e.target.value;
                    this.setState({curation})
                  }}
                  width={4}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <Radio
                    label='왼쪽 정렬'
                    name='description'
                    value='left'
                    checked={curation.description.align === 'left'}
                    onChange={(e) => {
                      curation.description.align = 'left';
                      this.setState({
                        curation
                      })
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label='가운데 정렬'
                    name='description'
                    value='center'
                    checked={curation.description.align === 'center'}
                    onChange={e => {
                      curation.description.align = 'center';
                      this.setState({
                        curation
                      })
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label='오른쪽 정렬'
                    name='description'
                    value='right'
                    checked={curation.description.align === 'right'}
                    onChange={e => {
                      curation.description.align = 'right';
                      this.setState({
                        curation
                      })
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Fragment>
          </Form>
        </Container>

        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'하단 버튼 및 아이템 개수'} dividing size={'huge'}/>
          <Form>
            <Form.Field width={4}>
              <Radio
                toggle
                label={'버튼 노출 여부'}
                checked={curation.hasButton}
                name={'hasButton'}
                onClick={() => {
                  curation.hasButton = !curation.hasButton;
                  this.setState({
                    curation
                  })
                }}
              />
            </Form.Field>

            <Form.Group>
              <Form.Input
                width={8}
                name={'buttonText'}
                label={'buttonText'}
                disabled={!curation.hasButton}
                value={curation.buttonText}
                onChange={this.handleChange}
              />
              <Form.Input
                width={4}
                name={'buttonTextColor'}
                label={'buttonTextColor'}
                disabled={!curation.hasButton}
                value={curation.buttonTextColor}
                onChange={this.handleChange}
              />
              <Form.Input
                width={4}
                name={'buttonBorderColor'}
                label={'buttonBorderColor'}
                disabled={!curation.hasButton}
                value={curation.buttonBorderColor}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Input
              width={4}
              name={'maxNumberOfItems'}
              label={'maxNumberOfItems'}
              value={curation.maxNumberOfItems.toString()}
              onChange={(e, {value}) => {
                curation.maxNumberOfItems = _.isNumber(Number(value)) ? Number(value) : 0;
                this.setState({
                  curation
                })
              }}
            />

          </Form>
        </Container>

        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'배경 및 사진'} dividing size={'huge'}/>
          <Form>
            <Form.Group>
              <Form.Input
                label={'배경 색'}
                name={'backgroundColor'}
                value={curation.backgroundColor}
                onChange={this.handleChange}
              />
              <Form.Input
                name={'bottomModuleBackgroundColor'}
                label={'bottomModuleBackgroundColor'}
                value={curation.bottomModuleBackgroundColor}
                onChange={this.handleChange}
              />

              <Form.Input
                label={'텍스트 색'}
                name={'textColor'}
                style={{
                  color: curation.textColor,
                }}
                value={curation.textColor}
                onChange={this.handleChange}
              />
              <Form.Input
                label={'headerColor'}
                name={'headerColor'}
                value={curation.headerColor}
                onChange={this.handleChange}
              />
              <Form.Input
                label={'subHeaderColor'}
                name={'subHeaderColor'}
                value={curation.subHeaderColor}
                onChange={this.handleChange}
              />
              <Form.Input
                label={'descriptionColor'}
                name={'descriptionColor'}
                value={curation.descriptionColor}
                onChange={this.handleChange}
              />


            </Form.Group>
          </Form>

          <Divider/>

          <Grid>
            <Grid.Row columns={6}>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  MAIN
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          curation.imageUrl = imageUrl;
                          this.setState({
                            curation
                          })
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(curation.imageUrl)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    curation.imageUrl = '';
                    this.setState({
                      curation
                    })
                  }}/>
                <Image src={curation.imageUrl || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  배경
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          curation.backgroundImage = imageUrl;
                          this.setState({
                            curation
                          })
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(curation.backgroundImage)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    curation.backgroundImage = '';
                    this.setState({
                      curation
                    })
                  }}/>
                <Image src={curation.backgroundImage || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  배경 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          curation.backgroundImageLarge = imageUrl;
                          this.setState({
                            curation
                          })
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(curation.backgroundImageLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    curation.backgroundImageLarge = '';
                    this.setState({
                      curation
                    })
                  }}/>
                <Image src={curation.backgroundImageLarge || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={6}>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  정사각
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="square"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'square');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(curation.image && curation.image.square)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    curation.image.square = '';
                    this.setState({
                      curation
                    })
                  }}/>
                <Image src={(curation.image && curation.image.square) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  가로
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="horizontal"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'horizontal');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(curation.image && curation.image.horizontal)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    curation.image.horizontal = ''
                    this.setState({
                      curation
                    })
                  }}/>
                <Image src={(curation.image && curation.image.horizontal) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  세로
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="vertical"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'vertical');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(curation.image && curation.image.vertical)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    curation.image.vertical = ''
                    this.setState({
                      curation
                    })
                  }}/>
                <Image src={(curation.image && curation.image.vertical) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  정사각 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="imageSquareLarge"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'squareLarge');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(curation.image && curation.image.squareLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    curation.image.squareLarge = ''
                    this.setState({
                      curation
                    })
                  }}/>
                <Image src={(curation.image && curation.image.squareLarge) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  가로 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="imageHorizontalLarge"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'horizontalLarge');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(curation.image && curation.image.horizontalLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    curation.image.horizontalLarge = '';
                    this.setState({
                      curation
                    })
                  }}/>
                <Image src={(curation.image && curation.image.horizontalLarge) || mockUpImages.planeImage}
                       size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  세로 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="imageVerticalLarge"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'verticalLarge');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(curation.image && curation.image.verticalLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    curation.image.verticalLarge = ''
                    this.setState({
                      curation
                    })
                  }}/>
                <Image src={(curation.image && curation.image.verticalLarge) || mockUpImages.planeImage}
                       size={'medium'}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'설명모듈'} dividing size={'huge'}/>
          <Button
            content={'모듈 추가하기'}
            onClick={() => {
              curation.descriptionModules.push(_.cloneDeep(NEW_DESCRIPTION_MODULE));
              this.setState({curation})
            }}/>
          <Divider hidden/>
          <Grid>
            {
              curation.descriptionModules.map((module, i) => {
                return (
                  <Grid.Row key={i}>
                    <Grid.Column width={2}>
                      <Button
                        size={'mini'}
                        icon
                        negative
                        onClick={() => {
                          const a = window.confirm('삭제하시겠습니까?');
                          if (!a) return;
                          curation.descriptionModules.splice(i, 1);
                          this.setState({curation})
                        }}>
                        <Icon name={'remove'}/>
                      </Button>
                      <Button
                        size={'mini'}
                        icon
                        disabled={i === 0}
                        onClick={() => {
                          curation.descriptionModules = moveItemInArrayFromIndexToIndex(curation.descriptionModules, i, i - 1);
                          this.setState({curation})
                        }}>
                        <Icon name={'angle up'}/>
                      </Button>
                      <Button
                        size={'mini'}
                        icon
                        disabled={i === curation.descriptionModules.length - 1}
                        onClick={() => {
                          curation.descriptionModules = moveItemInArrayFromIndexToIndex(curation.descriptionModules, i, i + 1);
                          this.setState({curation})
                        }}>
                        <Icon name={'angle down'}/>
                      </Button>
                    </Grid.Column>
                    <Grid.Column width={14}>
                      <Card fluid>
                        <Card.Content>
                          <Form key={i}>
                            {/* header */}
                            <Form.Group style={{alignItems: 'center'}}>
                              <Form.Input
                                width={10}
                                label={'header'}
                                value={module.header.content}
                                onChange={(e, {value}) => {
                                  curation.descriptionModules[i].header.content = value;
                                  this.setState({
                                    curation
                                  })
                                }}
                              />
                              <Form.Input
                                width={3}
                                label={'color'}
                                value={module.header.color}
                                onChange={(e, {value}) => {
                                  curation.descriptionModules[i].header.color = value;
                                  this.setState({
                                    curation
                                  })
                                }}
                              />
                              <div style={{marginTop: 30}}>
                                <Form.Field>
                                  <Radio
                                    label='LEFT'
                                    name={`header-${i}`}
                                    value='left'
                                    checked={module.header.align === 'left'}
                                    onChange={(e) => {
                                      curation.descriptionModules[i].header.align = 'left';
                                      this.setState({
                                        curation
                                      })
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <Radio
                                    label='CENTER'
                                    name={`header-${i}`}
                                    value='center'
                                    checked={module.header.align === 'center'}
                                    onChange={(e) => {
                                      curation.descriptionModules[i].header.align = 'center';
                                      this.setState({
                                        curation
                                      })
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <Radio
                                    label='RIGHT'
                                    name={`header-${i}`}
                                    value='right'
                                    checked={module.header.align === 'right'}
                                    onChange={(e) => {
                                      curation.descriptionModules[i].header.align = 'right';
                                      this.setState({
                                        curation
                                      })
                                    }}
                                  />
                                </Form.Field>
                              </div>
                            </Form.Group>

                            {/* subHeader */}
                            <Form.Group style={{alignItems: 'center'}}>
                              <Form.Input
                                width={10}
                                label={'subHeader'}
                                value={module.subHeader.content}
                                onChange={(e, {value}) => {
                                  curation.descriptionModules[i].subHeader.content = value;
                                  this.setState({
                                    curation
                                  })
                                }}
                              />
                              <Form.Input
                                width={3}
                                label={'color'}
                                value={module.subHeader.color}
                                onChange={(e, {value}) => {
                                  curation.descriptionModules[i].subHeader.color = value;
                                  this.setState({
                                    curation
                                  })
                                }}
                              />
                              <div style={{marginTop: 30}}>
                                <Form.Field>
                                  <Radio
                                    label='LEFT'
                                    name={`subHeader-${i}`}
                                    value='left'
                                    checked={module.subHeader.align === 'left'}
                                    onChange={(e) => {
                                      curation.descriptionModules[i].subHeader.align = 'left';
                                      this.setState({
                                        curation
                                      })
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <Radio
                                    label='CENTER'
                                    name={`subHeader-${i}`}
                                    value='center'
                                    checked={module.subHeader.align === 'center'}
                                    onChange={(e) => {
                                      curation.descriptionModules[i].subHeader.align = 'center';
                                      this.setState({
                                        curation
                                      })
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <Radio
                                    label='RIGHT'
                                    name={`subHeader-${i}`}
                                    value='right'
                                    checked={module.subHeader.align === 'right'}
                                    onChange={(e) => {
                                      curation.descriptionModules[i].subHeader.align = 'right';
                                      this.setState({
                                        curation
                                      })
                                    }}
                                  />
                                </Form.Field>
                              </div>
                            </Form.Group>

                            {/* description */}
                            <Form.Group>
                              <Form.TextArea
                                width={10}
                                label={'description'}
                                rows={10}
                                value={module.description.content}
                                onChange={(e, {value}) => {
                                  curation.descriptionModules[i].description.content = value;
                                  this.setState({
                                    curation
                                  })
                                }}
                              />

                              <Form.Input
                                width={3}
                                label={'color'}
                                value={module.description.color}
                                onChange={(e, {value}) => {
                                  curation.descriptionModules[i].description.color = value;
                                  this.setState({
                                    curation
                                  })
                                }}
                              />
                              <div style={{marginTop: 23}}>
                                <Form.Field>
                                  <Radio
                                    label='LEFT'
                                    name='title'
                                    value='left'
                                    checked={module.description.align === 'left'}
                                    onChange={(e) => {
                                      curation.descriptionModules[i].description.align = 'left';
                                      this.setState({
                                        curation
                                      })
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <Radio
                                    label='CENTER'
                                    name='title'
                                    value='left'
                                    checked={module.description.align === 'center'}
                                    onChange={(e) => {
                                      curation.descriptionModules[i].description.align = 'center';
                                      this.setState({
                                        curation
                                      })
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <Radio
                                    label='RIGHT'
                                    name='title'
                                    value='left'
                                    checked={module.description.align === 'right'}
                                    onChange={(e) => {
                                      curation.descriptionModules[i].description.align = 'right';
                                      this.setState({
                                        curation
                                      })
                                    }}
                                  />
                                </Form.Field>
                              </div>
                            </Form.Group>
                          </Form>
                        </Card.Content>
                        <Card.Content>
                          <Form>
                            <Form.Field>
                              <Radio
                                toggle
                                label={module.hasYouTubeId ? 'YouTube 영상 있음' : 'YouTube 영상 없음'}
                                checked={module.hasYouTubeId}
                                onChange={() => {
                                  curation.descriptionModules[i].hasYouTubeId = !module.hasYouTubeId;
                                  this.setState({curation})
                                }}
                              />
                            </Form.Field>
                            <Form.Input
                              disabled={!module.hasYouTubeId}
                              width={10}
                              label={'youTubeId'}
                              value={module.youTubeId}
                              onChange={(e, {value}) => {
                                curation.descriptionModules[i].youTubeId = value;
                                this.setState({
                                  curation
                                })
                              }}
                            />
                          </Form>
                        </Card.Content>
                        <Card.Content>
                          <Form>
                            <Form.Field>
                              <Radio
                                toggle
                                label={module.hasButton ? '버튼있음' : '버튼없음'}
                                checked={module.hasButton}
                                onChange={() => {
                                  curation.descriptionModules[i].hasButton = !module.hasButton;
                                  this.setState({curation})
                                }}
                              />
                            </Form.Field>
                            <Form.Input
                              disabled={!module.hasButton}
                              width={10}
                              label={'externalLink'}
                              value={module.externalLink}
                              onChange={(e, {value}) => {
                                curation.descriptionModules[i].externalLink = value;
                                this.setState({
                                  curation
                                })
                              }}
                            />
                            <Form.Input
                              disabled={!module.hasButton}
                              width={10}
                              label={'buttonText'}
                              value={module.buttonText}
                              onChange={(e, {value}) => {
                                curation.descriptionModules[i].buttonText = value;
                                this.setState({
                                  curation
                                })
                              }}
                            />

                          </Form>
                        </Card.Content>
                        <Card.Content>
                          {/* 이미지 추가 */}
                          <Header size={'small'}>
                            이미지 추가
                          </Header>
                          <Button as={'label'} size={'mini'}>
                            이미지 추가
                            <FileUploader
                              hidden
                              accept="image/*"
                              name={`module${i}`}
                              randomizeFilename
                              storageRef={firebase.storage().ref(storageRef)}
                              onUploadSuccess={async filename => {
                                await firebase.storage()
                                  .ref(storageRef)
                                  .child(filename)
                                  .getDownloadURL()
                                  .then(imageUrl => {
                                    curation.descriptionModules[i].imageUrl = imageUrl;
                                    this.setState({curation})
                                  });
                              }}
                            />
                          </Button>
                          <Button
                            size={'mini'}
                            negative
                            disabled={!module.imageUrl}
                            content={'이미지 삭제'}
                            onClick={() => {
                              const a = window.confirm('이미지를 삭제하시겠습니까?');
                              if (!a) return;
                              curation.descriptionModules[i].imageUrl = '';
                              this.setState({
                                curation
                              })
                            }}/>
                          <Divider hidden/>
                          <Form>
                            <Form.Field>
                              <Radio
                                toggle
                                label={module.isImageFullWidth ? 'FULL WIDTH' : 'FULL WIDTH 아님'}
                                checked={module.isImageFullWidth}
                                onChange={() => {
                                  curation.descriptionModules[i].isImageFullWidth = !module.isImageFullWidth;
                                  this.setState({curation})
                                }}
                              />
                            </Form.Field>
                          </Form>
                          <Divider hidden/>
                          <Image src={module.imageUrl || mockUpImages.planeImage} size={'small'}/>
                        </Card.Content>

                        <Card.Content>
                          {/* 지도 추가 */}
                          <Header size={'small'}>
                            지도 추가
                          </Header>
                          <Form>
                            <Form.Field>
                              <Radio
                                toggle
                                label={module.hasLocation ? '지도 있음' : '지도 없음'}
                                checked={module.hasLocation}
                                onChange={() => {
                                  curation.descriptionModules[i].hasLocation = !module.hasLocation;
                                  this.setState({curation})
                                }}
                              />
                            </Form.Field>

                            <Form.Input
                              disabled={!module.hasLocation}
                              width={10}
                              label={'장소명'}
                              value={module.locationName}
                              onChange={(e, {value}) => {
                                curation.descriptionModules[i].locationName = value;
                                this.setState({curation})
                              }}
                            />

                            <Form.Input
                              disabled={!module.hasLocation}
                              width={10}
                              label={'검색하기'}
                              value={this.state.locationSearchKeyword}
                              onChange={(e, {value}) => {
                                this.setState({locationSearchKeyword: value})
                              }}
                            />
                            <Button content={'검색'}
                                    onClick={async ()=>{
                                      if (this.state.locationSearchKeyword === '') return window.alert('검색어를 입력해주세요');
                                      const res = await kakaoMapSearch(this.state.locationSearchKeyword);
                                      console.log(res);
                                      this.setState({
                                        searchedLocations: res.data && res.data.documents,
                                        isEnd: res.data && res.data.meta.is_end,
                                      })
                                    }}/>
                            <List>
                              {
                                this.state.searchedLocations.map((location, j) => {
                                  return (
                                    <List.Item key={j}>
                                      <List.Header onClick={() => {
                                        const locationCoordinate = new firebase.firestore.GeoPoint(Number(location.y), Number(location.x));

                                        console.log(locationCoordinate.latitude);
                                        console.log(locationCoordinate.longitude);
                                        curation.descriptionModules[i].locationCoordinate = locationCoordinate;

                                        this.setState({
                                          curation
                                        })
                                      }}>{location.place_name}</List.Header>
                                    </List.Item>
                                  )
                                })
                              }
                            </List>
                          </Form>
                          {
                            module.hasLocation && (
                              <GoogleMapComponent
                                isMarkerShown
                                location={module.locationCoordinate}
                                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapKey}&v=3.exp&libraries=geometry,drawing,places`}
                                loadingElement={<div style={{height: `100%`}}/>}
                                containerElement={<div style={{height: `400px`}}/>}
                                mapElement={<div style={{height: `100%`}}/>}
                              />
                            )
                          }

                        </Card.Content>
                      </Card>
                    </Grid.Column>
                  </Grid.Row>

                )
              })
            }
          </Grid>


        </Container>
      </Fragment>
    );
  }

}

const styles = {
  verticalModule: {
    marginBottom: 50,
    marginTop: 50
  },
  header: {
    marginTop: 40,
  }
};

const enhance = connect(
  state => ({
    auth: state.auth
  }),
  {
    ...authActionCreators
  }
);
export default enhance(AdminCurationDetailContainer);
