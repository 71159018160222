export const NEW_INQUIRY = () => {
  return {
    userId: '',

    question: '',
    answer: '',

    rate: 0,
    like: false,

    answerImages: [],
    questionImage: [],

    isAnswered: false,
    isDeleted: false,
    isPublic: true,
    createdAt: new Date(),
    modifiedAt: new Date(),

    answeredAt: new Date(),
  }
};