import React, {Component} from 'react';
import axios from 'axios';
import {API_NEW} from "../../constants/API";
import {Card, Container, Segment, Grid, Header, Image, Loader} from 'semantic-ui-react'
import ScrapComponent from "../scrap/ScrapComponent";

class BookClubManageScrapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAndScraps: [],
      isLoaded: false,

    }
  }


  componentDidMount() {
    this._loadScraps();
  }

  _loadScraps = async () => {
    const {bookClubId, isbn, book} = this.props;
    await axios
      .get(`${API_NEW}/scrap/book-club`, {
        params: {
          bookClubId,
          isbn,
        }
      })
      .then(res => {
        console.log(res.data);
        this.setState({
          userAndScraps: res.data,
          isLoaded: true,
        })
      })
      .catch(e => {
        console.log(e);
      })
  };

  render() {
    const {userAndScraps, isLoaded} = this.state;
    const {book} = this.props;

    if (!isLoaded) return <Loader/>;

    return (
      <Container fluid>
        <Grid>
          <Grid.Row>
            <Grid.Column width={3}>
              <Image
                src={book.cover}
              />
              <Header content={book.title}/>
              <p>
                {book.publisher}
              </p>
            </Grid.Column>
            <Grid.Column width={13}>
              {
                userAndScraps.map((item, i) => {
                  return (
                    <Segment key={i}>
                      <Header content={item.user.displayName + ' ' + item.scraps.length}/>

                      <Card.Group itemsPerRow={6} doubling>
                        {
                          item.scraps.map((scrap, i) => {
                            return (
                              <ScrapComponent
                                showsUserInfo
                                showsBookInfo
                                key={i}
                                scrap={scrap}/>
                            )
                          })
                        }
                      </Card.Group>
                    </Segment>
                  )
                })
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </Container>
    );
  }
}


export default BookClubManageScrapComponent;
