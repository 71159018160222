import _ from "lodash";

export const NOTIFICATION_TYPE_BOOK_CLUB = 'NOTIFICATION_TYPE_BOOK_CLUB';
export const NOTIFICATION_TYPE_BOOK_CLUB_APPLY_STATUS = 'NOTIFICATION_TYPE_BOOK_CLUB_APPLY_STATUS';
export const NOTIFICATION_TYPE_BOOK_CLUB_MEMBER = 'NOTIFICATION_TYPE_BOOK_CLUB_MEMBER';

export const NOTIFICATION_TYPE_ESSAY = 'NOTIFICATION_TYPE_ESSAY';
export const NOTIFICATION_TYPE_ESSAY_COMMENT = 'NOTIFICATION_TYPE_ESSAY_COMMENT';
export const NOTIFICATION_TYPE_ESSAY_LIKE = 'NOTIFICATION_TYPE_ESSAY_LIKE';

export const NOTIFICATION_TYPE_SCRAP = 'NOTIFICATION_TYPE_SCRAP';
export const NOTIFICATION_TYPE_SCRAP_COMMENT = 'NOTIFICATION_TYPE_SCRAP_COMMENT';
export const NOTIFICATION_TYPE_SCRAP_LIKE = 'NOTIFICATION_TYPE_SCRAP_LIKE';

export const NOTIFICATION_TYPE_CASH = 'NOTIFICATION_TYPE_CASH';

export const NOTIFICATION_TYPE_USER = 'NOTIFICATION_TYPE_USER';
export const NOTIFICATION_TYPE_FOLLOW = 'NOTIFICATION_TYPE_FOLLOW';
export const NOTIFICATION_TYPE_INQUIRY = 'NOTIFICATION_TYPE_INQUIRY';

export const NOTIFICATION_TYPE_STORY = 'NOTIFICATION_TYPE_STORY';

const pushNotificationTypeList = [
  NOTIFICATION_TYPE_BOOK_CLUB,
  NOTIFICATION_TYPE_BOOK_CLUB_APPLY_STATUS,
  NOTIFICATION_TYPE_BOOK_CLUB_MEMBER,
  NOTIFICATION_TYPE_ESSAY,
  NOTIFICATION_TYPE_ESSAY_COMMENT,
  NOTIFICATION_TYPE_ESSAY_LIKE,
  NOTIFICATION_TYPE_SCRAP,
  NOTIFICATION_TYPE_SCRAP_COMMENT,
  NOTIFICATION_TYPE_SCRAP_LIKE,
  NOTIFICATION_TYPE_CASH,
  NOTIFICATION_TYPE_USER,
  NOTIFICATION_TYPE_FOLLOW,
  NOTIFICATION_TYPE_INQUIRY,
  NOTIFICATION_TYPE_STORY,

];

export const getPushNotificationDropDownList = () => {
  return _.map(pushNotificationTypeList, (type, i) => ({
    key: i,
    text: type,
    value: type,
  }))
};

export const getNewPushNotification = () => {
  return {
    notification: {
      title: '',
      body: '',
      sound: 'default',
    },
    data: {
      type: '',
      bookClubId: '',
      essayId: '',
      inquiryId: '',
      scrapId: '',
      userId: '',
      transactionId: '',
      storyId: '',
    }
  };

};