import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {authActionCreators} from "../../redux/auth/authStore";
import {
  Button,
  Table,
  Container,
  Modal,
  List,
  Header,
  Form,
  Divider,
  Grid,
  Image,
  Icon,
  Input,
  Card,
  Loader,
  Segment,
  Radio,
} from "semantic-ui-react";
import {db} from "../../constants/firebase";
import {firebase} from "../../constants/firebase";
import FileUploader from "react-firebase-file-uploader";
import moment from "moment";
import _ from 'lodash';
import {
  bookClubHolderDropDownList,
  bookClubStatusDropDownList,
  HELD_BY_PARTNER,
  NEW_BOOK_CLUB
} from "../../dataStructure/bookClub";
import mockUpImages from "../../constants/mockUpImages";
import {moveItemInArrayFromIndexToIndex} from "../../middleware/arrayHandler";
import {NEW_DESCRIPTION_MODULE} from "../../dataStructure/descriptionModule";
import SearchBook, {QUERY_TYPE_KEYWORD} from "../../middleware/SearchBook";
import GoogleMapComponent from '../../components/map/GoogleMapComponent';
import {GoogleMapKey} from "../../config/keys";
import {kakaoMapSearch} from "../../middleware/SearchLocation";
import {getColorCode} from "../../constants/Colors";
import {contentTypeDropDownList, NEW_CONTENT_MODULE} from "../../dataStructure/contentModule";
import ContentModuleComponent from "../../components/contentModule/ContentModuleComponent";


const storageRef = `bookclub/${moment().format("YYYYMM")}`;
const getTextAlignDropDownList = () => {
  return [
    {
      key: 0,
      value: 'left',
      text: 'left'
    },
    {
      key: 1,
      value: 'center',
      text: 'center'
    },
    {
      key: 2,
      value: 'right',
      text: 'right'
    }
  ]
};

class AdminBookClubDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookClub: props.location.state ? props.location.state.bookClub : {},
      isNew: this.props.match.params.bookClubId === undefined,
      bookClubBooks: [],
      bookClubEssaySchedules: [],

      isLoaded: false,
      isNewBookClub: null,
      isUploading: false,
      uploadProgress: 0,

      searchLeaderValue: '',
      searchedLeaderList: [],

      searchBookValue: '',
      searchedBookList: [],

      searchEssayScheduleValue: '',
      searchedEssayScheduleList: [],

      partners: [],
      partnerList: [],

      bookClubBookSearchVisible: false,
      bookClubEssayScheduleSearchVisible: false,


      locationSearchKeyword: '',
      searchedLocations: [],
      isEnd: false,
      pageableCount: 0,
      totalCount: 0,


      modalOpen: false,
    };
  }

  componentDidMount() {
    // console.log(this.state.bookClub);
    this._loadBookClub();
    this._loadPartner();
    this._loadLeader()
  }

  _loadPartner = async () => {
    db.collection('partners')
      .get()
      .then(snapshot => {
        const partners = [];
        snapshot.forEach(doc => {
          let partner = Object.assign(doc.data(), {id: doc.id});
          partners.push(partner)
        });

        let partnerList = _.map(partners, (partner, index) => ({
          key: index,
          text: partner.name,
          value: partner.id
        }));
        partnerList = [{key: 'a', text: '없음', value: ''}, ...partnerList];
        this.setState({
          partners,
          partnerList
        })
      })
      .catch(e => {
        console.log(e)
      })
  };
  _loadLeader = async () => {
    db.collection('leaders')
      .get()
      .then(snapshot => {
        const leaders = [];
        snapshot.forEach(doc => {
          let leader = Object.assign(doc.data(), {id: doc.id});
          leaders.push(leader)
        });

        let leaderList = _.map(leaders, (leader, index) => ({
          key: index,
          text: leader.name,
          value: leader.id,
          leaderUserId: leader.userId,
        }));
        leaderList = [{key: 'a', text: '없음', value: '', leaderUserId: ''}, ...leaderList];
        this.setState({
          leaders,
          leaderList
        })
      })
      .catch(e => {
        console.log(e)
      })
  };
  _loadBookClub = async () => {
    const isNewBookClub = this.props.match.params.bookClubId === undefined;

    console.log({
      isNewBookClub
    });


    if (isNewBookClub) {
      this.setState({
        isNewBookClub,
        bookClub: _.cloneDeep(NEW_BOOK_CLUB),
        isLoaded: true,
      })
    } else {
      this._loadBookClubBooks();
      this._loadBookClubEssaySchedules();

      const {bookClubId} = this.props.match.params;
      db.collection('bookClubs')
        .doc(bookClubId)
        .get()
        .then(doc => {
          if (!doc.exists) return window.alert('경고\n 북클럽을 불러올 수 없습니다.');

          const bookClub = Object.assign(_.cloneDeep(NEW_BOOK_CLUB), doc.data(), {id: doc.id});
          bookClub.createdAt = new Date(moment(bookClub.createdAt ? bookClub.createdAt.seconds * 1000 : null).toISOString());
          bookClub.modifiedAt = new Date(moment(bookClub.modifiedAt ? bookClub.modifiedAt.seconds * 1000 : null).toISOString());
          bookClub.startDate = new Date(moment(bookClub.startDate ? bookClub.startDate.seconds * 1000 : null).toISOString());
          bookClub.endDate = new Date(moment(bookClub.endDate ? bookClub.endDate.seconds * 1000 : null).toISOString());
          bookClub.registerStartDate = new Date(moment(bookClub.registerStartDate ? bookClub.registerStartDate.seconds * 1000 : null).toISOString());
          bookClub.registerEarlyBirdEndDate = new Date(moment(bookClub.registerEarlyBirdEndDate ? bookClub.registerEarlyBirdEndDate.seconds * 1000 : null).toISOString());
          bookClub.registerEndDate = new Date(moment(bookClub.registerEndDate ? bookClub.registerEndDate.seconds * 1000 : null).toISOString());

          this.setState({
            bookClub,
            isNewBookClub,
            isLoaded: true,
          })
        })
        .catch(e => {
          console.log(e);
        })
    }

  };

  _loadBookClubBooks = async () => {
    const {bookClubId} = this.props.match.params;
    db.collection('bookClubBooks')
      .where('bookClubId', '==', bookClubId)
      .orderBy('order', 'asc')
      .get()
      .then(snapshot => {
        const bookClubBooks = [];
        snapshot.forEach(doc => {
          let bookClubBook = Object.assign(doc.data(), {id: doc.id});
          bookClubBooks.push(bookClubBook)
        });
        this.setState({
          bookClubBooks
        })
      })
      .catch(e => {
        console.log(e)
      })
  };
  _addBookClubBooks = async (book) => {
    const {bookClubId} = this.props.match.params;
    const newBookClubBook = Object.assign(book, {
      bookClubId: bookClubId,
      order: this.state.bookClubBooks.length + 1,
      createdAt: new Date(),
      modifiedAt: new Date(),
      isDeleted: false,
    });
    db.collection('bookClubBooks')
      .add(newBookClubBook)
      .then(() => {
        this._loadBookClubBooks()
      })
      .catch(e => {
        console.log(e);
      })
  };
  _removeBookClubBook = async (book) => {

    const a = window.confirm('삭제하시겠습니까?');
    if (!a) return;

    db.collection('bookClubBooks')
      .doc(book.id)
      .delete()
      .then(() => {
        this._loadBookClubBooks()
      })
      .catch(e => {
        console.log(e)
      })

  };


  _loadBookClubEssaySchedules = async () => {
    const {bookClubId} = this.props.match.params;
    db.collection('bookClubEssaySchedules')
      .where('bookClubId', '==', bookClubId)
      .where('isDeleted', '==', false)
      .orderBy('order', 'asc')
      .orderBy('startDate', 'asc')
      .get()
      .then(snapshot => {
        const bookClubEssaySchedules = [];
        snapshot.forEach(doc => {
          let bookClubEssaySchedule = Object.assign(doc.data(), {id: doc.id});
          bookClubEssaySchedule.createdAt = new Date(moment(bookClubEssaySchedule.createdAt ? bookClubEssaySchedule.createdAt.seconds * 1000 : null).toISOString());
          bookClubEssaySchedule.modifiedAt = new Date(moment(bookClubEssaySchedule.modifiedAt ? bookClubEssaySchedule.modifiedAt.seconds * 1000 : null).toISOString());
          bookClubEssaySchedule.startDate = new Date(moment(bookClubEssaySchedule.startDate ? bookClubEssaySchedule.startDate.seconds * 1000 : null).toISOString());
          bookClubEssaySchedule.endDate = new Date(moment(bookClubEssaySchedule.endDate ? bookClubEssaySchedule.endDate.seconds * 1000 : null).toISOString());

          bookClubEssaySchedules.push(bookClubEssaySchedule)
        });
        this.setState({
          bookClubEssaySchedules
        });
      })
      .catch(e => {
        console.log(e)
      })
  };
  _addBookClubEssaySchedule = async (book) => {
    const {bookClubId} = this.props.match.params;
    const newBookClubSchedule = Object.assign(book, {
      bookClubId: bookClubId,
      order: this.state.bookClubEssaySchedules.length + 1,
      createdAt: new Date(),
      modifiedAt: new Date(),
      isDeleted: false,
    });

    db.collection('bookClubEssaySchedules')
      .add(newBookClubSchedule)
      .then(() => {
        this._loadBookClubEssaySchedules()
      })
      .catch(e => {
        console.log(e);
      })
  };

  _removeEssaySchedule = async (bookClubEssaySchedule) => {
    const a = window.confirm('삭제하시겠습니까?');
    if (!a) return;

    db.collection('bookClubEssaySchedules')
      .doc(bookClubEssaySchedule.id)
      .delete()
      .then(() => {
        this._loadBookClubEssaySchedules()
      })
      .catch(e => {
        console.log(e)
      })
  }


  _updateOrAddBookClub = async () => {
    const confirm = window.confirm('업로드 하시겠습니까?');
    if (!confirm) return;

    const isNewBookClub = this.props.match.params.bookClubId === undefined;
    const {bookClub} = this.state;


    if (isNewBookClub) {
      bookClub.createdAt = new Date();
      bookClub.modifiedAt = new Date();

      db.collection('bookClubs')
        .add(bookClub)
        .then(doc => {
          window.alert('북클럽이 등록되었습니다.');
          this.props.history.replace(`/admin/bookclub/read/${doc.id}`);
        })
        .catch(e => {
          console.log(e);
        })

    } else {
      bookClub.modifiedAt = new Date();

      db.collection('bookClubs')
        .doc(bookClub.id)
        .update(bookClub)
        .then(() => {
          window.alert('북클럽이 수정되었습니다.');
          this._loadBookClub()
        })
        .catch(e => {
          console.log(e);
        })
    }
  };
  _deleteBookClub = async () => {

    const confirm = window.confirm('삭제하시겠습니까?');
    if (!confirm) return;
    const {bookClub} = this.state;
    const {
      participantIds,
      applicantIds,
      deniedIds,
    } = bookClub;

    if (participantIds.length + applicantIds.length + deniedIds.length > 0) {
      return window.alert('지원자 또는 참여자가 있는 북클럽은 삭제할 수 없습니다.');
    }

    db.collection('bookClubs')
      .doc(bookClub.id)
      .update({
        isDeleted: true
      })
      .then(() => {
        window.alert('삭제되었습니다.');
        this.props.history.replace('/admin/bookclub')
      })
  };
  _restoreBookClub = async () => {
    const confirm = window.confirm('복구 하시겠습니까?');
    if (!confirm) return;
    const {bookClub} = this.state;

    db.collection('bookClubs')
      .doc(bookClub.id)
      .update({
        isDeleted: false
      })
      .then(() => {
        window.alert('복구 되었습니다.');
        this.props.history.replace('/admin/bookclub')
      })
  };

  handleChange = e => {
    const {bookClub} = this.state;
    const {name, value} = e.target;
    bookClub[name] = value;
    this.setState({
      bookClub
    });
  };

  handleUploadStart = () => {
    this.setState({
      isUploading: true,
      uploadProgress: 0
    });
  };
  handleProgress = progress => {
    this.setState({
      uploadProgress: progress
    });
  }
  handleUploadError = error => {
    this.setState({
      isUploading: false
      // Todo: handle error
    });
    console.error(error);
  };
  handleUploadSuccess = async (filename, imageCategory) => {
    const {bookClub} = this.state;
    this.setState({progress: 100, isUploading: false});
    await firebase.storage()
      .ref(storageRef)
      .child(filename)
      .getDownloadURL()
      .then(imageUrl => {
        bookClub.image[imageCategory] = imageUrl;
        console.log(bookClub);
        this.setState({bookClub})
      });
  };

  render() {
    const {
      bookClub,
      isLoaded,
      bookClubBooks,
      bookClubEssaySchedules,
      searchedBookList,
      searchedEssayScheduleList,
      isNewBookClub,
      isNew,
    } = this.state;
    if (!isLoaded) {
      return (
        <Loader active/>
      )
    }
    return (
      <Fragment>

        <Container as={Segment} vertical basic>
          <Button content={isNewBookClub ? '만들기' : '수정하기'} primary
                  onClick={this._updateOrAddBookClub}/>
          <Button content={'삭제하기'}
                  negative
                  disabled={this.state.isNewBookClub || bookClub.isDeleted}
                  onClick={this._deleteBookClub}/>
          <Button content={'복구하기'}
                  positive
                  onClick={this._restoreBookClub}
                  disabled={!bookClub.isDeleted}/>
        </Container>

        <Container as={Segment}>
          <Header as='h3'>북클럽 기본 설정</Header>
          <Radio
            toggle
            label={bookClub.isPublic ? "공개" : "비공개"}
            checked={bookClub.isPublic}
            style={{margin: "10px 0"}}
            onClick={() => {
              bookClub.isPublic = !bookClub.isPublic;
              this.setState({
                bookClub
              });
            }}
          />
          <Form>
            <Form.Group widths={'equal'}>
              <Form.Dropdown
                placeholder={'status'}
                selection
                label="status"
                name="status"
                value={bookClub.status}
                options={bookClubStatusDropDownList()}
                onChange={(e, {value}) => {
                  bookClub.status = value;
                  this.setState({bookClub})
                }}
              />
              <Form.Dropdown
                placeholder={'heldBy'}
                selection
                label="heldBy"
                name="heldBy"
                value={bookClub.heldBy}
                options={bookClubHolderDropDownList()}
                onChange={(e, {value}) => {
                  bookClub.heldBy = value;
                  this.setState({bookClub})
                }}
              />
              <Form.Dropdown
                disabled={bookClub.heldBy !== HELD_BY_PARTNER}
                placeholder={'partnerId'}
                selection
                label="partnerId"
                name="partnerId"
                value={bookClub.partnerId}
                options={this.state.partnerList}
                onChange={(e, {value}) => {
                  bookClub.partnerId = value;
                  this.setState({bookClub})
                }}
              />
            </Form.Group>
          </Form>
        </Container>

        <Container as={Segment}>
          <Header
            size={'small'}
            content={'북클럽 커버 설정'}
          />
          <Form>
            <Form.Group>
              <Form.TextArea
                name={'title'}
                label={'title'}
                value={bookClub.header.content}
                onChange={e => {
                  bookClub.header.content = e.target.value;
                  this.setState({bookClub})
                }}
                width={7}
              />
              <Form.Dropdown
                width={3}
                selection
                placeholder={'titleColor'}
                label={'titleColor'}
                name={'titleColor'}
                value={bookClub.header.color}
                options={getColorCode()}
                onChange={(e, {value}) => {
                  bookClub.header.color = value;
                  this.setState({bookClub})
                }}
              />
              <Form.Input
                name={'titleColor'}
                label={'titleColor'}
                value={bookClub.header.color}
                onChange={e => {
                  bookClub.header.color = e.target.value;
                  this.setState({bookClub})
                }}
                width={3}
              />
              <Form.Dropdown
                width={3}
                selection
                placeholder={'textAlign'}
                label={'textAlign'}
                name={'titleTextAlign'}
                value={bookClub.header.align}
                options={getTextAlignDropDownList()}
                onChange={(e, {value}) => {
                  bookClub.header.align = value;
                  this.setState({bookClub})
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.TextArea
                name={'subTitle'}
                label={'subTitle'}
                value={bookClub.subHeader.content}
                onChange={e => {
                  bookClub.subHeader.content = e.target.value;
                  this.setState({bookClub})
                }}
                width={7}
              />
              <Form.Dropdown
                width={3}
                selection
                placeholder={'subTitleColor'}
                label={'subTitleColor'}
                name={'subTitleColor'}
                value={bookClub.subHeader.color}
                options={getColorCode()}
                onChange={(e, {value}) => {
                  bookClub.subHeader.color = value;
                  this.setState({bookClub})
                }}
              />
              <Form.Input
                name={'subTitleColor'}
                label={'subTitleColor'}
                value={bookClub.subHeader.color}
                onChange={e => {
                  bookClub.subHeader.color = e.target.value;
                  this.setState({bookClub})
                }}
                width={3}
              />
              <Form.Dropdown
                width={3}
                selection
                placeholder={'textAlign'}
                label={'textAlign'}
                name={'subTitleTextAlign'}
                value={bookClub.subHeader.align}
                options={getTextAlignDropDownList()}
                onChange={(e, {value}) => {
                  bookClub.subHeader.align = value;
                  this.setState({bookClub})
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.TextArea
                name={'description'}
                label={'description'}
                style={{
                  textAlign: bookClub.descriptionNew.align,
                  color: bookClub.descriptionNew.color,
                }}
                value={bookClub.descriptionNew.content}
                onChange={e => {
                  bookClub.descriptionNew.content = e.target.value;
                  this.setState({bookClub})
                }}
                width={7}
              />
              <Form.Dropdown
                width={3}
                selection
                placeholder={'descriptionColor'}
                label={'descriptionColor'}
                name={'descriptionColor'}
                value={bookClub.descriptionNew.color}
                options={getColorCode()}
                onChange={(e, {value}) => {
                  bookClub.descriptionNew.color = value;
                  this.setState({bookClub})
                }}
              />
              <Form.Input
                name={'descriptionColor'}
                label={'descriptionColor'}
                value={bookClub.descriptionNew.color}
                onChange={e => {
                  bookClub.descriptionNew.color = e.target.value;
                  this.setState({bookClub})
                }}
                width={3}
              />
              <Form.Dropdown
                width={3}
                selection
                placeholder={'textAlign'}
                label={'textAlign'}
                name={'subTitleTextAlign'}
                value={bookClub.descriptionNew.align}
                options={getTextAlignDropDownList()}
                onChange={(e, {value}) => {
                  bookClub.descriptionNew.align = value;
                  this.setState({bookClub})
                }}
              />
            </Form.Group>
          </Form>
        </Container>

        <Container as={Segment}>
          <Form>
            <Header
              size={'small'}
              content={'날짜 설정'}
            />

            <Form.Group widths={'equal'}>
              <Form.Input
                // disabled={true}
                name={'registerStartDate'}
                label={'registerStartDate'}
                type={'date'}
                value={moment(bookClub.registerStartDate).format('YYYY-MM-DD')}
                onChange={(e, {value, name}) => {
                  bookClub.registerStartDate = new Date(value);
                  this.setState({bookClub})
                }}
              />
              <Form.Input
                // disabled={true}
                name={'registerEndDate'}
                label={'registerEndDate'}
                type={'date'}
                value={moment(bookClub.registerEndDate).format('YYYY-MM-DD')}
                onChange={(e, {value, name}) => {
                  bookClub.registerEndDate = new Date(value);
                  this.setState({bookClub})
                }}
              />
            </Form.Group>
            <Form.Group widths={'equal'}>
              <Form.Input
                // disabled={true}
                name={'startDate'}
                label={'startDate'}
                type={'date'}
                value={moment(bookClub.startDate).format('YYYY-MM-DD')}
                onChange={(e, {value, name}) => {
                  bookClub.startDate = new Date(value);
                  this.setState({bookClub})
                }}
              />
              <Form.Input
                // disabled={true}
                name={'endDate'}
                label={'endDate'}
                type={'date'}
                value={moment(bookClub.endDate).format('YYYY-MM-DD')}
                onChange={(e, {value, name}) => {
                  bookClub.endDate = new Date(value);
                  this.setState({bookClub})
                }}
              />

            </Form.Group>
            <Header
              content={'회비'}
              style={styles.header}/>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Input
                    type={'number'}
                    name={'defaultMembership'}
                    label={'defaultMembership'}
                    value={bookClub.defaultMembership.toString()}
                    onChange={(e, {value}) => {
                      bookClub.defaultMembership = Number(value);
                      this.setState({bookClub})
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Radio
                    toggle
                    label={'hasEarlyBirdPromotion'}
                    checked={bookClub.hasEarlyBirdPromotion}
                    onChange={e => {
                      bookClub.hasEarlyBirdPromotion = !bookClub.hasEarlyBirdPromotion;
                      this.setState({bookClub})
                    }}
                  />
                  <Form.Input
                    disabled={!bookClub.hasEarlyBirdPromotion}
                    type={'number'}
                    name={'earlyBirdMembership'}
                    label={'earlyBirdMembership'}
                    value={bookClub.earlyBirdMembership.toString()}
                    onChange={(e, {value}) => {
                      bookClub.earlyBirdMembership = Number(value);
                      this.setState({bookClub})
                    }}
                  />
                  <Form.Input
                    disabled={!bookClub.hasEarlyBirdPromotion}
                    name={'registerEarlyBirdEndDate'}
                    label={'registerEarlyBirdEndDate'}
                    type={'date'}
                    value={moment(bookClub.registerEarlyBirdEndDate).format('YYYY-MM-DD')}
                    onChange={(e, {value, name}) => {
                      bookClub.registerEarlyBirdEndDate = new Date(value);
                      this.setState({bookClub})
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Container>

        <Container as={Segment}>
          <Form>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Radio
                    toggle
                    label={'isPrivate'}
                    checked={bookClub.isPrivate}
                    onChange={e => {
                      bookClub.isPrivate = !bookClub.isPrivate;
                      this.setState({bookClub})
                    }}
                  />
                  <Form.Input
                    disabled={!bookClub.isPrivate}
                    name={'privatePassword'}
                    label={'privatePassword'}
                    value={bookClub.privatePassword}
                    onChange={(e, {value}) => {
                      bookClub.privatePassword = value;
                      this.setState({bookClub})
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Radio
                    toggle
                    label={bookClub.hasLeader ? "리더 있음" : "리더 없음"}
                    checked={bookClub.hasLeader}
                    onClick={() => {
                      bookClub.hasLeader = !bookClub.hasLeader;
                      this.setState({bookClub});
                    }}
                  />
                  <Form.Dropdown
                    disabled={!bookClub.hasLeader}
                    placeholder={'leaderId'}
                    selection
                    label="leaderId"
                    name="leaderId"
                    value={bookClub.leaderId}
                    options={this.state.leaderList}
                    onChange={(e, {value, leaderUserId}) => {
                      bookClub.leaderId = value;
                      bookClub.leaderUserId = leaderUserId || '';
                      this.setState({bookClub})
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Container>


        <Container as={Segment}>
          <Form>
            <Grid>
              <Grid.Row columns={4}>
                <Grid.Column>
                  <Header
                    size={'small'}
                    content={'정원'}
                  />
                  <Form.Input
                    width={4}
                    name={'maxNumOfMembers'}
                    label={'maxNumOfMembers'}
                    value={bookClub.maxNumOfMembers.toString()}
                    onChange={(e, {value}) => {
                      bookClub.maxNumOfMembers = Number(value)
                      this.setState({bookClub})
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Header
                    size={'small'}
                    content={'온/오프라인 설정'}
                  />
                  <Form.Radio
                    toggle
                    label={bookClub.hasOffline ? "오프라인" : "온라인"}
                    checked={bookClub.hasOffline}
                    // style={{margin: "10px 0"}}
                    onClick={() => {
                      bookClub.hasOffline = !bookClub.hasOffline;
                      this.setState({bookClub})
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Header
                    size={'small'}
                    content={'오프라인 모임'}
                  />
                  <Form.Radio
                    toggle
                    label={bookClub.hasOfflineMeeting ? "오프라인 모임 있음" : "오프라인 모임 없음"}
                    checked={bookClub.hasOfflineMeeting}
                    // style={{margin: "10px 0"}}
                    onClick={() => {
                      bookClub.hasOfflineMeeting = !bookClub.hasOfflineMeeting;
                      this.setState({bookClub})
                    }}
                  />
                  <Form.Input
                    disabled={!bookClub.hasOfflineMeeting}
                    type={'number'}
                    name={'numberOfOfflineMeetings'}
                    label={'numberOfOfflineMeetings'}
                    value={bookClub.numberOfOfflineMeetings}
                    onChange={e => {
                      bookClub.numberOfOfflineMeetings = Number(e.target.value);
                      this.setState({bookClub})
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Header
                    size={'small'}
                    content={'온라인 모임'}
                  />
                  <Form.Radio
                    toggle
                    label={bookClub.hasOnlineMeeting ? "온라인 모임 있음" : "온라인 모임 없음"}
                    checked={bookClub.hasOnlineMeeting}
                    // style={{margin: "10px 0"}}
                    onClick={() => {
                      bookClub.hasOnlineMeeting = !bookClub.hasOnlineMeeting;
                      this.setState({bookClub})
                    }}
                  />
                  <Form.Input
                    disabled={!bookClub.numberOfOnlineMeetings}
                    type={'number'}
                    name={'numberOfOnlineMeetings'}
                    label={'numberOfOnlineMeetings'}
                    value={bookClub.numberOfOnlineMeetings}
                    onChange={e => {
                      bookClub.numberOfOnlineMeetings = Number(e.target.value);
                      this.setState({bookClub})
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={4}>
                <Grid.Column>
                  <Header
                    size={'small'}
                    content={'책'}
                  />
                  <Form.Input
                    // disabled={!bookClub.numberOfOnlineMeetings}
                    type={'number'}
                    name={'numberOfBooks'}
                    label={'numberOfBooks'}
                    value={bookClub.numberOfBooks}
                    onChange={e => {
                      bookClub.numberOfBooks = Number(e.target.value);
                      this.setState({bookClub})
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Header
                    size={'small'}
                    content={'디파짓'}
                  />
                  <Form.Radio
                    toggle
                    label={bookClub.hasDeposit ? "디파짓 있음" : "디파짓 없음"}
                    checked={bookClub.hasDeposit}
                    // style={{margin: "10px 0"}}
                    onClick={() => {
                      bookClub.hasDeposit = !bookClub.hasDeposit;
                      this.setState({bookClub})
                    }}
                  />
                  <Form.Input
                    disabled={!bookClub.hasDeposit}
                    type={'number'}
                    name={'depositPerEssay'}
                    label={'depositPerEssay'}
                    value={bookClub.depositPerEssay}
                    onChange={e => {
                      bookClub.depositPerEssay = Number(e.target.value);
                      this.setState({bookClub})
                    }}
                  />
                  <Form.Input
                    disabled={!bookClub.hasDeposit}
                    type={'number'}
                    name={'depositPerScrap'}
                    label={'depositPerScrap'}
                    value={bookClub.depositPerScrap}
                    onChange={e => {
                      bookClub.depositPerScrap = Number(e.target.value);
                      this.setState({bookClub})
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Header
                    size={'small'}
                    content={'스크랩'}
                  />
                  <Form.Radio
                    toggle
                    label={bookClub.scrapMandatory ? "스크랩 필수" : "스크랩 필수 아님"}
                    checked={bookClub.scrapMandatory}
                    // style={{margin: "10px 0"}}
                    onClick={() => {
                      bookClub.scrapMandatory = !bookClub.scrapMandatory;
                      this.setState({bookClub})
                    }}
                  />
                  <Form.Radio
                    toggle
                    label={bookClub.scrapDaily ? "데일리 스크랩" : "챕터 스크랩"}
                    checked={bookClub.scrapDaily}
                    // style={{margin: "10px 0"}}
                    onClick={() => {
                      bookClub.scrapDaily = !bookClub.scrapDaily;
                      this.setState({bookClub})
                    }}
                  />
                  <Form.Input
                    // disabled={!bookClub.scrapMandatory}
                    type={'number'}
                    name={'numberOfScraps'}
                    label={'numberOfScraps'}
                    value={bookClub.numberOfScraps}
                    onChange={e => {
                      bookClub.numberOfScraps = Number(e.target.value);
                      this.setState({bookClub})
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Header
                    size={'small'}
                    content={'에세이'}
                  />
                  <Form.Radio
                    toggle
                    label={bookClub.essayMandatory ? "에세이 필수" : "에세이 필수 아님"}
                    checked={bookClub.essayMandatory}
                    // style={{margin: "10px 0"}}
                    onClick={() => {
                      bookClub.essayMandatory = !bookClub.essayMandatory;
                      this.setState({bookClub})
                    }}
                  />
                  <Form.Input
                    // disabled={!bookClub.numberOfOnlineMeetings}
                    type={'number'}
                    name={'numberOfEssays'}
                    label={'numberOfEssays'}
                    value={bookClub.numberOfEssays}
                    onChange={e => {
                      bookClub.numberOfEssays = Number(e.target.value);
                      this.setState({bookClub})
                    }}
                  />
                </Grid.Column>

              </Grid.Row>
            </Grid>
          </Form>
        </Container>


        <Container as={Segment}>
          <Grid>
            <Grid.Row columns={6}>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  coverImageUrl
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          bookClub.coverImageUrl = imageUrl;
                          this.setState({bookClub})
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(bookClub.coverImageUrl)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    bookClub.coverImageUrl = '';
                    this.setState({bookClub})
                  }}/>
                <Image src={bookClub.coverImageUrl || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={6}>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  정사각
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="square"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'square');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(bookClub.image && bookClub.image.square)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    bookClub.image.square = '';
                    this.setState({
                      bookClub
                    })
                  }}/>
                <Image src={(bookClub.image && bookClub.image.square) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  가로
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="horizontal"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'horizontal');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(bookClub.image && bookClub.image.horizontal)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    bookClub.image.horizontal = '';
                    this.setState({bookClub})
                  }}/>
                <Image src={(bookClub.image && bookClub.image.horizontal) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  세로
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="vertical"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'vertical');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(bookClub.image && bookClub.image.vertical)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    bookClub.image.vertical = '';
                    this.setState({bookClub})
                  }}/>
                <Image src={(bookClub.image && bookClub.image.vertical) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  정사각 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="imageSquareLarge"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'squareLarge');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(bookClub.image && bookClub.image.squareLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    bookClub.image.squareLarge = '';
                    this.setState({bookClub})
                  }}/>
                <Image src={(bookClub.image && bookClub.image.squareLarge) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  가로 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="imageHorizontalLarge"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'horizontalLarge');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(bookClub.image && bookClub.image.horizontalLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    bookClub.image.horizontalLarge = '';
                    this.setState({bookClub})
                  }}/>
                <Image src={(bookClub.image && bookClub.image.horizontalLarge) || mockUpImages.planeImage}
                       size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  세로 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="imageVerticalLarge"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'verticalLarge');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(bookClub.image && bookClub.image.verticalLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    bookClub.image.verticalLarge = '';
                    this.setState({bookClub})
                  }}/>
                <Image src={(bookClub.image && bookClub.image.verticalLarge) || mockUpImages.planeImage}
                       size={'medium'}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Container>
        {/* 에세이 스케줄 */}
        {
          !isNew && (
            <Fragment>
              <Container as={Segment} vertical style={styles.verticalModule}>
                <Table size={'small'} basic compact={'very'}>
                  <Table.Body>
                    {
                      bookClubBooks.map((bookClubBook, i) => {
                        return (
                          <Table.Row key={i}>
                            <Table.Cell>
                              <Image src={bookClubBook.cover} size={'mini'}/>
                            </Table.Cell>
                            <Table.Cell>
                              {bookClubBook.title}
                            </Table.Cell>
                            <Table.Cell>
                              {bookClubBook.title} <br/>
                              {bookClubBook.pubDate}
                            </Table.Cell>
                            <Table.Cell>
                              <Input
                                value={bookClubBook.order.toString()}
                                onChange={(e, {value}) => {
                                  bookClubBooks[i].order = Number(value);
                                  this.setState({
                                    bookClubBooks
                                  })
                                }}
                              />
                            </Table.Cell>
                            <Table.Cell width={1}>
                              <Button size={'mini'} onClick={() => {
                                this._removeBookClubBook(bookClubBook);
                              }}>
                                삭제
                              </Button>
                            </Table.Cell>
                            <Table.Cell width={1}>
                              <Button size={'mini'} onClick={() => {
                                db.collection('bookClubBooks')
                                  .doc(bookClubBook.id)
                                  .update(Object.assign(bookClubBook, {modifiedAt: new Date()}))
                                  .then(() => {
                                    window.alert('반영되었습니다.')
                                    this._loadBookClubBooks();
                                  })
                              }}>
                                반영
                              </Button>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })
                    }

                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell colSpan='4'>
                        <Button onClick={() => {
                          this.setState({bookClubBookSearchVisible: !this.state.bookClubBookSearchVisible})
                        }}>
                          {this.state.bookClubBookSearchVisible ? '책 검색 닫기' : '책 검색 열기'}
                        </Button>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
                {
                  this.state.bookClubBookSearchVisible && (
                    <Table size={'small'} compact>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell colSpan={'6'}>
                            <Form>
                              <Form.Input
                                value={this.state.searchBookValue}
                                name={'searchBookValue'}
                                label={'searchBookValue'}
                                onChange={(e, {value}) => {
                                  this.setState({
                                    searchBookValue: value
                                  })
                                }}
                              >
                                <input/>
                                <Form.Button type={'submit'} onClick={async (e) => {
                                  e.preventDefault();
                                  const res = await SearchBook.AladinItemSearchFromFirebase({
                                    query: this.state.searchBookValue,
                                    queryType: QUERY_TYPE_KEYWORD,
                                    page: 1,
                                    perPage: 30,
                                  });
                                  if (!res.err) {
                                    const {item: searchedBookList} = res;
                                    this.setState({searchedBookList});
                                  } else {
                                    window.alert(res.err)
                                  }
                                }}>
                                  검색
                                </Form.Button>
                              </Form.Input>
                            </Form>
                          </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                          <Table.HeaderCell>제목</Table.HeaderCell>
                          <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {
                          searchedBookList && searchedBookList.map((book, i) => {
                            return (
                              <Table.Row key={i}>
                                <Table.Cell>
                                  <Image src={book.cover} size={'mini'}/>
                                </Table.Cell>
                                <Table.Cell>{book.title}</Table.Cell>
                                <Table.Cell>
                                  {book.publisher}<br/>
                                  {book.pubDate}
                                </Table.Cell>
                                <Table.Cell>
                                  <Button size={'mini'} onClick={() => {
                                    this._addBookClubBooks(_.cloneDeep(book));
                                  }}>
                                    추가하기
                                  </Button>
                                </Table.Cell>
                              </Table.Row>
                            )
                          })
                        }
                      </Table.Body>
                    </Table>

                  )
                }
              </Container>
              <Container as={Segment} vertical style={styles.verticalModule}>
                <Table size={'small'} basic compact={'very'}>
                  <Table.Body>
                    {
                      bookClubEssaySchedules.map((bookClubEssaySchedule, i) => {
                        return (
                          <Table.Row key={i}>
                            <Table.Cell>
                              <Image src={bookClubEssaySchedule.cover} size={'mini'}/>
                            </Table.Cell>
                            <Table.Cell>
                              {bookClubEssaySchedule.title}
                            </Table.Cell>
                            <Table.Cell>
                              <Input
                                value={bookClubEssaySchedule.order.toString()}
                                onChange={(e, {value}) => {
                                  bookClubEssaySchedules[i].order = Number(value);
                                  this.setState({
                                    bookClubEssaySchedules
                                  })
                                }}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Input
                                value={moment(bookClubEssaySchedule.startDate).format('YYYY-MM-DD')}
                                type={'date'}
                                onChange={(e, {value}) => {
                                  bookClubEssaySchedules[i].startDate = new Date(value);
                                  this.setState({
                                    bookClubEssaySchedules
                                  })
                                }}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Input
                                value={moment(bookClubEssaySchedule.endDate).format('YYYY-MM-DD')}
                                type={'date'}
                                onChange={(e, {value}) => {
                                  bookClubEssaySchedules[i].endDate = new Date(value);
                                  this.setState({
                                    bookClubEssaySchedules
                                  })
                                }}
                              />
                            </Table.Cell>
                            <Table.Cell width={1}>
                              <Button
                                negative
                                size={'mini'} onClick={() => {
                                this._removeEssaySchedule(bookClubEssaySchedule);
                              }}>
                                삭제
                              </Button>
                            </Table.Cell>
                            <Table.Cell width={1}>
                              <Button size={'mini'} onClick={() => {
                                db.collection('bookClubEssaySchedules')
                                  .doc(bookClubEssaySchedule.id)
                                  .update(Object.assign(bookClubEssaySchedule, {modifiedAt: new Date()}))
                                  .then(() => {
                                    window.alert('반영되었습니다.');
                                    this._loadBookClubEssaySchedules();
                                  })
                              }}>
                                반영
                              </Button>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })
                    }
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell colSpan='4'>
                        <Button onClick={() => {
                          this.setState({bookClubEssayScheduleSearchVisible: !this.state.bookClubEssayScheduleSearchVisible})
                        }}>
                          {this.state.bookClubEssayScheduleSearchVisible ? '에세이 스케줄 검색 닫기' : '에세이 스케줄 검색 열기'}
                        </Button>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
                {
                  this.state.bookClubEssayScheduleSearchVisible && (
                    <Table size={'small'} compact={'very'}>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell colSpan={'6'}>
                            <Form>
                              <Form.Input
                                value={this.state.searchEssayScheduleValue}
                                name={'searchEssayScheduleValue'}
                                label={'searchEssayScheduleValue'}
                                onChange={(e, {value}) => {
                                  this.setState({
                                    searchEssayScheduleValue: value
                                  })
                                }}
                              >
                                <input/>
                                <Form.Button type={'submit'} onClick={async (e) => {
                                  e.preventDefault();
                                  const res = await SearchBook.AladinItemSearchFromFirebase({
                                    query: this.state.searchEssayScheduleValue,
                                    queryType: QUERY_TYPE_KEYWORD,
                                    page: 1,
                                    perPage: 30,
                                  });
                                  if (!res.err) {
                                    let {item: searchedEssayScheduleList} = res;

                                    searchedEssayScheduleList.forEach((book, i) => {
                                      searchedEssayScheduleList[i].startDate = new Date();
                                      searchedEssayScheduleList[i].endDate = new Date();
                                    });
                                    this.setState({searchedEssayScheduleList});

                                  } else {
                                    window.alert(res.err)
                                  }
                                }}>
                                  검색
                                </Form.Button>
                              </Form.Input>
                            </Form>
                          </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                          <Table.HeaderCell></Table.HeaderCell>
                          <Table.HeaderCell>제목</Table.HeaderCell>
                          <Table.HeaderCell>출판사<br/>출판일</Table.HeaderCell>
                          <Table.HeaderCell>시작일</Table.HeaderCell>
                          <Table.HeaderCell>종료일</Table.HeaderCell>
                          <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {
                          searchedEssayScheduleList && searchedEssayScheduleList.map((bookClubEssaySchedule, i) => {

                            return (
                              <Table.Row key={i}>
                                <Table.Cell>
                                  <Image src={bookClubEssaySchedule.cover} size={'mini'}/>
                                </Table.Cell>
                                <Table.Cell>{bookClubEssaySchedule.title}</Table.Cell>
                                <Table.Cell>
                                  {bookClubEssaySchedule.publisher}<br/>
                                  {bookClubEssaySchedule.pubDate}
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    value={moment(bookClubEssaySchedule.startDate ? bookClubEssaySchedule.startDate : new Date()).format('YYYY-MM-DD')}
                                    type={'date'}
                                    onChange={(e, {value}) => {
                                      searchedEssayScheduleList[i].startDate = new Date(value);
                                      this.setState({
                                        searchedEssayScheduleList
                                      })
                                    }}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    value={moment(bookClubEssaySchedule.endDate ? bookClubEssaySchedule.endDate : new Date()).format('YYYY-MM-DD')}
                                    type={'date'}
                                    onChange={(e, {value}) => {
                                      searchedEssayScheduleList[i].endDate = new Date(value);
                                      this.setState({
                                        searchedEssayScheduleList
                                      })
                                    }}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <Button size={'mini'} onClick={() => {
                                    this._addBookClubEssaySchedule(_.cloneDeep(bookClubEssaySchedule));
                                  }}>
                                    추가하기
                                  </Button>
                                </Table.Cell>
                              </Table.Row>
                            )
                          })
                        }
                      </Table.Body>
                    </Table>

                  )
                }

              </Container>
            </Fragment>
          )
        }


        {/* 컨텐츠 모듈 */}
        <Container as={Segment} vertical>
          {
            bookClub.contentModules.map((contentModule, index) => {
              return (
                <ContentModuleComponent
                  save={this._updateOrAddBookClub}
                  onChangeState={(module) => {
                    bookClub.contentModules[index] = module;
                    this.setState({bookClub});
                  }}
                  moveModuleFoward={() => {
                    if (index === 0) return window.alert('처음입니다');
                    bookClub.contentModules = moveItemInArrayFromIndexToIndex(bookClub.contentModules, index, index - 1);
                    this.setState({bookClub})
                  }}
                  moveModuleBack={() => {
                    if (index === bookClub.contentModules.length - 1) return window.alert('끝입니다');
                    bookClub.contentModules = moveItemInArrayFromIndexToIndex(bookClub.contentModules, index, index + 1);
                    this.setState({bookClub})
                  }}
                  deleteModule={() => {
                    const a = window.confirm('삭제하시겠습니까?');
                    if (!a) return;
                    bookClub.contentModules.splice(index, 1);
                    this.setState({bookClub});
                  }}
                  addModule={() => {
                    this.setState({
                      modalOpen: true,
                      indexOfContentModuleAdded: index + 1,
                    })
                  }}
                  contentModule={contentModule}
                  key={index}/>
              )
            })
          }
          <Button
            content={'추가하기'}
            onClick={() => {
              this.setState({
                modalOpen: true,
                indexOfContentModuleAdded: bookClub.contentModules.length,
              })
            }}
          />
          <Button
            primary
            content={'저장하기'}
            onClick={this._updateOrAddBookClub}/>
          <Modal open={this.state.modalOpen}>
            <Modal.Content>
              {
                contentTypeDropDownList().map((contentType, i) => {
                  return (
                    <Button
                      fluid
                      content={contentType.text}
                      key={i}
                      style={{marginBottom: 5}}
                      onClick={() => {
                        bookClub.contentModules.splice(this.state.indexOfContentModuleAdded, 0, NEW_CONTENT_MODULE(contentType.value));
                        this.setState({
                          bookClub
                        })
                      }}
                    />
                  )
                })
              }
            </Modal.Content>
            <Modal.Actions>
              <Button content={'취소'}
                      negative
                      onClick={() => {
                        this.setState({modalOpen: false,})
                      }}/>
            </Modal.Actions>
          </Modal>
        </Container>

        <Container as={Segment} vertical basic style={{backgroundColor:'yellow'}}>
          {/*<Button
            content={'모듈 추가하기'}
            onClick={() => {
              bookClub.descriptionModules.push(_.cloneDeep(NEW_DESCRIPTION_MODULE));
              this.setState({bookClub})
            }}/>*/}
          <Divider hidden/>
          <Grid>
            {
              bookClub.descriptionModules.map((module, i) => {
                return (
                  <Grid.Row key={i}>
                    <Grid.Column width={2}>
                      <Button
                        size={'mini'}
                        icon
                        negative
                        onClick={() => {
                          const a = window.confirm('삭제하시겠습니까?');
                          if (!a) return;
                          bookClub.descriptionModules.splice(i, 1);
                          this.setState({bookClub})
                        }}>
                        <Icon name={'remove'}/>
                      </Button>
                      <Button
                        size={'mini'}
                        icon
                        disabled={i === 0}
                        onClick={() => {
                          bookClub.descriptionModules = moveItemInArrayFromIndexToIndex(bookClub.descriptionModules, i, i - 1);
                          this.setState({bookClub})
                        }}>
                        <Icon name={'angle up'}/>
                      </Button>
                      <Button
                        size={'mini'}
                        icon
                        disabled={i === bookClub.descriptionModules.length - 1}
                        onClick={() => {
                          bookClub.descriptionModules = moveItemInArrayFromIndexToIndex(bookClub.descriptionModules, i, i + 1);
                          this.setState({bookClub})
                        }}>
                        <Icon name={'angle down'}/>
                      </Button>
                    </Grid.Column>
                    <Grid.Column width={14}>
                      <Card fluid>
                        <Card.Content>
                          <Form key={i}>
                             header
                            <Form.Group style={{alignItems: 'center'}}>
                              <Form.Input
                                width={10}
                                label={'header'}
                                value={module.header.content}
                                onChange={(e, {value}) => {
                                  bookClub.descriptionModules[i].header.content = value;
                                  this.setState({
                                    bookClub
                                  })
                                }}
                              />
                              <Form.Input
                                width={3}
                                label={'color'}
                                value={module.header.color}
                                onChange={(e, {value}) => {
                                  bookClub.descriptionModules[i].header.color = value;
                                  this.setState({
                                    bookClub
                                  })
                                }}
                              />
                              <div style={{marginTop: 30}}>
                                <Form.Field>
                                  <Radio
                                    label='LEFT'
                                    name={`header-${i}`}
                                    value='left'
                                    checked={module.header.align === 'left'}
                                    onChange={(e) => {
                                      bookClub.descriptionModules[i].header.align = 'left';
                                      this.setState({
                                        bookClub
                                      })
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <Radio
                                    label='CENTER'
                                    name={`header-${i}`}
                                    value='center'
                                    checked={module.header.align === 'center'}
                                    onChange={(e) => {
                                      bookClub.descriptionModules[i].header.align = 'center';
                                      this.setState({
                                        bookClub
                                      })
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <Radio
                                    label='RIGHT'
                                    name={`header-${i}`}
                                    value='right'
                                    checked={module.header.align === 'right'}
                                    onChange={(e) => {
                                      bookClub.descriptionModules[i].header.align = 'right';
                                      this.setState({
                                        bookClub
                                      })
                                    }}
                                  />
                                </Form.Field>
                              </div>
                            </Form.Group>

                             subHeader
                            <Form.Group style={{alignItems: 'center'}}>
                              <Form.Input
                                width={10}
                                label={'subHeader'}
                                value={module.subHeader.content}
                                onChange={(e, {value}) => {
                                  bookClub.descriptionModules[i].subHeader.content = value;
                                  this.setState({
                                    bookClub
                                  })
                                }}
                              />
                              <Form.Input
                                width={3}
                                label={'color'}
                                value={module.subHeader.color}
                                onChange={(e, {value}) => {
                                  bookClub.descriptionModules[i].subHeader.color = value;
                                  this.setState({
                                    bookClub
                                  })
                                }}
                              />
                              <div style={{marginTop: 30}}>
                                <Form.Field>
                                  <Radio
                                    label='LEFT'
                                    name={`subHeader-${i}`}
                                    value='left'
                                    checked={module.subHeader.align === 'left'}
                                    onChange={(e) => {
                                      bookClub.descriptionModules[i].subHeader.align = 'left';
                                      this.setState({
                                        bookClub
                                      })
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <Radio
                                    label='CENTER'
                                    name={`subHeader-${i}`}
                                    value='center'
                                    checked={module.subHeader.align === 'center'}
                                    onChange={(e) => {
                                      bookClub.descriptionModules[i].subHeader.align = 'center';
                                      this.setState({
                                        bookClub
                                      })
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <Radio
                                    label='RIGHT'
                                    name={`subHeader-${i}`}
                                    value='right'
                                    checked={module.subHeader.align === 'right'}
                                    onChange={(e) => {
                                      bookClub.descriptionModules[i].subHeader.align = 'right';
                                      this.setState({
                                        bookClub
                                      })
                                    }}
                                  />
                                </Form.Field>
                              </div>
                            </Form.Group>

                             description
                            <Form.Group>
                              <Form.TextArea
                                width={10}
                                label={'description'}
                                rows={10}
                                value={module.description.content}
                                onChange={(e, {value}) => {
                                  bookClub.descriptionModules[i].description.content = value;
                                  this.setState({
                                    bookClub
                                  })
                                }}
                              />

                              <Form.Input
                                width={3}
                                label={'color'}
                                value={module.description.color}
                                onChange={(e, {value}) => {
                                  bookClub.descriptionModules[i].description.color = value;
                                  this.setState({
                                    bookClub
                                  })
                                }}
                              />
                              <div style={{marginTop: 23}}>
                                <Form.Field>
                                  <Radio
                                    label='LEFT'
                                    name='title'
                                    value='left'
                                    checked={module.description.align === 'left'}
                                    onChange={(e) => {
                                      bookClub.descriptionModules[i].description.align = 'left';
                                      this.setState({
                                        bookClub
                                      })
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <Radio
                                    label='CENTER'
                                    name='title'
                                    value='left'
                                    checked={module.description.align === 'center'}
                                    onChange={(e) => {
                                      bookClub.descriptionModules[i].description.align = 'center';
                                      this.setState({
                                        bookClub
                                      })
                                    }}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <Radio
                                    label='RIGHT'
                                    name='title'
                                    value='left'
                                    checked={module.description.align === 'right'}
                                    onChange={(e) => {
                                      bookClub.descriptionModules[i].description.align = 'right';
                                      this.setState({
                                        bookClub
                                      })
                                    }}
                                  />
                                </Form.Field>
                              </div>
                            </Form.Group>
                          </Form>
                        </Card.Content>
                        <Card.Content>
                          <Form>
                            <Form.Field>
                              <Radio
                                toggle
                                label={module.hasYouTubeId ? 'YouTube 영상 있음' : 'YouTube 영상 없음'}
                                checked={module.hasYouTubeId}
                                onChange={() => {
                                  bookClub.descriptionModules[i].hasYouTubeId = !module.hasYouTubeId;
                                  this.setState({bookClub})
                                }}
                              />
                            </Form.Field>
                            <Form.Input
                              disabled={!module.hasYouTubeId}
                              width={10}
                              label={'youTubeId'}
                              value={module.youTubeId}
                              onChange={(e, {value}) => {
                                bookClub.descriptionModules[i].youTubeId = value;
                                this.setState({
                                  bookClub
                                })
                              }}
                            />
                          </Form>
                        </Card.Content>
                        <Card.Content>
                          <Form>
                            <Form.Field>
                              <Radio
                                toggle
                                label={module.hasButton ? '버튼있음' : '버튼없음'}
                                checked={module.hasButton}
                                onChange={() => {
                                  bookClub.descriptionModules[i].hasButton = !module.hasButton;
                                  this.setState({bookClub})
                                }}
                              />
                            </Form.Field>
                            <Form.Input
                              disabled={!module.hasButton}
                              width={10}
                              label={'externalLink'}
                              value={module.externalLink}
                              onChange={(e, {value}) => {
                                bookClub.descriptionModules[i].externalLink = value;
                                this.setState({
                                  bookClub
                                })
                              }}
                            />
                            <Form.Input
                              disabled={!module.hasButton}
                              width={10}
                              label={'buttonText'}
                              value={module.buttonText}
                              onChange={(e, {value}) => {
                                bookClub.descriptionModules[i].buttonText = value;
                                this.setState({
                                  bookClub
                                })
                              }}
                            />

                          </Form>
                        </Card.Content>
                        <Card.Content>
                           이미지 추가
                          <Header size={'small'}>
                            이미지 추가
                          </Header>
                          <Button as={'label'} size={'mini'}>
                            이미지 추가
                            <FileUploader
                              hidden
                              accept="image/*"
                              name={`module${i}`}
                              randomizeFilename
                              storageRef={firebase.storage().ref(storageRef)}
                              onUploadSuccess={async filename => {
                                await firebase.storage()
                                  .ref(storageRef)
                                  .child(filename)
                                  .getDownloadURL()
                                  .then(imageUrl => {
                                    bookClub.descriptionModules[i].imageUrl = imageUrl;
                                    this.setState({bookClub})
                                  });
                              }}
                            />
                          </Button>
                          <Button
                            size={'mini'}
                            negative
                            disabled={!module.imageUrl}
                            content={'이미지 삭제'}
                            onClick={() => {
                              const a = window.confirm('이미지를 삭제하시겠습니까?');
                              if (!a) return;
                              bookClub.descriptionModules[i].imageUrl = '';
                              this.setState({
                                bookClub
                              })
                            }}/>
                          <Divider hidden/>
                          <Form>
                            <Form.Field>
                              <Radio
                                toggle
                                label={module.isImageFullWidth ? 'FULL WIDTH' : 'FULL WIDTH 아님'}
                                checked={module.isImageFullWidth}
                                onChange={() => {
                                  bookClub.descriptionModules[i].isImageFullWidth = !module.isImageFullWidth;
                                  this.setState({bookClub})
                                }}
                              />
                            </Form.Field>
                          </Form>
                          <Divider hidden/>
                          <Image src={module.imageUrl || mockUpImages.planeImage} size={'small'}/>
                        </Card.Content>
                        <Card.Content>
                           지도 추가
                          <Header size={'small'}>
                            지도 추가
                          </Header>
                          <Form>
                            <Form.Field>
                              <Radio
                                toggle
                                label={module.hasLocation ? '지도 있음' : '지도 없음'}
                                checked={module.hasLocation}
                                onChange={() => {
                                  bookClub.descriptionModules[i].hasLocation = !module.hasLocation;
                                  this.setState({bookClub})
                                }}
                              />
                            </Form.Field>

                            <Form.Input
                              disabled={!module.hasLocation}
                              width={10}
                              label={'장소명'}
                              value={module.locationName}
                              onChange={(e, {value}) => {
                                bookClub.descriptionModules[i].locationName = value;
                                this.setState({bookClub})
                              }}
                            />

                            <Form.Input
                              disabled={!module.hasLocation}
                              width={10}
                              label={'검색하기'}
                              value={this.state.locationSearchKeyword}
                              onChange={(e, {value}) => {
                                this.setState({locationSearchKeyword: value})
                              }}
                            />
                            <Button content={'검색'} onClick={async () => {
                              if (this.state.locationSearchKeyword === '') return window.alert('검색어를 입력해주세요');
                              const res = await kakaoMapSearch(this.state.locationSearchKeyword);
                              console.log(res);
                              this.setState({
                                searchedLocations: res.data && res.data.documents,
                                isEnd: res.data && res.data.meta.is_end,
                              })
                            }}/>
                            <List>
                              {
                                this.state.searchedLocations.map((location, j) => {
                                  return (
                                    <List.Item key={j}>
                                      <List.Header onClick={() => {
                                        const locationCoordinate = new firebase.firestore.GeoPoint(Number(location.y), Number(location.x));

                                        console.log(locationCoordinate.latitude);
                                        console.log(locationCoordinate.longitude);
                                        bookClub.descriptionModules[i].locationCoordinate = locationCoordinate;

                                        this.setState({
                                          bookClub
                                        })
                                      }}>{location.place_name}</List.Header>
                                    </List.Item>
                                  )
                                })
                              }
                            </List>
                          </Form>
                          {
                            module.hasLocation && (
                              <GoogleMapComponent
                                isMarkerShown
                                location={module.locationCoordinate}
                                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapKey}&v=3.exp&libraries=geometry,drawing,places`}
                                loadingElement={<div style={{height: `100%`}}/>}
                                containerElement={<div style={{height: `400px`}}/>}
                                mapElement={<div style={{height: `100%`}}/>}
                              />
                            )
                          }

                        </Card.Content>
                      </Card>
                    </Grid.Column>
                  </Grid.Row>

                )
              })
            }
          </Grid>

        </Container>

        <Container as={Segment}>
          <Form>
            <Header style={styles.header}>
              TAGS
            </Header>
            {
              bookClub.tags && bookClub.tags.map((tag, i) => {
                return (
                  <Form.Input
                    width={3}
                    name={`tag-${i}`}
                    value={tag}
                    key={i}
                    onChange={(e, {value}) => {
                      bookClub.tags[i] = value;
                      this.setState({
                        bookClub
                      })
                    }}
                  >
                    <input/>
                    <Button icon onClick={() => {
                      bookClub.tags.splice(i, 1);
                      this.setState({
                        bookClub
                      })
                    }}>
                      <Icon name={'close'}/>
                    </Button>
                  </Form.Input>
                )
              })
            }
            <Button onClick={() => {
              if (bookClub.tags) {
                bookClub.tags.push('');
              } else {
                bookClub.tags = ['']
              }
              this.setState({
                bookClub
              })
            }}>
              태그 추가하기
            </Button>
          </Form>
        </Container>

      </Fragment>
    );
  }
}

const styles = {
  verticalModule: {
    marginBottom: 100
  },
  header: {
    marginTop: 40,
  }
}

const enhance = connect(
  state => ({
    auth: state.auth
  }),
  {
    ...authActionCreators
  }
);
export default enhance(AdminBookClubDetailContainer);
