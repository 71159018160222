import React, {Component} from 'react';
import {connect} from 'react-redux'
import {authActionCreators} from "../../redux/auth/authStore";

class SpaceMainScreen extends Component {
  render() {
    return (
      <div>
        SpaceMainScreen
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    auth: state.auth
  }),
  {
    ...authActionCreators,
  }
);

export default enhance(SpaceMainScreen);
