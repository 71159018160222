import React, {Component} from "react";
import {connect} from "react-redux";
import {authActionCreators} from "../../redux/auth/authStore";
import {Link} from "react-router-dom";
import {Table, Segment, Button, Container, Loader, Label, Header} from "semantic-ui-react";
import {
  CURATION_CATEGORY_BOOK,
  CURATION_CATEGORY_BOOK_LIST,
  CURATION_CATEGORY_BOOKCLUB,
  CURATION_CATEGORY_BOOKCLUB_LIST,
  CURATION_CATEGORY_BOOKCLUB_LIST_BEFORE_RECRUIT,
  CURATION_CATEGORY_BOOKCLUB_LIST_ENDED,
  CURATION_CATEGORY_BOOKCLUB_LIST_ING,
  CURATION_CATEGORY_BOOKCLUB_LIST_RECRUITING,
  CURATION_CATEGORY_ESSAY,
  CURATION_CATEGORY_ESSAY_LIST,
  CURATION_CATEGORY_LEADER,
  CURATION_CATEGORY_LEADER_LIST,
  CURATION_CATEGORY_MEMBER,
  CURATION_CATEGORY_MEMBER_LIST,
  CURATION_CATEGORY_NOTICE,
  CURATION_CATEGORY_NOTICE_LIST,
  CURATION_CATEGORY_SCRAP, CURATION_CATEGORY_SCRAP_LIST,
  CURATION_CATEGORY_TIP,
  CURATION_LAYOUT_CARD,
  CURATION_LAYOUT_GRID,
  CURATION_LAYOUT_HORIZONTAL_LIST,
  CURATION_LAYOUT_LIST_IN_CARD,
} from "../../dataStructure/curation";
import Network from "../../library/Network";

class AdminCurationMainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curations: [],
      isLoaded: false,
    };
  }

  componentDidMount() {
    this._loadCurationData();
  }

  _loadCurationData = async () => {
    const res = await Network.getAllCurations();
    if (res.err) return window.alert('큐레이션을 불러오는데 실패하였습니다.');
    this.setState({
      isLoaded: true,
      curations: res,
    });
  };

  render() {
    const {curations, isLoaded} = this.state;
    if (!isLoaded) return <Loader active/>
    return (
      <Container as={Segment} vertical>
        <Header>큐레이션 어드민페이지</Header>
        <Button as={Link} to={'/admin/curation/write'}>
          큐레이션 만들기
        </Button>
        <Table compact={'very'} size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>order</Table.HeaderCell>
              <Table.HeaderCell>isPublic</Table.HeaderCell>
              <Table.HeaderCell>isDeleted</Table.HeaderCell>
              <Table.HeaderCell>Header</Table.HeaderCell>
              <Table.HeaderCell>category</Table.HeaderCell>
              <Table.HeaderCell>layout</Table.HeaderCell>
              <Table.HeaderCell>태그</Table.HeaderCell>
              <Table.HeaderCell width={1}>버튼</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {curations.map((curation, i) => {
              let layoutLabelColor;
              let categoryLabelColor;
              switch (curation.layout) {
                case CURATION_LAYOUT_CARD:
                  layoutLabelColor = 'red';
                  break;
                case CURATION_LAYOUT_LIST_IN_CARD:
                  layoutLabelColor = 'orange';
                  break;
                case CURATION_LAYOUT_GRID:
                  layoutLabelColor = 'blue';
                  break;
                case CURATION_LAYOUT_HORIZONTAL_LIST:
                  layoutLabelColor = 'green';
                  break;
                default:
                  layoutLabelColor = 'grey';
                  break;
              }

              switch (curation.category) {
                case CURATION_CATEGORY_TIP:
                  categoryLabelColor = 'red';
                  break;
                case CURATION_CATEGORY_BOOK:
                case CURATION_CATEGORY_BOOK_LIST:
                  categoryLabelColor = 'orange';
                  break;
                case CURATION_CATEGORY_BOOKCLUB:
                case CURATION_CATEGORY_BOOKCLUB_LIST:
                case CURATION_CATEGORY_BOOKCLUB_LIST_BEFORE_RECRUIT:
                case CURATION_CATEGORY_BOOKCLUB_LIST_RECRUITING:
                case CURATION_CATEGORY_BOOKCLUB_LIST_ING:
                case CURATION_CATEGORY_BOOKCLUB_LIST_ENDED:
                  categoryLabelColor = 'yellow';
                  break;
                case CURATION_CATEGORY_ESSAY:
                case CURATION_CATEGORY_ESSAY_LIST:
                  categoryLabelColor = 'olive';
                  break;
                case CURATION_CATEGORY_MEMBER:
                case CURATION_CATEGORY_MEMBER_LIST:
                  categoryLabelColor = 'green';
                  break;
                case CURATION_CATEGORY_NOTICE:
                case CURATION_CATEGORY_NOTICE_LIST:
                  categoryLabelColor = 'teal';
                  break;
                case CURATION_CATEGORY_LEADER:
                case CURATION_CATEGORY_LEADER_LIST:
                  categoryLabelColor = 'blue';
                  break;
                case CURATION_CATEGORY_SCRAP:
                case CURATION_CATEGORY_SCRAP_LIST:
                  categoryLabelColor = 'violet';
                  break;
                default:
                  categoryLabelColor = 'grey';
                  break;


              }
              return (
                <Table.Row key={i}>
                  <Table.Cell>{curation.order}</Table.Cell>
                  <Table.Cell>
                    {curation.isPublic ? "공개" : "비공개"}
                  </Table.Cell>
                  <Table.Cell>
                    {curation.isDeleted ? "삭제됨" : ""}
                  </Table.Cell>
                  <Table.Cell>
                    <b>{curation.title.content}</b> <br/>
                    <span style={{fontSize: '0.7rem', color: 'grey'}}>{curation.subTitle.content}</span>
                  </Table.Cell>
                  <Table.Cell>
                    <Label content={curation.category} size={'mini'} color={categoryLabelColor}/>
                  </Table.Cell>
                  <Table.Cell>
                    <Label content={curation.layout} size={'mini'} color={layoutLabelColor}/>
                  </Table.Cell>
                  <Table.Cell>
                    <Label content={curation.tag} size={'mini'}/>
                  </Table.Cell>
                  <Table.Cell>
                    <Button as={Link}
                            to={{
                              pathname: `/admin/curation/read/${curation.id}`,
                              state: {
                                curation
                              }
                            }}
                            size='mini'>
                      상세보기
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    auth: state.auth
  }),
  {
    ...authActionCreators
  }
);
export default enhance(AdminCurationMainContainer);
