import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {adminActionCreators} from "../../redux/admin/adminStore";
import _ from 'lodash';
import {Container, Segment, Table, Loader, Button, Card, Grid,} from 'semantic-ui-react';
import ScrapComponent from "../../components/scrap/ScrapComponent";

class AdminScrapMainContainer extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.fetchScrapsAdmin();
  }

  render() {
    const {scraps, scrapIsLoaded} = this.props.admin;
    if (!scrapIsLoaded) return <Loader active/>
    return (
      <div>
        AdminScrapMainContainer
        <Container as={Segment} vertical>
          <Button
            content={'새로고침'}
            onClick={() => {
              this.props.fetchScrapsAdmin();
            }}
          />
        </Container>
        <Container as={Segment} vertical fluid>
          <Card.Group itemsPerRow={4}>
            {
              scraps.map((scrap, i) => {
                return (
                  <ScrapComponent
                    showsUserInfo
                    showsBookInfo
                    showsPushButton
                    key={i}
                    scrap={scrap}/>
                )
              })
            }
          </Card.Group>
          <Button
            disabled={!scrapIsLoaded}
            content={'더 불러오기'}
            onClick={() => {
              this.props.fetchScrapsMoreAdmin();
            }}
          />
        </Container>
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
    ...adminActionCreators,
  }
);


export default enhance(AdminScrapMainContainer);