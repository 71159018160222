import React, { Component } from "react";
import { connect } from "react-redux";
import { authActionCreators } from "../../redux/auth/authStore";
import { db } from "../../constants/firebase";
import { Link } from "react-router-dom";
import { Table, Segment, Button, Container, Header } from "semantic-ui-react";
import moment from "moment";

class AdminBannerMainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: []
    };
  }

  componentDidMount() {
    this._loadBannerData();
  }

  _loadBannerData = async () => {
    await db
      .collection("banners")
      .get()
      .then(snapshot => {
        const banners = [];
        snapshot.forEach(doc => {
          const banner = Object.assign(doc.data(), { id: doc.id });
          banners.push(banner);
        });
        this.setState({
          banners
        });
        console.log(this.state.banners);
      });
  };
  render() {
    // [
    //   {
    //     backgroundColor: "grey",
    //     createdAt: { seconds: 1563614313, nanoseconds: 785000000 },
    //     header: "리더스가 무엇인가요?",
    //     id: "eYxiQd5oYJPxMbubtT7G",
    //     isPublic: true,
    //     order: 0,
    //     subHeader: "리더스 서비스에 대해서 알려드려요 :)",
    //     tags: ["BANNER_01"],
    //     targetValue: "",
    //     type: "BANNER_TYPE_NOTICE"
    //   },
    //   {
    //     backgroundColor: "grey",
    //     createdAt: { seconds: 1563587654, nanoseconds: 457000000 },
    //     header: "배너 헤더",
    //     isPublic: true,
    //     order: 0,
    //     subHeader: "서브 헤더",
    //     tags: ["BANNER_01"],
    //     targetValue: "",
    //     type: "BANNER_TYPE_NOTICE",
    //     id: "jcH0p7S26LNbAgNoCMVr"
    //   }
    // ];
    const { banners } = this.state;
    return (
      <Container as={Segment} vertical>
        <Header>배너 어드민페이지</Header>
        <Table compact size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No</Table.HeaderCell>
              <Table.HeaderCell>배너헤더</Table.HeaderCell>
              <Table.HeaderCell>배너서브헤더</Table.HeaderCell>
              <Table.HeaderCell>배너ID</Table.HeaderCell>
              <Table.HeaderCell>isPublic</Table.HeaderCell>
              <Table.HeaderCell>생성일</Table.HeaderCell>
              <Table.HeaderCell>태그</Table.HeaderCell>
              <Table.HeaderCell>Order</Table.HeaderCell>
              <Table.HeaderCell>버튼</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {banners.map((banner, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>{i}</Table.Cell>
                  <Table.Cell>{banner.header}</Table.Cell>
                  <Table.Cell>{banner.subHeader}</Table.Cell>
                  <Table.Cell>{banner.id}</Table.Cell>
                  <Table.Cell>{banner.isPublic ? "공개" : "비공개"}</Table.Cell>
                  <Table.Cell>
                    {moment(banner.createdAt.seconds * 1000).format(
                      "YYYY. MM. DD"
                    )}
                  </Table.Cell>
                  <Table.Cell>{banner.tags}</Table.Cell>
                  <Table.Cell>{banner.order}</Table.Cell>
                  <Table.Cell>
                    <Button
                      as={Link}
                      to={{pathname: `/admin/banner/${banner.id}`, state: {banner: banner}}}
                      size={"mini"}
                    >
                      상세보기
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

const enhance = connect(
  state => ({
    auth: state.auth
  }),
  {
    ...authActionCreators
  }
);
export default enhance(AdminBannerMainContainer);
