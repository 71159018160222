export const essayActions = {
  FETCH_ALL_MY_ESSAYS: 'FETCH_ALL_MY_ESSAYS',
  FETCH_ALL_MY_ESSAYS_SUCCESS: 'FETCH_ALL_MY_ESSAYS_SUCCESS',
};



export const essayActionCreators = {
  fetchAllMyEssays: (cb) => ({type: essayActions.FETCH_ALL_MY_ESSAYS, cb}),
  fetchAllMyEssaysSuccess: (essays, cb) => ({type: essayActions.FETCH_ALL_MY_ESSAYS_SUCCESS, cb, essays})
};


const initialState = {
  essays: [],
};


export const essayReducer = (state = initialState, action) => {

  switch (action.type) {
    case essayActions.FETCH_ALL_MY_ESSAYS_SUCCESS:
      return {
        ...state,
        essays: [
          ...action.essays
        ]
      }
    default:
      return {
        ...state
      }
  }
};