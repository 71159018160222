import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {authActionCreators} from "../../redux/auth/authStore";
import {Modal, Image, Header, Button, List, Divider} from 'semantic-ui-react';

class UserSMSComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      detailModalOpen: false,
    }
  }


  componentDidMount() {

  }

  _loadUserInfo = async () => {

  };

  _handleModalOpen = () => {
    this.setState({detailModalOpen: true,})
  };


  render() {
    const {userId, user} = this.props;
    if (!user || !user.id) return <div/>;

    return (
      <Fragment>
        <Button size={'mini'} compact content={'SMS'} onClick={this._handleModalOpen}/>
        <Modal open={this.state.detailModalOpen}>
          <Header>
            {user.displayName}
          </Header>
          <Modal.Content image>
            <Image wrapped size='medium' src={user.photoURL}/>
            <Modal.Description>
              <p>
                {user.detailProfile && user.detailProfile.name}
              </p>
              <p>
                {user.detailProfile && user.detailProfile.phoneNumber}
              </p>
              <p>
                {user.detailProfile && user.detailProfile.description}
              </p>
              <p>
                {user.detailProfile && user.detailProfile.career}
              </p>
              <p>
                {user.detailProfile && user.detailProfile.education}
              </p>
              <Divider/>
              <List bulleted>
                {user.token && user.token.map((token, i) => {
                  return (
                    <List.Item style={{fontSize: '0.3rem'}} key={i}>
                      [{i + 1}] {token} <br/>
                    </List.Item>
                  )
                })}
              </List>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button content={'닫기'}
                    negative
                    onClick={() => {
                      this.setState({detailModalOpen: false,})
                    }}/>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators,
  }
);


export default enhance(UserSMSComponent);