import React, {Component} from 'react';
import {connect} from 'react-redux';
import {firebaseDB} from "../../constants/firebase";
import {Container, Header, Segment} from "semantic-ui-react";
import {withLineEnter} from "../../middleware/TextHandler";

class PrivacyPolicyContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      privacyPolicy: {
        modules: [],
      },
    }
  }

  componentDidMount() {
    this._loadAdminSettings();
  }

  _loadAdminSettings = async () => {
    await firebaseDB
      .adminSettings
      .doc('privacyPolicy')
      .get()
      .then(doc => {
        if (!doc.exists) return;
        const privacyPolicy = Object.assign(doc.data(), {id: doc.id});
        console.log({privacyPolicy})
        this.setState({
          privacyPolicy
        })
      })
      .catch(e => {
          console.log(e)
        }
      )
  };

  render() {
    return (
      <div>
        {
          this.state.privacyPolicy?.modules?.map((item, i) => {
            return (
              <Segment key={i} vertical basic as={Container}>
                {!!item.title && (<Header content={item.title}/>)}
                {!!item.description && (<p>{withLineEnter(item.description)} </p>)}
                {!!item.detail && (<p>{withLineEnter(item.detail)} </p>)}

              </Segment>
            )
          })
        }
      </div>
    );
  }
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(PrivacyPolicyContainer);
