import React, {Component} from 'react';
import {BrowserRouter} from 'react-router-dom'
import {Route} from 'react-router'
import {connect} from 'react-redux';
import {authActionCreators} from "./redux/auth/authStore";
import Layout from "./components/shared/Layout";
import 'semantic-ui-css/semantic.min.css';
import {auth, db} from "./constants/firebase";
import {PrivateRoute, PrivateRouteForAdmin} from "./components/shared/PrivateRoute";

import HomeMainContainer from "./containers/home/HomeMainContainer";
import LoginContainer from "./containers/auth/LoginContainer";
import SignUpContainer from "./containers/auth/SignUpContainer";
import MyBookClubMainContainer from "./containers/myBookClub/MyBookClubMainContainer";
import AppMainContainer from "./containers/about/AppMainContainer";
import SpaceMainScreen from "./containers/about/SpaceMainScreen";
import AdminBannerMainContainer from "./containers-admin/banner/AdminBannerMainContainer";
import AdminBookClubMainContainer from "./containers-admin/bookClub/AdminBookClubMainContainer";
import AdminCouponMainContainer from "./containers-admin/coupon/AdminCouponMainContainer";
import AdminLeaderMainContainer from "./containers-admin/leader/AdminLeaderMainContainer";
import AdminLeaderDetailContainer from './containers-admin/leader/AdminLeaderDetailContainer';
import AdminCurationMainContainer from './containers-admin/curation/AdminCurationMainContainer';
import AdminUserMainContainer from './containers-admin/user/AdminUserMainContainer'
import AdminPartnerMainContainer from './containers-admin/partner/AdminPartnerMainContainer'
import AdminNoticeMainContainer from './containers-admin/notice/AdminNoticeMainContainer'
import AdminBookClubDetailContainer from './containers-admin/bookClub/AdminBookClubDetailContainer';
import AdminBannerDetailContainer from './containers-admin/banner/AdminBannerDetailContainer';
import AdminCurationDetailContainer from './containers-admin/curation/AdminCurationDetailContainer';
import AdminNoticeDetailContainer from './containers-admin/notice/AdminNoticeDetailContainer';
import AdminPartnerDetailContainer from './containers-admin/partner/AdminPartnerDetailContainer';
import AdminCouponDetailContainer from './containers-admin/coupon/AdminCouponDetailContainer';
import AdminUserDetailContainer from './containers-admin/user/AdminUserDetailContainer';
import AdminTipMainContaier from "./containers-admin/tip/AdminTipMainContaier";
import AdminTipDetailScreen from "./containers-admin/tip/AdminTipDetailScreen";
import AdminEssayMainScreen from "./containers-admin/essay/AdminEssayMainScreen";
import AdminEssayDetailScreen from "./containers-admin/essay/AdminEssayDetailScreen";
import MyBookClubDetailContainer from '../src/containers/myBookClub/MyBookClubDetailContainer'
import AdminBookMainContainer from "./containers-admin/book/AdminBookMainContainer";
import AdminTransactionDetailContainer from "./containers-admin/transaction/AdminTransactionDetailContainer";
import AdminTransactionMainContainer from "./containers-admin/transaction/AdminTransactionMainContainer";
import AdminUserBooksMainContainer from "./containers-admin/user/AdminUserBooksMainContainer";
import AdminBookshelfMainContainer from "./containers-admin/user/AdminBookshelfMainContainer";
import AdminFAQMainContainer from "./containers-admin/faq/AdminFAQMainContainer";
import AdminFAQDetailContainer from "./containers-admin/faq/AdminFAQDetailContainer";
import AdminCouponCodeDetailContainer from "./containers-admin/couponCode/AdminCouponCodeDetailContainer";
import AdminCouponCodeMainContainer from "./containers-admin/couponCode/AdminCouponCodeMainContainer";
import AdminCouponIssueContainer from "./containers-admin/coupon/AdminCouponIssueContainer";
import AdminBookClubManageMainContainer from "./containers-admin/bookClubManage/AdminBookClubManageMainContainer";
import AdminSettingMainContainer from "./containers-admin/admin/AdminSettingMainContainer";
import AdminFaqApplyMainContainer from "./containers-admin/faq-apply/AdminFaqApplyMainContainer";
import AdminFaqApplyDetailContainer from "./containers-admin/faq-apply/AdminFaqApplyDetailContainer";
import AdminPrivacyPolicyContainer from "./containers-admin/admin/AdminPrivacyPolicyContainer";
import AdminTermsOfServiceContainer from "./containers-admin/admin/AdminTermsOfServiceContainer";
import AdminBookClubRuleContainer from "./containers-admin/admin/AdminBookClubRuleContainer";
import AboutReadersContainer from "./containers/about/AboutReadersContainer";
import AboutCameraContainer from "./containers/about/AboutCameraContainer";
import AboutBookClubContainer from "./containers/about/AboutBookClubContainer";
import AboutLibraryContainer from "./containers/about/AboutLibraryContainer";
import AboutReadersProContainer from "./containers/about/AboutReadersProContainer";
import MyBookClubEssayScheduleDetailContainer from "./containers/myBookClub/MyBookClubEssayScheduleDetailContainer";
import EssayWriteAndModifyContainer from "./containers/essay/EssayWriteAndModifyContainer";
import AdminInquiryMainContainer from "./containers-admin/inquiry/AdminInquiryMainContainer";
import AdminInquiryDetailContainer from "./containers-admin/inquiry/AdminInquiryDetailContainer";
import AdminUpdateRequestMainContainer from "./containers-admin/updateRequest/AdminUpdateRequestMainContainer";
import AdminUpdateRequestDetailContainer from "./containers-admin/updateRequest/AdminUpdateRequestDetailContainer";
import AdminBookClubBannerDetailContainer from "./containers-admin/bookClubBanner/AdminBookClubBannerDetailContainer";
import AdminBookClubBannerMainContainer from "./containers-admin/bookClubBanner/AdminBookClubBannerMainContainer";
import AdminStoryMainContainer from "./containers-admin/story/AdminStoryMainContainer";
import AdminStoryDetailContainer from "./containers-admin/story/AdminStoryDetailContainer";
import AdminPlaceMainContainer from "./containers-admin/place/AdminPlaceMainContainer";
import AdminPlaceDetailContainer from "./containers-admin/place/AdminPlaceDetailContainer";
import AdminScrapMainContainer from "./containers-admin/scrap/AdminScrapMainContainer";
import BookClubDescriptionBookContainer from "./containers-admin/bookClub-description/BookClubDescriptionBookContainer";
import BookClubDescriptionDepositContainer
  from "./containers-admin/bookClub-description/BookClubDescriptionDepositContainer";
import BookClubDescriptionEssaysContainer
  from "./containers-admin/bookClub-description/BookClubDescriptionEssaysContainer";
import BookClubDescriptionHeadCountContainer
  from "./containers-admin/bookClub-description/BookClubDescriptionHeadCountContainer";
import BookClubDescriptionMembershipContainer
  from "./containers-admin/bookClub-description/BookClubDescriptionMembershipContainer";
import BookClubDescriptionOfflineMeetingContainer
  from "./containers-admin/bookClub-description/BookClubDescriptionOfflineMeetingContainer";
import BookClubDescriptionOnlineMeetingContainer
  from "./containers-admin/bookClub-description/BookClubDescriptionOnlineMeetingContainer";
import BookClubDescriptionScrapsContainer
  from "./containers-admin/bookClub-description/BookClubDescriptionScrapsContainer";
import AdminSettingBankAccountContainer from "./containers-admin/admin/AdminSettingBankAccountContainer";
import AdminPushMainContainer from "./containers-admin/push/AdminPushMainContainer";
import AdminBookClubManageScrapContainer from "./containers-admin/bookClubManage/AdminBookClubManageScrapContainer";
import NetworkApiV1 from "./library/NetworkApiV1";
import {Loader} from "semantic-ui-react";
import BookClubListIngContainer from "./containers/book-club-list/BookClubListIngContainer";
import BookClubListEndContainer from "./containers/book-club-list/BookClubListEndContainer";
import BookClubListRecruitingContainer from "./containers/book-club-list/BookClubListRecruitingContainer";
import BookClubDetailContainer from "./containers/book-club/BookClubDetailContainer";
import BookClubApplyDetailContainer from "./containers/book-club-my/BookClubApplyDetailContainer";
import BookClubMyDetailContainer from "./containers/book-club-my/BookClubMyDetailContainer";
import InquiryMainContainer from "./containers/inquiry/InquiryMainContainer";
import HomeMainContainerBackup from "./containers/home/HomeMainContainerBackup";
import PrivacyPolicyContainer from "./containers/terms/PrivacyPolicyContainer";


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    }
  }


  componentDidMount() {
    // this.onAddListener();
  }

  onAddListener = () => {
    auth.onAuthStateChanged(async user => {
      if (user) {
        // console.log('로그인 되어 있음');
        NetworkApiV1.setFirebaseUid(user.uid);
        this.props.appInitialize(() => {
          this.setState({isLoaded: true})
        });
      } else {
        // console.log('로그인 되어 있지 않음')
        this.setState({isLoaded: true})
      }
    })
  };

  render() {
    const {isLoggedIn} = this.props.auth;
    const {isAdmin} = this.props.auth.user;
    // if (!this.state.isLoaded) return <Loader active/>;
    const publicRoute = [
      {path: '/', component: HomeMainContainerBackup},
      {path: '/about/app', component: AppMainContainer},
      {path: '/about/readers', component: AboutReadersContainer},
      {path: '/about/library', component: AboutLibraryContainer},
      {path: '/about/camera', component: AboutCameraContainer},
      {path: '/about/bookclub', component: AboutBookClubContainer},
      {path: '/privacy', component: PrivacyPolicyContainer},
      /*{path: '/about/space', component: SpaceMainScreen},
      {path: '/about/readers-pro', component: AboutReadersProContainer},
      {path: '/login', component: LoginContainer},
      {path: '/signup', component: SignUpContainer},
      {path: '/book-club/:bookClubId', component: BookClubDetailContainer},
      {path: '/book-club/list/ing', component: BookClubListIngContainer},
      {path: '/book-club/list/end', component: BookClubListEndContainer},
      {path: '/book-club/list/recruiting', component: BookClubListRecruitingContainer},*/
    ];

    const privateRoute = [
      {path: '/inquiry', component: InquiryMainContainer},
      {path: '/my/book-club/apply/:bookClubId', component: BookClubApplyDetailContainer},
      {path: '/my/book-club/:bookClubId', component: BookClubMyDetailContainer},

      {path: '/my/bookclub', component: MyBookClubMainContainer},
      {path: '/my/bookclub/:bookClubId', component: MyBookClubDetailContainer},
      {path: '/my/bookclub-essay/write', component: EssayWriteAndModifyContainer},
      {path: '/my/bookclub-essay/modify/:essayId', component: EssayWriteAndModifyContainer},
      {path: '/my/bookclub-essay/read/:bookClubEssayScheduleId', component: MyBookClubEssayScheduleDetailContainer},
    ];

    return (
      <BrowserRouter>
        <Layout>
          {
            publicRoute.map((route, i) => (
              <Route key={i} exact path={route.path} component={route.component}/>
            ))
          }

          {
            privateRoute.map((route, i) => (
              <PrivateRoute key={i} exact isLoggedIn={isLoggedIn} path={route.path} component={route.component}/>
            ))
          }
        </Layout>
      </BrowserRouter>
    );
  }
}

const enhance = connect(
  state => ({
    auth: state.auth
  }),
  {
    ...authActionCreators,
  }
);

export default enhance(App);
