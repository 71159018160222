import {firebase} from "../constants/firebase";
import Colors from "../constants/Colors";
import mockUpImages from "../constants/mockUpImages";
import _ from 'lodash';

export const BOOK_CLUB_BANNER_TYPE_BOOK_CLUB = 'BOOK_CLUB_BANNER_TYPE_BOOK_CLUB';
export const BOOK_CLUB_BANNER_TYPE_BOOK_CLUB_LIST = 'BOOK_CLUB_BANNER_TYPE_BOOK_CLUB_LIST';

export const bookClubBannerTypeList = [
  BOOK_CLUB_BANNER_TYPE_BOOK_CLUB,
  BOOK_CLUB_BANNER_TYPE_BOOK_CLUB_LIST,
]

export const bookClubBannerTypeDropDownList = () => {
  return _.map(bookClubBannerTypeList, (status, index) => ({
    key: index,
    text: status,
    value: status,
  }))
}

export const NEW_BOOK_CLUB_BANNER = () => {
  return {
    type: BOOK_CLUB_BANNER_TYPE_BOOK_CLUB,
    isDeleted: false,
    isPublic: false,
    title: {
      content: 'TITLE',
      color: Colors.mainBlack,
      fontSize: 14,
      fontFamily: 'regular',
    },
    subTitle: {
      content: 'SUB_TITLE',
      color: Colors.mainBlack,
      fontSize: 10,
      fontFamily: 'bold',
    },
    imageUrl: mockUpImages.planeImage,
    bookClubIds: [],
    tag: '',
    order: 0,
    backgroundColor: 'rgba(0,0,0,0.1)',
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
  }
};