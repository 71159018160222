export const bookClubActions = {
  FETCH_MY_BOOK_CLUBS: 'FETCH_MY_BOOK_CLUBS',

  FETCH_MY_BOOK_CLUBS_APPROVED: 'FETCH_MY_BOOK_CLUBS_APPROVED',
  FETCH_MY_BOOK_CLUBS_APPROVED_SUCCESS: 'FETCH_MY_BOOK_CLUBS_APPROVED_SUCCESS',

  FETCH_MY_BOOK_CLUBS_BEFORE_APPROVAL: 'FETCH_MY_BOOK_CLUBS_BEFORE_APPROVAL',
  FETCH_MY_BOOK_CLUBS_BEFORE_APPROVAL_SUCCESS: 'FETCH_MY_BOOK_CLUBS_BEFORE_APPROVAL_SUCCESS',

  FETCH_MY_BOOK_CLUBS_DENIED: 'FETCH_MY_BOOK_CLUBS_DENIED',
  FETCH_MY_BOOK_CLUBS_DENIED_SUCCESS: 'FETCH_MY_BOOK_CLUBS_DENIED_SUCCESS',

  FETCH_ALL_BOOK_CLUBS: 'FETCH_ALL_BOOK_CLUBS',
  FETCH_ALL_BOOK_CLUBS_SUCCESS: 'FETCH_ALL_BOOK_CLUBS_SUCCESS',

};


export const bookClubActionCreators = {
  fetchMyBookClubs: (cb) => ({type: bookClubActions.FETCH_MY_BOOK_CLUBS, cb}),

  fetchMyBookClubsApproved: (cb) => ({
    type: bookClubActions.FETCH_MY_BOOK_CLUBS_APPROVED,
    cb
  }),
  fetchMyBookClubsApprovedSuccess: (bookClubs) => ({
    type: bookClubActions.FETCH_MY_BOOK_CLUBS_APPROVED_SUCCESS,
    bookClubs
  }),

  fetchMyBookClubsBeforeApproval: (cb) => ({
    type: bookClubActions.FETCH_MY_BOOK_CLUBS_BEFORE_APPROVAL,
    cb
  }),
  fetchMyBookClubsBeforeApprovalSuccess: (bookClubs) => ({
    type: bookClubActions.FETCH_MY_BOOK_CLUBS_BEFORE_APPROVAL_SUCCESS,
    bookClubs
  }),

  fetchMyBookClubsDenied: (cb) => ({
    type: bookClubActions.FETCH_MY_BOOK_CLUBS_DENIED,
    cb
  }),
  fetchMyBookClubsDeniedSuccess: (bookClubs) => ({
    type: bookClubActions.FETCH_MY_BOOK_CLUBS_DENIED_SUCCESS,
    bookClubs
  }),
  fetchAllBookClubs: (cb) => ({type: bookClubActions.FETCH_ALL_BOOK_CLUBS, cb}),
  fetchAllBookClubsSuccess: (bookClubs) => ({type: bookClubActions.FETCH_ALL_BOOK_CLUBS_SUCCESS, bookClubs}),

};


const initialState = {
  myBookClubsApproved: [],
  myBookClubsBeforeApproval: [],
  myBookClubsDenied: [],

  bookClubs: [],
};


export const bookClubReducer = (state = initialState, action) => {

  switch (action.type) {
    case bookClubActions.FETCH_ALL_BOOK_CLUBS_SUCCESS:
      return {
        ...state,
        bookClubs: action.bookClubs,
      };

    case bookClubActions.FETCH_MY_BOOK_CLUBS_APPROVED_SUCCESS:
      return {
        ...state,
        myBookClubsApproved: action.bookClubs,
      };

    case bookClubActions.FETCH_MY_BOOK_CLUBS_BEFORE_APPROVAL_SUCCESS:
      return {
        ...state,
        myBookClubsBeforeApproval: action.bookClubs,
      };

    case bookClubActions.FETCH_MY_BOOK_CLUBS_DENIED_SUCCESS:
      return {
        ...state,
        myBookClubsDenied: action.bookClubs,
      };

    default:
      return state;
  }
};