import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {authActionCreators} from "../../redux/auth/authStore";
import {
  Button,
  Grid,
  Segment,
  Loader,
  Container,
  Table,
  Divider,
  Message,
  Header,
  Icon,
  Form,
  Radio,
  Image,
  List
} from "semantic-ui-react";
import {db, storage} from "../../constants/firebase";
import {firebase} from "../../constants/firebase";
import FileUploader from "react-firebase-file-uploader";
import _ from 'lodash';
import {NEW_PARTNER} from "../../dataStructure/partner";
import Network from "../../library/Network";
import moment from "moment";
import mockUpImages from "../../constants/mockUpImages";

const storageRef = `partner/${moment().format("YYYYMM")}`;


class AdminPartnerDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partner: {},
      isLoaded: false,

    };
  }


  componentDidMount() {
    this._loadPartner();
  }

  _loadPartner = async () => {
    const isNewPartner = this.props.match.params.partnerId === undefined;

    if (isNewPartner) {
      this.setState({
        isNewPartner,
        partner: _.cloneDeep(NEW_PARTNER),
        isLoaded: true,
      })
    } else {
      const {partnerId} = this.props.match.params;
      const res = await Network.getPartnerByPartnerId(partnerId);
      if (res.err) return window.alert('파트너 정보를 찾을 수 없습니다.');


      this.setState({
        isNewPartner,
        partner: Object.assign(NEW_PARTNER, res),
        isLoaded: true,
      })
    }
  };



  handleChange = e => {
    const {partner} = this.state;
    const {name, value} = e.target;
    partner[name] = value;
    this.setState({
      partner
    });
  };

  handleSubmit = async () => {
    const confirm = window.confirm("업로드 하시겠습니까?");
    if (!confirm) return;

    const {partner} = this.state;
    console.log(partner);

    const isNewPartner = this.props.match.params.partnerId === undefined;

    let res;
    if (isNewPartner) {
      res = await Network.postPartner(partner);
      if (res.err) return window.alert('파트너 생성에 실패하였습니다.');
      window.alert('완료');
      this.props.history.replace(`/admin/partner/detail/${res.id}`)

    } else {
      res = await Network.updatePartner(partner);
      if (res.err) return window.alert('파트너 수정에 오류가 발생하였습니다.');
      window.alert('완료');
    }
  };


  handleUploadSuccess = async (filename, imageCategory) => {
    const {partner} = this.state;

    await firebase.storage()
      .ref(storageRef)
      .child(filename)
      .getDownloadURL()
      .then(imageUrl => {
        partner.image[imageCategory] = imageUrl;
        this.setState({partner})
      });
  };

  deletePartner = async () => {
    const confirm = window.confirm("삭제하시겠습니까?");
    if (!confirm) return;

    const res = await Network.updatePartner(Object.assign(this.state.partner, {isDeleted: true}));

    if (res.err) return window.alert('삭제에 실패하였습니다.');

    window.alert('삭제되었습니다.');
    this.props.history.replace('/admin/partner');
  };

  restore = async () => {
    const confirm = window.confirm("복구 하시겠습니까?");
    if (!confirm) return;

    const res = await Network.updatePartner(Object.assign(this.state.partner, {isDeleted: false}));

    if (res.err) return window.alert('복구에 실패하였습니다.');

    window.alert('복구되었습니다.');
    this._loadPartner();

  };

  render() {
    const {partner, isLoaded} = this.state;
    if (!isLoaded) {
      return (
        <Loader active={true}/>
      )
    }
    return (
      <Fragment>
        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={`${partner.name} 파트너 정보`} dividing/>
          <Button content={this.state.isNewPartner ? '만들기' : '수정하기'} primary onClick={this.handleSubmit}/>
          <Button content={'삭제'} negative disabled={this.state.isNewPartner || partner.isDeleted}
                  onClick={this.deletePartner}/>
          <Button content={'복구'} positive disabled={!partner.isDeleted} onClick={this.restore}/>
        </Container>
        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'기본정보'} dividing/>
          <Form>
            <Form.Field>
              <Radio
                toggle
                label={partner.isPublic ? "공개" : "비공개"}
                checked={partner.isPublic}
                onClick={() => {
                  partner.isPublic = !partner.isPublic;
                  this.setState({
                    partner
                  });
                }}
                style={{margin: "10px 0"}}/>
            </Form.Field>
            <Form.Group widths={'equal'}>
              <Form.Input
                fluid
                label="Order"
                name="order"
                value={partner.order}
                onChange={this.handleChange}
              />
              <Form.Input
                disabled={true}
                fluid
                label="파트너 ID"
                name="id"
                value={partner.id}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group widths={'equal'}>
              <Form.Input
                label="파트너명"
                name="name"
                value={partner.name}
                onChange={this.handleChange}
              />
              <Form.TextArea
                label="파트너설명"
                name="description"
                value={partner.description}
                onChange={this.handleChange}
              />
            </Form.Group>
          </Form>
        </Container>


        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'태그'} dividing/>
          <Button
            onClick={() => {
              partner.tags.push("");
              this.setState({
                partner
              });
            }}>
            태그 추가
          </Button>
          <Form>
            {
              partner.tags.map((tag, i) => {
                return (
                  <Form.Input
                    key={i}
                    name={`tags${i}`}
                    value={tag}
                    onChange={e => {
                      partner.tags[i] = e.target.value;
                      this.setState({
                        partner
                      });
                    }}
                  >
                    <input/>
                    <Button
                      icon
                      onClick={() => {
                        const a = window.confirm('삭제하시겠습니까?');
                        if (!a) return;
                        partner.tags.splice(i, 1);
                        this.setState({
                          partner
                        })
                      }}>
                      <Icon name={'close'}/>
                    </Button>
                  </Form.Input>
                );
              })}
          </Form>
        </Container>
        <Container as={Segment} vertical basic style={styles.verticalModule}>
          <Header content={'배경 및 사진'} dividing/>
          <Form>
            <Form.Group>
              <Form.Input
                label={'배경 색'}
                name={'backgroundColor'}
                value={partner.backgroundColor}
                onChange={this.handleChange}
              />
              <Form.Input
                label={'텍스트 색'}
                name={'textColor'}
                value={partner.textColor}
                onChange={this.handleChange}
              />
              <Form.Input
                label={'이름 색'}
                name={'nameColor'}
                value={partner.nameColor}
                onChange={this.handleChange}
              />
              <Form.Input
                label={'설명 색'}
                name={'descriptionColor'}
                value={partner.descriptionColor}
                onChange={this.handleChange}
              />
            </Form.Group>
          </Form>

          <Divider />

          <Grid>
            <Grid.Row columns={6}>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  MAIN
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          partner.imageUrl = imageUrl;
                          this.setState({
                            partner
                          })
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(partner.imageUrl)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    partner.imageUrl = '';
                    this.setState({
                      partner
                    })
                  }}/>
                <Image src={partner.imageUrl || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  배경
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          partner.backgroundImage = imageUrl;
                          this.setState({
                            partner
                          })
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(partner.backgroundImage)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    partner.backgroundImage = '';
                    this.setState({
                      partner
                    })
                  }}/>
                <Image src={partner.backgroundImage || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  배경 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          partner.backgroundImageLarge = imageUrl;
                          this.setState({
                            partner
                          })
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(partner.backgroundImageLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    partner.backgroundImageLarge = '';
                    this.setState({
                      partner
                    })
                  }}/>
                <Image src={partner.backgroundImageLarge || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>


              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  profile
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          partner.profileImage = imageUrl;
                          this.setState({
                            partner
                          })
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(partner.profileImage)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    partner.profileImage = '';
                    this.setState({
                      partner
                    })
                  }}/>
                <Image src={partner.profileImage || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>

              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  topBanner
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          partner.topBannerImage = imageUrl;
                          this.setState({
                            partner
                          })
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(partner.topBannerImage)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    partner.topBannerImage = '';
                    this.setState({
                      partner
                    })
                  }}/>
                <Image src={partner.topBannerImage || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  squareBanner
                  <FileUploader
                    hidden
                    accept="image/*"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={async filename => {
                      await firebase.storage()
                        .ref(storageRef)
                        .child(filename)
                        .getDownloadURL()
                        .then(imageUrl => {
                          partner.squareBannerImage = imageUrl;
                          this.setState({
                            partner
                          })
                        })
                        .catch(e => {
                          console.error(e);
                        })
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(partner.backgroundImageLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    partner.squareBannerImage = '';
                    this.setState({
                      partner
                    })
                  }}/>
                <Image src={partner.squareBannerImage || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>

            </Grid.Row>
            <Grid.Row columns={6}>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  정사각
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="square"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'square');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(partner.image && partner.image.square)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    partner.image.square = '';
                    this.setState({
                      partner
                    })
                  }}/>
                <Image src={(partner.image && partner.image.square) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  가로
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="horizontal"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'horizontal');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(partner.image && partner.image.horizontal)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    partner.image.horizontal = ''
                    this.setState({
                      partner
                    })
                  }}/>
                <Image src={(partner.image && partner.image.horizontal) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  세로
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="vertical"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'vertical');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(partner.image && partner.image.vertical)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    partner.image.vertical = ''
                    this.setState({
                      partner
                    })
                  }}/>
                <Image src={(partner.image && partner.image.vertical) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  정사각 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="imageSquareLarge"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'squareLarge');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(partner.image && partner.image.squareLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    partner.image.squareLarge = ''
                    this.setState({
                      partner
                    })
                  }}/>
                <Image src={(partner.image && partner.image.squareLarge) || mockUpImages.planeImage} size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  가로 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="imageHorizontalLarge"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'horizontalLarge');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(partner.image && partner.image.horizontalLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    partner.image.horizontalLarge = '';
                    this.setState({
                      partner
                    })
                  }}/>
                <Image src={(partner.image && partner.image.horizontalLarge) || mockUpImages.planeImage}
                       size={'medium'}/>
              </Grid.Column>
              <Grid.Column>
                <Button as={'label'} size={'mini'}>
                  세로 Large
                  <FileUploader
                    hidden
                    accept="image/*"
                    name="imageVerticalLarge"
                    randomizeFilename
                    storageRef={firebase.storage().ref(storageRef)}
                    onUploadSuccess={filename => {
                      this.handleUploadSuccess(filename, 'verticalLarge');
                    }}
                  />
                </Button>
                <Button
                  size={'mini'}
                  disabled={!(partner.image && partner.image.verticalLarge)}
                  content={'삭제'}
                  negative
                  onClick={() => {
                    partner.image.verticalLarge = ''
                    this.setState({
                      partner
                    })
                  }}/>
                <Image src={(partner.image && partner.image.verticalLarge) || mockUpImages.planeImage}
                       size={'medium'}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

const styles = {
  verticalModule: {
    marginBottom: 50,
    marginTop: 50
  },
  header: {
    marginTop: 40,
  }
};

const enhance = connect(
  state => ({
    auth: state.auth
  }),
  {
    ...authActionCreators
  }
);
export default enhance(AdminPartnerDetailContainer);
